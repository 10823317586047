import styled from 'styled-components';

export const Container = styled.div`
    margin: ${({ $mt }) => $mt || '5px'} 0 42px 0;
    position: relative;
    width: 100%;
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 16px;

    /* overflow-x: auto; */
    margin-top: 12px;
    scrollbar-width: none;

    /*Webkit(Chrome, Android browser, Safari, Edge...) */
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Heading = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${({ align }) => (align ? 'space-between' : 'center')};
    align-items: center;
    margin-bottom: 12px;

    span {
        display: flex;
    }
`;
