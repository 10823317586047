import React from 'react';
import * as IconFeather from 'react-feather';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import UrlMapper from '@/config/url-mapper';
import { Button, Icon, Typography } from '@/components/atoms';
import { WhatsappButtonProps } from './WhatsappButton.types';
import { ButtonContainer, CloseTooltips, Tooltips } from './WhatsappButton.styles';

const timeToShow = parseInt(process.env.NEXT_PUBLIC_TIME_TO_SHOW_WHATSAPP_BUTTON as string) || 180000;

const WhatsappButton: React.FC<WhatsappButtonProps> = ({ showOnScroll, scrollOffset }) => {
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme
    const [showTooltip, setShowTooltip] = React.useState(true);
    const [offset, setOffset] = React.useState(0);

    // Show button for interval
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!showTooltip) setShowTooltip(true);
        }, timeToShow);
        return () => clearInterval(interval);
    }, [showTooltip]);

    React.useEffect(() => {
        const handleScroll = () => {
            setOffset(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <ButtonContainer id="whatsapp-button" show={scrollOffset ? offset > scrollOffset || !showOnScroll : false}>
            <Tooltips show={showTooltip}>
                <Typography variant="cap" align="center" style={{ padding: '8px 20px', display: 'flex', alignItems: 'center' }}>
                    {t('common:whatsapp-button.need-help')}
                </Typography>
                <CloseTooltips onClick={() => setShowTooltip(false)}>
                    <IconFeather.X size={12} color={theme.colors.neutral.neutral10} strokeWidth={2} />
                </CloseTooltips>
            </Tooltips>
            <Button
                as="a"
                content={<Icon.Whatsapp size={30} color={theme.colors.neutral.neutral10} strokeWidth={2} />}
                variant="filled"
                type="button"
                rounded
                width="60px"
                selected
                role="button"
                aria-label="Whatsapp"
                target="_blank"
                href={UrlMapper.whatsapp_agents}
                rel="noopener noreferrer"
                onMouseEnter={() => setShowTooltip(true)}
                style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)' }}
            />
        </ButtonContainer>
    );
};

WhatsappButton.defaultProps = {
    showOnScroll: true,
    scrollOffset: 570,
};

export default WhatsappButton;
