import styled, { css } from 'styled-components';

export const Seats = styled.div`
    z-index: 2;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
export const MapContainer = styled.div`
    padding-top: 80px;
    position: relative;
`;
export const Header = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`;

export const AirlineContainer = styled.div`
    top: -70px;
    position: absolute;
    transform: scale(0.4);
`;

export const MapSeats = styled.div`
    padding-top: 20px;
    width: 98px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
`;

export const Section = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
`;

export const Card = styled.span`
    width: 12px;
    height: 12px;
    border-radius: 3px;

    ${({ status }) =>
        status === 1
            ? css`
                  background-color: ${({ theme }) => theme.colors.neutral.neutral80};
              `
            : status === 2
            ? css`
                  background-color: ${({ theme }) => theme.colors.tertiary.tertiary70};
              `
            : status === 3 &&
              css`
                  background-color: yellow;
              `}
`;

export const Legend = styled.ul`
    margin: 0;
    padding: 15px 0 0;
    list-style: none;

    li {
        padding-top: 5px;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    li > span {
        margin-right: 5px;
    }
`;
