import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const WrapperContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 26px 16px;

    ${({ theme }) => theme.media.md`
       padding: 26px 24px;
    `}

    ${({ theme }) => theme.media.lg`
       padding: 29px 45px;
       width: 90%;
       margin: 0 auto;
    `}

  ${({ theme }) => theme.media.xl`
        padding: 32px 30px 30px;
    `}

  ${({ theme }) => theme.media.xxl`
        width: 100%;
    `}
`;

export const ContainerDropdown = styled.div`
    width: 150px;
    padding: 16px 0 20px 15px;
`;

export const CustomForm = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ theme }) => theme.media.xxl`
        flex-direction: row;
        gap: 18px;
    `}
`;

export const RowHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-left: 10px;
`;

export const RowContainer = styled.div``;

export const Row1 = styled.div`
    display: flex;
    position: relative;
    width: 100%;

    ${({ theme }) => theme.media.md`
        & > div {
            display: flex;
            width: 100%;
        }
    `}

    ${({ theme }) => theme.media.xxl`
            width: 40%;
    `}
`;

export const Row2 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;

    ${({ theme }) => theme.media.md`
        display: grid;
        grid-template-columns: 1fr 1fr 50px;
        gap: 16px;
    `}

    ${({ theme }) => theme.media.xxl`
        width: 60%;
        gap: 18px;
    `}
`;

export const ElementDatePicker = styled.div`
    display: flex;
    position: relative;

    .react-datepicker-popper {
        width: 190%;
        max-width: 350px;
    }
`;

export const ElementPassenger = styled.div`
    margin-bottom: 30px;

    & > div {
        padding: 0;
        left: 0;
    }
`;

export const ElementButton = styled.div`
    .compact {
        display: none;

        ${({ theme }) => theme.media.md`
           display: block;
        `}
    }

    .full {
        display: block;

        ${({ theme }) => theme.media.md`
           display: none;
        `}
    }
`;

export const ElementFilter = styled.div`
    width: 100%;

    ${({ theme }) => theme.media.md`
        & > div {
            width: 50%;
        }
    `}

    #form-group-origin {
        margin-bottom: 0;
    }

    #form-group-destination {
        margin-bottom: 30px;
    }

    #origin {
        border-radius: 24px 24px 0 0;

        ${({ theme }) => theme.media.md`
            border-radius: 24px 0 0 24px;
        `}

        &.focused {
            ${({ theme }) => theme.media.md`
                border-radius: 24px 0 0 0;
            `}
        }
    }

    #destination {
        border-radius: 0 0 24px 24px;

        ${({ theme }) => theme.media.md`
            border-radius: 0 24px 24px 0;
        `}

        &.focused {
            ${({ theme }) => theme.media.md`
                border-radius: 0 24px 0 0;
            `}
        }
    }
`;

export const Element = styled.div`
    width: 100%;
`;

export const InvertButton = styled.button.withConfig({
    shouldForwardProp: (prop) => !['rotation'].includes(prop),
})<{ rotation: number }>`
    width: 28px;
    height: 28px;
    top: 35px;
    right: 56px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border: 2px solid ${({ theme }) => theme.colors.neutral.neutral96};
    background: ${({ theme }) => theme.colors.basic.white};
    border-radius: 18px;

    &:hover {
        cursor: pointer;
    }

    svg {
        transition: all 0.5s ease-in-out;
        transform: rotate(${({ rotation }) => rotation}deg);
    }

    ${({ theme }) => theme.media.md`
       top: 12px;
       left: calc(50% - 14px);
    `}
`;

export const Selector = styled.div`
    display: flex;
    justify-content: space-between;

    svg {
        margin-left: 5px;
    }
`;

export const OptionContainer = styled.ul`
    list-style: none;
    padding-inline-start: 0;
    width: 200px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    margin: 0;
`;

export const Option = styled.li.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{ isActive: boolean }>`
    display: flex;
    padding: 14px 16px;
    white-space: nowrap;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary.primary90};
    }

    &:active {
        background: ${({ theme }) => theme.colors.primary.primary70};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme }) => theme.colors.primary.primary50};
        `}
`;
