import React from 'react';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/atoms';
import { FlightPaymentProps } from './FlightSummary.types';
import { InnerWrapper } from './FlightSummary.styles';

const FlightSummary: React.FC<FlightPaymentProps> = () => {
    const { t } = useTranslation(); // Translations

    return (
        <InnerWrapper>
            <Typography variant="h6">{t('flights:payment-title')}</Typography>
            <Typography variant="body2">{t('flights:payment-info')}</Typography>
        </InnerWrapper>
    );
};

export default FlightSummary;
