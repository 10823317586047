import styled from 'styled-components';

export const Container = styled.div`
    width: 100% !important;
    overflow: hidden;

    ${({ theme }) => theme.media.xs`
    margin-left: 0;
    margin-right: inherit;
    `}
`;

export const ChipContainer = styled.div`
    width: 20%;
`;

export const Summary = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 12px;
    margin-top: 24px;
    width: 100%;

    ${({ theme }) => theme.media.md`
        width: 60% ;  
    `}
`;

export const MapContainer = styled.div`
    padding-top: 12px;
    width: 100%;

    ${({ theme }) => theme.media.md`
        width: 60% ;  
    `}

    & > div {
        border-radius: 20px;
    }
`;

export const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 6px;
`;

export const Frame2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    gap: 12px;
`;

export const ListItems = styled.ul`
    columns: 1;
    column-gap: 4px;
    padding: 0;
    margin: 0;
    width: 60%;

    & > li {
        break-inside: avoid;
    }

    ${({ theme }) => theme.media.md`
    
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2;
        column-gap: 20px;
    `}
`;

export const Item = styled.li`
    display: flex;
    flex-direction: row;
    padding: 0;
    gap: 8px;
`;

export const TabContainer = styled.div`
    display: flex;
    width: 60%;
    background-color: white;
    align-items: stretch;
`;

export const TabItems = styled.div`
    background-color: white;
    padding: 8px;
    cursor: pointer;
`;
