import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Dropdown, Icon, Typography } from '@/components/atoms';
import { UserDropdownProps } from './UserDropdown.types';
import { IconUserOptions, Option, OptionsContainer } from './UserDropdown.styles';

const UserDropdown: React.FC<UserDropdownProps> = ({ id, items, status }) => {
    const { t } = useTranslation(); // Translations

    return (
        <Dropdown
            id={id}
            triggerLabel={t('common:user-dropdown.label')}
            trigger={
                <IconUserOptions>
                    <Icon.User size={28} />
                </IconUserOptions>
            }
        >
            <OptionsContainer>
                {items
                    .filter((opt) => opt.visible(status))
                    .map((opt) => (
                        <Option key={opt.key}>
                            {opt.href && (
                                <Link href={opt.href} passHref>
                                    <Typography as="span" variant="sub2">
                                        {opt.text}
                                    </Typography>
                                </Link>
                            )}
                            {opt.onClick && (
                                <Typography as="a" variant="sub2" onClick={opt.onClick}>
                                    {opt.text}
                                </Typography>
                            )}
                        </Option>
                    ))}
            </OptionsContainer>
        </Dropdown>
    );
};

export default UserDropdown;
