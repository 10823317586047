export const basic: { [key: string]: string} = {
    white: '#FFFFFF',
    black: '#000000',
    transparent: 'transparent',
};

export const primary: { [key: string]: string} = {
    primary95: '#FFF8E6',
    primary90: '#FEF1CC',
    primary80: '#FEE399',
    primary70: '#FDD566',
    primary60: '#FDC733',
    primary50: '#FCB900',
    primary40: '#CA9400',
    primary30: '#976F00',
    primary20: '#654A00',
    primary10: '#322500',
};

export const secondary: { [key: string]: string} = {
    secondary95: '#FFF3E6',
    secondary90: '#FFE6CC',
    secondary80: '#FFCE99',
    secondary70: '#FEB566',
    secondary60: '#FE9D33',
    secondary50: '#FE8400',
    secondary40: '#CB6A00',
    secondary30: '#984F00',
    secondary20: '#663500',
    secondary10: '#331A00',
};

export const tertiary: { [key: string]: string} = {
    tertiary95: '#EBEBF4',
    tertiary90: '#D6D7E9',
    tertiary80: '#ACB0D2',
    tertiary70: '#8388BC',
    tertiary60: '#5961A5',
    tertiary50: '#30398F',
    tertiary40: '#262E72',
    tertiary30: '#1D2256',
    tertiary20: '#131739',
    tertiary10: '#0A0B1D',
};

export const success: { [key: string]: string} = {
    success95: '#ECF3EA',
    success90: '#D9E8D5',
    success80: '#B2D2AC',
    success70: '#8CBB82',
    success60: '#65A559',
    success50: '#3F8E2F',
    success40: '#327226',
    success30: '#26551C',
    success20: '#193913',
    success10: '#0D1C09',
};

export const error: { [key: string]: string} = {
    error95: '#F9E7E7',
    error90: '#F4CFCF',
    error80: '#E99F9F',
    error70: '#DE6F6F',
    error60: '#D33F3F',
    error50: '#C80F0F',
    error40: '#A00C0C',
    error30: '#780909',
    error20: '#500606',
    error10: '#280303',
};

export const neutral: { [key: string]: string} = {
    neutral98: '#FAFAFA',
    neutral96: '#F5F5F5',
    neutral94: '#EFEFEF',
    neutral92: '#EAEAEA',
    neutral90: '#E5E5E5',
    neutral80: '#CCCCCC',
    neutral70: '#B2B2B2',
    neutral60: '#999999',
    neutral50: '#808080',
    neutral40: '#666666',
    neutral30: '#4D4D4D',
    neutral20: '#343434',
    neutral10: '#1E1E1E',
};

export const hex2Rgba = (hex: string, alpha: number | null = null): string => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
};
