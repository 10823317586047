import React from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
// import Cookies from 'js-cookie';
import { withRecaptcha } from '@/lib/recaptcha';
import useNavigations from '@/modules/navigations';
import { useSessionCookie } from '@/modules/session-cookie';
import { WhatsappButton } from '@/components/molecules';
import { Header, Footer, Sidebar } from '@/components/organisms';
import { MainLayoutProps } from './MainLayout.types';
import { Page } from "./MainLayout.styles";
import { useModal } from '@/modules/modal';
// import { ModalNewsletterForm } from '@/forms/newsletters-form';

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { navigations } = useNavigations();
    const [activeItem, setActiveItem] = React.useState('');

    const { sessionID, setSessionID, removeSessionID } = useSessionCookie();
    const { data: session, status } = useSession(); // Get session status
    const { setModal } = useModal(); // Modal

    // States
    const [width, setWidth] = React.useState(0);
    const [loading] = React.useState(false);
    const [sidebarIsOpen, setSidebarIsOpen] = React.useState(false);

    // User is Subscribed
    const userIsSubscribed = session?.user?.isSubscribed;

    // Router
    const router = useRouter();

    // Update activeItem everytime the url changes
    React.useEffect(() => {
        let currentPath = navigations.main.find((path) => path.href === router.pathname);

        if (!currentPath) currentPath = navigations.main.find((path) => router.pathname.includes(path.key));

        if (currentPath) setActiveItem(currentPath.key);
    }, [navigations.main, router.pathname]);

    React.useEffect(() => {
        setSessionID();
    }, [setSessionID]);

    // Sidebar resize handler
    React.useEffect(() => {
        const resizeHandler = (e: { preventDefault: () => void }) => {
            e.preventDefault();

            if (width !== window.innerWidth) {
                setWidth(window.innerWidth);

                if (width > 991) {
                    setSidebarIsOpen(false);
                }
            }
        };

        window.addEventListener('resize', resizeHandler);
        setWidth(window.innerWidth);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [width]);

    // Show newsletters modal
    // React.useEffect(() => {
    //     const modalShownToday = Cookies.get('modalShownDate') === new Date().toLocaleDateString();
    //     if (!userIsSubscribed) setModal(<ModalNewsletterForm />);
        // if (!modalShownToday) {
        //    setTimeout(()=>{
        //        // Show the modal if it hasn't been shown today
        //        if (!userIsSubscribed) setModal(<ModalNewsletterForm />);
        //
        //        // Set a cookie with today's date, to indicate that it has been displayed.
        //        const expirationDate = new Date();
        //        expirationDate.setHours(expirationDate.getHours() + (process.env.NEXT_PUBLIC_NEWSLETTER_MODAL_EXPIRATION_IN_HOURS ? parseInt(process.env.NEXT_PUBLIC_NEWSLETTER_MODAL_EXPIRATION_IN_HOURS) : 0));
        //        Cookies.set('modalShownDate', new Date().toLocaleDateString(), { expires: expirationDate });
        //    }, (process.env.NEXT_PUBLIC_NEWSLETTER_MODAL_DELAY_IN_MILLISECONDS ? parseInt(process.env.NEXT_PUBLIC_NEWSLETTER_MODAL_DELAY_IN_MILLISECONDS) : 5000))
        // }
    // }, [userIsSubscribed]);

    const handleToggleSidebar = () => {
        document.body.style.overflow = !sidebarIsOpen ? 'hidden' : 'inherit';
        setSidebarIsOpen(!sidebarIsOpen);
    };

    return (
        <Page id="outer-container" showing={!loading}>
            {sidebarIsOpen && <Sidebar isOpen={sidebarIsOpen} handleToggleSidebar={handleToggleSidebar} activeItem={activeItem} />}

            <Header status={status} handleToggleSidebar={handleToggleSidebar} activeItem={activeItem} />

            <main id="page-wrap">{children}</main>

            <Footer />

            <WhatsappButton />
        </Page>
    );
};

export default withRecaptcha(MainLayout);
