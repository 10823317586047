const UrlMapper = {
    'forgot-password': {
        es: '/recuperar-contrasena',
        en: '/forgot-password',
    },
    'forgot-verification': {
        es: '/recuperar-contrasena/verificacion',
        en: '/forgot-password/verification',
    },
    'recover-success': {
        es: '/recuperar-contrasena/satisfactorio',
        en: '/forgot-password/success',
    },
    'jobs-apply': {
        en: '/jobs/apply',
        es: '/empleos/aplicar',
    },
    'jobs/[slug]': {
        es: '/empleos',
        en: '/jobs',
    },
    'jobs/apply/[slug]': {
        es: '/empleos',
        en: '/jobs',
    },
    jobs: {
        es: '/empleos',
        en: '/jobs',
    },
    signup: {
        es: '/registrarse',
        en: '/signup',
    },
    'signup-verification': {
        es: '/registrarse/verificacion',
        en: '/signup/verification',
    },
    'signup-activate': {
        es: '/registrarse/activacion',
        en: '/signup/activate',
    },
    'document-verification': {
        es: '/verificacion',
        en: '/verification',
    },
    'document-download': {
        es: '/verificacion/descarga',
        en: '/verification/download',
    },
    'verify-email': {
        es: '/verificacion-correo',
        en: '/verify-email',
    },
    'about-us': {
        es: '/sobre-nosotros',
        en: '/about-us',
    },
    'cookies-policy': {
        es: '/politica-de-cookies',
        en: '/cookies-policy',
    },
    root: {
        es: '/',
        en: '/',
    },
    login: {
        es: '/acceder',
        en: '/login',
    },
    'pay-verification': {
        es: '/verificacion-de-pago',
        en: '/pay-verification',
    },
    'privacy-policy': {
        es: '/politica-de-privacidad',
        en: '/privacy-policy',
    },
    'return-policy': {
        es: '/politica-de-devoluciones',
        en: '/return-policy',
    },
    'terms-and-conditions': {
        es: '/terminos-y-condiciones',
        en: '/terms-and-conditions',
    },
    'user-profile': {
        es: '/perfil-de-usuario',
        en: '/user-profile',
    },
    flights: {
        es: '/vuelos',
        en: '/flights',
    },
    destinations: {
        es: '/destinos',
        en: '/destinations',
    },
    'destinations/[slug]': {
        es: '/destinos',
        en: '/destinations',
    },
    'destinations-experiences': {
        es: '/destinos/experiencias',
        en: '/destinations/experiences',
    },
    'destinations/experiences/book': {
        es: '/destinos/experiencias/reservar',
        en: '/destinations/experiences/book',
    },
    'destinations/experiences/[slug]': {
        es: '/destinos/experiencias',
        en: '/destinations/experiences',
    },
};

export default UrlMapper;
