import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

// Config
import { reserveLink } from 'config/experiences';

// Utils
import { currencyFormatter } from 'utils/helpers';

// Atoms
import { Button, Typography } from 'components/atoms';

// Styles
import { Table, TableHead, TableBody, TableRow, TableHeadCell, TableCell } from './PricingTable.styles';

const PricingTable = ({ variants }) => {
    // Hooks
    const { t } = useTranslation(); // Translations

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeadCell>
                        <Typography type="h6" $align="left">
                            {t('experiences:pricing-table.label.origin')}
                        </Typography>
                    </TableHeadCell>
                    <TableHeadCell>
                        <Typography type="h6" $align="center">
                            {t('experiences:pricing-table.label.price')}
                        </Typography>
                    </TableHeadCell>
                    <TableHeadCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {variants.map((item) => {
                    return (
                        <TableRow key={item.origin.name}>
                            <TableCell>
                                <Typography type="sub1" $align="left">
                                    {item.origin.name}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography type="body1" $align="center">
                                    {currencyFormatter.format(item.price)}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Button
                                    as="a"
                                    content={t('experiences:pricing-table.button.reserve')}
                                    variant="filled"
                                    width="100%"
                                    target="_blank"
                                    href={reserveLink}
                                    rel="noopener noreferrer"
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

PricingTable.propTypes = {
    variants: PropTypes.array,
};

export default PricingTable;
