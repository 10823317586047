import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { CitiesQuery, CityType } from '@/graphql/types';
import { CITIES_QUERY } from '@/graphql/queries';

export const useCities = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<CitiesQuery>(CITIES_QUERY, {
        variables: {
            report: true,
        },
        context: { headers: { 'X-Language': i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { cities: data?.cities?.objects as CityType[], loading, error };
};
