import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Button, Typography } from '@/components/atoms';
import { VideoWrapper } from '@/components/molecules';
import { AboutUsProps } from './AboutUs.types';
import { Wrapper, TextWrapper } from './AboutUs.styles';

const AboutUs: React.FC<AboutUsProps> = ({ video_url, image_preview, label }) => {
    const { t } = useTranslation(); // Translations
    const router = useRouter(); // Router instance

    return (
        <Wrapper>
            <TextWrapper>
                <Typography variant="h1" mb={28}>
                    {t('about-us:section.title')}
                </Typography>
                <Typography variant="body1" align="justify" mb={40}>
                    {t('about-us:section.description')}
                </Typography>
                <Button
                    content={t('about-us:button.contact')}
                    variant="filled"
                    type="button"
                    width="180px"
                    onClick={() => {
                        void router.push('#contact', undefined, { shallow: true });
                    }}
                />
            </TextWrapper>

            <VideoWrapper video_url={video_url} image_preview={image_preview} label={label} />
        </Wrapper>
    );
};

export default AboutUs;
