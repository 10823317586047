import React from 'react';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import { ThemeProvider } from 'styled-components';
import { SessionProvider } from 'next-auth/react';
import { ModalProvider } from '@/modules/modal';
import { ThemeProviderContext } from '@/modules/theme';
import { GlobalStateProvider } from '@/modules/global-state';
import { SessionCookieProvider } from '@/modules/session-cookie';
import GlobalStyle from '@/styles/globals';
import defaultTheme from '@/themes/default';
import SEO from '@/config/next-seo';
import { LayoutProps } from './Layout.types';

// Environment variables
const env = process.env.NEXT_PUBLIC_ENVIRONMENT as EnvironmentType;
const assetPrefix = process.env.ASSET_PREFIX;

const Layout: React.FC<LayoutProps> = ({ children, session }) => {
    const { i18n } = useTranslation();     // Translations

    const lang = i18n.language as LanguageType;

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />

                {/* Pinterest */}
                <meta name="p:domain_verify" content={process.env.NEXT_PUBLIC_PINTEREST_KEY} />

                {/* Google Search Console */}
                <meta name="google-site-verification" content={process.env.NEXT_PUBLIC_GOOGLE_SEARCH_CONSOLE} />

                {/* Opengraph */}
                <meta name="application-name" content={SEO[env][lang].openGraph.site_name} />
                <meta name="apple-mobile-web-app-title" content={SEO[env][lang].openGraph.site_name} />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="mobile-web-app-capable" content="yes" />

                {/* Windows icons */}
                <meta name="msapplication-config" content={`${assetPrefix}/browserconfig.xml`} />
                <meta name="msapplication-TileColor" content="#272d33" />
                <meta name="msapplication-TileImage" content={`${assetPrefix}/images/icons/mstile-144x144.png`} />
                <meta name="msapplication-tap-highlight" content="no" />
                <meta name="theme-color" content="#272d33" />

                {/* Android */}
                <link rel="icon" type="image/png" sizes="192x192" href={`${assetPrefix}/images/icons/android-chrome-192x192.png`} />

                {/* Apple icons */}
                <link rel="apple-touch-icon" sizes="57x57" href={`${assetPrefix}/images/icons/apple-touch-icon-57x57.png`} />
                <link rel="apple-touch-icon" sizes="60x60" href={`${assetPrefix}/images/icons/apple-touch-icon-60x60.png`} />
                <link rel="apple-touch-icon" sizes="72x72" href={`${assetPrefix}/images/icons/apple-touch-icon-72x72.png`} />
                <link rel="apple-touch-icon" sizes="76x76" href={`${assetPrefix}/images/icons/apple-touch-icon-76x76.png`} />
                <link rel="apple-touch-icon" sizes="114x114" href={`${assetPrefix}/images/icons/apple-touch-icon-114x114.png`} />
                <link rel="apple-touch-icon" sizes="120x120" href={`${assetPrefix}/images/icons/apple-touch-icon-120x120.png`} />
                <link rel="apple-touch-icon" sizes="144x144" href={`${assetPrefix}/images/icons/apple-touch-icon-144x144.png`} />
                <link rel="apple-touch-icon" sizes="152x152" href={`${assetPrefix}/images/icons/apple-touch-icon-152x152.png`} />
                <link rel="apple-touch-icon" sizes="180x180" href={`${assetPrefix}/images/icons/apple-touch-icon-180x180.png`} />

                {/* Favicon */}
                <link rel="icon" type="image/png" sizes="32x32" href={`${assetPrefix}/images/icons/favicon-32x32.png`} />
                <link rel="icon" type="image/png" sizes="16x16" href={`${assetPrefix}/images/icons/favicon-16x16.png`} />
                <link rel="icon" href={`${assetPrefix}/favicon.ico`} />

                {/* Manifest */}
                <link rel="manifest" href={`${assetPrefix}/site.${env}.webmanifest`} />
            </Head>

            <SessionProvider session={session} refetchInterval={parseInt(process.env.SESSION_KEEP_ALIVE || '36000')}>
                {/* SEO Config */}
                <DefaultSeo
                    {...SEO[env][lang]}
                    openGraph={{
                        locale: i18n.language,
                    }}
                    languageAlternates={[
                        {
                            hrefLang: i18n.language,
                            href: `${SEO[env][lang].canonical}${lang}`,
                        },
                    ]}
                />

                {/* Global Styles */}
                <GlobalStyle theme={defaultTheme} />

                {/* Content */}
                <ThemeProviderContext.Provider value={defaultTheme}>
                    <ThemeProvider theme={defaultTheme}>
                        <SessionCookieProvider>
                            <GlobalStateProvider>
                                <ModalProvider>{children}</ModalProvider>
                            </GlobalStateProvider>
                        </SessionCookieProvider>
                    </ThemeProvider>
                </ThemeProviderContext.Provider>
            </SessionProvider>
        </>
    );
};

export default Layout;
