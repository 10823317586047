import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, USER_FRAGMENT } from '@/graphql/fragments';

export const ME = gql`
    ${ERRORS_FRAGMENT}
    ${USER_FRAGMENT}
    query Me {
        me {
            status
            errors {
                ...ErrorType
            }
            object {
                ...UserType
            }
        }
    }
`;

export const VERIFY_FORGOT_PASSWORD_TOKEN = gql`
    query VerifyForgotPassword($email: String!, $token: String!) {
        verifyForgotPassword(token: $token, email: $email) {
            isValid
        }
    }
`;
