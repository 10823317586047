import React from 'react';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import { useRouter } from 'next/router';
import UrlMapper from '@/config/url-mapper';
import { Typography } from '@/components/atoms';
import { JobOffersProps } from './JobOffers.types';
import { AlternativeDialog, Chevron, Table, TableContent, TableRow } from './JobOffers.styles';

const JobOffers: React.FC<JobOffersProps> = ({ offers, isLoading }) => {
    const { t } = useTranslation(); // Translations
    const router = useRouter(); // Router

    const handleOnClick = (slug: string) => {
        void router.push(`${UrlMapper.job_offers}/${slug}`, undefined, { shallow: true });
    };

    if (isLoading) return null;

    if (offers?.length === 0 || null) {
        return (
            <AlternativeDialog id="no-job-offers-available">
                <FeatherIcon.AlertTriangle size={60} strokeWidth={2} />
                <Typography variant="body1" align="center" weight={700} mb={10}>
                    {t('job-offers:job-offers.label.sorry')}
                </Typography>
                <Typography variant="body1" align="center">
                    {t('job-offers:job-offers.label.empty_jobs')}
                </Typography>
            </AlternativeDialog>
        );
    }

    return (
        <Table>
            {offers?.map((offer) => (
                <TableRow key={`offer-${offer.id}`}>
                    <TableContent onClick={() => handleOnClick(offer.slug)}>
                        <Typography variant="body1" weight={600} align="left">
                            {offer.name}
                        </Typography>
                        <Typography variant="body1" align="left">
                            {offer.category.name}
                        </Typography>
                        <Typography variant="body1" align="left">
                            {offer.workingTime}
                        </Typography>
                        <Typography variant="body1" align="left">
                            {`${offer.location} / ${offer.workingMode}`}
                        </Typography>
                    </TableContent>
                    <Chevron>
                        <FeatherIcon.ChevronRight width={40} height={40} strokeWidth={1} />
                    </Chevron>
                </TableRow>
            ))}
        </Table>
    );
};

export default JobOffers;
