import styled, { createGlobalStyle, css } from "styled-components";

export const SwiperGlobalStyles = createGlobalStyle`
  .LoaderSwiper.swiper {
    width: 320px;
  }

  .LoaderSwiper .swiper-wrapper {
    cursor: default !important;
  }

  .LoaderSwiper .swiper-slide {
    background-position: center;
    background-size: cover;
    background-color: transparent;
    width: 120px;
    opacity: 0;
  }

  .LoaderSwiper .swiper-slide svg {
    display: block;
    width: 120px;
  }

  .LoaderSwiper.swiper-3d .swiper-slide-shadow-left {
    background-image: none !important;
  }

  .LoaderSwiper.swiper-3d .swiper-slide-shadow-right {
    background-image: none !important;
  }

  .LoaderSwiper.swiper-3d .swiper-slide-active {
    z-index: 9999;
    opacity: 1;
  }

  .LoaderSwiper.swiper-3d .swiper-slide-prev {
    transform: translate(20px) scale(0.75) !important;
    opacity: 1;
  }

  .LoaderSwiper.swiper-3d .swiper-slide-next {
    transform: translate(-20px) scale(0.75) !important;
    opacity: 1;
  }
`;

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['asComponent'].includes(prop),
})<{ asComponent: boolean }>`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-items: center;

    ${({asComponent}) => asComponent && css`
       margin: 150px 0;
    `}

    ${({asComponent}) => !asComponent && css`
        background: ${({ theme }) => theme.colors.neutral.neutral98};
        height: 100vh;
        position: fixed;
        z-index: 99999;
    `}

    & > div {
        min-width: 200px;
    }
`;
