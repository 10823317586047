import HeadPhonesIcon from 'assets/images/headphones-micro.svg';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Container, CustomForm, Drag, ElementDatePicker, Row } from './ReserveExperience.styles';

// Config
import UrlMapper from '@/config/url-mapper';

// Icons
import * as FeatherIcon from 'react-feather';

// Atoms
import { Button, Icon, Select, Typography } from '@/components/atoms';

//Molecules

import { Datepicker } from '@/components/molecules';

// Hooks
import { useMediaQuery } from '@/modules/media-query';
// import useChangeFormatDate from 'hooks/FormatDate';

// Styles
import { neutral } from '@/styles/colors';

function ReserveExperience({ experience }) {
    // Hooks
    const { t } = useTranslation(); // Translations
    const [frecuency, setFrecuency] = React.useState(null);
    const [availableFrom, setAvailableFrom] = React.useState(null);
    const [openContainer, setOpenContainer] = React.useState(false);

    // Media Queries
    const isResponsive = useMediaQuery('(max-width: 360px)');
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const initialValues = {
        origin: null,
        lodging: '',
        departure: null,
        toReturn: null,
    };

    const availabilyDate = (origin) => {
        const copyOrigins = experience?.origins ? [...experience.origins] : [];
        const element = copyOrigins?.find((elem) => elem?.id === origin);
        if (element?.frecuency?.frequency?.length > 0) {
            const convertDate = element?.frecuency?.frequency?.map((elem) => new Date(elem));
            setFrecuency(convertDate);
        }
        if (element?.availableFrom) setAvailableFrom(new Date(element.availableFrom));
    };

    return (
        <Container open={openContainer}>
            {isResponsive && <Drag onClick={() => setOpenContainer(!openContainer)} />}
            <Typography variant="h6">{t('packages:section.reserve.title')}</Typography>
            {experience ? (
                <Formik enableReinitialize initialValues={initialValues}>
                    {({ errors, values, touched, setFieldValue, setFieldTouched, handleBlur }) => {
                        const reserveDetails =
                            (values.origin ? `Origen: ${values.origin.label}\n` : '') +
                            (values.lodging ? `Alojamiento: ${values.lodging.label}\n` : '') +
                            (values.departure ? `Fecha de salida: ${new Date(values.departure).toLocaleDateString()}\n` : '') +
                            (values.toReturn ? `Fecha de retorno: ${new Date(values.toReturn).toLocaleDateString()}\n` : '');

                        const message =
                            `Hola, quisiera saber si tiene disponibilidad para el paquete turístico ${experience?.name}` +
                            `${reserveDetails.length > 0 ? ' con los siguientes detalles:\n' + reserveDetails : ''}`;

                        const encodeMessage = encodeURIComponent(message);

                        var whatsAppLink = `${UrlMapper.whatsapp_agents}?text=${encodeMessage}`;

                        return (
                            <CustomForm>
                                <Row>
                                    <Select
                                        id="origin"
                                        key={`origin__${JSON.stringify(values.origin)}`}
                                        label={t('packages:section.reserve-label.origin')}
                                        placeholder={t('packages:section.reserve-placeholder.origin')}
                                        defaultValue={values?.origin || null}
                                        leftIcon={<FeatherIcon.MapPin size={24} color={neutral.neutral10} />}
                                        options={experience?.origins?.map((item) => ({
                                            value: item?.id,
                                            label: `${item?.origin?.name}, ${item?.origin?.country?.name} (${item?.origin?.country?.code})`,
                                        }))}
                                        onChange={(option) => {
                                            setFieldValue('origin', option);
                                            setFieldValue('departure', null);
                                            setFieldValue('toReturn', null);
                                            availabilyDate(option.value);
                                        }}
                                        onBlur={() => setFieldTouched('origin', true)}
                                        errors={touched.origin && errors.origin}
                                        success={(touched.origin || values?.origin !== '') && !errors.origin}
                                    />
                                </Row>
                                <Row>
                                    <ElementDatePicker>
                                        <Datepicker
                                            dateFormat="dd/MM/yyyy"
                                            id="departure"
                                            selected={values?.departure}
                                            label={t('packages:section.reserve.departure')}
                                            onChange={(departure) => setFieldValue('departure', departure)}
                                            placeholder={t('packages:section.reserve-placeholder.departure')}
                                            leftIcon={<FeatherIcon.Calendar size={24} color={neutral.neutral10} />}
                                            borderRadius={isDesktop ? '24px 0 0 24px' : '24px'}
                                            includeDates={frecuency}
                                            whitContainer="210px"
                                            height="48px"
                                            minDate={new Date(availableFrom)}
                                            maxDate={values?.toReturn}
                                            startDate={values?.departure}
                                            endDate={values?.toReturn}
                                            onBlur={handleBlur}
                                            errors={touched.departure && errors.departure}
                                            success={(touched.departure || values?.departure !== '') && !errors.departure}
                                        />
                                        <Datepicker
                                            dateFormat="dd/MM/yyyy"
                                            id="toReturn"
                                            selected={values?.toReturn}
                                            label={t('packages:section.reserve.return')}
                                            leftIcon={<FeatherIcon.Calendar size={24} color={neutral.neutral10} />}
                                            borderRadius={isDesktop ? '0 24px 24px 0' : '24px'}
                                            whitContainer="210px"
                                            height="48px"
                                            includeDates={frecuency}
                                            selectsEnd
                                            onChange={(toReturn) => setFieldValue('toReturn', toReturn)}
                                            placeholder={t('packages:section.reserve-placeholder.return')}
                                            startDate={values?.departure}
                                            endDate={values?.toReturn}
                                            minDate={values?.departure || new Date()}
                                            errors={touched.toReturn && errors.toReturn}
                                            onBlur={handleBlur}
                                            success={(touched.toReturn || values?.toReturn !== '') && !errors.toReturn}
                                        />
                                    </ElementDatePicker>
                                </Row>
                                <Row>
                                    <Select
                                        id="lodging"
                                        key={`lodging__${JSON.stringify(values.lodging)}`}
                                        label={t('packages:section.reserve-label.lodging')}
                                        placeholder={t('packages:section.reserve-placeholder.lodging')}
                                        defaultValue={values?.lodging || null}
                                        leftIcon={<Icon.Hotels size={24} color={neutral.neutral10} />}
                                        options={experience?.lodgings?.map((item) => ({ value: item?.id, label: item?.lodging?.name }))}
                                        onChange={(option) => {
                                            setFieldValue('lodging', option);
                                        }}
                                        onBlur={() => setFieldTouched('lodging', true)}
                                        errors={touched.lodging && errors.lodging}
                                        success={(touched.lodging || values?.lodging !== '') && !errors.lodging}
                                    />
                                </Row>
                                <Typography variant="btn" mb="16px" weight={600}>
                                    {t('packages:section.reserve.descripcion1')}
                                    <br />
                                    <Typography variant="btn" weight={300}>
                                        {t('packages:section.reserve.descripcion2')}
                                    </Typography>
                                </Typography>

                                <Button
                                    as="a"
                                    content={t('packages:btn.availability')}
                                    type="button"
                                    icon={<HeadPhonesIcon size={24} strokeWidth={2} />}
                                    iconPosition="left"
                                    width="100%"
                                    role="button"
                                    aria-label="reserve_package"
                                    target="_blank"
                                    href={whatsAppLink}
                                    rel="noopener noreferrer"
                                />
                            </CustomForm>
                        );
                    }}
                </Formik>
            ) : null}
        </Container>
    );
}

ReserveExperience.propTypes = {
    experience: PropTypes.object,
};
export default ReserveExperience;
