import { useTranslation } from 'next-i18next';
import UrlMapper from '@/config/url-mapper';

const useNavigations = () => {
    const { t } = useTranslation(); // Translations

    const common = [
        {
            key: 'home',
            text: t('navigations:home'),
            href: UrlMapper.homepage,
        },
        {
            key: 'destinations',
            text: t('navigations:destinations'),
            href: UrlMapper.destinations,
        },
        {
            key: 'about-us',
            text: t('navigations:about-us'),
            href: UrlMapper.about_us,
        },
        {
            key: 'jobs',
            text: t('navigations:jobs'),
            href: UrlMapper.job_offers,
        },
    ];

    return {
        navigations: {
            main: [...common],
            sidebar: [...common],
            footer: [
                {
                    key: 'sitemap',
                    title: t('navigations:sitemap'),
                    items: [
                        ...common,
                        {
                            key: 'document-verification',
                            text: t('navigations:document-verification'),
                            href: UrlMapper.document_verification,
                        },
                        {
                            key: 'payment-verification',
                            text: t('navigations:payment-verification'),
                            href: UrlMapper.payment_verification,
                        },
                    ],
                },

                {
                    key: 'relevant-information',
                    title: t('navigations:relevant-information'),
                    items: [
                        {
                            key: 'terms-and-conditions',
                            text: t('navigations:terms-and-conditions'),
                            href: UrlMapper.terms_and_conditions,
                        },
                        {
                            key: 'cookies-policy',
                            text: t('navigations:cookies-policy'),
                            href: UrlMapper.cookie_policy,
                        },
                        {
                            key: 'privacy-policy',
                            text: t('navigations:privacy-policy'),
                            href: UrlMapper.privacy_policy,
                        },
                        {
                            key: 'return-policy',
                            text: t('navigations:return-policy'),
                            href: UrlMapper.return_policy,
                        },
                    ],
                },
            ],
        },
    };
};

export default useNavigations;
