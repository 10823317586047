import React from 'react';
import RecaptchaProvider from './RecaptchaProvider';

const withRecaptcha = <P extends object>(Component: React.ComponentType<P>) => {
    return class WithRecaptcha extends React.PureComponent<P> {
        render() {
            return (
                <RecaptchaProvider>
                    <Component {...this.props} />
                </RecaptchaProvider>
            );
        }
    };
};

export default withRecaptcha;
