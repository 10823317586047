import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 56px 0;
    display: flex;
    justify-content: center;
    flex-flow: column;

    ${({ theme }) => theme.media.md`
        padding: 142px 0 126px;
    `}

    ${({ theme }) => theme.media.xl`
        flex-flow: row;
    `}
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 15px 50px;

    ${({ theme }) => theme.media.xl`
       flex-basis: 50%;
       padding: 1rem 2rem 0 0;
       align-items: flex-start;
    `}

    ${({ theme }) => theme.media.xxl`
       flex-basis: 50%;
    `}
`;
