import styled from 'styled-components';

export const Slideshow = styled.div`
    width: 300px;

    ${({ theme }) => theme.media.sm`
        min-width: 380px;
        width: 500px;
    `}

    ${({ theme }) => theme.media.lg`
        width: 800px;
    `}
`;
