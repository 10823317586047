import React from 'react';
import { useTranslation } from 'next-i18next';
import * as Icon from 'react-feather';
import { useTheme } from 'styled-components';
import Moment from 'react-moment';
import FlightsForm from '@/forms/flights-form';
import { Typography, Button } from '@/components/atoms';
import { Section } from '@/components/molecules';
import { SearchSummarySectionProps } from './SearchSummary.types';
import { Header, Row, Item, Content } from './SearchSummary.styles';

const SearchSummary: React.FC<SearchSummarySectionProps> = ({ filters }) => {
    const { t } = useTranslation();
    const theme = useTheme(); // Get current theme
    const [open, setOpen] = React.useState(false);

    const passengersText = () => {
        const passengers = [];

        if (filters.passengers.adults > 0) {
            passengers.push(
              t(`flights:passenger.Adult${filters.passengers.adults > 1 ? '_plural' : ''}`, {
                  count: filters.passengers.adults,
              })
            );
        }

        if (filters.passengers.children > 0) {
            passengers.push(
              t(`flights:passenger.Child${filters.passengers.children > 1 ? '_plural' : ''}`, {
                  count: filters.passengers.children,
              })
            );
        }

        return passengers.join(', ');
    };



    return (
        <>
            <Header>
                <Row>
                    <Item>
                        <Typography variant="body2">
                            <span>{filters.origin.split(' - ')[0]}</span>
                            <Icon.Repeat size={18} color={theme.colors.basic.black} strokeWidth={2} />
                            <span>{filters.destination.split(' - ')[0]}</span>
                        </Typography>
                    </Item>
                    <Item>
                        <Typography variant="body2">
                            <Icon.Calendar size={18} color={theme.colors.basic.black} strokeWidth={2} />
                            <Moment format="DD MMM.">{filters.departure_date}</Moment>
                            {filters.return_date && (
                                <>
                                    {' - '}
                                    <Moment format="DD MMM.">{filters.return_date}</Moment>
                                </>
                            )}
                        </Typography>
                    </Item>
                    <Item>
                        <Typography variant="body2">
                            <Icon.Users size={18} color={theme.colors.basic.black} strokeWidth={2} />
                            {passengersText()}
                        </Typography>
                    </Item>
                </Row>
                <Button content={t('filters:tab.flights.modify')} type="button" variant="filled-tonal" width={'190px'} onClick={() => setOpen(!open)} />
            </Header>
            <Content showContent={open}>
                <Section id="search-form" as="div" container="oversized" bgcolor="transparent" style={{ padding: '0' }}>
                    <FlightsForm refetching />
                </Section>
            </Content>
        </>
    );
};

export default SearchSummary;
