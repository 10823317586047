import styled, { css } from 'styled-components';

export const DropdownZone = styled.div`
    position: relative;
    padding: 0 10px;
    left: 10px;
`;

export const TriggerContainer = styled.div`
    cursor: pointer;
`;

export const DropdownContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['borderRadius', 'visible'].includes(prop),
})<{ borderRadius?: string; visible: boolean }>`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    background: ${({ theme }) => theme.colors.basic.white};
    box-shadow: 0 0 30px rgb(127 137 161 / 25%);
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 15%));
    transition: ease all 0.3s;
    top: calc(100% - 10px);
    opacity: 0;
    visibility: hidden;
    border-radius: ${({ borderRadius }) => borderRadius || '20px'};
    margin-top: 5px;
    padding: 10px 0;

    ${({ visible }) =>
        visible &&
        css`
            opacity: 1;
            top: calc(100% + 10px);
            visibility: visible;
        `}
    &::before {
        top: -20px;
        left: 50%;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 10px solid transparent;
        border-bottom-color: ${({ theme }) => theme.colors.basic.white};
        margin-left: -10px;
        z-index: 99;
    }
`;
