import styled, { css } from 'styled-components';

export const ImageGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 8px;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 380px;

    ${({ theme }) => theme.media.sm`
        grid-gap: 15px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    `}

    ${({ imagesCount }) =>
        imagesCount === 1 &&
        css`
            grid-gap: 0;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(1, 1fr);
        `};

    ${({ imagesCount }) =>
        imagesCount === 2 &&
        css`
            grid-gap: 0 20px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
        `};
`;

export const Info = styled.div`
    position: relative;
    cursor: pointer;

    ${({ first, imagesCount }) =>
        first &&
        imagesCount === 1 &&
        css`
            grid-column: 1 / 4;
        `}

    ${({ first, imagesCount }) =>
        first &&
        imagesCount === 2 &&
        css`
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        `}

    ${({ first, imagesCount }) =>
        first &&
        imagesCount > 2 &&
        css`
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
        `}

    ${({ containMore }) =>
        containMore &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: '';
                background-color: rgba(0, 0, 0, 0.4);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        `}

    .others-img-btn {
        border: none;
        z-index: 2;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        color: ${({ theme }) => theme.colors.basic.white};
    }
`;
