import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral98};
    padding: 25px 20px;
    gap: 20px;
    border-radius: 25px;
    margin-top: 20px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Content = styled.div`
    /* padding-top: 25px; */
    padding-left: 5px;

    li {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
    }
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
