'use client';
import React from 'react';
import styled, { css } from 'styled-components';
import { OptionalTypographyProps } from './Typography.types';
import dynamic from "next/dynamic";

const typeMap: Record<OptionalTypographyProps['variant'], keyof React.JSX.IntrinsicElements> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    sub1: 'p',
    sub2: 'p',
    body1: 'p',
    body2: 'p',
    btn: 'span',
    cap: 'span',
    ovl: 'span',
};

const ForwardRefComponent = React.forwardRef<HTMLElement, OptionalTypographyProps>(function TypographyForwardRef({ variant = 'body1', children, ...props }, ref) {
    return React.createElement(typeMap[variant], { ref, ...props }, children);
});

const Typography = styled(ForwardRefComponent).withConfig({
    shouldForwardProp: (prop) => !['uppercase', 'textDecoration', 'size', 'weight', 'lineheight', 'maxlines', 'align', 'color', 'uppercase', 'mt', 'mb', 'ml', 'mr'].includes(prop),
})<
    Omit<OptionalTypographyProps, 'children'> & {
        as?: keyof React.JSX.IntrinsicElements;
    }
>`
    font-family: ${({ theme, variant }) => theme.font[variant]?.family};
    font-size: ${({ theme, variant }) => theme.font[variant]?.mobile.size};
    font-weight: ${({ theme, variant }) => theme.font[variant]?.mobile.weight};
    line-height: ${({ theme, variant }) => theme.font[variant]?.mobile.lineheight};
    font-style: ${({ theme, variant }) => theme.font[variant]?.mobile.style};
    color: ${({ theme, color }) => color || theme.colors.neutral.neutral10};
    text-align: ${({ align }) => align};
    margin-top: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : mt || '0')};
    margin-bottom: ${({ mb }) => (typeof mb === 'number' ? `${mb}px` : mb || '0')};
    margin-left: ${({ ml }) => (typeof ml === 'number' ? `${ml}px` : ml || '0')};
    margin-right: ${({ mr }) => (typeof mr === 'number' ? `${mr}px` : mr || '0')};

    ${({ theme, variant }) => theme.media.md`
        font-size: ${theme.font[variant]?.tablet.size};
        font-weight: ${theme.font[variant]?.tablet.weight};
        line-height: ${theme.font[variant]?.tablet.lineheight};
        font-style: ${theme.font[variant]?.tablet.style};
    `}

    ${({ theme, variant }) => theme.media.xl`
        font-size: ${theme.font[variant]?.desktop.size};
        font-weight: ${theme.font[variant]?.desktop.weight};
        line-height: ${theme.font[variant]?.desktop.lineheight};
        font-style: ${theme.font[variant]?.desktop.style};
    `}

    ${({ size }) =>
        size &&
        css`
            font-size: ${size} !important;
        `}

    ${({ weight }) =>
        weight &&
        css`
            font-weight: ${weight} !important;
        `}

    ${({ lineheight }) =>
        lineheight &&
        css`
            line-height: ${lineheight} !important;
        `}

    ${({ maxlines }) =>
        maxlines &&
        maxlines > 0 &&
        css`
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: ${maxlines}; /* number of lines to show */
            -webkit-box-orient: vertical;
        `}

    ${({ textDecoration }) =>
        textDecoration &&
        css`
            text-decoration: underline;
            text-decoration-color: ${({ theme }) => theme.colors.primary.primary50};
            text-decoration-thickness: 3px;
            text-underline-position: under;
        `}

    ${({ uppercase }) =>
        uppercase &&
        css`
            text-transform: uppercase;
        `}
`;

Typography.displayName = 'Typography';

Typography.defaultProps = {
    variant: 'body1',
    align: 'left',
    uppercase: false,
};

export default Typography;
