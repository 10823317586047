import styled, { css } from 'styled-components';
import { SelectProps } from './Select.types';

export const FormGroup = styled.div`
    position: relative !important;
    width: 100%;
`;

export const FormLabel = styled.label.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled', 'leftIcon', 'focusSelect'].includes(prop),
})<Omit<SelectProps, 'options'> & { haveErrors?: boolean; focusSelect?: boolean }>`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme, haveErrors, disabled }) => (disabled ? theme.colors.neutral.neutral60 : !haveErrors ? theme.colors.neutral.neutral10 : theme.colors.error.error50)};
    display: block;
    position: absolute;
    top: 15px;
    padding: ${({ leftIcon }) => (leftIcon ? '0 0.2rem 0.6rem 3.4rem' : '0 0.2rem 0.6rem 1.3rem')};
    transition: all 0.25s ease-in-out;
    margin-bottom: 0;
    pointer-events: none;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.mobile.style};
    `}

  ${({ focusSelect, defaultValue, leftIcon }) =>
        (focusSelect || defaultValue) &&
        css`
            transform: translateY(-70%);
            padding: ${leftIcon ? '0.44rem 0.2rem 0 3.4rem' : '0.44rem 0.2rem 0 1.3rem'};
            font-size: ${({ theme }) => theme.font.cap.mobile.size} !important;
            font-weight: ${({ theme }) => theme.font.cap.mobile.weight} !important;
            line-height: ${({ theme }) => theme.font.cap.mobile.lineheight} !important;
            color: ${({ theme }) => theme.colors.tertiary.tertiary50};

            ${({ theme }) => theme.media.md`
                 font-size: ${theme.font.cap.desktop.size} !important;
                 font-weight: ${theme.font.cap.desktop.weight} !important;
                 line-height: ${theme.font.cap.desktop.lineheight} !important;
            `}
        `}
`;

export const FormLeftIcon = styled.div.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled', 'focusSelect'].includes(prop),
})<Omit<SelectProps, 'options'> & { haveErrors?: boolean; focusSelect?: boolean }>`
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    top: 0;
    left: 20px;
    z-index: 2;
    height: 50px;
    width: 24px !important;
    line-height: 38px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;

    svg {
        left: 3px;
        position: absolute;
        color: ${({ theme, haveErrors, disabled, focusSelect }) =>
            disabled ? theme.colors.neutral.neutral60 : !haveErrors || focusSelect ? theme.colors.neutral.neutral10 : theme.colors.error.error50};
        overflow: hidden;
        vertical-align: middle;
    }
`;

export const HelpBlock = styled.span`
    font-family: ${({ theme }) => theme.font.cap.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size} !important;
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight} !important;
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight} !important;
    font-style: ${({ theme }) => theme.font.cap.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral20};
    background-color: transparent !important;
    position: absolute;
    margin-block-start: 0;
    margin-block-end: 0;
    width: 70%;
    padding-top: 2px;
    padding-left: 20px;
    text-align: left;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.tablet.size};
        font-weight: ${theme.font.cap.tablet.weight};
        line-height: ${theme.font.cap.tablet.lineheight};
        font-style: ${theme.font.cap.tablet.style};
    `}

   ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
        font-style: ${theme.font.cap.mobile.style};
    `}
`;

export const ErrorBlock = styled(HelpBlock)`
    color: ${({ theme }) => theme.colors.error.error50};
`;
