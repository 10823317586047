import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import UrlMapper from '@/config/url-mapper';
import { Button, Typography } from '@/components/atoms';
import { ErrorProps } from './Error.types';
import { Buttons } from './Error.styles';

const Error: React.FC<ErrorProps> = ({ image, title, subtitle1, subtitle2 }) => {
    const { t, i18n } = useTranslation(); // Translations
    const router = useRouter(); // Router

    return (
        <>
            {image}
            <Typography as="h1" variant="h3" align="center" mt="30px" mb="15px">
                {title}
            </Typography>
            <Typography variant="body1" align="center" mb="10px">
                {subtitle1}
            </Typography>
            <Typography variant="body1" align="center">
                {subtitle2}
            </Typography>
            <Buttons>
                <Button
                    content={t('error:button.go-to-home')}
                    variant="outlined"
                    type="button"
                    width="220px"
                    onClick={() => {
                        router.push(UrlMapper.homepage).then((r) => {});
                    }}
                />
                <Button
                    content={t('error:button.see-destinations')}
                    variant="filled"
                    type="button"
                    width="220px"
                    onClick={() => {
                        router.push(UrlMapper.destinations).then((r) => {});
                    }}
                />
            </Buttons>
        </>
    );
};

export default Error;
