'use client';
import React from 'react';
import { DropdownProps } from './Dropdown.types';
import { DropdownZone, TriggerContainer, DropdownContainer } from './Dropdown.styles';

const Dropdown: React.FC<DropdownProps> = ({ id, trigger, triggerLabel, children, closeDropdown, alwaysVisible, borderRadius }) => {
    const ref = React.useRef<HTMLDivElement>(null); // Reference
    const [visible, setVisible] = React.useState<boolean>(false);

    const outsideClickHandler = React.useCallback(
        (event: MouseEvent) => {
            if (alwaysVisible) return;
            // Close if click outside the popup
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setVisible(false);
            }
        },
        [alwaysVisible]
    );

    // useEffect for event listener
    React.useEffect(() => {
        // Adding a click event listener
        document.addEventListener('click', outsideClickHandler);

        // Removing a click event listener
        return () => document.removeEventListener('click', outsideClickHandler);
    }, [outsideClickHandler]);

    // useEffect for close dropdown
    React.useEffect(() => {
        if (!closeDropdown) return;

        setVisible(false);
    }, [closeDropdown]);

    return (
        <DropdownZone id={id} ref={ref}>
            <TriggerContainer role="menu" aria-label={triggerLabel}>
                {React.cloneElement(trigger, {
                    onClick: () => {
                        setVisible(!visible);
                    },
                })}
            </TriggerContainer>

            <DropdownContainer visible={visible} borderRadius={borderRadius}>
                {children}
            </DropdownContainer>
        </DropdownZone>
    );
};

export default Dropdown;
