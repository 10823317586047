import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from 'styled-components';

// icon
import * as FeatherIcon from 'react-feather';

// data
import { numAdditional } from 'config/experiences';

// Atoms
import { Typography } from '@/components/atoms';

// Styles
import { Container, Frame2, IconContainer, Item, ListItems, TitleContainer } from './ExperienceBaggage.styles';

const PolicyGroup = ({ baggage, type, numBagg }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme(); // Get current theme

    return (
        <Item>
            {type === 'free' && (
                <>
                    <IconContainer>
                        <FeatherIcon.Check size={20} color={theme.colors.neutral.neutral10} strokeWidth={2} />
                    </IconContainer>
                    <Typography type="body1" $align="left">{`${baggage?.baggage?.qty} ${
                        baggage?.type === 'hand' ? t('packages:baggage-policy.free-hand') : t('packages:baggage-policy.free-invoiced')
                    } ${baggage?.baggage?.weightKg} kg${baggage?.comments !== '' ? `: ${baggage?.comments}` : ''}`}</Typography>
                </>
            )}
            {type === 'additional' && (
                <>
                    <IconContainer>
                        <FeatherIcon.DollarSign size={20} color={theme.colors.neutral.neutral10} strokeWidth={2} />
                    </IconContainer>
                    <Typography variant="body1" $align="left">
                        {baggage?.unlimited
                            ? `${t('packages:baggage-policy.more-additional', { num: numAdditional[numBagg][i18n.language] })} ${baggage?.weightKg} kg: $${Math.round(
                                  baggage?.price
                              )}`
                            : `${numAdditional[numBagg][i18n.language]} ${t('packages:baggage-policy.additional')} ${baggage?.baggage?.weightKg} kg: $${Math.round(
                                  baggage?.price
                              )}`}
                    </Typography>
                </>
            )}
        </Item>
    );
};
const ExperienceBaggage = ({ experience }) => {
    // Translations
    const { t } = useTranslation();
    const [baggageFree, setBaggageFree] = React.useState(false);
    const [listBaggFree, setListBaggFree] = React.useState([]);
    const [listBaggAddit, setListBaggAddit] = React.useState([]);
    const theme = useTheme();
    const hasBaggage = listBaggFree.length > 0 || listBaggAddit.length > 0;

    React.useEffect(() => {
        const arrayFree = experience?.baggage?.filter((item) => item?.type === 'hand' || item?.type === 'invoiced') || [];
        const arrayAdditional = experience?.baggage?.filter((item) => item?.type === 'additional') || [];
        if (arrayFree?.some((item) => item?.comments !== '')) {
            setBaggageFree(true);
        }
        if (experience?.unlimitedBaggage) {
            const unlimitedItem = {
                id: 'unlimited01',
                unlimited: experience?.unlimitedBaggage,
                weightKg: experience?.unlimitedBaggageWeight,
                price: experience?.unlimitedBaggagePrice,
            };
            arrayAdditional.push(unlimitedItem);
        }
        setListBaggFree(arrayFree);
        setListBaggAddit(arrayAdditional);
    }, [experience]);

    return hasBaggage ? (
        <>
            <TitleContainer>
                <Typography variant="h2">{t('packages:experience.baggage.policy')}</Typography>
            </TitleContainer>
            <Container>
                {baggageFree && (
                    <ListItems>
                        <Typography variant="h6" $align="left">
                            {t('packages:section.baggage-policy.free')}
                        </Typography>
                        {listBaggFree?.map((item) => (
                            <PolicyGroup key={item.id} baggage={item} type="free" />
                        ))}
                    </ListItems>
                )}
                {listBaggAddit.length > 0 && (
                    <ListItems>
                        <Typography variant="h6" $align="left">
                            {t('packages:section.baggage-policy.additional')}
                        </Typography>
                        {listBaggAddit?.map((item, index) => (
                            <PolicyGroup key={item.id} baggage={item} type="additional" numBagg={index} />
                        ))}
                    </ListItems>
                )}
            </Container>
            {experience?.baggageComments && (
                <Frame2>
                    <Typography type="body1" $align="left" $color={theme.colors.tertiary.tertiary50}>
                        {experience?.baggageComments}
                    </Typography>
                </Frame2>
            )}
        </>
    ) : null;
};

ExperienceBaggage.propTypes = {
    experience: PropTypes.object,
};

PolicyGroup.propTypes = {
    baggage: PropTypes.object,
    numBagg: PropTypes.number,
    type: PropTypes.oneOf(['free', 'additional']),
};

export default ExperienceBaggage;
