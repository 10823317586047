import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import * as FeatherIcon from 'react-feather';
import { useTranslation } from 'next-i18next';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatCurrency } from '@/utils/helpers';
import { Typography } from '@/components/atoms';

import { FeaturedDestinationProps } from './ExperienceSlider.types';
import { SwiperGlobalStyles, Overlay, Card, CardImage, CardContent, Service, Price } from './ExperienceSlider.styles';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';

const DEFAULT_IMAGE = '/images/default-logo.png';

type FeatherIconName = keyof typeof FeatherIcon;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ExperienceSlider: React.FC<FeaturedDestinationProps> = ({ packages, type = 'newExperiences' }) => {
    const { t } = useTranslation();

    return (
        packages?.length && (
            <Container>
                {type === 'relatedExperiences' && (
                    <Wrapper>
                        <Typography variant="h2" align="center" color="#CB6A00">
                            {t('packages:related-experiences-title')}
                        </Typography>
                        <Typography variant="body1" align="center">
                            {t('packages:related-experiences-descripcion')}
                        </Typography>
                    </Wrapper>
                )}
                <SwiperGlobalStyles />
                <Swiper
                    modules={[Autoplay, Navigation]}
                    navigation
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1400: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                    speed={500}
                    className="PackagesSwiper"
                >
                    {packages?.map((item) => {
                        const principalImage = item?.images?.filter((image) => image?.principal === true)[0];

                        return (
                            <SwiperSlide key={`experience-${item.id}`}>
                                {principalImage && (
                                    <Card
                                    // onClick={() => {
                                    //     void router.push(UrlMapper.destination_details.replace('[slug]', item.slug as string));
                                    // }}
                                    >
                                        <CardImage>
                                            <Image
                                                src={principalImage?.image?.url || DEFAULT_IMAGE}
                                                alt={item?.name as string}
                                                fill
                                                style={{ objectPosition: 'center center', objectFit: 'cover' }}
                                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                            />
                                            <Overlay />
                                        </CardImage>
                                        <CardContent>
                                            <Typography variant="sub1">{item?.name}</Typography>
                                            <Typography variant="body2" maxlines={2} style={{ paddingTop: '10px' }}>
                                                {item?.destination.description}
                                            </Typography>
                                            {item?.services?.map((service, index) => {
                                                return (
                                                    <Service key={service?.id}>
                                                        {service?.service?.iconVariants?.map((icon) => {
                                                            if (icon?.theme === 'react-feather') {
                                                                const iconName = icon?.name as FeatherIconName;
                                                                const DynamicIcon = FeatherIcon[iconName];
                                                                return DynamicIcon ? <DynamicIcon key={icon.id} /> : <i key={icon.id} />;
                                                            }
                                                        })}

                                                        <Typography variant="body2" style={{ paddingTop: index === 0 ? '10px' : '4px' }}>
                                                            {service?.service?.name}
                                                        </Typography>
                                                    </Service>
                                                );
                                            })}
                                            <Link href={`/experience-details/${item.id}`}>
                                                <Typography variant="btn">
                                                    {t('packages:experience-card.button.more-details')}
                                                    <FeatherIcon.ChevronRight />
                                                </Typography>
                                            </Link>
                                            <Price>
                                                <Typography variant="body2">{t('packages:experience-card.label.since')}</Typography>
                                                <Typography variant="h5">{formatCurrency(item?.price as number)}</Typography>
                                            </Price>
                                        </CardContent>
                                    </Card>
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Container>
        )
    );
};

export default ExperienceSlider;
