import React from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import Airline from 'assets/images/copa.svg';
// Atoms
import { Typography } from 'components/atoms';

// Styles
import { Seats, MapContainer, MapSeats, AirlineContainer, Header, Card, Section, Legend } from './FlightSeats.styles';

const SeatCard = () => {
    // State
    const [status, setStatus] = React.useState(1);

    const handleStatus = () => {
        const value = status === 1 ? 2 : 1;
        setStatus(value);
    };

    return <Card status={status} onClick={handleStatus} />;
};

const FlightSeats = () => {
    // Hooks
    const { t } = useTranslation(); // Translations

    const createSeatsArray = (columnsSize, rowsSize) => {
        const array = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < columnsSize.length; i++) {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < rowsSize; j++) {
                const seat = { position: columnsSize[i] + (j + 1).toString(), state: 'active' };
                array.push(seat);
            }
        }
        return array;
    };

    // const allSeats = createSeatsArray(columns, rows);
    // Section 3X5
    const section1 = createSeatsArray(['A', 'B', 'C'], 8);
    const section2 = createSeatsArray(['D', 'F', 'G'], 8);
    const section3 = createSeatsArray(['A', 'B', 'C'], 8);
    const section4 = createSeatsArray(['D', 'F', 'G'], 8);

    return (
        <>
            <Image src="/images/plane-05.png" alt="" layout="fill" objectFit="cover" objectPosition="center center" />

            <Seats>
                <MapContainer>
                    <Header>
                        <AirlineContainer>
                            <Airline />
                        </AirlineContainer>
                        <Typography type="cap" $align="center">
                            {t('flights:flights')} CA1326
                        </Typography>
                    </Header>
                    <MapSeats>
                        <Section>
                            {section1.map((item) => {
                                return <SeatCard key={item.position} />;
                            })}
                        </Section>
                        <Section>
                            {section2.map((item) => {
                                return <SeatCard key={item.position} />;
                            })}
                        </Section>
                        <Section>
                            {section3.map((item) => {
                                return <SeatCard key={item.position} />;
                            })}
                        </Section>
                        <Section>
                            {section4.map((item) => {
                                return <SeatCard key={item.position} />;
                            })}
                        </Section>
                        <Section>
                            {section3.map((item) => {
                                return <SeatCard key={item.position} />;
                            })}
                        </Section>
                        <Section>
                            {section4.map((item) => {
                                return <SeatCard key={item.position} />;
                            })}
                        </Section>
                    </MapSeats>
                    <Legend>
                        <li>
                            <SeatCard />
                            <Typography type="cap">{t('flights:seats.available')}</Typography>
                        </li>
                        <li>
                            <SeatCard stateValue={2} />
                            <Typography type="cap">{t('flights:seats.sold')}</Typography>
                        </li>
                    </Legend>
                </MapContainer>
            </Seats>
        </>
    );
};

export default FlightSeats;
