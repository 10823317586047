import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, TOKEN_FRAGMENT, USER_FRAGMENT } from "@/graphql/fragments";

export const SIGN_UP = gql`
    ${ERRORS_FRAGMENT}
    mutation SignUp(
        $firstName: String!
        $lastName: String!
        $email: String!
        $password: String!
        $subscribeNewsletter: Boolean
        $acceptTerms: Boolean!
        $recaptchaToken: String!
        $callbackUrl: String
    ) {
        signup(
            input: {
                firstName: $firstName
                lastName: $lastName
                email: $email
                password: $password
                subscribeNewsletter: $subscribeNewsletter
                acceptTerms: $acceptTerms
                recaptchaToken: $recaptchaToken
                callbackUrl: $callbackUrl
            }
        ) {
            status
            errors {
                ...ErrorType
            }
            verificationToken
        }
    }
`;

export const LOGIN = gql`
    ${ERRORS_FRAGMENT}
    ${TOKEN_FRAGMENT}
    mutation Login($email: String!, $password: String!, $recaptchaToken: String!) {
        login(email: $email, password: $password, recaptchaToken: $recaptchaToken) {
            status
            errors {
                ...ErrorType
            }
            response {
                ...AccessTokenType
            }
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    ${ERRORS_FRAGMENT}
    mutation ForgotUserPassword($email: String!, $recaptchaToken: String!, $callbackUrl: String) {
        forgotPassword(email: $email, recaptchaToken: $recaptchaToken, callbackUrl: $callbackUrl) {
            status
            errors {
                ...ErrorType
            }
        }
    }
`;

export const RECOVER_PASSWORD = gql`
    ${ERRORS_FRAGMENT}
    ${TOKEN_FRAGMENT}
    mutation RecoverPassword($email: String!, $password: String!, $token: String!) {
        recoverPassword(email: $email, password: $password, token: $token) {
            status
            errors {
                ...ErrorType
            }
            response {
                ...AccessTokenType
            }
        }
    }
`;

export const VERIFY_ACCOUNT = gql`
    ${ERRORS_FRAGMENT}
    ${TOKEN_FRAGMENT}
    mutation VerifyAccount($email: String!, $token: String!) {
        verifyAccount(email: $email, token: $token) {
            status # Int
            errors {
                ...ErrorType
            }
            response {
                ...AccessTokenType
            }
        }
    }
`;

export const UPDATE_PROFILE = gql`
    ${ERRORS_FRAGMENT}
    ${USER_FRAGMENT}
    mutation UpdateUserProfile(
        $firstName: String!
        $lastName: String!
        $country: ID
        $email: String!
        $phone: String
        $image: Upload
        $birthday: Date
        $passport: String
        $passportExpireAt: Date
        $passportEmittedAt: Date
        $passportEmissionCountry: ID
        $callbackUrl: String
    ) {
        updateProfile(
            input: {
                firstName: $firstName
                lastName: $lastName
                country: $country
                email: $email
                phone: $phone
                image: $image
                birthday: $birthday
                passport: $passport
                passportExpireAt: $passportExpireAt
                passportEmittedAt: $passportEmittedAt
                passportEmissionCountry: $passportEmissionCountry
                callbackUrl: $callbackUrl
            }
        ) {
            status # Int
            errors {
                ...ErrorType
            }
            user {
                ...UserType
            }
        }
    }
`;

export const VERIFY_NEW_EMAIL = gql`
    ${ERRORS_FRAGMENT}
    ${TOKEN_FRAGMENT}
    ${USER_FRAGMENT}
    mutation VerifyNewEmail($email: String!, $token: String!) {
        verifyNewEmail(email: $email, token: $token) {
            status # Int
            errors {
                ...ErrorType
            }
            response {
                ...AccessTokenType
            }
            user {
                ...UserType
            }
        }
    }
`;
