import { FontTypes } from 'styled-components';
import { Montserrat } from 'next/font/google';
import localFont from 'next/font/local';

export const montserrat = Montserrat({
    weight: ['400', '500', '600', '700', '800', '900'],
    style: ['normal', 'italic'],
    subsets: ['latin'],
    display: 'swap',
});

export const chagodIconsFont = localFont({
    src: '../assets/fonts/icons/Chagodtour-Icon-Pack.woff2',
    display: 'swap',
});

const fonts: FontTypes = {
    h1: {
        key: 'h1',
        name: 'Heading 1',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '48px',
            lineheight: '54px',
            weight: 800,
            style: 'normal',
        },
        tablet: {
            size: '48px',
            lineheight: '54px',
            weight: 800,
            style: 'normal',
        },
        desktop: {
            size: '48px',
            lineheight: '54px',
            weight: 800,
            style: 'normal',
        },
    },
    h2: {
        key: 'h2',
        name: 'Heading 2',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '28px',
            lineheight: '32px',
            weight: 600,
            style: 'normal',
        },
        tablet: {
            size: '34px',
            lineheight: '38px',
            weight: 600,
            style: 'normal',
        },
        desktop: {
            size: '42px',
            lineheight: '52px',
            weight: 700,
            style: 'normal',
        },
    },
    h3: {
        key: 'h3',
        name: 'Heading 3',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '22px',
            lineheight: '26px',
            weight: 600,
            style: 'normal',
        },
        tablet: {
            size: '22px',
            lineheight: '26px',
            weight: 600,
            style: 'normal',
        },
        desktop: {
            size: '38px',
            lineheight: '48px',
            weight: 600,
            style: 'normal',
        },
    },
    h4: {
        key: 'h4',
        name: 'Heading 4',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '20px',
            lineheight: '24px',
            weight: 600,
            style: 'normal',
        },
        tablet: {
            size: '20px',
            lineheight: '24px',
            weight: 600,
            style: 'normal',
        },
        desktop: {
            size: '34px',
            lineheight: '38px',
            weight: 600,
            style: 'normal',
        },
    },
    h5: {
        key: 'h5',
        name: 'Heading 5',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '20px',
            lineheight: '24px',
            weight: 600,
            style: 'normal',
        },
        tablet: {
            size: '20px',
            lineheight: '24px',
            weight: 600,
            style: 'normal',
        },
        desktop: {
            size: '28px',
            lineheight: '32px',
            weight: 600,
            style: 'normal',
        },
    },
    h6: {
        key: 'h6',
        name: 'Heading 6',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '18px',
            lineheight: '22px',
            weight: 600,
            style: 'normal',
        },
        tablet: {
            size: '18px',
            lineheight: '22px',
            weight: 600,
            style: 'normal',
        },
        desktop: {
            size: '20px',
            lineheight: '26px',
            weight: 600,
            style: 'normal',
        },
    },
    sub1: {
        key: 'sub1',
        name: 'Subtitle 1',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '18px',
            lineheight: '24px',
            weight: 600,
            style: 'normal',
        },
        tablet: {
            size: '18px',
            lineheight: '24px',
            weight: 600,
            style: 'normal',
        },
        desktop: {
            size: '18px',
            lineheight: '24px',
            weight: 600,
            style: 'normal',
        },
    },
    sub2: {
        key: 'sub2',
        name: 'Subtitle 2',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '16px',
            lineheight: '20px',
            weight: 600,
            style: 'normal',
        },
        tablet: {
            size: '16px',
            lineheight: '20px',
            weight: 600,
            style: 'normal',
        },
        desktop: {
            size: '16px',
            lineheight: '20px',
            weight: 600,
            style: 'normal',
        },
    },
    body1: {
        key: 'body1',
        name: 'Body 1',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '14px',
            lineheight: '20px',
            weight: 400,
            style: 'normal',
        },
        tablet: {
            size: '14px',
            lineheight: '20px',
            weight: 400,
            style: 'normal',
        },
        desktop: {
            size: '18px',
            lineheight: '24px',
            weight: 400,
            style: 'normal',
        },
    },
    body2: {
        key: 'body2',
        name: 'Body 2',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '14px',
            lineheight: '20px',
            weight: 400,
            style: 'normal',
        },
        tablet: {
            size: '14px',
            lineheight: '20px',
            weight: 400,
            style: 'normal',
        },
        desktop: {
            size: '14px',
            lineheight: '20px',
            weight: 400,
            style: 'normal',
        },
    },
    btn: {
        key: 'btn',
        name: 'Button',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '14px',
            lineheight: '20px',
            weight: 500,
            style: 'normal',
        },
        tablet: {
            size: '14px',
            lineheight: '20px',
            weight: 500,
            style: 'normal',
        },
        desktop: {
            size: '14px',
            lineheight: '20px',
            weight: 500,
            style: 'normal',
        },
    },
    cap: {
        key: 'cap',
        name: 'Caption',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '12px',
            lineheight: '15px',
            weight: 400,
            style: 'normal',
        },
        tablet: {
            size: '12px',
            lineheight: '15px',
            weight: 400,
            style: 'normal',
        },
        desktop: {
            size: '12px',
            lineheight: '15px',
            weight: 400,
            style: 'normal',
        },
    },
    ovl: {
        key: 'ovl',
        name: 'Overline',
        family: montserrat.style.fontFamily,
        mobile: {
            size: '9px',
            lineheight: '12px',
            weight: 400,
            style: 'normal',
        },
        tablet: {
            size: '9px',
            lineheight: '12px',
            weight: 400,
            style: 'normal',
        },
        desktop: {
            size: '9px',
            lineheight: '12px',
            weight: 400,
            style: 'normal',
        },
    },
    icons: {
        chagod: chagodIconsFont.style.fontFamily,
    },
};

export default fonts;
