import { gql } from "@apollo/client";
import { COUNTRY_FRAGMENT } from "./country.gql";
import { LOCATION_FRAGMENT } from "./location.gql";

export const CITY_FRAGMENT = gql`
    ${COUNTRY_FRAGMENT}
    ${LOCATION_FRAGMENT}
    fragment CityType on CityType {
        id
        name
        code
        country {
            ...CountryType
        }
        timezone
        location {
            ...CoordinatePointType
        }
    }
`;
