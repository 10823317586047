import React from 'react';
import { useTranslation } from 'next-i18next';
import { basic } from '@/styles/colors';
import { Typography, Dropdown, Button } from '@/components/atoms';
import { NumberPicker } from '@/components/molecules';
import { PassengerPickerProps } from './PassengerPicker.types';
import { SelectorContainer, Selector, InfoContainer, ButtonContainer } from './PassengerPicker.styles';

const PassengerPicker: React.FC<PassengerPickerProps> = ({ id, trigger, passengers, setPassengers }) => {
    const { t } = useTranslation(); // Translations

    // Close dropdown and reset state rooms
    const handleReset = () => {
        setPassengers({ adults: 0, children: 0 });
    };

    return (
        <Dropdown
            id={id}
            triggerLabel={t('filters:tab.flights.passengers.label')}
            trigger={trigger}
        >
            <SelectorContainer>
                <Selector>
                    <InfoContainer>
                        <Typography variant="body1" color={basic.black}>
                            {t('filters:tab.flights.passengers.adult')}
                        </Typography>
                        <Typography variant="cap" color={basic.black}>
                            {t('filters:tab.flights.passengers.adult-info')}
                        </Typography>
                    </InfoContainer>
                    <NumberPicker value={passengers.adults} setValue={(value) => setPassengers({ ...passengers, adults: value })} />
                </Selector>
                <Selector>
                    <InfoContainer>
                        <Typography variant="body1" color={basic.black}>
                            {t('filters:tab.flights.passengers.children')}
                        </Typography>
                        <Typography variant="cap" color={basic.black}>
                            {t('filters:tab.flights.passengers.children-info')}
                        </Typography>
                    </InfoContainer>
                    <NumberPicker value={passengers.children} setValue={(value) => setPassengers({ ...passengers, children: value })} />
                </Selector>
                <ButtonContainer>
                    <Typography variant="body1" color={basic.black}>
                        {t('filters:tab.flights.passengers.total')} {passengers.adults + passengers.children}
                    </Typography>
                    <Button content={t('filters:tab.flights.passengers.reset')} type="button" variant="outlined" width="150px" onClick={() => handleReset()} />
                </ButtonContainer>
            </SelectorContainer>
        </Dropdown>
    );
};

export default PassengerPicker;
