import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import { useTheme } from 'styled-components';

// Atoms
import { Button, Typography } from 'components/atoms';

// Molecules
import { ExperienceCard, ExperienceCardSkeleton } from 'components/molecules';

// Styles
import { AlternativeDialog, CardGrid, Tabs } from './Experiences.styles';

const itemsPerPage = parseInt(process.env.NEXT_PUBLIC_EXPERIENCES_PER_PAGE) || 9;

const Experiences = ({ data, setData, offset, setOffset, setFilters, loadingMore, setLoadingMore, loadingSkeleton, setLoadingSkeleton }) => {
    // Hooks
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme

    const tabs = [
        { id: 'all', text: t('experiences:label.all') },
        { id: 'new', text: t('experiences:label.new') },
        { id: 'offer', text: t('experiences:label.offer') },
    ];

    // States
    const [selected, setSelected] = React.useState('all');

    const moreExperiences = async () => {
        setLoadingSkeleton(true);
        setLoadingMore(true);
        setOffset(offset + 1);
    };

    const handleChangeTab = (selectedTab) => {
        // Set selected tab
        setSelected(selectedTab);

        // Start skeleton loading
        setLoadingSkeleton(true);

        // Reset data
        setData({
            objects: [],
            alternatives: [],
            pageInfo: {},
        });

        // Reset pagination
        setOffset(1);

        switch (selectedTab) {
            case 'offer':
                setFilters([
                    {
                        field: 'IN_OFFER',
                        operation: 'GT',
                        value: '1',
                    },
                ]);
                break;
            case 'new':
                setFilters([
                    {
                        field: 'NEW',
                        operation: 'SELECTED_DAYS_AGO',
                        value: '"True"',
                    },
                ]);
                break;
            case 'visited':
                setFilters([
                    {
                        field: 'IS_DELETED',
                        operation: 'EQUAL',
                        value: 'True',
                    },
                ]);
                break;
            default:
                setFilters([]);
                break;
        }
    };

    return (
        <>
            <Tabs>
                {tabs.map((tab) => (
                    <li key={tab.id}>
                        <Typography
                            as="a"
                            type="h5"
                            $color={tab.id === selected ? theme.colors.secondary.secondary50 : theme.colors.neutral.neutral10}
                            $align="center"
                            onClick={() => handleChangeTab(tab.id)}
                        >
                            {tab.text}
                        </Typography>
                    </li>
                ))}
            </Tabs>

            {!loadingSkeleton && data?.objects?.length === 0 && (
                <AlternativeDialog>
                    <Typography type="body1" $align="center" $weight={700}>
                        {t('experiences:experiences.label.sorry')}
                    </Typography>
                    <Typography type="body1" $align="center">
                        {t('experiences:experiences.label.no-experiences')}
                    </Typography>
                    {data?.alternatives?.length > 0 && (
                        <Typography type="body1" $align="center" $weight={600}>
                            {t('experiences:experiences.label.alternative')}
                        </Typography>
                    )}
                </AlternativeDialog>
            )}

            <CardGrid>
                {data?.objects?.map((item) => {
                    return <ExperienceCard key={item.id} item={item} />;
                })}
                {data?.objects?.length === 0 &&
                    data?.alternatives?.map((item) => {
                        return <ExperienceCard key={item.id} item={item} />;
                    })}
                {loadingSkeleton &&
                    [...Array(itemsPerPage)].map((e, i) => {
                        // eslint-disable-next-line react/no-array-index-key
                        return <ExperienceCardSkeleton key={i} />;
                    })}
            </CardGrid>

            {data?.pageInfo?.hasNext && (
                <Button
                    content={t('experiences:experiences.button.search-other-experiences')}
                    type="button"
                    variant="outlined"
                    icon={<FeatherIcon.Plus size={24} strokeWidth={2} />}
                    iconPosition="left"
                    width="300px"
                    onClick={moreExperiences}
                    isSubmitting={loadingMore}
                    disabled={loadingMore}
                />
            )}
        </>
    );
};

Experiences.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    offset: PropTypes.number,
    setOffset: PropTypes.func,
    setFilters: PropTypes.func,
    loadingMore: PropTypes.bool,
    setLoadingMore: PropTypes.func,
    loadingSkeleton: PropTypes.bool,
    setLoadingSkeleton: PropTypes.func,
};

export default Experiences;
