import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './image.gql';
import { CITY_FRAGMENT } from './city.gql';

export const CARRIER_FRAGMENT = gql`
    ${IMAGE_FRAGMENT}
    fragment CarrierType on CarrierType {
        id
        name
        code
        designation
        logo {
            ...ImageType
        }
        footer
    }
`;

export const KIU_PRICE_FRAGMENT = gql`
    fragment KIUPriceType on KIUPriceType {
        amount
        currency
    }
`;

export const KIU_TAX_FRAGMENT = gql`
    ${KIU_PRICE_FRAGMENT}
    fragment KIUTaxType on KIUTaxType {
        code
        price {
            ...KIUPriceType
        }
    }
`;

export const KIU_DATE_FRAGMENT = gql`
    ${CITY_FRAGMENT}
    ${CARRIER_FRAGMENT}
    fragment KIUDateType on KIUDateType {
        date
        isValid
        routes {
            origin {
                ...CityType
            }
            destination {
                ...CityType
            }
            departingDatetime
            arrivingDatetime
            stopsQty
            isValid
            flights {
                departingDatetime
                arrivingDatetime
                stopQty
                flightNumber
                duration
                departingCity {
                    ...CityType
                }
                arrivingCity {
                    ...CityType
                }
                carrier {
                    ...CarrierType
                }
                flightClasses {
                    code
                }
            }
            errors
            airlines
            categories
            duration
        }
    }
`;
