import React from 'react';
import * as IconFeather from 'react-feather';
import { useTheme } from 'styled-components';
import { useMediaQuery } from '@/modules/media-query';
import { ScrollCardProps } from './ScrollCard.types';
import { CardsContainer, Container, ButtonPrev, ButtonNext, Wrapper } from './ScrollCard.styles';

const ScrollCard: React.FC<ScrollCardProps> = ({ children, mt, contentType }) => {
    const theme = useTheme();
    const desktop = useMediaQuery('(min-width: 992px)'); // Media Queries
    const [restValue, setRestValue] = React.useState(0);
    const [activeArrows, setActiveArrows] = React.useState(false);
    const [widthContainer, setWidthContainer] = React.useState(null);
    const [widthContent, setWidthContent] = React.useState(null);
    const containerRef = React.useRef(0);
    const contentRef = React.useRef(0);

    React.useEffect(() => {
        const handleResize = () => {
            setWidthContainer(containerRef.current.offsetWidth);
            setWidthContent(contentRef.current.offsetWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    React.useEffect(() => {
        if (desktop && widthContent > widthContainer) {
            setActiveArrows(true);
            setRestValue(widthContent - widthContainer);
        }
    }, [widthContent, widthContainer]);

    React.useEffect(() => {
        if (!desktop) setActiveArrows(false);
        if (contentRef.current) setWidthContent(contentRef.current.offsetWidth);
    }, [desktop, contentRef.current]);

    const limit = React.useMemo(() => widthContent - widthContainer, [widthContent, widthContainer]);

    const prev = () => {
        containerRef.current.scrollLeft -= width + 20;
        setRestValue((prevRestValue) => prevRestValue + width + 20);
    };

    const next = () => {
        containerRef.current.scrollLeft += width + 20;
        setRestValue((prevRestValue) => prevRestValue - width - 20);
    };

    return (
        <CardsContainer mt={mt}>
            {activeArrows && restValue < limit && (
                <ButtonPrev onClick={prev} contentType={contentType}>
                    <IconFeather.ChevronLeft size={24} color={theme.colors.primary.primary10} strokeWidth={2} />
                </ButtonPrev>
            )}
            <Container ref={containerRef}>
                <Wrapper contentType={contentType} ref={contentRef}>
                    {children}
                </Wrapper>
            </Container>
            {activeArrows && restValue > 0 && (
                <ButtonNext onClick={next} contentType={contentType}>
                    <IconFeather.ChevronRight size={24} color={theme.colors.primary.primary10} strokeWidth={2} />
                </ButtonNext>
            )}
        </CardsContainer>
    );
};

ScrollCard.defaultProps = {
    contentType: 'default',
};

export default ScrollCard;
