import styled from 'styled-components';
import { Form } from 'formik';

export const CustomForm = styled(Form)`
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div`
    display: flex;
    gap: 20px;
`;

export const ErrorBlock = styled.span`
    width: 100%;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.error.error50};
`;
