import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Button, Typography } from '@/components/atoms';
import { PassengerCardProps } from './PassengerCard.types';
import { Container, Row, Column } from './PassengerCard.styles';

const PassengerCard: React.FC<PassengerCardProps> = ({ status, passenger, idNumber, onEdit, children }) => {
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current

    const passengerTypes: {
        [key: string]: string;
    } = {
        Adult: t('flights:passenger.type.adult'),
        Child: t('flights:passenger.type.child'),
    };

    return (
        <Container>
            <Row>
                <Column>
                    <Typography variant="h6" align="left" color={status === 'disabled' ? theme.colors.neutral.neutral70 : theme.colors.neutral.neutral10}>
                        {t('flights:passenger.label')} {passenger.number} - {passengerTypes[passenger.type]}
                    </Typography>
                    {status === 'saved' && (
                        <Typography variant="body2" align="left">
                            {idNumber}
                        </Typography>
                    )}
                </Column>
                {status === 'saved' && <Button content={t('flights:passenger.edit')} type="submit" variant="filled" onClick={onEdit} />}
            </Row>
            {status === 'active' && children}
        </Container>
    );
};
export default PassengerCard;
