import styled from 'styled-components';

export const Main = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};
`;
