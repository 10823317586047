import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { registerLocale } from 'react-datepicker';

import Datepicker from './Datepicker';

registerLocale('es', es);
registerLocale('en', en);

export default Datepicker;
