import React from 'react';
import Link from 'next/link';
import { useTheme } from 'styled-components';
import { Typography } from '@/components/atoms';
import { NavigationProps } from './Navigation.types';
import { Nav, Items } from './Navigation.styles';

const Navigation: React.FC<NavigationProps> = ({ id, type, title, menuItems, activeItem, handleToggleSidebar }) => {
    const theme = useTheme();

    return (
        <Nav id={id} type={type}>
            {type !== 'main' && title && (
                <Typography as="h3" variant="sub1" color={theme.colors.tertiary.tertiary50}>
                    {title}
                </Typography>
            )}
            <Items orientation={type === 'main' ? 'row' : 'column'} type={type}>
                {menuItems.map((item) => {
                    return (
                        <li key={item.key}>
                            <Link href={item.href} passHref>
                                <Typography
                                    as="span"
                                    variant={type === 'footer' ? 'body2' : 'sub2'}
                                    color={
                                        type === 'footer'
                                            ? theme.colors.neutral.neutral10
                                            : activeItem === item.key
                                            ? theme.colors.secondary.secondary50
                                            : theme.colors.tertiary.tertiary50
                                    }
                                    onClick={handleToggleSidebar}
                                    className={type !== 'footer' && activeItem === item.key ? 'active' : ''}
                                >
                                    {item.text}
                                </Typography>
                            </Link>
                        </li>
                    );
                })}
            </Items>
        </Nav>
    );
};

Navigation.defaultProps = {
    type: 'main',
};

export default Navigation;
