import styled, { css } from 'styled-components';
import { TabProps } from './Tab.types';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['link', 'variant'].includes(prop),
})<Omit<TabProps, 'children'>>`
    width: 100%;
    height: 68px;

    ${({ link }) =>
        link &&
        css`
            position: sticky;
            top: 56px;
            z-index: 1200;
            background-color: ${({ theme }) => theme.colors.basic.white};
        `};

    ${({ variant, theme }) =>
        variant === 'secondary' &&
        css`
            background-color: ${theme.colors.basic.white};

            & > * {
                margin-right: auto;
                margin-left: auto;
                position: relative;
                max-width: 95%;

                ${theme.media.sm`
                    max-width: 540px;
                `}

                ${theme.media.md`
                    max-width: 736px;
                `}

                ${theme.media.lg`
                    max-width: 960px;
                `}

                ${theme.media.xl`
                    max-width: 1180px;
                `}
            }
        `};
`;

export const TabContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<Omit<TabProps, 'children'>>`
    border-radius: 0;
    display: grid;
    grid-auto-flow: column;
    margin-left: auto;
    overflow-x: scroll; /* Add the ability to scroll */
    overflow-y: hidden;
    scrollbar-width: none;
    width: 100%;

    /* Webkit(Chrome, Android browser, Safari, Edge...) */

    &::-webkit-scrollbar {
        display: none;
    }

    ${({ variant, theme }) =>
        variant === 'primary' &&
        css`
            div:first-child {
                border-radius: 0;

                ${theme.media.xl`
                    border-radius: 25px 0 0;
                `}
            }

            div:last-child {
                border-radius: 0;

                ${theme.media.xl`
                    border-radius: 0 25px 0 0;
                `}
            }
        `}

    ${({ theme }) => theme.media.lg`
        display: flex;
        flex-direction: row;
        align-items: stretch;
    `}
`;

export const TabItem = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant', 'selected'].includes(prop),
})<
    Omit<TabProps, 'children'> & {
        selected?: boolean;
        onMouseEnter?: () => void;
        onMouseLeave?: () => void;
    }
>`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
    padding: 10px;
    width: 160px;

    ${({ variant, selected, theme }) =>
        variant === 'primary' &&
        css`
            height: 68px;
            background-color: ${theme.colors.primary.primary50};
            border-right: 1px solid ${theme.colors.neutral.neutral96};
            transition: all 0.2s ease-in-out;

            &:last-child {
                border-right: none;
            }

            ${selected &&
            css`
                background-color: ${theme.colors.neutral.neutral96};

                svg,
                path {
                    stroke: ${theme.colors.secondary.secondary50};
                }

                p,
                i::before {
                    color: ${theme.colors.secondary.secondary50};
                }
            `}

            ${theme.media.lg`
                width: 100%;
                height: 70px;
            `}
        `}

    ${({ variant, selected }) =>
        variant === 'secondary' &&
        css`
            background-color: transparent;
            height: 52px;

            ${selected &&
            css`
                border-bottom: 3px solid ${({ theme }) => theme.colors.secondary.secondary50};

                p {
                    color: ${({ theme }) => theme.colors.secondary.secondary50};
                }
            `}
        `}
`;

export const IconContainer = styled.span.withConfig({
    shouldForwardProp: (prop) => !['isSelected', 'isShown'].includes(prop),
})<{
    isSelected?: boolean;
    isShown?: boolean;
}>`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

    ${({ isSelected, isShown }) =>
        (isSelected || isShown) &&
        css`
            margin-top: 15px;
            transform: translateY(-5px);
        `}
`;

export const ShowText = styled.span.withConfig({
    shouldForwardProp: (prop) => !['isSelected', 'isShown'].includes(prop),
})<
    Omit<TabProps, 'children'> & {
        isSelected?: boolean;
        isShown?: boolean;
    }
>`
    opacity: 0;
    height: 0;
    overflow: hidden;
    transform: translateY(20px);
    transition: all 0.6s ease-in-out;

    ${({ isSelected, isShown }) =>
        (isSelected || isShown) &&
        css`
            height: auto;
            overflow: visible;
            opacity: 1;
            transform: translateY(-5px);
        `}
`;

export const Box = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isSelected', 'isLoaded'].includes(prop),
})<{
    isSelected?: boolean;
    isLoaded?: boolean;
}>`
    width: 100%;
    transform: scaleY(0);
    transform-origin: top;
    border-radius: 0;
    opacity: 0;
    transition: all 0.6s ease-in-out;
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};

    ${({isLoaded, isSelected}) => !isLoaded && isSelected && css`
        min-height: 199px;
        opacity: 1;
        transform: scaleY(1);
    `}

    ${({isLoaded, isSelected}) => !isLoaded && !isSelected && css`
        transform: scaleY(0);
    `}

    ${({isLoaded, isSelected}) => isLoaded && isSelected && css`
        transform: scaleY(1);
        opacity: 1;
    `}

    ${({ theme }) => theme.media.lg`
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    `}
`;
