import React from 'react';
import Image from 'next/image';
import { useTheme } from 'styled-components';
import { Button, Typography } from '@/components/atoms';
import { BannerProps } from './Banner.types';
import { BannerContainer, Content, ImageSection } from './Banner.styles';

const Banner: React.FC<BannerProps> = ({ type, data }) => {
    const theme = useTheme(); // Get current theme

    return (
        <BannerContainer type={type}>
            <ImageSection type={type}>
                <Image
                    src={data.image.url}
                    alt={data.image.alt}
                    fill
                    priority
                    style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                />
            </ImageSection>
            <Content type={type}>
                <Typography variant="h2" color={type === 'contact' ? theme.colors.tertiary.tertiary40 : theme.colors.basic.white}>
                    {data.title}
                </Typography>
                <Typography variant="body1" color={type === 'contact' ? theme.colors.neutral.neutral10 : theme.colors.basic.white} mt="12px" mb="36px">
                    {data.description}
                </Typography>
                {type === 'contact' ? (
                    <Button as="a" content={data.buttonText} icon={data.buttonIcon} variant="filled" href={data.buttonLink} target="_blank" rel="noopener noreferrer" />
                ) : (
                    <Button as="a" content={data.buttonText} variant="filled" href={data.buttonLink} target="_blank" rel="noopener noreferrer" />
                )}
            </Content>
        </BannerContainer>
    );
};

export default Banner;
