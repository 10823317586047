import styled, { createGlobalStyle, css } from 'styled-components';
import { PhoneInputProps } from './PhoneInput.types';

export const HelpBlock = styled.span`
    font-family: ${({ theme }) => theme.font.cap.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size} !important;
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight} !important;
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight} !important;
    font-style: ${({ theme }) => theme.font.cap.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral20};
    background-color: transparent !important;
    position: absolute;
    margin-block-start: 0;
    margin-block-end: 0;
    width: 70%;
    padding-top: 2px;
    padding-left: 20px;
    text-align: left;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.tablet.size};
        font-weight: ${theme.font.cap.tablet.weight};
        line-height: ${theme.font.cap.tablet.lineheight};
        font-style: ${theme.font.cap.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
        font-style: ${theme.font.cap.mobile.style};
    `}
`;

export const ErrorBlock = styled(HelpBlock)`
    color: ${({ theme }) => theme.colors.error.error50};
`;

export const PhoneInputGlobalStyles = createGlobalStyle<PhoneInputProps & { focusSelect: boolean; haveErrors?: boolean; value: string }>`
  .phone-container {
    & .selected-flag::before {
      box-shadow: none !important;
      border: 0 !important;
    }
  }

  .phone-search-input {
    padding: 5px !important;

    input {
      width: 100%;
      margin: 0 !important;
      height: 36px;
      padding: 2px 10px;
    }
  }

  .phone-input {
    font-family: ${({ theme }) => theme.font.body2.family} !important;
    font-size: ${({ theme }) => theme.font.body2.mobile.size} !important;
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight} !important;
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight} !important;
    font-style: ${({ theme }) => theme.font.body2.mobile.style} !important;
    color: ${({ theme }) => theme.colors.neutral.neutral10} !important;
    caret-color: ${({ theme }) => theme.colors.tertiary.tertiary50} !important;
    border: 1px solid transparent !important;
    border-radius: ${({ borderRadius }) => borderRadius || '24px'} !important;
    background-color: ${({ theme }) => theme.colors.basic.white} !important;
    background-clip: padding-box !important;
    box-sizing: border-box !important;
    width: 100% !important;
    height: 50px !important;
    letter-spacing: 0.005em !important;
    display: block !important;
    overflow: visible !important;
    transition: all 0.5s ease-in-out !important;
    margin: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    padding: 1.31rem 3.4rem 0.44rem 3.4rem !important;

    ${({ value, focusSelect }) =>
            !value &&
            !focusSelect &&
            css`
              color: transparent !important;
            `}
    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size} !important;
        font-weight: ${theme.font.body2.tablet.weight} !important;
        line-height: ${theme.font.body2.tablet.lineheight} !important;
        font-style: ${theme.font.body2.tablet.style} !important;
    `}
    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size} !important;
        font-weight: ${theme.font.body2.desktop.weight} !important;
        line-height: ${theme.font.body2.desktop.lineheight} !important;
        font-style: ${theme.font.body2.desktop.style} !important;
    `}
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${({ theme }) => theme.colors.neutral.neutral60} !important;
      opacity: 0 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${({ theme }) => theme.colors.neutral.neutral60} !important;
      opacity: 0 !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${({ theme }) => theme.colors.neutral.neutral60} !important;
      opacity: 0 !important;
    }

    &:not(:focus, :read-only, [autoFocus], :disabled) {
      ${({ haveErrors }) =>
              haveErrors &&
              css`
                color: ${({ theme }) => theme.colors.error.error50} !important;
                background-color: ${({ theme }) => theme.colors.error.error95} !important;
                border: none !important;
              `};
    }

    &:hover {
      ${({ disabled }) =>
              !disabled &&
              css`
                border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80} !important;
              `};
    }

    &:focus,
    &:read-only[autoFocus] {
      border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80} !important;

      ~ ${HelpBlock} {
        ${({ haveErrors }) =>
                haveErrors &&
                css`
                  display: none;
                `};
      }
    }

    &:focus ~ label,
    &.edited:not(:placeholder-shown, :disabled) ~ label,
    &:read-only[autoFocus] ~ label {
      transform: translateY(-70%) !important;
      padding: 0.44rem 0.2rem 0 3.4rem !important;
      color: ${({ theme, haveErrors, value }) => ((!haveErrors && value) || (haveErrors && !value) ? theme.colors.neutral.neutral10 : theme.colors.error.error50)} !important;
    }

    &:focus ~ label {
      color: ${({ theme }) => theme.colors.neutral.neutral10} !important;
    }
  }
`;

export const FormGroup = styled.div`
    position: relative !important;
    width: 100%;
`;

export const FormLabel = styled.label.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled', 'focusSelect'].includes(prop),
})<PhoneInputProps & { haveErrors?: boolean; focusSelect?: boolean }>`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme, haveErrors, disabled }) => (disabled ? theme.colors.neutral.neutral60 : !haveErrors ? theme.colors.neutral.neutral10 : theme.colors.error.error50)};
    display: block;
    position: absolute;
    top: 15px;
    padding: 0 0.2rem 0.6rem 3.4rem;
    transition: all 0.25s ease-in-out;
    margin-bottom: 0;
    pointer-events: none;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.mobile.style};
    `}

  ${({ theme, focusSelect, haveErrors, disabled, defaultValue }) =>
        (focusSelect || defaultValue) &&
        css`
            transform: translateY(-70%);
            padding: 0.44rem 0.2rem 0 3.4rem;
            font-size: ${theme.font.cap.mobile.size} !important;
            font-weight: ${theme.font.cap.mobile.weight} !important;
            line-height: ${theme.font.cap.mobile.lineheight} !important;
            color: ${disabled ? theme.colors.neutral.neutral60 : !haveErrors ? theme.colors.neutral.neutral10 : theme.colors.error.error50} !important;

            ${theme.media.md`
                 font-size: ${theme.font.cap.desktop.size} !important;
                 font-weight: ${theme.font.cap.desktop.weight} !important;
                 line-height: ${theme.font.cap.desktop.lineheight} !important;
            `}
        `}
`;
