import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { basic } from 'styles/colors';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as IconFeather from 'react-feather';
import { useTheme } from 'styled-components';

// Utils
import UrlMapper from 'utils/url-mapper';
import { capitalize } from 'utils/helpers';

// Atoms
import { Typography } from 'components/atoms';

// Styles
import { CardContainer, Frame, CardContainerIcon, FrameContainer, Frame1, CircleIcon } from './DestinationCard.styles';

const imgBackup = '/images/default-logo.png';

const DestinationCard = ({ card, icon, type, url, stored }) => {
    // Hooks
    const { i18n } = useTranslation(); // Translations
    const router = useRouter(); // Router
    const theme = useTheme(); // Get current theme
    const [principalDest, setPrincipalDest] = React.useState({}); // save the principal image for destinations
    const [principalExp, setPrincipalExp] = React.useState({}); // save the principal image for experience

    // State
    const [activeIcon, setActiveIcon] = React.useState(false);

    const handleClick = () => setActiveIcon(!activeIcon);

    const saveLocalStorage = () => {
        const storage = JSON.parse(localStorage.getItem('view-destiny'));
        const cardSave = { ...principalDest, storage: true };

        if (!storage) {
            localStorage.setItem('view-destiny', JSON.stringify([cardSave]));
        } else {
            const exist = storage.find((item) => item.id === cardSave.id);
            if (!exist) {
                storage.unshift(cardSave);
                localStorage.setItem('view-destiny', JSON.stringify(storage));
            }
        }
    };

    React.useEffect(() => {
        const imgDestiny = card?.city?.images?.find((image) => image.principal === true);
        const imgExperience = card?.images?.find((image) => image.principal === true);

        if (imgExperience) {
            setPrincipalExp(imgExperience.image);
        }

        if (card?.storage) {
            setPrincipalDest(card);
        } else if (card?.city) {
            setPrincipalDest({
                id: card.id,
                image: imgDestiny?.image ? { url: imgDestiny.image.url, name: imgDestiny.image.name } : null,
                destination: card.destination,
                country: capitalize(card.city.country?.name),
            });
        }
    }, []);

    return (
        <>
            {type === 'destiny' ? (
                <CardContainer
                    onClick={() => {
                        if (stored) saveLocalStorage();
                        router.push(`${UrlMapper[url][i18n.language]}/${card.id}`, undefined, { shallow: true });
                    }}
                >
                    {icon ? (
                        <>
                            <Image src={principalExp?.url || imgBackup} alt={principalExp?.name} layout="fill" objectFit="cover" priority objectPosition="center center" />
                            <CircleIcon active={activeIcon} onClick={handleClick}>
                                {icon}
                            </CircleIcon>
                        </>
                    ) : card?.storage ? (
                        <>
                            <Image src={card?.image?.url || imgBackup} alt={card?.image?.name} layout="fill" objectFit="cover" priority objectPosition="center center" />
                            <Frame>
                                <Typography type="h6" $color={basic.white}>
                                    {card?.destination}
                                </Typography>
                                <Typography type="body1" $color={basic.white}>
                                    {card?.country}
                                </Typography>
                            </Frame>
                        </>
                    ) : (
                        <>
                            <Image
                                src={principalDest?.image?.url || imgBackup}
                                alt={principalDest?.image?.name}
                                layout="fill"
                                objectFit="cover"
                                priority
                                objectPosition="center center"
                            />
                            <Frame>
                                <Typography type="h6" $color={basic.white}>
                                    {principalDest?.destination}
                                </Typography>
                                <Typography type="body1" $color={basic.white}>
                                    {principalDest?.country}
                                </Typography>
                            </Frame>
                        </>
                    )}
                </CardContainer>
            ) : (
                <CardContainerIcon>
                    <Link href={`${UrlMapper.destinations[i18n.language]}/${card.id}`} passHref>
                        <a>
                            <FrameContainer>
                                <IconFeather.MapPin size={24} color={theme.colors.neutral.neutral10} strokeWidth={2} />
                                <Frame1>
                                    <Typography type="sub2">{card?.destination}</Typography>
                                    <Typography type="body2">{capitalize(card?.city?.country?.name)}</Typography>
                                </Frame1>
                            </FrameContainer>
                        </a>
                    </Link>
                </CardContainerIcon>
            )}
        </>
    );
};

DestinationCard.defaultProps = {
    type: 'destiny',
    url: 'destinations',
};

DestinationCard.propTypes = {
    card: PropTypes.object,
    icon: PropTypes.node,
    type: PropTypes.oneOf(['destiny', 'search']),
    url: PropTypes.string,
    stored: PropTypes.bool,
};

export default DestinationCard;
