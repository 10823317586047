import React from 'react';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import { Suggestion, TextfieldProps, TextfieldType } from './Textfield.types';
import {
    FormGroup,
    FormControl,
    FormLeftIcon,
    FormRightIcon,
    FormLabel,
    Prefix,
    Suffix,
    HelpBlock,
    ErrorBlock,
    LetterCounter,
    DataSuggestions,
    ListOption,
    NoSuggestions,
    NoSuggestionsText,
} from './Textfield.styles';

const Textfield: React.FC<TextfieldProps> = ({
    id,
    tabIndex,
    type,
    placeholder,
    leftIcon,
    rightIcon,
    label,
    value,
    maxlength,
    mb,
    borderRadius,
    widthInput,
    help,
    errors,
    success,
    disabled,
    editable,
    focused,
    onChange,
    onFocus,
    onBlur,
    autoComplete,
    prefix,
    suffix,
    revealPasswordIcon,
    showClearIcon,
    onClearIcon,
    dataSuggestions,
    requireValueInSuggestions = false,
    className,
    ...props
}) => {
    const { t } = useTranslation();
    const formControlRef = React.useRef<HTMLDivElement>(null);
    const refInput = React.useRef<HTMLInputElement>(null); // Reference
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const [inputType, setInputType] = React.useState<TextfieldType>(type as TextfieldType);
    const [count, setCount] = React.useState<number>(0);
    const [filteredSuggestions, setFilteredSuggestions] = React.useState<Suggestion[]>([]);
    const [selectedSuggestion, setSelectedSuggestion] = React.useState<boolean>(false);

    // Max length
    React.useEffect(() => {
        if (maxlength) {
            const inputElement = refInput.current;

            const handleInput = (event: Event) => {
                const target = event.target as HTMLInputElement;
                if (target) {
                    setCount(target.value.length);
                }
            };

            inputElement?.addEventListener('input', handleInput);

            return () => inputElement?.removeEventListener('input', handleInput);
        }
    }, [maxlength]);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (formControlRef.current && !formControlRef.current.contains(event.target as Node)) {
                if (requireValueInSuggestions) {
                    const isValidSuggestion = dataSuggestions?.some((suggestion) => suggestion.value === value);

                    if (!isValidSuggestion) {
                        onChange?.({ target: { name: id, value: '' } } as React.ChangeEvent<HTMLInputElement>);
                    }
                }

                setIsFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dataSuggestions, id, onChange, requireValueInSuggestions, value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            const filtered =
                dataSuggestions?.filter((item) => {
                    return item.value.toLowerCase().includes(e.target.value.toLowerCase());
                }) || [];
            setFilteredSuggestions(filtered);
        } else {
            setSelectedSuggestion(false);
            setFilteredSuggestions([]);
        }

        if (onChange) {
            onChange(e);
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);

        if (onFocus) {
            onFocus(e);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur(e);
        }
    };

    const onOptionClick = (selected: Suggestion) => {
        if (onChange) {
            const event = {
                target: {
                    name: id,
                    value: selected.value,
                },
            } as React.ChangeEvent<HTMLInputElement>;

            onChange(event);
            setSelectedSuggestion(true);
            setIsFocused(false);
        }

        setFilteredSuggestions([]);

        refInput.current?.blur();
    };

    const handleClearIcon = (e: React.MouseEvent) => {
        if (onClearIcon) {
            onClearIcon(e);
        }

        setFilteredSuggestions([]);
        setSelectedSuggestion(false);
        setIsFocused(false);
    };

    return (
        <FormGroup ref={formControlRef} id={`form-group-${id}`} mb={mb}>
            <FormControl
                id={id}
                ref={refInput}
                tabIndex={tabIndex}
                type={inputType}
                placeholder={placeholder}
                value={type !== 'file' ? (!disabled ? value : '') : undefined}
                autoComplete={autoComplete || 'off'}
                maxLength={maxlength}
                widthInput={widthInput}
                borderRadius={borderRadius}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                readOnly={!editable}
                className={`${value ? `edited ${className}` : `${className}`}`}
                haveErrors={!!errors}
                success={!!success}
                disabled={disabled}
                haveLeftIcon={!!leftIcon}
                haveRightIcon={!!rightIcon || showClearIcon}
                withPrefix={prefix !== undefined && prefix !== ''}
                withSuffix={suffix !== undefined && suffix !== ''}
                {...props}
            />

            {type !== 'search' && (
                <FormLabel htmlFor={id} haveErrors={!!errors} haveLeftIcon={!!leftIcon} haveRightIcon={!!rightIcon} disabled={disabled}>
                    {label}
                </FormLabel>
            )}

            {leftIcon && (
                <FormLeftIcon haveErrors={!!errors} disabled={disabled} notEditable={!editable} focused={focused} withPrefix={prefix !== undefined && prefix !== ''}>
                    {leftIcon}
                </FormLeftIcon>
            )}

            {prefix && <Prefix haveLeftIcon={!!leftIcon}>{prefix}</Prefix>}

            {suffix && <Suffix haveRightIcon={!!rightIcon}>{suffix}</Suffix>}

            {rightIcon && (
                <FormRightIcon haveErrors={!!errors} disabled={disabled}>
                    {rightIcon}
                </FormRightIcon>
            )}

            {revealPasswordIcon && (
                <FormRightIcon
                    haveErrors={!!errors}
                    disabled={disabled}
                    onClick={() => {
                        setInputType(inputType === 'password' ? 'text' : 'password');
                    }}
                >
                    {inputType === 'password' && <FeatherIcon.Eye />}
                    {inputType === 'text' && <FeatherIcon.EyeOff />}
                </FormRightIcon>
            )}

            {showClearIcon && (
                <FormRightIcon haveErrors={!!errors} disabled={disabled} onClick={handleClearIcon}>
                    <FeatherIcon.X />
                </FormRightIcon>
            )}

            {!disabled && (!errors ? help && <HelpBlock>{help}</HelpBlock> : <ErrorBlock>{errors}</ErrorBlock>)}

            {maxlength && !disabled && <LetterCounter haveErrors={!!errors}>{`${count} / ${maxlength}`}</LetterCounter>}

            {value !== '' && isFocused && !selectedSuggestion && dataSuggestions && dataSuggestions.length > 0 && (
                <>
                    {filteredSuggestions.length > 0 ? (
                        <DataSuggestions>
                            {filteredSuggestions?.map((suggestion) => {
                                return (
                                    <ListOption
                                        key={suggestion.key}
                                        onClick={() => onOptionClick(suggestion)}
                                        dangerouslySetInnerHTML={{ __html: `${suggestion.value.replace(new RegExp(`(${value})`, 'i'), '<b>$1</b>')}` }}
                                    />
                                );
                            })}
                        </DataSuggestions>
                    ) : (
                        <NoSuggestions>
                            <NoSuggestionsText>{t('common:no-suggestions-to-show')}</NoSuggestionsText>
                        </NoSuggestions>
                    )}
                </>
            )}
        </FormGroup>
    );
};

Textfield.defaultProps = {
    type: 'text',
    editable: true,
};

export default Textfield;
