import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles

import 'swiper/css';
import 'swiper/css/navigation';

const ExperienceCarousel = ({ slides }) => {
    const [listImages, setListImages] = React.useState([]); // array for list of images with principal image

    React.useEffect(() => {
        const arrayImages = [];
        let copyObj = {};
        slides?.forEach((item) => {
            if (item.principal) copyObj = item;
            else arrayImages.push(item);
        });
        if (copyObj?.principal) arrayImages.unshift(copyObj);
        setListImages(arrayImages);
    }, [slides]);

    return (
        <Swiper
            id="carousel"
            pagination={{
                type: 'fraction',
            }}
            navigation
            modules={[Navigation, Pagination]}
            className="DestinationCarouselSwiper"
        >
            {listImages?.map((item) => {
                return (
                    <SwiperSlide key={item?.id}>
                        {item?.image?.url && (
                            <>
                                <Image src={item?.image?.url} alt={item?.image?.name} objectFit="cover" objectPosition="center center" priority layout="fill" />
                            </>
                        )}
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

ExperienceCarousel.propTypes = {
    slides: PropTypes.array,
};

export default ExperienceCarousel;
