import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

// Atoms
import { Typography } from 'components/atoms';

// Molecules
import { Rating } from 'components/molecules';

// Styles
import { TabPresentationContainer, RatingContainer, TextContainer, Wrapper } from './ExperienceSummary.styles';
import ReserveExperience from '../ReserveExperience';

const ExperienceSummary = ({ item, experience }) => {
    const { t } = useTranslation();
    const { title, text2, linkText } = item;

    return (
        <Wrapper>
            <TabPresentationContainer>
                {title ? <Typography variant="h2">{t(`${title}`)}</Typography> : null}
                <RatingContainer>
                    <Rating fillItems={3} link={t(`${linkText}`)} />
                </RatingContainer>

                <TextContainer>
                    {text2 && (
                        <div>
                            <Typography variant="body1">{t(`${text2}`)}</Typography>
                        </div>
                    )}
                </TextContainer>
            </TabPresentationContainer>
            <ReserveExperience experience={experience} />
        </Wrapper>
    );
};

ExperienceSummary.propTypes = {
    item: PropTypes.object,
};

export default ExperienceSummary;
