import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;

    ${({ theme }) => theme.media.md`
       align-items: center;
    `}

    ${({ theme }) => theme.media.lg`
       align-items: flex-start;
       flex-direction: row;
    `}
`;

export const First = styled.div`
    width: 100%;

    ${({ theme }) => theme.media.lg`
        width: calc(100% - 320px);
    `}
`;

export const Second = styled.div`
    width: 100%;
    position: static;

    ${({ theme }) => theme.media.md`
         width: 320px;
     `};

    ${({ theme }) => theme.media.lg`
        width: 280px;
        position: sticky;
        top: 85px;
    `};
`;
