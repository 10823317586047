import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    & > ul {
        columns: 1;
        column-gap: 4px;

        & > li {
            break-inside: avoid;
        }
    }

    ${({ theme }) => theme.media.md`
     & > ul {
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2;
        column-gap: 20px;
        }
    `}
`;
