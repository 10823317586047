import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Button, Typography } from '@/components/atoms';
import { NumberPickerProps } from './NumberPicker.types';
import { Container } from './NumberPicker.styles';

const NumberPicker: React.FC<NumberPickerProps> = ({ value, setValue }) => {
    return (
        <Container>
            <Button
                width="48px"
                variant="outlined"
                rounded
                disabled={value === 0}
                content={<FeatherIcon.Minus size={20} />}
                onClick={(e) => {
                    e.preventDefault();
                    setValue(value - 1);
                }}
            />
            <Typography
                variant="body1"
                style={{
                    margin: 'auto 22px',
                    width: '10px',
                }}
            >
                {value}
            </Typography>
            <Button
                width="48px"
                variant="outlined"
                rounded
                content={<FeatherIcon.Plus size={20} />}
                onClick={(e) => {
                    e.preventDefault();
                    setValue(value + 1);
                }}
            />
        </Container>
    );
};

NumberPicker.defaultProps = {
    value: 0,
};

export default NumberPicker;
