import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT } from '@/graphql/fragments';

export const JOB_REQUEST = gql`
    ${ERRORS_FRAGMENT}
    mutation JobOfferApply(
        $jobOffer: ID!
        $firstName: String!
        $lastName: String!
        $email: String!
        $phone: String!
        $address: String!
        $country: ID!
        $linkedinProfile: String
        $curriculum: Upload!
        $reason: String
        $expectedPayment: String
        $recaptchaToken: String
    ) {
        jobRequest(
            input: {
                jobOffer: $jobOffer
                firstName: $firstName
                lastName: $lastName
                email: $email
                phone: $phone
                address: $address
                country: $country
                linkedinProfile: $linkedinProfile
                curriculum: $curriculum
                reason: $reason
                expectedPayment: $expectedPayment
            },
            recaptchaToken: $recaptchaToken
        ) {
            status # Int
            errors {
                ...ErrorType
            }
        }
    }
`;
