import React from 'react';
import { useTranslation } from 'next-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface RecaptchaProviderProps {
    children: React.ReactNode;
}

const RecaptchaProvider: React.FC<RecaptchaProviderProps> = ({ children }) => {
    const { i18n } = useTranslation(); // Translations

    if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) return null;

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY} language={i18n.language}>
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default RecaptchaProvider;
