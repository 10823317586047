import { keyframes } from 'styled-components';

export const moveForever = keyframes`
    from {
        transform: translate3d(-90px,0,0);
    }

    to {
        transform: translate3d(85px,0,0);
    }
`;

export const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const fadeInFromCenter = keyframes`
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
`;

export const fadeInToCenter = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0);
    }
`;

export const fadeOut = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

export const pulsate = keyframes`
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
`;

export const skeletonLoading = keyframes`
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
`;

export const inputFileState = keyframes`
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
`;
