import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import { useTheme } from 'styled-components';

// Atoms
import { Typography } from 'components/atoms';

// styles
import { RatingContainer, IconContainer } from './Rating.styles';

const Rating = ({ fillItems, reviews, $mt, link }) => {
    // Hooks
    const theme = useTheme(); // Get current theme

    // Translations
    const { t } = useTranslation();

    const currentColor = theme.colors.secondary.secondary50;
    const stepsView = [...Array(5)];

    return (
        <RatingContainer $mt={$mt}>
            <IconContainer>
                {React.Children.toArray(
                    stepsView.map((e, i) => {
                        return <FeatherIcon.Star size={20} strokeWidth={2} fill={i <= fillItems ? currentColor : 'none'} color={currentColor} />;
                    })
                )}
            </IconContainer>
            {link ? (
                <Typography type="btn" $color={theme.colors.tertiary.tertiary50}>
                    {link}
                    <FeatherIcon.ChevronRight size={20} strokeWidth={1} />
                </Typography>
            ) : (
                <Typography type="btn">
                    {reviews} {t('destinations:details.review')}
                </Typography>
            )}
        </RatingContainer>
    );
};

Rating.propTypes = {
    fillItems: PropTypes.number,
    reviews: PropTypes.number,
    $mt: PropTypes.string,
    link: PropTypes.string,
};

export default Rating;
