import React from 'react';
import createPersistedState from 'use-persisted-state';
import { PersistentState } from "./Wizard.types";
import { initialState } from './WizardReducer';

const usePersistentWizardState = createPersistedState<PersistentState>('flight-wizard');

const usePersistentWizard = (): [PersistentState, React.Dispatch<React.SetStateAction<PersistentState>>] => {
    const [state, setState] = usePersistentWizardState(initialState);

    return [state, setState];
};

export default usePersistentWizard;
