import React from 'react';
import WizardContext from './WizardContext';
import { WizardContextType } from './Wizard.types';

const useWizard = (): WizardContextType => {
    const context = React.useContext(WizardContext);
    if (!context) {
        throw new Error('useWizard must be used within a WizardProvider');
    }
    return context;
};

export default useWizard;
