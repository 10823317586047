import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    * {
        outline: none;
    }

    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }

    html {
        width: 100%;
        min-height: 100vh;
        font-size: 16px;
        height: 100%;
        scroll-behavior: smooth !important;
    }

    ::-webkit-scrollbar {
        width: 0.7em;
    }

    ::-webkit-scrollbar-track {
        border-radius: 35px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.primary.primary50};
        border-radius: 25px;
        border: 0.2em solid transparent;
        background-clip: padding-box;
    }

    section[id]{
        scroll-margin-top: 56px;
    }

    section[id^="section"]{
        scroll-margin-top: 112px;
    }

    body {
        font-family: ${({ theme }) => theme.font.body1.family};
        font-size: ${({ theme }) => theme.font.body1.mobile.size};
        font-weight: ${({ theme }) => theme.font.body1.mobile.weight};
        line-height: ${({ theme }) => theme.font.body1.mobile.lineheight};
        font-style: normal;
        color: ${({ theme }) => theme.colors.neutral.neutral10};
        background-color: ${({ theme }) => theme.colors.basic.white};
        overflow-x: hidden !important;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizelegibility;
        margin: 0;
        width: 100%;
        min-height: 100vh;

        ${({ theme }) => theme.media.md`
            font-size: ${theme.font.body1.tablet.size};
            font-weight: ${theme.font.body1.tablet.weight};
            line-height: ${theme.font.body1.tablet.lineheight};
        `}

        ${({ theme }) => theme.media.xl`
            font-size: ${theme.font.body1.desktop.size};
            font-weight: ${theme.font.body1.desktop.weight};
            line-height: ${theme.font.body1.desktop.lineheight};
        `}

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
                outline: none;
                cursor: pointer;
                transition: all 0.2s linear;
            }
        }

        img {
            border-style: none;
        }

        input,
        textarea,
        button,
        select,
        svg,
        div,
        a {
            -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
        }

        // TODO: Remove for this
        .swal2-container {
            z-index: 9999;
        }

        &.loading-page {
            overflow: hidden;
        }

        & .grecaptcha-badge {
           z-index: 9999;
        }
    }
`;

export default GlobalStyles;
