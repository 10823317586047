import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, JOB_OFFER_FRAGMENT, PAGE_INFO_FRAGMENT } from '@/graphql/fragments';

export const JOB_OFFERS_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${JOB_OFFER_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    query JobOffers($skip: Int, $take: Int, $report: Boolean) {
        jobOffers(report: $report, skip: $skip, take: $take) {
            status
            errors {
                ...ErrorType
            }
            objects {
                ...JobOfferType
            }
            pageInfo {
                ...PageInfoType
            }
        }
    }
`;

export const JOB_OFFER_BY_SLUG = gql`
    ${ERRORS_FRAGMENT}
    ${JOB_OFFER_FRAGMENT}
    query JobOfferBySlug($slug: String!) {
        jobOfferBySlug(slug: $slug) {
            status
            errors {
                ...ErrorType
            }
            object {
                ...JobOfferType
            }
        }
    }
`;
