import styled from 'styled-components';
import { fadeIn } from '@/styles/animations';

export const Card = styled.div`
    display: flex;
    padding: 40px 30px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    align-self: stretch;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.colors.neutral.neutral98};
    animation: ${fadeIn} 1s ease-in-out;
`;

export const TopContainer = styled.div`
    display: flex;
    flex-flow: row;
    width: 100%;
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
`;

export const Departure = styled.div`
    padding: 0 30px 0 0;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
`;

export const Scale = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

export const Arrival = styled.div`
    padding: 0 0 0 30px;
`;

export const Separator = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral90};
    height: 1px;
    width: 100%;
    margin: 20px 0 0;
`;
