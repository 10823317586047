import React from 'react';
import { useTranslation } from 'next-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

// Icons
import * as FeatherIcon from 'react-feather';

// Utils
import UrlMapper from 'utils/url-mapper';
import { capitalize } from 'utils/helpers';

// Data
import { COUNTRIES } from '@/graphql/queries';

// Atoms
import { Textfield, Typography, Datepicker, Select, Button } from 'components/atoms';

// Styles
import { CustomForm, Row, Col, Buttons } from './PersonalInfo.styles';

const PersonalInfo = ({ saveUser, errorss, send }) => {
    // Hooks
    const { t, i18n } = useTranslation(); // Translations
    const router = useRouter(); // Router

    // User session
    const { data: session } = useSession();
    const user = session?.user;

    // Forms initial values
    const initialValues = {
        firstName: user?.firstName ? user.firstName : '',
        lastName: user?.lastName ? user.lastName : '',
        birthday: user?.birthday ? new Date(user.birthday) : null,
        country: user?.country ? { value: user.country.id, label: user.country.name } : null,
        email: user?.email,
        phone: user?.phone ? user.phone : '',
        passport: user?.passport ? user.passport : '',
        dateIssue: user?.passportEmittedAt ? new Date(user.passportEmittedAt) : null,
        expirationDate: user?.passportExpireAt ? new Date(user.passportExpireAt) : null,
        countryPasp: user?.passportEmissionCountry ? { value: user.passportEmissionCountry.id, label: user.passportEmissionCountry.name } : null,
    };

    // query that returns the countries
    const {
        // loading,
        // error,
        data: countries,
    } = useQuery(COUNTRIES, {
        variables: { report: true },
        fetchPolicy: 'cache-and-network',
        context: { headers: { Language: i18n.language } },
    });

    // list of select countries
    const options = countries?.countries?.objects.map((count) => ({ value: count.id, label: capitalize(count.name) }));

    // Validation schema
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .matches(/^([^0-9]*)$/, t('profile:personal-info.no-number.firstname'))
            .required(t('profile:personal-info.required.firstname')),
        lastName: Yup.string()
            .matches(/^([^0-9]*)$/, t('profile:personal-info.no-number.lastname'))
            .required(t('profile:personal-info.required.lastname')),
        email: Yup.string().email(t('profile:contact-info.invalid.email')).required(t('profile:contact-info.required.email')),
    });

    const changeFormatDate = (oldDate) => {
        const date = new Date(oldDate);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let newDate;

        if (month < 10 && day < 10) {
            newDate = `${year}-0${month}-0${day}`;
        } else if (day < 10) {
            newDate = `${year}-${month}-0${day}`;
        } else if (month < 10) {
            newDate = `${year}-0${month}-${day}`;
        } else {
            newDate = `${year}-${month}-${day}`;
        }

        return newDate;
    };

    const handleSaveUser = (values, { resetForm }) => {
        let birth;
        let issue;
        let expiration;
        if (values.birthday) birth = changeFormatDate(values.birthday);
        if (values.dateIssue) issue = changeFormatDate(values.dateIssue);
        if (values.expirationDate) expiration = changeFormatDate(values.expirationDate);

        saveUser(values, birth, issue, expiration, resetForm);
    };

    return (
        <>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSaveUser}>
                {({ errors, values, touched, setFieldError, handleChange, handleBlur, setFieldValue, setFieldTouched }) => {
                    React.useEffect(() => {
                        errorss.map((error) => {
                            return setFieldError(error.field, error.messages);
                        });
                    }, [setFieldError, errorss]);

                    return (
                        <CustomForm>
                            <Typography type="h2" $align="center" $textDecoration>
                                {t('profile:section.label.personal-info')}
                            </Typography>
                            <Row>
                                <Col>
                                    <Textfield
                                        id="firstName"
                                        type="text"
                                        label={t('profile:personal-info.label.firstname')}
                                        placeholder={t('profile:personal-info.placeholder.firstname')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.firstName}
                                        errors={touched.firstName && errors.firstName}
                                        success={(touched.firstName || values?.firstName !== '') && !errors.firstName}
                                    />
                                </Col>
                                <Col>
                                    <Textfield
                                        id="lastName"
                                        type="text"
                                        label={t('profile:personal-info.label.lastname')}
                                        placeholder={t('profile:personal-info.placeholder.lastname')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.lastName}
                                        errors={touched.lastName && errors.lastName}
                                        success={(touched.lastName || values?.lastName !== '') && !errors.lastName}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Datepicker
                                    dateFormat="dd-MM-yyyy"
                                    id="birthday"
                                    label={t('profile:personal-info.label.birthday')}
                                    placeholderText={t('profile:personal-info.placeholder.birthday')}
                                    selected={values?.birthday}
                                    value={values?.birthday}
                                    onChange={(birthday) => setFieldValue('birthday', birthday)}
                                    maxDate={new Date()}
                                    leftIcon={<FeatherIcon.Calendar />}
                                    onBlur={handleBlur}
                                    errors={touched.birthday && errors.birthday}
                                    success={(touched.birthday || values?.birthday !== '') && !errors.birthday}
                                />
                                <Col>
                                    <Select
                                        id="country"
                                        key={`country__${JSON.stringify(values?.country)}`}
                                        label={t('profile:personal-info.label.country')}
                                        placeholder={t('profile:personal-info.placeholder.country')}
                                        defaultValue={values?.country || null}
                                        options={options}
                                        onChange={(option) => {
                                            setFieldValue('country', option);
                                        }}
                                        onBlur={() => setFieldTouched('country', true)}
                                        isClearable
                                        errors={touched.country && errors.country}
                                        success={(touched.country || values?.country !== '') && !errors.country}
                                    />
                                </Col>
                            </Row>
                            <Typography type="h2" $align="center" $textDecoration>
                                {t('profile:section.label.contact-info')}
                            </Typography>
                            <Row>
                                <Col>
                                    <Textfield
                                        id="email"
                                        type="email"
                                        label={t('profile:contact-info.label.email')}
                                        placeholder={t('profile:contact-info.placeholder.email')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.email}
                                        errors={touched.email && errors.email}
                                        success={(touched.email || values?.email !== '') && !errors.email}
                                    />
                                </Col>
                                <Col>
                                    <Textfield
                                        id="phone"
                                        type="text"
                                        label={t('profile:contact-info.label.phone')}
                                        placeholder={t('profile:contact-info.placeholder.phone')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.phone}
                                        errors={touched.phone && errors.phone}
                                        success={(touched.phone || values?.phone !== '') && !errors.phone}
                                    />
                                </Col>
                            </Row>
                            <Typography type="h2" $align="center" $textDecoration>
                                {t('profile:section.label.passport-info')}
                            </Typography>
                            <Row>
                                <Col>
                                    <Textfield
                                        id="passport"
                                        type="text"
                                        label={t('profile:passport-info.label.passport')}
                                        placeholder={t('profile:passport-info.placeholder.passport')}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.passport}
                                        errors={touched.passport && errors.passport}
                                        success={(touched.passport || values?.passport !== '') && !errors.passport}
                                    />
                                </Col>
                                <Col>
                                    <Select
                                        id="countryPasp"
                                        key={`countryPasp__${JSON.stringify(values?.countryPasp)}`}
                                        label={t('profile:passport-info.label.country')}
                                        placeholder={t('profile:passport-info.placeholder.country')}
                                        defaultValue={values?.countryPasp || null}
                                        options={options}
                                        onChange={(option) => {
                                            setFieldValue('countryPasp', option);
                                        }}
                                        onBlur={() => setFieldTouched('countryPasp', true)}
                                        isClearable
                                        errors={touched.countryPasp && errors.countryPasp}
                                        success={(touched.countryPasp || values?.countryPasp !== '') && !errors.countryPasp}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Datepicker
                                    dateFormat="dd-MM-yyyy"
                                    id="dateIssue"
                                    label={t('profile:passport-info.label.issue-date')}
                                    placeholderText={t('profile:passport-info.placeholder.issue-date')}
                                    selected={values?.dateIssue}
                                    onChange={(dateIssue) => setFieldValue('dateIssue', dateIssue)}
                                    maxDate={values?.expirationDate}
                                    leftIcon={<FeatherIcon.Calendar />}
                                    onBlur={handleBlur}
                                    value={values?.dateIssue}
                                    errors={touched.dateIssue && errors.dateIssue}
                                    success={(touched.dateIssue || values?.dateIssue !== '') && !errors.dateIssue}
                                />
                                <Datepicker
                                    dateFormat="dd-MM-yyyy"
                                    id="expirationDate"
                                    label={t('profile:passport-info.label.expiration-date')}
                                    placeholderText={t('profile:passport-info.placeholder.expiration-date')}
                                    selected={values?.expirationDate}
                                    onChange={(expirationDate) => setFieldValue('expirationDate', expirationDate)}
                                    minDate={values?.dateIssue}
                                    leftIcon={<FeatherIcon.Calendar />}
                                    onBlur={handleBlur}
                                    value={values?.expirationDate}
                                    errors={touched.expirationDate && errors.expirationDate}
                                    success={(touched.expirationDate || values?.expirationDate !== '') && !errors.expirationDate}
                                />
                            </Row>
                            <Buttons>
                                <Button
                                    content={t('profile:button.go-to-home')}
                                    variant="outlined"
                                    type="button"
                                    disabled={send}
                                    onClick={() => {
                                        router.push(UrlMapper.root[i18n.language]);
                                    }}
                                />
                                <Button content={t('profile:button.save')} variant="filled" type="submit" isSubmitting={send} disabled={send} />
                            </Buttons>
                        </CustomForm>
                    );
                }}
            </Formik>
        </>
    );
};

PersonalInfo.propTypes = {
    saveUser: PropTypes.func,
    send: PropTypes.bool,
    errorss: PropTypes.array,
};

export default PersonalInfo;
