import React from 'react';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import Link from 'next/link';
import { neutral } from '@/styles/colors';
import { Typography } from '@/components/atoms';
import { BreadcrumbsProps } from './Breadcrumbs.types';
import { Wrapper, Container, Item, ItemContainer } from './Breadcrumbs.styles';

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, current }) => {
    const { t } = useTranslation(); // Translations

    const size = breadcrumbs.length;

    return (
        <Wrapper>
            <Container>
                <ul>
                    {breadcrumbs.map((item, i) => {
                        return (
                            <Item key={item.id}>
                                {!item.link && item.onClick && (
                                    <ItemContainer onClick={item.onClick}>
                                        <Typography variant="body2" weight={current === item.id ? 600 : 400}>
                                            {item.value}
                                        </Typography>
                                    </ItemContainer>
                                )}
                                {!item.link && !item.onClick && (
                                    <Typography variant="body2" weight={current === item.id ? 600 : 400}>
                                        {item.value}
                                    </Typography>
                                )}
                                {item.link && (
                                    <Link href={item.link} passHref>
                                        <Typography variant="body2">{t(`${item.value}`)}</Typography>
                                    </Link>
                                )}
                                {i <= size - 2 && (
                                    <span>
                                        <FeatherIcon.ChevronRight size={20} color={neutral.neutral10} />
                                    </span>
                                )}
                            </Item>
                        );
                    })}
                </ul>
            </Container>
        </Wrapper>
    );
};

export default Breadcrumbs;
