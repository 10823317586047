import React from 'react';
import { Button, Typography } from '@/components/atoms';
import MailboxSVG from '@/assets/images/messages/mailbox.svg';
import FlagSVG from '@/assets/images/messages/flag.svg';
import { AuthMessageProps } from './AuthMessage.types';
import { ButtonContainer, Content, ImageWrapper } from './AuthMessage.styles';

const AuthMessage: React.FC<AuthMessageProps> = ({ title, message, image, button }) => {
    return (
        <Content>
            <ImageWrapper>
                {image === 'flag' && <FlagSVG />}
                {image === 'mailbox' && <MailboxSVG />}
            </ImageWrapper>
            <Typography
                as="h1"
                variant="h2"
                align="center"
                mt="19px"
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />
            <Typography
                variant="body1"
                align="center"
                mt="3px"
                mb="18px"
                dangerouslySetInnerHTML={{
                    __html: message,
                }}
            />

            {button && (
                <ButtonContainer>
                    <Button
                        content={button?.content}
                        as={button?.link ? 'a' : 'button'}
                        type="button"
                        variant="filled"
                        onClick={button.onClick}
                        target={button?.link ? '_blank' : ''}
                        href={button?.link ? button.link : undefined}
                    />
                </ButtonContainer>
            )}
        </Content>
    );
};

AuthMessage.defaultProps = {
    image: 'mailbox',
};

export default AuthMessage;
