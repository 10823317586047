import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import { Button, Typography } from '@/components/atoms';
import { CarouselProps } from './Carousel.types';
import { CarouselStyles, Overlay, SlideContent } from './Carousel.styles';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Carousel: React.FC<CarouselProps & OptimalChildrenType> = ({ slides, isDynamic, contentCentered, overlay, btnIcon, children, height }) => {
    const { t } = useTranslation(); // Translations
    const router = useRouter(); // Router
    const theme = useTheme(); // Get current theme
    const [loaded, setLoaded] = React.useState(false);

    return (
        <>
            <CarouselStyles loaded={loaded} height={height || 650} />
            <Swiper
                id="carousel"
                tag="section"
                rewind
                centeredSlides
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                }}
                pagination={slides?.length > 1}
                navigation={slides?.length > 1}
                modules={[Autoplay, Navigation, Pagination]}
                className="CarouselSwiper"
            >
                {slides?.map((item) => {
                    const formattedTitle = item?.title?.replace(/<[^>]+>/g, '');

                    return (
                        <SwiperSlide key={`key-${item.id}`}>
                            <Overlay overlay={overlay} />
                            {item?.image && item?.image?.url && (
                                <Image
                                    src={item.image.url}
                                    alt={formattedTitle || t('carousel:image.alt')}
                                    fill
                                    priority
                                    onLoadingComplete={() => setLoaded(true)}
                                    style={{
                                        objectPosition: item.image.position || 'center center',
                                    }}
                                />
                            )}

                            <SlideContent centered={contentCentered} isDynamic={isDynamic}>
                                {item.title && (
                                    <Typography
                                        variant="h1"
                                        color={theme.colors.basic.white}
                                        align={contentCentered ? 'center' : 'left'}
                                        dangerouslySetInnerHTML={{
                                            __html: item?.title,
                                        }}
                                        mb="25px"
                                    />
                                )}

                                {item.subtitle && (
                                    <Typography
                                        variant="body1"
                                        color={theme.colors.basic.white}
                                        align={contentCentered ? 'center' : 'left'}
                                        mb="25px"
                                        maxlines={5}
                                        dangerouslySetInnerHTML={{
                                            __html: item?.subtitle,
                                        }}
                                    />
                                )}

                                {item.buttonText && (
                                    <Button
                                        content={item.buttonText}
                                        variant="filled"
                                        icon={btnIcon}
                                        iconPosition="left"
                                        type="button"
                                        onClick={() => {
                                            if (item.buttonLink) {
                                                router
                                                    .push(item.buttonLink, undefined, { shallow: true })
                                                    .then(() => {})
                                                    .catch(() => {});
                                            }
                                        }}
                                    />
                                )}

                                {children}
                            </SlideContent>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
};

export default Carousel;
