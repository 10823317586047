import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'next-i18next';
// import { capitalize } from '@/utils/helpers';
// import { useCountries } from '@/services/countries';
import { useWizard } from '@/modules/wizard';
import { Textfield, Select, PhoneInput, Asterisk, Button, Checkbox } from '@/components/atoms';
import type { OptionType } from '@/components/atoms/Select';
import { PassengerFormType, PassengersFormProps } from './PassengersForm.types';
import { CustomForm, Row } from './PassengersForm.styles';
import { AirTraveler, IdDocumentTypeEnum, NamePrefixEnum, PassengerTypesEnum } from '@/graphql/types';

const PassengersForm: React.FC<PassengersFormProps> = ({ passenger, initialData, position, isTheFirst, isTheLast }) => {
    const { t } = useTranslation(); // Translations
    const { state, dispatch } = useWizard();
    // const { countries } = useCountries();

    // Forms initial values
    const initialValues: PassengerFormType = {
        title: initialData?.namePrefix || '',
        firstName: initialData?.givenName || '',
        lastName: initialData?.surname || '',
        idDocumentType: initialData?.idDocumentType || '',
        idNumber: initialData?.idNumber || '',
        countryAccessCode: ((state?.data?.sameContactInfo ? state?.data?.samePhoneCode : initialData?.countryAccessCode) as string) || 'us',
        phoneAreaCityCode: ((state?.data?.sameContactInfo ? state?.data?.samePhoneCode : initialData?.phoneAreaCityCode) as string) || 'us',
        phoneNumber: ((state?.data?.sameContactInfo ? state?.data?.samePhone : initialData?.phoneNumber) as string) || '',
        email: ((state?.data?.sameContactInfo ? state?.data?.sameEmail : initialData?.email) as string) || '',
        sameContactInfo: state?.data?.sameContactInfo || false,
    };

    const titleOptions = [
        { value: 'MR', label: t('flights:passenger.title.mr') },
        { value: 'MSS', label: t('flights:passenger.title.ms') },
        { value: 'JR', label: t('flights:passenger.title.jr') },
    ];

    const documentTypeOptions = [
        { value: 'PASSPORT', label: t('flights:passenger.id_document_type.passport') },
        { value: 'DNI', label: t('flights:passenger.id_document_type.dni') },
    ];

    // const countryOptions = countries?.map((count: { id: string; name: string }) => ({
    //     value: count.id,
    //     label: capitalize(count.name),
    // }));

    // Validation schema
    const validationSchema = Yup.object({
        title: Yup.mixed().notRequired(),
        firstName: Yup.string().required(t('flights:passenger.firstname.required')),
        lastName: Yup.string().required(t('flights:passenger.lastname.required')),
        idDocumentType: Yup.mixed().required(t('flights:passenger.id_document_type.required')),
        idNumber: Yup.string().required(t('flights:passenger.id_number.required')),
        countryAccessCode: Yup.mixed().required(t('flights:passenger.country.required')),
        phoneAreaCityCode: Yup.string().required(t('flights:passenger.phone.required')),
        phoneNumber: Yup.lazy(() =>
            isTheFirst || !state?.data?.sameContactInfo
                ? Yup.string()
                      .required(t('flights:passenger.phone.required'))
                      .test('phone-no-match', t('flights:passenger.phone.required'), function (value) {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          const phoneAreaCityCode = this.parent.phoneAreaCityCode;
                          return phoneAreaCityCode !== value;
                      })
                : Yup.string().notRequired()
        ),
        email: Yup.lazy(() =>
            isTheFirst || !state?.data?.sameContactInfo
                ? Yup.string().email(t('flights:passenger.email.invalid')).required(t('flights:passenger.email.required'))
                : Yup.string().notRequired()
        ),
        sameContactInfo: Yup.boolean().notRequired(),
    });

    const handleSubmit = (values: PassengerFormType) => {
        const airTraveler: Omit<AirTraveler, 'phoneAreaCityCode' | 'phoneNumber' | 'email' | 'middleName'> & {
            phoneAreaCityCode?: string;
            phoneNumber?: string;
            email?: string;
        } = {
            passengerType: passenger.type as PassengerTypesEnum,
            namePrefix: values.title as unknown as NamePrefixEnum,
            givenName: values.firstName as string,
            surname: values.lastName as string,
            idNumber: values.idNumber as string,
            idDocumentType: values.idDocumentType as unknown as IdDocumentTypeEnum,
            countryAccessCode: values.countryAccessCode as string,
            phoneAreaCityCode: values.phoneAreaCityCode,
            phoneNumber: values.phoneNumber,
            email: values.email,
            travelerRefNumber: passenger.number,
        };

        const newTravelersArray = [...(state.data?.travelers || [])];

        if (position >= 0 && position < newTravelersArray.length) {
            newTravelersArray[position] = airTraveler as AirTraveler;
        } else {
            newTravelersArray.push(airTraveler as AirTraveler);
        }

        dispatch({
            type: 'UPDATE_DATA',
            payload: {
                passengerActive: !isTheLast ? position + 1 : position,
                travelers: newTravelersArray as [AirTraveler],
            },
        });

        if (isTheLast) {
            dispatch({ type: 'NEXT_STEP' });
        }
    };

    return (
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, values, touched, handleChange, handleBlur, setFieldValue, setFieldTouched }) => {
                const selectedTitleOption = titleOptions.find((option) => option.value === values.title);
                const selectedIdDocumentTypeOption = documentTypeOptions.find((option) => option.value === values.idDocumentType);

                return (
                    <CustomForm>
                        <Row style={{ paddingTop: '28px' }}>
                            <div style={{ width: '50%' }}>
                                <Select
                                    id="title"
                                    key="title"
                                    label={t('flights:passenger.title.label')}
                                    placeholder={t('flights:passenger.title.placeholder')}
                                    defaultValue={selectedTitleOption}
                                    options={titleOptions}
                                    isClearable
                                    onChange={(option) => {
                                        const optionValue = option as OptionType;
                                        void setFieldValue('title', optionValue?.value || '');
                                    }}
                                    onBlur={() => void setFieldTouched('title', true)}
                                    errors={touched.title && errors.title}
                                    success={(touched.title || values?.title !== '') && !errors.title}
                                />
                            </div>
                            <Textfield
                                id="firstName"
                                type="text"
                                label={<Asterisk text={t('flights:passenger.firstname.label')} />}
                                placeholder={t('flights:passenger.firstname.placeholder')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.firstName}
                                errors={touched.firstName && errors.firstName}
                                success={(touched.firstName || values?.firstName !== '') && !errors.firstName}
                            />
                            <Textfield
                                id="lastName"
                                type="text"
                                label={<Asterisk text={t('flights:passenger.lastname.label')} />}
                                placeholder={t('flights:passenger.lastname.placeholder')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.lastName}
                                errors={touched.lastName && errors.lastName}
                                success={(touched.lastName || values?.lastName !== '') && !errors.lastName}
                            />
                        </Row>
                        <Row>
                            <div style={{ width: '80%' }}>
                                <Select
                                    id="idDocumentType"
                                    key="idDocumentType"
                                    label={<Asterisk text={t('flights:passenger.id_document_type.label')} />}
                                    placeholder={t('flights:passenger.id_document_type.placeholder')}
                                    defaultValue={selectedIdDocumentTypeOption}
                                    options={documentTypeOptions}
                                    isClearable
                                    onChange={(option) => {
                                        const optionValue = option as OptionType;
                                        void setFieldValue('idDocumentType', optionValue?.value || '');
                                    }}
                                    onBlur={() => void setFieldTouched('idDocumentType', true)}
                                    errors={touched.idDocumentType && errors.idDocumentType}
                                    success={(touched.idDocumentType || values?.idDocumentType !== '') && !errors.idDocumentType}
                                />
                            </div>
                            <Textfield
                                id="idNumber"
                                type="text"
                                label={<Asterisk text={t('flights:passenger.id_number.label')} />}
                                placeholder={t('flights:passenger.id_number.placeholder')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.idNumber}
                                errors={touched.idNumber && errors.idNumber}
                                success={(touched.idNumber || values?.idNumber !== '') && !errors.idNumber}
                            />
                            {/*<Select*/}
                            {/*    id="countryAccessCode"*/}
                            {/*    key="countryAccessCode"*/}
                            {/*    label={<Asterisk text={t('flights:passenger.country.label')} />}*/}
                            {/*    placeholder={t('flights:passenger.country.placeholder')}*/}
                            {/*    defaultValue={values?.countryAccessCode}*/}
                            {/*    options={countryOptions}*/}
                            {/*    isClearable*/}
                            {/*    onChange={(option) => {*/}
                            {/*        void setFieldValue('countryAccessCode', option);*/}
                            {/*    }}*/}
                            {/*    onBlur={() => void setFieldTouched('countryAccessCode', true)}*/}
                            {/*    errors={touched.countryAccessCode && errors.countryAccessCode}*/}
                            {/*    success={(touched.countryAccessCode || values?.countryAccessCode?.value !== '') && !errors.countryAccessCode}*/}
                            {/*/>*/}
                        </Row>
                        {(isTheFirst || !state?.data?.sameContactInfo) && (
                            <Row>
                                <PhoneInput
                                    id="phoneNumber"
                                    key="phoneNumber"
                                    label={<Asterisk text={t('flights:passenger.phone.label')} />}
                                    placeholder={t('flights:passenger.phone.placeholder')}
                                    searchPlaceholder={t('flights:passenger.phone.search')}
                                    searchNotFound={t('flights:passenger.phone.search-not-found')}
                                    country={values.phoneAreaCityCode}
                                    preferredCountries={['jm', 'us', 'cu']}
                                    defaultValue={values?.phoneNumber}
                                    onChange={(value, data) => {
                                        void setFieldValue('countryAccessCode', data.dialCode);
                                        void setFieldValue('phoneAreaCityCode', data.dialCode);
                                        void setFieldValue('phoneNumber', value);
                                    }}
                                    onBlur={() => {
                                        void setFieldTouched('countryAccessCode', true);
                                        void setFieldTouched('phoneAreaCityCode', true);
                                        void setFieldTouched('phoneNumber', true);
                                    }}
                                    errors={touched.phoneNumber && errors.phoneNumber}
                                    success={(touched.phoneNumber || values?.phoneNumber !== '') && !errors.phoneNumber}
                                />
                                <Textfield
                                    id="email"
                                    type="text"
                                    label={<Asterisk text={t('flights:passenger.email.label')} />}
                                    placeholder={t('flights:passenger.email.placeholder')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.email}
                                    errors={touched.email && errors.email}
                                    success={(touched.email || values?.email !== '') && !errors.email}
                                />
                            </Row>
                        )}

                        {isTheFirst && (
                            <Row>
                                <Checkbox
                                    label={t('flights:passenger.same-contact-info.label')}
                                    checked={values.sameContactInfo as boolean}
                                    onChange={(e) => {
                                        void setFieldValue('sameContactInfo', e.target.checked);
                                        dispatch({
                                            type: 'UPDATE_DATA',
                                            payload: {
                                                sameContactInfo: e.target.checked,
                                            },
                                        });

                                        if (e.target.checked) {
                                            dispatch({
                                                type: 'UPDATE_DATA',
                                                payload: {
                                                    samePhoneCode: values.countryAccessCode as string,
                                                    samePhone: values.phoneNumber as string,
                                                    sameEmail: values.email as string,
                                                },
                                            });
                                        } else {
                                            dispatch({
                                                type: 'UPDATE_DATA',
                                                payload: {
                                                    samePhoneCode: '',
                                                    samePhone: '',
                                                    sameEmail: '',
                                                },
                                            });
                                        }
                                    }}
                                />
                            </Row>
                        )}
                        <Row
                            style={{
                                marginTop: isTheFirst ? '28px' : '',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button content={!isTheLast ? t('flights:passenger.button.save-continue') : t('flights:passenger.button.save')} type="submit" variant="filled" />
                        </Row>
                    </CustomForm>
                );
            }}
        </Formik>
    );
};

export default PassengersForm;
