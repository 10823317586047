import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT } from '@/graphql/fragments';

export const VERIFY_PAYMENT = gql`
    ${ERRORS_FRAGMENT}
    query PayVerification($code: String!, $amount: Decimal!, $recaptchaToken: String!) {
        payVerification(code: $code, amount: $amount, recaptchaToken: $recaptchaToken) {
            status
            errors {
                ...ErrorType
            }
            documentUrl
        }
    }
`;
