import styled, { css } from 'styled-components';
import { IconProps } from './Icon.types';

import Agent from '@/assets/svg/icons/agent.svg';
import Puzzle from '@/assets/svg/icons/puzzle.svg';
import Package from '@/assets/svg/icons/package.svg';
import Insurance from '@/assets/svg/icons/insurance.svg';
import Target2 from '@/assets/svg/icons/target.svg';
import SortFilter from '@/assets/svg/icons/sort-field.svg';
import Car2 from '@/assets/svg/icons/car-filter.svg';
import Hotel2 from '@/assets/svg/icons/hotel2.svg';
import Hotel3 from '@/assets/svg/icons/hotel3.svg';
import Plane2 from '@/assets/svg/icons/plane.svg';
import Plane3 from '@/assets/svg/icons/plane-empty.svg';

export const Icon = styled.i.withConfig({
    shouldForwardProp: (prop) => !['weight', 'align', 'size', 'width', 'color', 'padding', 'mt', 'mb'].includes(prop),
})<IconProps>`
    font-family: ${({ theme }) => theme.font.icons.chagod} !important;
    font-style: normal;
    font-weight: ${({ weight }) => weight || 'normal'};
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : mt || '0')};
    margin-bottom: ${({ mb }) => (typeof mb === 'number' ? `${mb}px` : mb || '0')};

    ${({ align }) =>
        align &&
        css`
            display: flex;
            justify-content: ${align || 'left'};
        `};

    &:before {
        content: '${({ content }) => content || ''}';
        font-size: ${({ size }) => size || '24'}px;
        font-weight: ${({ weight }) => weight || '400'};
        line-height: ${({ size }) => size || '24'}px;
        color: ${({ color, theme }) => color || theme.colors.neutral.neutral90};
        padding: ${({ padding }) => padding || '0'};
    }
`;

const Helm = styled(Icon)<IconProps>`
    &:before {
        content: '\\e000';
    }
`;

const User = styled(Icon)<IconProps>`
    &:before {
        content: '\\e001';
    }
`;

const Hotel = styled(Icon)<IconProps>`
    &:before {
        content: '\\e002';
    }
`;

const Flight = styled(Icon)<IconProps>`
    &:before {
        content: '\\e003';
    }
`;

const Car = styled(Icon)<IconProps>`
    &:before {
        content: '\\e004';
    }
`;

const Facebook = styled(Icon)<IconProps>`
    &:before {
        content: '\\e005';
    }
`;

const Instagram = styled(Icon)<IconProps>`
    &:before {
        content: '\\e006';
    }
`;

const Linkedin = styled(Icon)<IconProps>`
    &:before {
        content: '\\e007';
    }
`;

const Youtube = styled(Icon)<IconProps>`
    &:before {
        content: '\\e008';
    }
`;

const Telegram = styled(Icon)<IconProps>`
    &:before {
        content: '\\e009';
    }
`;

const Pinterest = styled(Icon)<IconProps>`
    &:before {
        content: '\\e00a';
    }
`;

const Whatsapp = styled(Icon)<IconProps>`
    &:before {
        content: '\\e00b';
    }
`;

const Hotels = styled(Icon)<IconProps>`
    &:before {
        content: '\\e00c';
    }
`;

const Plane = styled(Icon)<IconProps>`
    &:before {
        content: '\\e00d';
    }
`;

export {
    Agent,
    Helm,
    User,
    Flight,
    Car,
    Puzzle,
    Package,
    Insurance,
    Target2,
    Car2,
    Facebook,
    Youtube,
    Instagram,
    Linkedin,
    Telegram,
    Pinterest,
    Whatsapp,
    Hotels,
    Hotel,
    Hotel2,
    Hotel3,
    SortFilter,
    Plane,
    Plane2,
    Plane3,
};
