import styled, { createGlobalStyle } from 'styled-components';
import { Container } from '@/styles/common';
import { moveForever } from '@/styles/animations';

export const ComponentStyles = createGlobalStyle`
    .animated-background {
        background-color: ${({ theme }) => theme.colors.neutral.neutral96};
        width: 100%;
        height: 7vh;

        ${({ theme }) => theme.media.md`
            height: 10vh;
        `}
    }

    .animate-moveForever {
        animation: ${moveForever} 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .waves > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 10s;
    }

    .waves > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 15s;
    }

    .waves > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 20s;
    }

    .waves > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 5s;
    }
`;

export const Wrapper = styled.footer`
    background-color: ${({ theme }) => theme.colors.primary.primary95};
    margin-top: -10px;
`;

export const TopWrapper = styled(Container)`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 40px 15px 40px 15px;
    gap: 40px;

    ${({ theme }) => theme.media.md`
        flex-flow: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 80px 0;
        gap: 0;
    `}
`;

export const FirstColumn = styled.div`
    max-width: 280px;
    flex-basis: 30%;
`;

export const CenterColumn = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    ${({ theme }) => theme.media.md`
        align-items: flex-start;
        justify-content: space-around;
    `}

    ${({ theme }) => theme.media.xl`
        flex-flow: row;
        align-items: flex-start;
        justify-content: space-around;
        flex-basis: 43%;
        gap: 0;
    `}
`;

export const LastColumn = styled.div`
    flex-basis: 27%;
    width: 100%;
`;

export const Column4 = styled.div`

`;

export const BottomWrapper = styled.div`
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    flex-flow: column;
    gap: 3px;
    justify-content: center;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};

    a {
        padding-left: 5px;
        color: ${({ theme }) => theme.colors.tertiary.tertiary50};

        &:hover,
        &:active {
            opacity: 0.9;
        }
    }
`;

// export const Wrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//
//     ${({ theme }) => theme.media.lg`
//         flex-direction: row;
//     `}
// `;

export const LeftContainer = styled.div`
    width: 80%;
    height: 100%;

    ${({ theme }) => theme.media.md`
        width: 60%;
    `}

    ${({ theme }) => theme.media.lg`
        width: 25%;
        margin: 0;
    `}
`;

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;

    form {
        width: 100%;
    }

    ${({ theme }) => theme.media.md`
        align-items: flex-start;
    `}
`;
