import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

// Utils
import { datetoString } from 'utils/helpers';
// import { datetoString, extractTime, differenceFuntion } from 'utils/helpers';

// Schemas
import { FLIGHTS_PRICE_QUERY } from '@/graphql/queries';

// Atoms
import { Typography } from 'components/atoms';

// Molecules
import { FlightModalCard } from 'components/molecules';
// import { FlightsFareSwiper, FlightModalCard } from 'components/molecules';

// Hooks
// import { useDepartureSummary, useListAirport, useArrivalSummary, useBreadIndex } from 'hooks/PersistedStateFlights';

import { WrapperFrame, Header, SubFrame1, FrameContainer, FlightSummary } from './FlightTripModal.styles';

const FlightTripModal = ({ flight, ...rest }) => {
    const { t, i18n } = useTranslation(); // Translations

    const { origin, destination, departingDatetime, stopsQty, flights } = flight || {};
    // const { origin, destination, departingDatetime, arrivingDatetime, stopsQty, flights, isValid, airlines, categories, duration } = flight || {};

    // States
    // const [departureSummary, setDepartureSummary] = useDepartureSummary(); // persistent state departure summary
    // const [arrivalSummary, setArrivalSummary] = useArrivalSummary(); // persistent state arrival summary
    // const [listAirport] = useListAirport(); // persistent state list airport
    // const [breadIndex] = useBreadIndex(); // persistent state index

    // const flightSummary = breadIndex === 1 ? departureSummary : arrivalSummary;

    const { scaleHour, scaleMin, flightHour, flightMin, scaleList } = rest;
    // const { departureDate, departureLocation, arrivalLocation } = legDescriptions;

    const dateString = datetoString('short', new Date(departingDatetime));

    // React.useEffect(() => {
    //     const departureInfo = listAirport?.find((location) => location.AirportCode === departureLocation);
    //     const arrivalInfo = listAirport?.find((location) => location.AirportCode === arrivalLocation);

    //     if (breadIndex === 1) setDepartureSummary({ ...departureSummary, flightInfo: flight, departureInfo, arrivalInfo });
    //     if (breadIndex === 2) setArrivalSummary({ ...arrivalSummary, flightInfo: flight, departureInfo, arrivalInfo });
    // }, []);

    // function sliptScales() {
    //     const list = [];
    //     // eslint-disable-next-line no-plusplus
    //     for (let i = 0; i <= 2; i++) {
    //         const a = segment[i]?.arrival.time.substring(0, 8);
    //         const b = segment[i + 1]?.departure.time.substring(0, 8);

    //         if (a && b && a > b) {
    //             const result = differenceFuntion('24:00', a);

    //             const result2 = differenceFuntion('00:00', b);
    //             const sum = result + result2;
    //             list.push(sum);
    //         }

    //         if (a && b && b > a) {
    //             const result = differenceFuntion(a, b);
    //             list.push(result);
    //         }
    //     }
    //     return list;
    // }

    // const arrayScale = sliptScales();

    // kiuGetAvailablity
    const { loading: loadingPrice, data: price } = useQuery(FLIGHTS_PRICE_QUERY, {
        variables: {
            params: {
                outboundDepartureDatetimes: ['2023-06-10T19:00:00'],
                outboundArrivalDatetimes: ['2023-06-10T20:00:00'],
                outboundFlightNumbers: ['706'],
                outboundResBookDesigCodes: ['S'],
                outboundAirlineCodes: ['G0'],
                outboundOriginLocationCodes: ['CCS'],
                outboundDestinationLocationCodes: ['PMV'],
                returnDepartureDatetimes: ['2023-06-20T21:00:00'],
                returnArrivalDatetimes: ['2023-06-20T22:00:00'],
                returnFlightNumbers: ['707'],
                returnResBookDesigCodes: ['S'],
                returnAirlineCodes: ['G0'],
                returnOriginLocationCodes: ['PMV'],
                returnDestinationLocationCodes: ['CCS'],
                passengersQty: [{ passengerClass: 'ADULT', passengerQty: 1 }],
            },
        },
        context: { headers: { Language: i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    const amount = price?.kiuGetPrices?.response?.total || '';

    return (
        <WrapperFrame>
            <Header>
                <Typography type="h6" $align="center">
                    {`${origin.name} (${origin.code}) - ${destination.name} (${destination.code})`}
                </Typography>
                <SubFrame1>
                    <Typography type="body1" $align="center">
                        {/* {`${dateString} · ${time.hours > 0 ? `${time.hours}h` : ''} ${time.minutes > 0 ? `${time.minutes}min` : ''}`} */}
                        {`${dateString} · ${flightHour > 0 ? `${flightHour}h` : ''} ${flightMin > 0 ? `${flightMin}min` : ''}`}
                    </Typography>
                    <Typography type="body1" $align="center">
                        {stopsQty > 0
                            ? `${stopsQty} ${t('flights:text.scale')}: ${scaleHour > 0 ? `${scaleHour}h` : ''} ${scaleMin > 0 ? `${scaleMin}min` : ''}`
                            : t('flights:filter.non-stop')}
                    </Typography>
                </SubFrame1>
            </Header>
            <FrameContainer>
                {!loadingPrice && (
                    <Typography type="body1" $align="center">
                        {amount}
                    </Typography>
                )}
                <FlightSummary>
                    {flights?.map((scale, index) => (
                        <FlightModalCard scaleDuration={scaleList[index]} scale={scale} rest={rest} />
                    ))}
                </FlightSummary>
                {/* <Fare>
                    <FlightsFareSwiper />
                </Fare> */}
            </FrameContainer>
        </WrapperFrame>
    );
};

FlightTripModal.propTypes = {
    flight: PropTypes.object,
};

export default FlightTripModal;
