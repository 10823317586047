import { gql } from '@apollo/client';

export const IMAGE_FRAGMENT = gql`
    fragment ImageType on ImageType {
        name
        path
        extension
        size
        fullPath
        sizes
        url
        description
        isMain
    }
`;
