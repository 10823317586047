import React from 'react';
import Cookies, { CookieAttributes } from 'js-cookie';
import { v4 as generateUUID } from 'uuid';
import { SessionCookieProviderProps } from './SessionCookieContext.types';
import SessionCookieContext from './SessionCookieContext';

const SessionCookieProvider: React.FC<SessionCookieProviderProps> = ({ children }: SessionCookieProviderProps) => {
    const cookieName = process.env.NEXT_PUBLIC_SESSION_COOKIE_NAME || 'session_id';

    const [sessionID, setSessionID] = React.useState<string | undefined>(Cookies.get(cookieName));

    const removeSessionID = () => {
        Cookies.remove(cookieName);
        setSessionID(undefined);
    };

    const contextValue = {
        sessionID,
        setSessionID: (value?: string, options?: CookieAttributes) => {
            const expiresDate = new Date();
            expiresDate.setDate(expiresDate.getDate() + parseInt(process.env.NEXT_PUBLIC_SESSION_EXPIRATION_TIME as string) || 7);
            const cookieOptions: CookieAttributes = {
                expires: expiresDate,
                ...options,
            };

            if (!sessionID) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const uuid: string = generateUUID();
                Cookies.set(cookieName, uuid, cookieOptions);
                setSessionID(uuid);
            } else {
                Cookies.set(cookieName, value || sessionID, cookieOptions);
                setSessionID(value || sessionID);
            }
        },
        removeSessionID,
    };

    return <SessionCookieContext.Provider value={contextValue}>{children}</SessionCookieContext.Provider>;
};

export default SessionCookieProvider;
