import React from 'react';
import { useTranslation } from 'next-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTheme } from 'styled-components';
import { showErrorAlert, showSuccessAlert } from '@/modules/alerts';
import { useSubscribe } from '@/services/newsletters';
import { Button, Textfield, Typography, Asterisk } from '@/components/atoms';
import { NewsletterFormValues, NewslettersFormProps } from './Newsletters.types';
import { GlobalError } from "@/styles/common";
import { CustomForm, InputWrapper } from './NewslettersForm.styles';

const NewslettersForm: React.FC<NewslettersFormProps> = ({ type }) => {
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme
    const { executeRecaptcha } = useGoogleReCaptcha(); // Recaptcha
    const [globalError, setGlobalError] = React.useState<string>('');
    const { subscribe, loading } = useSubscribe();

    // Form initial values
    const initialValues = {
        [`newsletter_fullname_${type}`]: '',
        [`newsletter_email_${type}`]: '',
    };

    // Validation schema
    const validationSchema = Yup.object({
        [`newsletter_fullname_${type}`]: Yup.string().required(t('newsletter:required.fullname')),
        [`newsletter_email_${type}`]: Yup.string().email(t('newsletter:invalid.email')).required(t('newsletter:required.email')),
    });

    const handleError = async () => {
        await showErrorAlert({
            title: t('newsletter:error.message.title'),
            text: t('newsletter:error.message.text'),
            confirmButtonText: t('newsletter:button.close'),
        });
    };

    const handleOnSubmit = async (
        values: NewsletterFormValues,
        {
            setFieldError,
            resetForm,
        }: {
            setFieldError: (field: string, message: string | undefined) => void;
            resetForm: () => void;
        }
    ) => {
        setGlobalError('');

        if (executeRecaptcha) {
            const token = await executeRecaptcha('SUBSCRIBE_TO_NEWSLETTERS');

            await subscribe({
                variables: {
                    fullName: values[`newsletter_fullname_${type}`],
                    email: values[`newsletter_email_${type}`],
                    recaptchaToken: token,
                },
                onCompleted: async (data) => {
                    if (data?.subscribe?.status === 200) {
                        await showSuccessAlert({
                            title: t('contact:success.message.title'),
                            text: t('contact:success.message.text'),
                            confirmButtonText: t('contact:button.confirm'),
                            onConfirm: () => {
                                resetForm();
                            },
                        });
                    } else {
                        if (data?.subscribe?.errors && data?.subscribe?.errors?.length > 0) {
                            data?.subscribe?.errors?.map((err) => {
                                const field = err?.field || '';
                                const message = err?.messages.join(',') || '';
                                if (!field) {
                                    setGlobalError(message);
                                } else {
                                    setFieldError(field, message);
                                }
                            });
                        } else {
                            await handleError();
                        }
                    }
                },
                onError: async () => {
                    await handleError();
                },
            });
        }
    };

    return (
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
            {({ errors, values, touched, handleChange, handleBlur }) => {
                return (
                    <CustomForm>
                        <InputWrapper type={type}>
                            <Textfield
                                id={`newsletter_fullname_${type}`}
                                type="text"
                                label={<Asterisk text={t('newsletter:label.fullname')} />}
                                placeholder={t('newsletter:placeholder.fullname')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[`newsletter_fullname_${type}`]}
                                errors={touched[`newsletter_fullname_${type}`] && errors[`newsletter_fullname_${type}`]}
                                success={(touched[`newsletter_fullname_${type}`] || values[`newsletter_fullname_${type}`] !== '') && !errors[`newsletter_fullname_${type}`]}
                            />
                            <Textfield
                                id={`newsletter_email_${type}`}
                                type="text"
                                label={<Asterisk text={t('newsletter:label.email')} />}
                                placeholder={t('newsletter:placeholder.email')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[`newsletter_email_${type}`]}
                                errors={touched[`newsletter_email_${type}`] && errors[`newsletter_email_${type}`]}
                                success={(touched[`newsletter_email_${type}`] || values[`newsletter_email_${type}`] !== '') && !errors[`newsletter_email_${type}`]}
                            />
                        </InputWrapper>

                        {type === 'section' && (
                            <Typography variant="body2" color={theme.colors.neutral.neutral10} align="center" mb="18px">
                                {t('newsletter:label.description')}
                            </Typography>
                        )}
                        <GlobalError>{globalError}</GlobalError>
                        <Button
                            content={t('newsletter:button.subscribe')}
                            width={type === 'section' ? '200px' : '100%'}
                            variant={type === 'footer' ? 'outlined' : 'filled'}
                            type="submit"
                            isSubmitting={loading}
                            disabled={loading}
                        />
                    </CustomForm>
                );
            }}
        </Formik>
    );
};

NewslettersForm.defaultProps = {
    type: 'section',
};

export default NewslettersForm;
