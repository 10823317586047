import styled from 'styled-components';

export const Container = styled.div``;

export const DescriptionFrame = styled.div`
    width: 280px;
    padding-top: 18px;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
`;

export const ContainerListItems = styled.div`
    span svg {
        translate: 0 5px;
    }
`;

export const PricePerson = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        align-items: baseline;
    }
`;

export const TextDecoration = styled.span`
    text-decoration: line-through;
    padding-left: 5px;
`;
