import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import Image1 from '@/assets/images/loading/image1.svg';
import Image2 from '@/assets/images/loading/image2.svg';
import Image3 from '@/assets/images/loading/image3.svg';
import { SwiperLoadingProps } from './SwiperLoading.types';
import { SwiperGlobalStyles, Wrapper } from './SwiperLoading.styles';

import 'swiper/css';
import 'swiper/css/effect-coverflow';

const SwiperLoading: React.FC<SwiperLoadingProps> = ({ asComponent = false }) => {
    React.useEffect(() => {
        if (!asComponent) {
            document.body.classList.add('loading-page');

            return () => {
                document.body.classList.remove('loading-page');
            };
        }
    }, [asComponent]);

    return (
        <Wrapper asComponent={asComponent}>
            <SwiperGlobalStyles />
            <Swiper
                slidesPerView={3}
                spaceBetween={10}
                loop
                // loopedSlides={3}
                centeredSlides
                autoplay={{
                    delay: 800,
                    disableOnInteraction: false,
                }}
                modules={[EffectCoverflow, Autoplay]}
                effect="coverflow"
                grabCursor
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                className="LoaderSwiper"
                allowTouchMove={false}
            >
                <SwiperSlide>
                    <Image1 />
                </SwiperSlide>
                <SwiperSlide>
                    <Image2 />
                </SwiperSlide>
                <SwiperSlide>
                    <Image3 />
                </SwiperSlide>
                <SwiperSlide>
                    <Image1 />
                </SwiperSlide>
                <SwiperSlide>
                    <Image2 />
                </SwiperSlide>
                <SwiperSlide>
                    <Image3 />
                </SwiperSlide>
            </Swiper>
        </Wrapper>
    );
};

export default SwiperLoading;
