import styled from 'styled-components';

export const Container = styled.div`
    margin-right: auto;
    margin-left: auto;
    max-width: 95%;
    position: relative;

    ${({ theme }) => theme.media.sm`
        max-width: 540px;
    `}

    ${({ theme }) => theme.media.md`
        max-width: 720px;
    `}

    ${({ theme }) => theme.media.lg`
        max-width: 960px;
    `}

    ${({ theme }) => theme.media.xl`
        max-width: 1180px;
    `}
`;

export const GlobalError = styled.div`
    padding: 0 0 20px 0;
    color: ${({ theme }) => theme.colors.error.error50};
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}
`;
