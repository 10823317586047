import styled from 'styled-components';

export const LanguageSelector = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
        margin: 0 0 0 5px;
        width: 25px;
    }
`;

export const LanguageContainer = styled.ul`
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`;

export const Language = styled.li`
    display: flex;
    padding: 10px 25px;
    white-space: nowrap;

    &:hover {
        background: ${({ theme }) => theme.colors.neutral.neutral96};
    }

    a {
        display: block;
        padding-left: 10px;
    }
`;
