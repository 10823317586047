import styled, { css } from 'styled-components';
import { ChipProps } from './Chip.types';
// import { rotate } from 'styles/animations';

export const LeftSide = styled.span`
    svg {
        margin-right: 10px;
    }
`;

export const RightSide = styled.span`
    padding-left: 5px;
`;

const shadow = '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)';

export const ChipTag = styled.div.withConfig({
    shouldForwardProp: (prop) => !['disabled', 'display', 'type', 'elevated', 'selected'].includes(prop),
})<Omit<ChipProps, 'content' | 'handleClose' | 'icon' | 'iconFilter'>>`
    font-family: ${({ theme }) => theme.font.btn.family};
    font-size: ${({ theme }) => theme.font.btn.mobile.size};
    line-height: ${({ theme }) => theme.font.btn.mobile.lineheight};
    font-weight: ${({ theme }) => theme.font.btn.mobile.weight};
    font-style: ${({ theme }) => theme.font.btn.mobile.style};
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
    height: 36px !important;
    width: max-content;
    position: relative;
    display: ${({ display }) => (display ? 'flex' : '')};
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    gap: 8px;
    border-radius: 18px;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.btn.tablet.size};
        font-weight: ${theme.font.btn.tablet.weight};
        line-height: ${theme.font.btn.tablet.lineheight};
        font-style: ${theme.font.btn.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.btn.desktop.size};
        font-weight: ${theme.font.btn.desktop.weight};
        line-height: ${theme.font.btn.desktop.lineheight};
        font-style: ${theme.font.btn.desktop.style};
    `}

    & > span {
        display: flex;
        align-items: center;
    }

    // Suggestion Chip no elevated no selected
    ${({theme,  type, elevated, selected }) =>
        type === 'suggestion' &&
        !elevated &&
        !selected &&
        css`
            color: ${theme.colors.primary.primary20};
            border: 2px solid ${theme.colors.primary.primary40};
            background: transparent;

            &:hover {
                border: 2px solid ${theme.colors.primary.primary40};
                background: ${theme.colors.primary.primary90};
            }

            &:active {
                border: 2px solid ${theme.colors.primary.primary40};
                background: ${theme.colors.primary.primary80};
            }

            &:disabled,
            &[disabled] {
                color: ${theme.colors.neutral.neutral80};
                border: 2px solid ${theme.colors.neutral.neutral80};
                background: transparent;
            }
        `}

    // Suggestion Chip elevated no selected
    ${({ theme, type, elevated, selected }) =>
        type === 'suggestion' &&
        elevated &&
        !selected &&
        css`
            color: ${theme.colors.primary.primary20};
            border: none;
            background: ${theme.colors.primary.primary90};
            box-shadow: ${shadow};

            &:hover {
                background: ${theme.colors.primary.primary80};
            }

            &:active {
                background: ${theme.colors.primary.primary70};
            }

            &:disabled,
            &[disabled] {
                background: ${theme.colors.neutral.neutral94};
                color: ${theme.colors.neutral.neutral80};
            }
        `}

    // Suggestion Chip selected
    ${({ theme, type, elevated, selected }) =>
        type === 'suggestion' &&
        selected &&
        css`
            color: ${theme.colors.primary.primary20};
            background: ${theme.colors.primary.primary80};
            box-shadow: ${elevated ? shadow : 0};

            &:hover {
                background: ${theme.colors.primary.primary70};
            }

            &:active {
                background: ${theme.colors.primary.primary60};
            }

            &:disabled,
            &[disabled] {
                color: ${theme.colors.neutral.neutral70};
                background: ${theme.colors.neutral.neutral90};
            }
        `}

    // Input Chip
    ${({ theme, type, selected }) =>
        type === 'input' &&
        css`
            color: ${theme.colors.neutral.neutral10};
            //ToDo change hard code color
            border: ${!selected ? '2px solid #FCB900' : 'none'};
            background: ${!selected ? 'transparent' : theme.colors.primary.primary90};

            &:hover {
                border: ${!selected ? '2px solid #FCB900' : 'none'};
                background: ${(!selected ? theme.colors.primary.primary90 : theme.colors.primary.primary80)};
            }

            &:active {
                border: ${!selected ? '2px solid #FCB900' : 'none'};
                background: ${(!selected ? theme.colors.primary.primary80 : theme.colors.primary.primary70)};
            }
        `}

    // Filter Chip unselected default
    ${({ theme, type, elevated, selected }) =>
        type === 'filter' &&
        !elevated &&
        !selected &&
        css`
            border: 2px solid ${theme.colors.primary.primary50};
            background: transparent;

            &:hover {
                border: 2px solid ${theme.colors.primary.primary50};
                background: ${theme.colors.primary.primary90};
            }

            &:active {
                border: 2px solid ${theme.colors.primary.primary50};
                background: ${theme.colors.primary.primary80};
            }

            &:disabled,
            &[disabled] {
                border: 2px solid ${theme.colors.neutral.neutral80};
                background: transparent;
            }
        `}

    // Filter Chip elevated no selected
    ${({ theme, type, elevated, selected }) =>
        type === 'filter' &&
        elevated &&
        !selected &&
        css`
            border: 2px solid transparent;
            background: ${theme.colors.primary.primary90};
            box-shadow: ${shadow};

            &:hover {
                border: 2px solid transparent;
                background: ${theme.colors.primary.primary80};
                box-shadow: ${shadow};
            }

            &:active {
                border: 2px solid transparent;
                background: ${theme.colors.primary.primary70};
                box-shadow: ${shadow};
            }

            &:disabled,
            &[disabled] {
                background: ${theme.colors.neutral.neutral94};
                border: none;
            }
        `}

    // Filter Chip selected default
    ${({ theme, type, elevated, selected }) =>
        type === 'filter' &&
        !elevated &&
        selected &&
        css`
            border: 2px solid transparent;
            background: ${theme.colors.primary.primary80};

            &:hover {
                border: 2px solid transparent;
                background: ${ theme.colors.primary.primary70};
            }

            &:active {
                border: 2px solid transparent;
                background: ${ theme.colors.primary.primary60};
            }

            &:disabled,
            &[disabled] {
                background: ${theme.colors.neutral.neutral90};
                border: none;
            }
        `}


    // Filter Chip selected elevated
    ${({ theme, type, elevated, selected }) =>
        type === 'filter' &&
        elevated &&
        selected &&
        css`
            border: 2px solid transparent;
            background: ${theme.colors.primary.primary80};
            box-shadow: ${shadow};

            &:hover {
                border: 2px solid transparent;
                background: ${theme.colors.primary.primary70};
                box-shadow: ${shadow};
            }

            &:active {
                border: 2px solid transparent;
                background: ${theme.colors.primary.primary60};
                box-shadow: ${shadow};
            }

            &:disabled,
            &[disabled] {
                background: ${theme.colors.neutral.neutral90};
                border: 2px solid transparent;
                box-shadow: ${shadow};
            }
        `}
`;
