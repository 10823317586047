import styled from 'styled-components';

export const Block = styled.div`
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    &:first-of-type {
        padding: 0 0 5px 0;
    }
`;
