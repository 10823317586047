import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import useNavigations from '@/modules/navigations';
import { useSocialLinks } from '@/services/social';
import { hex2Rgba } from '@/styles/colors';
import { Logo, Typography } from '@/components/atoms';
import { Navigation, SocialLinks } from '@/components/molecules';
import NewslettersForm from '@/forms/newsletters-form';
import { BottomWrapper, FirstColumn, LastColumn, ComponentStyles, TopWrapper, CenterColumn, Wrapper } from './Footer.styles';

const Footer: React.FC = () => {
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme
    const { navigations } = useNavigations();
    const { social } = useSocialLinks();

    return (
        <>
            <ComponentStyles />

            <svg
                className="animated-background"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shapeRendering="auto"
            >
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="waves">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill={theme.colors.primary.primary70} className="animate-moveForever" />
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill={hex2Rgba(theme.colors.primary.primary80, 0.5)} className="animate-moveForever" />
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill={hex2Rgba(theme.colors.primary.primary90, 0.8)} className="animate-moveForever" />
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill={theme.colors.primary.primary95} className="animate-moveForever" />
                </g>
            </svg>
            <Wrapper>
                <TopWrapper>
                    <FirstColumn>
                        <Logo color="full-color" variant="full-vertical" link="/" alt="Chagod Tour" />
                        <SocialLinks id="footer-social-links" social={social} />
                    </FirstColumn>
                    <CenterColumn>
                        {navigations.footer.map((item) => {
                            return <Navigation id="nav-footer" key={item.key} type="footer" title={item.title} menuItems={item.items} />;
                        })}
                    </CenterColumn>
                    <LastColumn>
                        <Typography variant="body2" mb="10px">
                            {t('footer:subscription-form.title')}
                        </Typography>
                        <NewslettersForm type="footer" />
                    </LastColumn>
                </TopWrapper>
                <BottomWrapper>
                    <Typography variant="btn" align="center" color={theme.colors.tertiary.tertiary50}>
                        {t('footer:copyright.powered-by')}
                        <Link href="https://chagod.software/" target="_blank" role="link" aria-label="Chagod Software">
                            <b>Chagod Software</b>
                        </Link>
                    </Typography>
                    <Typography variant="ovl" align="center" uppercase>
                        &#169; 2023 Chagod Tour Jamaica Limited
                    </Typography>
                </BottomWrapper>
            </Wrapper>
        </>
    );
};

export default Footer;
