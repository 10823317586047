import React from 'react';
import { Tab } from '@/components/molecules';
import { FiltersTabsProps } from './FiltersTabs.types';
import { Wrapper } from './FiltersTabs.styles';

const FiltersTabs: React.FC<FiltersTabsProps> = ({ tabs }) => {
    const [tabSelected, setTabSelected] = React.useState<number>(0);

    const onTabSelected = (selected: number) => {
        setTabSelected(selected);
    };

    return (
        <Wrapper>
            <Tab.Tab onTabSelected={onTabSelected} >
                {tabs.map((tab) => {
                    return <Tab.TabHeader key={tab.key} icon={tab.icon} title={tab.title} />;
                })}
            </Tab.Tab>
            {tabs.map((tab, index) => {
                return (
                    <Tab.TabPanel key={tab.key} value={tabSelected} index={index}>
                        {tab.content}
                    </Tab.TabPanel>
                );
            })}
        </Wrapper>
    );
};

export default FiltersTabs;
