import { gql } from "@apollo/client";
import { ERRORS_FRAGMENT, IMAGE_FRAGMENT, PAGE_INFO_FRAGMENT } from "@/graphql/fragments";

export const SLIDES_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    ${IMAGE_FRAGMENT}
    query Slides($skip: Int, $take: Int, $report: Boolean, $searchFields: [SlideSearchFieldInput], $sortFields: SlideSortFieldInput) {
        slides(report: $report, skip: $skip, take: $take, searchFields: $searchFields, sortFields: $sortFields) {
            status
            errors {
                ...ErrorType
            }
            pageInfo {
                ...PageInfoType
            }
            objects {
                title
                subtitle
                image {
                    ...ImageType
                }
                buttonText
                buttonLink
                section
            }
        }
    }
`;
