import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, PACKAGES_FRAGMENT, PAGE_INFO_FRAGMENT } from '@/graphql/fragments';

export const PACKAGES_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    ${PACKAGES_FRAGMENT}
    query Packages($skip: Int, $take: Int, $report: Boolean) {
        packages(report: $report, skip: $skip, take: $take) {
            status
            errors {
                ...ErrorType
            }
            pageInfo {
                ...PageInfoType
            }
            objects {
                ...TouristPackageType
            }
        }
    }
`;

export const PACKAGE_BY_ID_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${PACKAGES_FRAGMENT}
    query PackageById($id: ID!) {
        packageById(id: $id) {
            status
            errors {
                ...ErrorType
            }
            object {
                ...TouristPackageType
            }
        }
    }
`;
