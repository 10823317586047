import React from 'react';
import { PassengerCard } from '@/components/molecules';
import { useWizard } from '@/modules/wizard';
import PassengersForm from '@/forms/passengers-form';
import { FlightPassengersProps } from './FlightPassengers.type';
import { InnerWrapper } from './FlightPassengers.styles';

const FlightPassengers: React.FC<FlightPassengersProps> = ({ passengers }) => {
    const { state, dispatch } = useWizard();

    const handleEdit = (position: number) => {
        dispatch({
            type: 'UPDATE_DATA',
            payload: {
                passengerActive: position,
            },
        });
    };

    return (
        <InnerWrapper>
            {React.Children.toArray(
                passengers?.map((passenger, index) => (
                    <PassengerCard
                        key={`${passenger.type}-${passenger.number}`}
                        passenger={passenger}
                        onEdit={() => handleEdit(index)}
                        status={index === state.data?.passengerActive ? 'active' : state.data?.travelers && state.data?.travelers[index] !== undefined ? 'saved' : 'disabled'}
                    >
                        <PassengersForm
                            passenger={passenger}
                            initialData={state.data?.travelers && state.data?.travelers[state.data?.passengerActive as number]}
                            position={index}
                            isTheFirst={index === 0}
                            isTheLast={passengers.length === index + 1}
                        />
                    </PassengerCard>
                ))
            )}
        </InnerWrapper>
    );
};

export default FlightPassengers;
