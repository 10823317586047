import styled, { css } from 'styled-components';
import { DisplayType, VariantType } from './FlightLine.types';

export const Progress = styled.div.withConfig({
    shouldForwardProp: (prop) => !['display'].includes(prop),
})<{ display: DisplayType }>`
    position: relative;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row;

    svg {
        width: 24px !important;
        height: 24px;
        z-index: 1;
    }

    ${({ display }) =>
        display === 'vertical' &&
        css`
            flex-direction: column;
            gap: 5px;

            svg {
                transform: rotate(90deg);
            }
        `}
`;

export const Bar = styled.span.withConfig({
    shouldForwardProp: (prop) => !['display', 'variant'].includes(prop),
})<{ display: DisplayType; variant: VariantType }>`
    position: absolute;
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.neutral.neutral10};
    inset: 0;
    margin: auto;
    width: 98%;
    height: 2px;

    ${({ display }) =>
        display === 'vertical' &&
        css`
            width: 2px;
            height: 98%;
        `}

    ${({ variant, display }) =>
        variant === 'simple' &&
        display === 'horizontal' &&
        css`
            width: 90%;
            right: 3%;
        `}

  ${({ variant, display }) =>
        variant === 'simple' &&
        display === 'vertical' &&
        css`
            height: 87%;
            bottom: 3%;
        `}
`;

export const CircleContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['bordered'].includes(prop),
})<{ bordered: boolean }>`
    display: inline-block;
    z-index: 1;
    position: relative;
    width: 12px !important;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.secondary.secondary50};

    ${({ bordered }) =>
        bordered &&
        css`
            width: 10px !important;
            height: 10px;
            background-color: ${({ theme }) => theme.colors.basic.white};
            border: 2px solid ${({ theme }) => theme.colors.secondary.secondary50};
        `}
`;
