import styled from 'styled-components';
import { fadeIn } from "@/styles/animations";

export const Wrapper = styled.div`
    padding-top: 20px;
    margin-top: 40px;
    width: 100%;
    gap: 20px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
`;

export const InnerWrapper = styled.div`
    display: flex;
    flex-flow: row;
    margin: 40px 0;
    gap: 20px;
`;

export const Filters = styled.div`
    flex-basis: 35%;
`;

export const Flights = styled.div`
    flex-basis: 65%;
`;

export const FlightsHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Card = styled.div`
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    align-self: stretch;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.colors.neutral.neutral98};
    animation: ${fadeIn} 1s ease-in-out;

    svg {
        margin: 0 auto 20px;
        stroke: ${({ theme }) => theme.colors.primary.primary50};
    }
`;
