import styled, { createGlobalStyle } from 'styled-components';

export const AdvertisingSwiperGlobalStyles = createGlobalStyle`
  .AdvertisingSwiper.swiper {
    display: flex;
    flex-direction: row;
    padding: 40px 0 50px;
    width: 100% !important;
    height: 100%;
    overflow: inherit;
  }

  .AdvertisingSwiper .swiper-wrapper {
    cursor: default !important;
    align-items: center;
  }

  .AdvertisingSwiper .swiper-pagination .swiper-pagination-bullet {
    background-color: #fee399;
    opacity: 1;
    width: 12px;
    height: 12px;
    bottom: -55px !important;
  }

  .AdvertisingSwiper .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #fcb900;
  }

  .AdvertisingSwiper .swiper-slide {
    border-radius: 25px;
    overflow: hidden;
  }

  .AdvertisingSwiper .swiper-button-next::after,
  .AdvertisingSwiper .swiper-button-prev::after {
    font-size: 36px;
    color: #ffffff;
    text-shadow: 2px 2px 4px #000000;
  }

  .AdvertisingSwiper .swiper-button-prev::after {
    margin-right: 0;
  }

  .AdvertisingSwiper .swiper-button-next::after {
    margin-left: 0;
  }

  .AdvertisingSwiper .swiper-slide {
    z-index: -1;
    opacity: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .AdvertisingSwiper .swiper-slide-active {
    aspect-ratio: 1 / 1;
    width: 100% !important;
    height: auto;
    max-height: 540px;
    z-index: 9999;
    opacity: 1;
    filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.25));
  }

  .AdvertisingSwiper .swiper-slide-prev,
  .AdvertisingSwiper .swiper-slide-next {
    z-index: 9998;
    opacity: 1;
    height: 65vw;
  }

  @media (min-width: 768px) {
    .AdvertisingSwiper .swiper-slide-prev,
    .AdvertisingSwiper .swiper-slide-next {
      height: 45vw;
    }
  }

  @media (min-width: 992px) {
    .AdvertisingSwiper.swiper {
      height: 600px;
    }

    .AdvertisingSwiper .swiper-slide-active {
      aspect-ratio: 1 / 1;
      width: 480px !important;
      height: 480px !important;
    }

    .AdvertisingSwiper .swiper-slide-prev,
    .AdvertisingSwiper .swiper-slide-next {
      height: 35vw;
    }
  }

  @media (min-width: 1200px) {
    .AdvertisingSwiper .swiper-slide-prev,
    .AdvertisingSwiper .swiper-slide-next {
      width: 380px !important;
      height: 380px !important;
    }
  }

  @media (min-width: 1400px) {
    .AdvertisingSwiper .swiper-button-next::after,
    .AdvertisingSwiper .swiper-button-prev::after {
      margin: 0;
      color: #1e1e1e;
      text-shadow: none;
    }

    .AdvertisingSwiper .swiper-button-next {
      right: -50px;
    }

    .AdvertisingSwiper .swiper-button-prev {
      left: -50px;
    }
  }
`;

export const AdvertisingSwiperDetailsGlobalStyles = createGlobalStyle`
  .AdvertisingDetailThumbsSwiper.swiper {
    width: 100%;
    overflow: visible;
  }

  .AdvertisingDetailThumbsSwiper .swiper-button-next,
  .AdvertisingDetailThumbsSwiper .swiper-button-prev {
    color: #ffffff;
    position: fixed;
    opacity: 0;
    z-index: 9998;
    transition: opacity 0.5s ease-in-out;
  }

  .AdvertisingDetailThumbsSwiper .swiper-button-prev {
    left: 5px;
  }

  .AdvertisingDetailThumbsSwiper .swiper-button-next {
    right: 5px;
  }

  .AdvertisingDetailThumbsSwiper .swiper-button-next::after,
  .AdvertisingDetailThumbsSwiper .swiper-button-prev::after {
    font-size: 36px;
    text-shadow: 2px 2px 4px #000000;
  }

  .AdvertisingDetailThumbsSwiper .swiper-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    cursor: default !important;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .AdvertisingDetailThumbsSwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .AdvertisingDetailThumbsSwiper .swiper-slide {
    text-align: center;
    width: 100px !important;
    height: 100px !important;
    cursor: pointer;
    filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.25));
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AdvertisingDetailThumbsSwiper .swiper-slide-active {
    scale: 1.1;
    z-index: 9999;
    border: 3px solid #fcb900;
    border-radius: 10px;
    opacity: 1;
  }

  @media (min-width: 768px) {
    .AdvertisingDetailThumbsSwiper .swiper-button-next,
    .AdvertisingDetailThumbsSwiper .swiper-button-prev {
      opacity: 1;
    }
  }

  @media (min-width: 992px) {
    .AdvertisingDetailThumbsSwiper.swiper {
      height: auto;
      margin-top: 80px;
    }
  }
`;

export const Slideshow = styled.div`
    width: 100%;
    max-width: 100vw;

    ${({ theme }) => theme.media.lg`
         max-width: 800px;
    `}

    ${({ theme }) => theme.media.xl`
         max-width: 980px;
    `}
`;

export const AdvertisingDetails = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 10px;

    ${({ theme }) => theme.media.lg`
        flex-flow: row;
        align-items: stretch;
        gap: 20px;
    `}
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;

    ${({ theme }) => theme.media.lg`
        flex-basis: 50%;
        width: 50%;
    `}

    img {
        width: 100% !important;
        object-fit: cover;
        aspect-ratio: 1 / 1;

        ${({ theme }) => theme.media.lg`
            border-radius: 25px;
        `}
    }
`;

export const ContentWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 60vh;
    padding: 5px 20px;

    ${({ theme }) => theme.media.lg`
        flex-basis: 50%;
        width: 50%;
        height: auto;
        padding: 0;
    `}

    p {
        overflow: hidden;
        overflow-y: auto;
        height: 40vh;
        padding-right: 20px;
    }
`;

export const IconsContainer = styled.div`
    position: absolute;
    left: 20px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 48px;
    height: 48px;
    transition: all 0.5s ease-in-out;

    & > div {
        align-self: flex-end;
        display: flex;
    }

    ${({ theme }) => theme.media.lg`
        left: 0;
        bottom: 0;

        & > div {
            align-self: auto;
            display: flex;
        }
    `}
`;

export const IconWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    transition: all 0.5s ease-in-out;
    z-index: 998;

    ${({ theme }) => theme.media.lg`
        width: 48px;
        height: 48px;
    `}
`;
