import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';

// Icon
import * as FeatherIcon from 'react-feather';

// Atoms
import { Typography, Chip } from 'components/atoms';

// Molecules
import { ScrollCard } from 'components/molecules';

// Hooks
import { useMediaQuery } from '@/modules/media-query';

// Styles
import { Container, Summary, MapContainer, Frame1, Frame2, ListItems, Item } from './ExperienceLodging.styles';

// To render Map
const MapWithNoSSR = dynamic(() => import('components/molecules/Map'), {
    ssr: false,
});

const ExperienceLodging = ({ experience }) => {
    // Translations
    const { t } = useTranslation();
    const theme = useTheme(); // Get current theme

    // Media Queries
    const isDesktop = useMediaQuery('(min-width: 992px)');
    const height = isDesktop ? '390px' : '231px';

    // States
    const [selected, setSelected] = React.useState(0);
    const hasLodgings = experience?.lodgings?.length > 0;

    const iconService = (service) => {
        const element = service?.iconVariants?.find((elem) => elem?.theme === 'react-feather');
        if (element?.name !== '' && Object.prototype.hasOwnProperty.call(FeatherIcon, element?.name)) {
            return React.createElement(FeatherIcon[element?.name], {
                size: 20,
                color: theme.colors.neutral.neutral10,
                strokeWidth: 2,
            });
        }
        return <FeatherIcon.CheckSquare size={20} />;
    };

    return hasLodgings ? (
        <Container>
            <Typography variant="h2">{t('packages:amenities-lodgings')}</Typography>
            <ScrollCard mt="12px" contentType="chips">
                {experience?.lodgings?.map((chip, index) => (
                    <Chip key={chip.id} type="filter" iconFilter={false} onClick={() => setSelected(index)} content={chip.lodging.name} selected={selected === index} />
                ))}
            </ScrollCard>
            <Summary>
                <Typography variant="h5" align="left">
                    {experience?.lodgings[selected]?.lodging?.name}
                </Typography>
                <Typography type="body2" align="left">
                    {experience?.lodgings[selected]?.lodging?.description}
                </Typography>
            </Summary>
            {experience?.lodgings[selected]?.lodging?.coordinates && (
                <>
                    <Typography variant="h5" mt="24px">
                        {t('packages:section.lodging-location.title')}
                    </Typography>
                    <MapContainer>
                        <MapWithNoSSR
                            latitude={Number(experience?.lodgings[selected]?.lodging?.coordinates?.yCoordinate)}
                            longitude={Number(experience?.lodgings[selected]?.lodging?.coordinates?.xCoordinate)}
                            height={height}
                            iconUrl="/images/map-pin2.svg"
                        />
                    </MapContainer>
                </>
            )}
            {experience?.lodgings[selected]?.lodging?.placesInterest.length > 0 && (
                <Frame1>
                    <Typography variant="h5" mt="24px">
                        {t('packages:section.lodging-interest.title')}
                    </Typography>
                    <ListItems>
                        {experience?.lodgings[selected]?.lodging?.placesInterest.map((interest) => (
                            <Item key={interest.id}>
                                <FeatherIcon.MapPin size={20} />
                                <Typography type="body2">{interest.name}</Typography>
                            </Item>
                        ))}
                    </ListItems>
                </Frame1>
            )}
            {experience?.lodgings[selected]?.lodging?.services.length > 0 && (
                <Frame2>
                    <Typography variant="h5" mt="24px">
                        {t('packages:section.lodging-services.title')}
                    </Typography>
                    <ListItems>
                        {experience?.lodgings[selected]?.lodging?.services.map((service) => (
                            <Item key={service.id}>
                                {iconService(service)}
                                <Typography type="body2">{service.name}</Typography>
                            </Item>
                        ))}
                    </ListItems>
                </Frame2>
            )}
        </Container>
    ) : null;
};

ExperienceLodging.propTypes = {
    experience: PropTypes.object,
};

export default ExperienceLodging;
