export * from './auth.gql';
export * from './city.gql';
export * from './country.gql';
export * from './destination.gql';
export * from './errors.gql';
export * from './image.gql';
export * from './jobs.gql';
export * from './kiu.gql';
export * from './location.gql';
export * from './pagination.gql';
export * from './passenger.gql';
export * from './social-media.gql';
