import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';

// Hooks
import { useModal } from '@/modules/modal';
import { useGlobalState } from '@/modules/global-state';

// Utils
import { formatBytes } from 'utils/helpers';

// Atoms
import { Button } from 'components/atoms';

// Styles
import { Wrapper, Image, IconWrapper, InnerWrapper, Body, ErrorBlock, ButtonContainer } from './ImageEditor.styles';

const maxSize = formatBytes(parseInt(process.env.NEXT_PUBLIC_FILE_MAX_SIZE));

// dynamic
const Avatar = dynamic(() => import('react-avatar-edit'), {
    ssr: false,
});

const ModalImageEditor = ({ setSelectedImage }) => {
    // Hooks
    const { t } = useTranslation(); // Translations
    const { unSetModal } = useModal(); // Modal
    const [global, dispatch] = useGlobalState(); // Global States

    // States
    const [error, setError] = React.useState('');

    const handleOnBeforeFileLoad = (elem) => {
        const file = elem.target.files[0];

        // File is too big!
        if (file.size > maxSize) {
            // eslint-disable-next-line no-param-reassign
            elem.target.value = '';
            setError(t('profile:image-editor.invalid.file-size'));
        }

        // Invalid extension
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
            // eslint-disable-next-line no-param-reassign
            elem.target.value = '';
            setError(t('profile:image-editor.invalid.file-type'));
        }
    };

    const handleCrop = (preview) => {
        dispatch({
            previewImage: preview,
        });
    };

    const handleFileLoad = () => {
        setError('');
    };

    const handleSubmit = () => {
        setSelectedImage(global.previewImage);

        unSetModal();
    };

    return (
        <InnerWrapper>
            <Body>
                <Avatar
                    width="100%"
                    height={300}
                    label={t('profile:image-editor.label.choose-avatar')}
                    onCrop={handleCrop}
                    onBeforeFileLoad={handleOnBeforeFileLoad}
                    onFileLoad={handleFileLoad}
                    exportAsSquare
                    cropRadius={0}
                    mimeTypes="image/jpeg,image/png,image/gif"
                />
                {error && <ErrorBlock>{error}</ErrorBlock>}
            </Body>
            <ButtonContainer>
                <Button content={t('profile:image-editor.button.change')} width="100%" type="button" variant="filled" onClick={handleSubmit} />
            </ButtonContainer>
        </InnerWrapper>
    );
};

ModalImageEditor.propTypes = {
    setSelectedImage: PropTypes.func,
};

const ImageEditor = ({ name, currentImage, setSelectedImage }) => {
    // Hooks
    const { setModal } = useModal(); // Modal

    const handleImageEdit = () => {
        setModal(<ModalImageEditor setSelectedImage={setSelectedImage} />);
    };

    return (
        <Wrapper onClick={handleImageEdit}>
            <Image src={currentImage || '/images/profile-user/defaultAvatar.png'} alt={name} layout="fill" />
            <IconWrapper>
                <FeatherIcon.Edit size={20} />
            </IconWrapper>
        </Wrapper>
    );
};

ImageEditor.propTypes = {
    name: PropTypes.string,
    currentImage: PropTypes.string,
    setSelectedImage: PropTypes.func,
};

export default ImageEditor;
