import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const Drag = styled.div`
    width: auto;
    margin: 0 auto 8px;
    padding: 0;
    height: 8px;
    background: ${({ theme }) => theme.colors.neutral.neutral80};
    border-radius: 4px;
`;

export const Container = styled.div`
    padding: 16px;
    width: 600px;
    margin: 0px 93px 0px 93px;
    background: ${({ theme }) => theme.colors.neutral.neutral96};
    border-radius: 24px 24px 0 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    box-shadow: 0 -4px 16px 8px rgb(0 0 0 / 16%);
    height: 66px;
    transition: all 0.5s ease-in-out;

    ${({ open }) =>
        open &&
        css`
            height: 450px;
            position: relative;
            top: 50%;
            bottom: 50%;
            right: 25%;
        `}

    ${({ theme }) => theme.media.md`
        top: 0;
        position: relative;
        border-radius: 24px;
        box-shadow: none;
        height: auto;
        width: auto;
        margin: 0;
        z-index: 2;
    `}
`;

export const CustomForm = styled(Form)`
    max-width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    ${({ theme }) => theme.media.md`
        max-width: 380px;
    `}
`;

export const ElementDatePicker = styled.div`
    display: flex;
    position: relative;

    .react-datepicker-popper {
        width: auto;
    }

    .react-datepicker__tab-loop {
        position: absolute !important;
        top: 80px !important;
    }
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > div {
        margin-bottom: 25px;
    }

    ${({ theme }) => theme.media.md`
        flex-direction: row;
    `}
`;
