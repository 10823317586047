import styled, { css } from 'styled-components';

export const Nav = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop),
})<{ type: string }>`
    display: flex;
    flex-direction: column;
    padding: 0;

    ${({ type }) =>
        type === 'sidebar' &&
        css`
            margin-top: 40px;
        `}

    h3 {
        padding: 10px 0 0;
        text-align: center;
        display: flex;
        justify-content: center;

        ${({ theme }) => theme.media.md`
            padding: 0;
            justify-content: flex-start;
        `}
    }
`;

export const Items = styled.ul.withConfig({
    shouldForwardProp: (prop) => !['type', 'orientation'].includes(prop),
})<{ type: MenuTypes, orientation: string }>`
    display: flex;
    list-style: none;
    flex-direction: ${({ orientation }) => orientation || 'column'};
    margin-block-start: 0;
    padding-inline-start: 0;
    margin: 0;

    ${({ type }) =>
        type === 'sidebar' &&
        css`
            gap: 15px;
        `}

    li {
        padding: ${({ orientation }) => (orientation === 'column' ? '4px 0' : '0 10px')};
        text-align: ${({ theme, type }) => theme.align.nav[type].mobile};

        ${({ theme, type }) => theme.media.md`
           text-align: ${theme.align.nav[type].tablet};
        `}

        ${({ theme, type }) => theme.media.md`
           text-align: ${theme.align.nav[type].desktop};
        `}

        a.active > span,
        a:active > span,
        a:hover > span {
            color: ${({ theme }) => theme.colors.secondary.secondary50};
        }
    }
`;
