import React from 'react';
import { useTranslation } from 'next-i18next';
import PhoneInput2 from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import { PhoneInputProps } from './PhoneInput.types';
import { ErrorBlock, FormLabel, FormGroup, HelpBlock, PhoneInputGlobalStyles } from './PhoneInput.styles';

const PhoneInput: React.FC<PhoneInputProps> = ({
    id,
    country,
    preferredCountries,
    searchPlaceholder,
    searchNotFound,
    tabIndex,
    label,
    placeholder,
    defaultValue,
    borderRadius,
    help,
    errors,
    success,
    disabled,
    onChange,
    onBlur,
    ...props
}) => {
    const { i18n } = useTranslation(); // Translations
    const [focusSelect, setFocusSelect] = React.useState<boolean>(false);

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        if (onBlur) {
            void onBlur(event);
        }

        if (defaultValue === null) {
            setFocusSelect(false);
        }
    };

    return (
        <>
            <PhoneInputGlobalStyles focusSelect={focusSelect} borderRadius={borderRadius} disabled={disabled} haveErrors={!!errors} value={defaultValue as string} />
            <FormGroup id={`form-group-${id}`}>
                <PhoneInput2
                    inputProps={{
                        id,
                        name: id,
                        tabIndex,
                    }}
                    key={`phone-input-${i18n.language}`}
                    localization={i18n.language === 'es' ? es : undefined}
                    country={country}
                    preferredCountries={preferredCountries}
                    countryCodeEditable={false}
                    enableSearch
                    searchPlaceholder={searchPlaceholder}
                    searchNotFound={searchNotFound}
                    disableSearchIcon={true}
                    value={defaultValue}
                    onChange={onChange}
                    onFocus={() => setFocusSelect(true)}
                    onBlur={handleBlur}
                    disabled={disabled}
                    placeholder={placeholder}
                    containerClass="phone-container"
                    inputClass="phone-input"
                    searchClass="phone-search-input"
                    {...props}
                />

                <FormLabel htmlFor={id} haveErrors={!!errors} focusSelect={focusSelect} defaultValue={defaultValue} disabled={disabled}>
                    {label}
                </FormLabel>

                {!errors ? help && <HelpBlock>{help}</HelpBlock> : <ErrorBlock>{errors as string}</ErrorBlock>}
            </FormGroup>
        </>
    );
};

export default PhoneInput;
