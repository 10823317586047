import styled from 'styled-components';

export const RatingContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${({ $mt }) => $mt || '10px'};

    span {
        margin-right: 10px;
    }

    & > * {
        display: flex;
    }
`;

export const IconContainer = styled.span`
    display: flex;
`;

// export const LinkReviewContainer = styled.span`
//     display: flex;
// `;
