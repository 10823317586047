export * from './advertising.gql';
export * from './auth.gql';
export * from './cities.gql';
export * from './countries.gql';
export * from './destinations.gql';
export * from './faqs.gql';
export * from './jobs.gql';
export * from './kiu.gql';
export * from './packages.gql';
export * from './payment.gql';
export * from './slides.gql';
export * from './social.gql';
export * from './verification.gql';
