import styled, { css } from 'styled-components';
import { fadeIn, fadeInFromCenter, fadeInToCenter, fadeOut } from '@/styles/animations';

export const ModalWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['closing'].includes(prop),
})<{ closing: boolean }>`
    position: fixed;
    z-index: 9999;
    inset: 0;
    background-color: ${({ theme }) => theme.colors.neutral.neutral20};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    animation: ${fadeIn} 0.3s ease-in-out;

    ${({ closing }) =>
        closing &&
        css`
            animation: ${fadeOut} 0.7s ease-in-out;
        `}
`;

export const CloseModal = styled.button`
    border: none;
    background: rgb(0 0 0 / 0%);
    color: #fff;
    z-index: 3;
    position: fixed;
    top: 7px;
    right: 0;
    cursor: pointer;

    ${({ theme }) => theme.media.lg`
        top: 15px;
        right: 25px;
    `}

    svg {
        filter: drop-shadow(2px 2px 4px rgb(0 0 0));
    }
`;

export const ModalBody = styled.div.withConfig({
    shouldForwardProp: (prop) => !['closing'].includes(prop),
})<{ closing: boolean }>`
    display: flex;
    transition: all 0.5s ease-in-out;
    animation: ${fadeInFromCenter} 0.7s ease-in-out;

    ${({ closing }) =>
        closing &&
        css`
            animation: ${fadeInToCenter} 0.7s ease-in-out;
        `}
`;
