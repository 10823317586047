import styled from 'styled-components';
import { skeletonLoading } from '@/styles/animations';

const Skeleton = styled.div.withConfig({
    shouldForwardProp: (prop) => !['width', 'height', 'radius', 'mt', 'mb', 'ml', 'mr'].includes(prop),
})<{
    width?: number;
    height?: number;
    radius?: number;
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
}>`
    animation: ${skeletonLoading} 1s linear infinite alternate;
    width: ${({ width }) => (width ? `${width}rem` : '100%')};
    height: ${({ height }) => (height ? `${height}rem` : '')};
    margin-top: ${({ mt }) => (mt ? `${mt}rem` : '0')};
    margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : '0')};
    margin-left: ${({ ml }) => (ml ? `${ml}rem` : '0')};
    margin-right: ${({ mr }) => (mr ? `${mr}rem` : '0')};
    border-radius: ${({ radius }) => (radius ? `${radius}px` : '0')};
`;

export default Skeleton;
