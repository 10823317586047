import styled, { css } from 'styled-components';
import { TextareaProps } from './Textarea.types';
import { FormikErrors } from 'formik';

export const FormGroup = styled.div`
    position: relative !important;
    margin-bottom: 36px;
`;

export const InnerFormGroup = styled.div.withConfig({
    shouldForwardProp: (prop) => !['focus', 'haveErrors'].includes(prop),
})<{
    focus: boolean;
    haveErrors: boolean;
}>`
    overflow: hidden;
    border-radius: 24px;
    background-color: ${({ theme }) => theme.colors.basic.white};
    background-clip: padding-box;
    border: 1px solid transparent;
    transition:
        all 0.25s ease-in-out,
        color 0s ease-in-out,
        background-color 0s ease-in-out,
        border 0s ease-in-out;
    padding: 24px 5px 0;

    ${({ theme, focus }) =>
        focus &&
        css`
            border: 1px solid ${theme.colors.neutral.neutral80};
        `}

    ${({ theme, focus, haveErrors }) =>
        !focus &&
        haveErrors &&
        css`
            background-color: ${theme.colors.error.error95};
            border: none;
        `};
`;

// noinspection CssInvalidPseudoSelector
export const FormControl = styled.textarea.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'haveLeftIcon', 'haveRightIcon', 'withPrefix', 'withSuffix', 'borderRadius', 'success'].includes(prop),
})<
    TextareaProps & {
        haveErrors?: boolean;
        haveLeftIcon?: boolean;
        haveRightIcon?: boolean;
        withPrefix?: boolean;
        withSuffix?: boolean;
    }
>`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    letter-spacing: 0.005em;
    padding: 0 0.6rem 0.6rem 20px;
    border: none;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    caret-color: ${({ theme }) => theme.colors.tertiary.tertiary50};
    display: block;
    width: 100%;
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height || '208px')};
    overflow: visible;
    margin: 0;
    outline: none;
    resize: none;
    border-radius: 24px;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}
    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.neutral.neutral60};
        opacity: 0; /* Firefox */
    }

    &:input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ theme }) => theme.colors.neutral.neutral60};
        opacity: 0;
    }

    &::input-placeholder {
        /* Microsoft Edge */
        color: ${({ theme }) => theme.colors.neutral.neutral60};
        opacity: 0;
    }

    &:focus {
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 1; /* Firefox */
        }

        &:input-placeholder {
            /* Internet Explorer 10-11 */
            opacity: 1;
        }

        &::input-placeholder {
            /* Microsoft Edge */
            opacity: 1;
        }
    }

    &:focus ~ span:first-of-type {
        ${({ haveErrors }) =>
            haveErrors &&
            css`
                display: none;
            `};
    }

    &:focus ~ span:last-of-type {
        color: ${({ theme }) => theme.colors.neutral.neutral60} !important;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
        transform: translateY(-70%);
        margin-left: 20px;
        padding: 0.44rem 0.2rem 0 0;
        font-size: ${({ theme }) => theme.font.cap.mobile.size};
        font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
        line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
        color: ${({ theme }) => theme.colors.tertiary.tertiary50};

        ${({ theme }) => theme.media.md`
            font-size: ${theme.font.cap.desktop.size};
            font-weight: ${theme.font.cap.desktop.weight};
            line-height: ${theme.font.cap.desktop.lineheight};
        `}
    }

    &:not(:focus) {
        ${({ haveErrors }) =>
            haveErrors &&
            css`
                background-color: ${({ theme }) => theme.colors.error.error95};
            `};
    }
`;

export const FormLabel = styled.label.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled'].includes(prop),
})<{ haveErrors?: boolean; disabled?: boolean }>`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme, haveErrors, disabled }) => (disabled ? theme.colors.neutral.neutral60 : !haveErrors ? theme.colors.neutral.neutral10 : theme.colors.error.error50)};
    display: block;
    position: absolute;
    top: 15px;
    height: 20px;
    margin-left: 1.3rem;
    padding: 0 0.2rem 0.44rem 0;
    transition:
        all 0.25s ease-in-out,
        color 0s ease-in-out,
        background-color 0s ease-in-out;
    margin-bottom: 0;
    pointer-events: none;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.mobile.style};
    `}
`;

export const HelpBlock = styled.span`
    font-family: ${({ theme }) => theme.font.cap.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.cap.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral20};
    background-color: transparent !important;
    position: absolute;
    margin-block-start: 0;
    margin-block-end: 0;
    width: 70%;
    padding-top: 4px;
    padding-left: 20px;
    text-align: left;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.tablet.size};
        font-weight: ${theme.font.cap.tablet.weight};
        line-height: ${theme.font.cap.tablet.lineheight};
        font-style: ${theme.font.cap.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
        font-style: ${theme.font.cap.desktop.style};
    `}
`;

export const ErrorBlock = styled(HelpBlock)<{
    children: string | false | string[] | FormikErrors<unknown> | FormikErrors<unknown>[];
}>`
    color: ${({ theme }) => theme.colors.error.error50};
`;

export const LetterCounter = styled.span.withConfig({
    shouldForwardProp: (prop) => !['haveErrors'].includes(prop),
})<{
    haveErrors: boolean;
}>`
    font-family: ${({ theme }) => theme.font.cap.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.cap.mobile.style};
    color: ${({ theme, haveErrors }) => (!haveErrors ? theme.colors.neutral.neutral20 : theme.colors.error.error50)};
    background-color: transparent !important;
    position: absolute;
    margin-block-start: 0;
    margin-block-end: 0;
    width: 100%;
    padding-top: 4px;
    padding-right: 20px;
    text-align: right;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
    `}
`;
