import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT } from '@/graphql/fragments';

export const SUBSCRIBE_NEWSLETTER = gql`
    ${ERRORS_FRAGMENT}
    mutation Subscribe($fullName: String!, $email: String!, $recaptchaToken: String!) {
        subscribe(input: { fullName: $fullName, email: $email} , recaptchaToken: $recaptchaToken) {
            status # Int
            errors {
                ...ErrorType
            }
        }
    }
`;

export const UNSUBSCRIBE_NEWSLETTER = gql`
    ${ERRORS_FRAGMENT}
    mutation Unsubscribe($email: String!) {
        unsubscribe(email: $email) {
            status # Int
            errors {
                ...ErrorType
            }
        }
    }
`;
