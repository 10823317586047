import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    padding: 25px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    align-self: stretch;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.colors.neutral.neutral98};
`;

export const Header = styled.div`
    width: 100%;
    padding: 0 10px;
`;

export const Content = styled.ul`
    list-style: none;
    width: 100%;
    margin-block-start: 1em;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 25px;
`;

export const Item = styled.li`
    padding: 8px 0;
`;
