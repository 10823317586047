import React from 'react';

// Icons
import IconLodgin from 'assets/images/lodgin.svg';
import IconPlane from 'assets/images/plane_simple.svg';
import * as FeatherIcon from 'react-feather';

const iconCalendar = <FeatherIcon.Calendar />;
const iconPlane = <IconPlane />;
const iconLodgin = <IconLodgin width="24px" />;

export const reviews = {
    pointsSummary: [
        {
            id: 'P1',
            services: {
                es: 'Ubicación',
                en: 'Location',
            },
            criterium: 4.8,
        },
        {
            id: 'P2',
            services: {
                es: 'Limpieza',
                en: 'Cleaning',
            },
            criterium: 4.9,
        },
        {
            id: 'P3',
            services: {
                es: 'Servicio',
                en: 'Service',
            },
            criterium: 5,
        },
        {
            id: 'P4',
            services: {
                es: 'Fiabilidad',
                en: 'Reliability',
            },
            criterium: 5,
        },
        {
            id: 'P5',
            services: {
                es: 'Relación calidad-precio',
                en: 'Value for money',
            },
            criterium: 5,
        },
    ],

    summary: {
        totalPoints: '4.9',
        review: {
            es: 'Muy buena',
            en: 'Very good',
        },
        stars: 3,
        totalreviews: 130,
    },

    allReviews: [
        {
            id: 'R01',
            name: 'John',
            criterium: 4.5,
            review: {
                es: 'Muy buena',
                en: 'Very good',
            },
            totalReviews: 140,
            utilReviews: 54,
            subject: 'Lorem ipsum',
            textreview:
                'Lorem ipsum dolor sit amet consectetur. Amet sit egestas ultrices vitae suspendisse a duis varius. Integer tellus phasellus tristique semper hac proin libero magna. Fringilla nullam egestas odio enim urna arcu donec. Imperdiet lobortis cras.',
            datestay: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
            reviewdate: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
        },
        {
            id: 'R02',
            name: 'John',
            criterium: 4.5,
            review: {
                es: 'Muy buena',
                en: 'Very good',
            },
            totalReviews: 140,
            utilReviews: 54,
            subject: 'Lorem ipsum',
            textreview:
                'Lorem ipsum dolor sit amet consectetur. Amet sit egestas ultrices vitae suspendisse a duis varius. Integer tellus phasellus tristique semper hac proin libero magna. Fringilla nullam egestas odio enim urna arcu donec. Imperdiet lobortis cras.',
            datestay: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
            reviewdate: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
        },
        {
            id: 'R03',
            name: 'John',
            criterium: 4.5,
            review: {
                es: 'Muy buena',
                en: 'Very good',
            },
            totalReviews: 140,
            utilReviews: 54,
            subject: 'Lorem ipsum',
            textreview:
                'Lorem ipsum dolor sit amet consectetur. Amet sit egestas ultrices vitae suspendisse a duis varius. Integer tellus phasellus tristique semper hac proin libero magna. Fringilla nullam egestas odio enim urna arcu donec. Imperdiet lobortis cras.',
            datestay: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
            reviewdate: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
        },
        {
            id: 'R04',
            name: 'John',
            criterium: 4.5,
            review: {
                es: 'Muy buena',
                en: 'Very good',
            },
            totalReviews: 140,
            utilReviews: 54,
            subject: 'Lorem ipsum',
            textreview:
                'Lorem ipsum dolor sit amet consectetur. Amet sit egestas ultrices vitae suspendisse a duis varius. Integer tellus phasellus tristique semper hac proin libero magna. Fringilla nullam egestas odio enim urna arcu donec. Imperdiet lobortis cras.',
            datestay: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
            reviewdate: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
        },
        {
            id: 'R05',
            name: 'John',
            criterium: 4.5,
            review: {
                es: 'Muy buena',
                en: 'Very good',
            },
            totalReviews: 140,
            utilReviews: 54,
            subject: 'Lorem ipsum',
            textreview:
                'Lorem ipsum dolor sit amet consectetur. Amet sit egestas ultrices vitae suspendisse a duis varius. Integer tellus phasellus tristique semper hac proin libero magna. Fringilla nullam egestas odio enim urna arcu donec. Imperdiet lobortis cras.',
            datestay: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
            reviewdate: {
                es: 'junio de 2022',
                en: 'June 2022',
            },
        },
    ],
};

export const experiences = {
    related: [
        {
            id: 1,
            name: 'Hostal Heredia',
            stars: 3,
            reviews: 1300,
            description: 'Lorem ipsum dolor sit amet, consect adipiscing elit.',
            price: 30,
            personType: { en: 'night', es: 'noche' },
            urlImg: '/images/experiences/heredia.png',
            details: [
                { icon: iconCalendar, value: { en: '2 days and 3 nights', es: '2 días y 3 noches' } },
                { icon: iconPlane, value: { en: 'Return flights included', es: 'Vuelos de ida y retorno incluidos' } },
                { icon: iconLodgin, value: { en: 'Accommodation included', es: 'Alojamiento incluido' } },
            ],
        },
        {
            id: 2,
            name: 'Hostal María y Tony',
            stars: 3,
            reviews: 1300,
            description: 'Lorem ipsum dolor sit amet, consect adipiscing elit.',
            price: 28,
            personType: { en: 'adult', es: 'adulto' },
            urlImg: '/images/experiences/maria_tony.png',
            details: [
                { icon: iconCalendar, value: { en: '2 days and 3 nights', es: '2 días y 3 noches' } },
                { icon: iconPlane, value: { en: 'Return flights included', es: 'Vuelos de ida y retorno incluidos' } },
                { icon: iconLodgin, value: { en: 'Accommodation included', es: 'Alojamiento incluido' } },
            ],
        },
        {
            id: 3,
            name: 'Hostal Dos Hermanas',
            stars: 3,
            reviews: 1300,
            description: 'Lorem ipsum dolor sit amet, consect adipiscing elit.',
            price: 45,
            personType: { en: 'night', es: 'noche' },
            urlImg: '/images/experiences/dos_hermanas.png',
            details: [
                { icon: iconCalendar, value: { en: '2 days and 3 nights', es: '2 días y 3 noches' } },
                { icon: iconPlane, value: { en: 'Return flights included', es: 'Vuelos de ida y retorno incluidos' } },
                { icon: iconLodgin, value: { en: 'Accommodation included', es: 'Alojamiento incluido' } },
            ],
        },
        {
            id: 4,
            name: 'Hostal Dos Hermanas',
            stars: 3,
            reviews: 1300,
            description: 'Lorem ipsum dolor sit amet, consect adipiscing elit.',
            price: 45,
            personType: { en: 'night', es: 'noche' },
            urlImg: '/images/experiences/dos_hermanas.png',
            details: [
                { icon: iconCalendar, value: { en: '2 days and 3 nights', es: '2 días y 3 noches' } },
                { icon: iconPlane, value: { en: 'Return flights included', es: 'Vuelos de ida y retorno incluidos' } },
                { icon: iconLodgin, value: { en: 'Accommodation included', es: 'Alojamiento incluido' } },
            ],
        },
    ],
};

export const breadcrumbs = [
    { id: 1, value: 'Fin de semana en el Hostal La Hiedra' },
    { id: 2, value: 'flights:bread.datePassengers' },
    { id: 3, value: 'flights:bread.payment' },
];

export const filterScore = [
    {
        label: {
            es: 'Excelente',
            en: 'Excellent',
        },
        value: '169',
    },
    {
        label: {
            es: 'Muy bueno',
            en: 'Very good',
        },
        value: '78',
    },
    {
        label: {
            es: 'Normal',
            en: 'Normal',
        },
        value: '65',
    },
    {
        label: {
            es: 'Malo',
            en: 'Bad',
        },
        value: '26',
    },
    {
        label: {
            es: 'Pésimo',
            en: 'Dreadful',
        },
        value: '13',
    },
];

export const filterTraveler = [
    {
        label: {
            es: 'Familias',
            en: 'Families',
        },
        value: '169',
    },
    {
        label: {
            es: 'Parejas',
            en: 'Couples',
        },
        value: '78',
    },
    {
        label: {
            es: 'Amigos',
            en: 'Friends',
        },
        value: '65',
    },
    {
        label: {
            es: 'Negocios',
            en: 'Business',
        },
        value: '26',
    },
    {
        label: {
            es: 'Solo',
            en: 'Alone',
        },
        value: '13',
    },
];

export const filterSeasons = [
    {
        label: {
            es: 'Primavera (Mar-May)',
            en: 'Spring (Mar-May)',
        },
        value: '169',
    },
    {
        label: {
            es: 'Verano (Jun-Ago)',
            en: 'Summer (Jun-Aug)',
        },
        value: '78',
    },
    {
        label: {
            es: 'Otoño (Sep-Nov)',
            en: 'Autumn (Sep-Nov)',
        },
        value: '65',
    },
    {
        label: {
            es: 'Invierno (Dic-Feb)',
            en: 'Winter (Dec-Feb)',
        },
        value: '26',
    },
];

export const filterLanguage = [
    {
        label: {
            es: 'Español',
            en: 'Spanish',
        },
        value: '169',
    },
    {
        label: {
            es: 'Inglés',
            en: 'English',
        },
        value: '78',
    },
    {
        label: {
            es: 'Francés',
            en: 'French',
        },
        value: '65',
    },
    {
        label: {
            es: 'Italiano',
            en: 'Italian',
        },
        value: '26',
    },
    {
        label: {
            es: 'Alemán',
            en: 'German',
        },
        value: '13',
    },
];

export const numAdditional = [
    { es: '1er', en: '1st' },
    { es: '2do', en: '2nd' },
    { es: '3ro', en: '3rd' },
    { es: '4to', en: '4th' },
    { es: '5to', en: '5th' },
    { es: '6to', en: '6th' },
    { es: '7mo', en: '7th' },
    { es: '8vo', en: '8th' },
    { es: '9no', en: '9th' },
    { es: '10mo', en: '10th' },
];
