import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import { Typography } from 'components/atoms';

// Styles
import { ListItems, Item, IconContainer } from './DestinationIconList.styles';

const DestinationIconList = ({ items }) => {
    return (
        <ListItems>
            {items?.map((item) =>
                React.Children.toArray(
                    <Item>
                        <IconContainer>{item.icon}</IconContainer>
                        <Typography type="body3">{item.text}</Typography>
                    </Item>
                )
            )}
        </ListItems>
    );
};

DestinationIconList.propTypes = {
    items: PropTypes.array,
};

export default DestinationIconList;
