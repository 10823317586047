import styled from 'styled-components';

export const ListItems = styled.ul`
    margin: 0;
    padding: 5px 0;
`;

export const Item = styled.li`
    display: flex;
    padding-top: 4px;
`;

export const IconContainer = styled.div`
    width: 20px;
    display: flex;
    justify-content: center;
    margin-right: 7px;
`;
