import React from 'react';
import * as FeatherIcon from 'react-feather';
import { CheckboxProps } from './Checkbox.types';
import { CheckboxContainer, CheckboxIcon, CheckboxIconContainer, CheckboxInput, CheckboxLabel, CheckboxRow, ErrorBlock, HelpBlock } from './Checkbox.styles';

const Checkbox: React.FC<CheckboxProps> = ({ label, help, checked, value, errors, disabled, onClick, onChange, tabIndex, width, height }) => {
    const [focus, setFocus] = React.useState(false);

    const haveErrors = !!(errors && errors?.length > 0);

    return (
        <>
            <CheckboxRow>
                <CheckboxContainer focus={focus} disabled={disabled} haveErrors={haveErrors}>
                    <CheckboxInput
                        tabIndex={tabIndex}
                        type="checkbox"
                        checked={checked}
                        value={value}
                        disabled={disabled}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onClick={onClick}
                        onChange={onChange}
                    />
                    <CheckboxIconContainer checked={checked} disabled={disabled} haveErrors={haveErrors} width={width} height={height}>
                        <CheckboxIcon checked={checked} disabled={disabled} haveErrors={haveErrors}>
                            <FeatherIcon.Check className="vx-icon" size={10} strokeWidth={6} />
                        </CheckboxIcon>
                    </CheckboxIconContainer>
                </CheckboxContainer>
                {label && (
                    <CheckboxLabel disabled={disabled} haveErrors={haveErrors}>
                        {label}
                    </CheckboxLabel>
                )}
            </CheckboxRow>

            {help && <HelpBlock>{help}</HelpBlock>}
            {errors && <ErrorBlock>{errors}</ErrorBlock>}
        </>
    );
};

export default Checkbox;
