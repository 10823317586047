import styled from 'styled-components';

export const PassengerSelector = styled.span`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const SelectorContainer = styled.ul`
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    padding: 20px;
`;

export const Selector = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 18px;
`;

export const InfoContainer = styled.div`
    min-width: 96px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-right: 48px;
`;

export const ButtonContainer = styled.li`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;
