import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

// Atoms
import { Typography, Button } from 'components/atoms';

// Styles
import { Wrapper } from './FlightPriceCard.styles';

const FlightPriceCard = ({ color, price, available }) => {
    // Translations
    const { t } = useTranslation();

    return (
        <Wrapper available={available} price={price} color={color}>
            {price && (
                <Typography type="sub1" $align="center">
                    {price}
                </Typography>
            )}
            {available && (
                <Button
                    content={available ? t('flights:priceBtn.details') : t('flights:price.soldOut')}
                    color={available ? 'primary' : 'transparent'}
                    type="button"
                    align="center"
                    width="110px"
                    disabled={!available}
                />
            )}

            {!available && (
                <Typography type="sub1" $align="center">
                    {price ? t('flights:price.soldOut') : t('flights:price.notAvailable')}
                </Typography>
            )}
        </Wrapper>
    );
};
FlightPriceCard.propTypes = {
    price: PropTypes.string,
    color: PropTypes.string,
    available: PropTypes.bool,
};

export default FlightPriceCard;
