import React from 'react';
import Image from 'next/image';
import { useTheme } from 'styled-components';
import * as FeatherIcon from 'react-feather';
import { VideoWrapperProps } from './VideoWrapper.types';
import { Wrapper, Video } from './VideoWrapper.styles';

const VideoWrapper: React.FC<VideoWrapperProps> = ({ video_url, image_preview, label }) => {
    const videoRef = React.useRef<HTMLVideoElement | null>(null); // Video Reference
    const theme = useTheme(); // Get current theme
    const [playVideo, setPlayVideo] = React.useState(false);

    React.useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const playEventHandler = () => {
            setPlayVideo(true);
        };

        const pauseEventHandler = () => {
            setPlayVideo(false);
        };

        videoElement.addEventListener('play', playEventHandler);
        videoElement.addEventListener('pause', pauseEventHandler);

        return () => {
            videoElement.removeEventListener('play', playEventHandler);
            videoElement.removeEventListener('pause', pauseEventHandler);
        };
    }, []);

    const handlePlayVideo = React.useCallback(() => {
        if (!playVideo) {
            void videoRef.current?.play();
        } else {
            void videoRef.current?.pause();
        }
    }, []);

    return (
        <Wrapper playVideo={playVideo} onClick={handlePlayVideo}>
            <FeatherIcon.Play size={60} fill={theme.colors.basic.white} color={theme.colors.basic.white} />

            <Image
                alt=""
                src={image_preview}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                style={{ objectFit: 'cover', objectPosition: 'center center' }}
                priority
            />

            <Video ref={videoRef} preload="true" controls controlsList="nodownload noplaybackrate" disableRemotePlayback disablePictureInPicture aria-label={label}>
                <source src={video_url} type="video/mp4" />
            </Video>
        </Wrapper>
    );
};

export default VideoWrapper;
