import styled, { css } from 'styled-components';
import { VariantType } from './Toast.types';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['top', 'variant', 'show'].includes(prop),
})<{ top: number | string; variant: VariantType; show: boolean }>`
    display: none;
    padding: 16px;
    max-width: 95% !important;
    position: absolute;
    top: ${({ top }) => typeof top === 'number' ? `${top}px` : top || '0'};
    border-radius: 8px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    background: ${({ theme, variant }) => (variant === 'info' ? theme.colors.basic.white : theme.colors.error.error90)};
    box-shadow:
        0 4px 8px 3px rgb(0 0 0 / 15%),
        0 1px 3px 0 rgb(0 0 0 / 30%);

    ${({ show }) =>
        show &&
        css`
            display: flex;
            align-items: center;
            gap: 8px;
            opacity: 1;
            pointer-events: visible;
        `}
`;

export const IconClose = styled.span`
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;
