import React from 'react';
import { Icon } from '@/components/atoms';
import { FlightLineProps } from './FlightLine.types';
import { Progress, Bar, CircleContainer } from './FlightLine.styles';

const FlightLine: React.FC<FlightLineProps> = ({ steps, display, variant }) => {
    const stepsChanged = variant === 'normal' ? steps + 2 : 2;

    const stepsView = [...Array(stepsChanged)];

    return (
        <Progress display={display}>
            {React.Children.toArray(
                stepsView.map((e, i) => {
                    return (
                        <>
                            {i < stepsChanged - 1 ? <CircleContainer bordered={i > 0} /> : variant === 'normal' ? <Icon.Plane2 /> : <Icon.Plane3 />}
                            <Bar display={display} variant={variant} />
                        </>
                    );
                })
            )}
        </Progress>
    );
};

FlightLine.defaultProps = {
    steps: 0,
    display: 'horizontal',
    variant: 'normal',
};

export default FlightLine;
