import React from 'react';

const removeReservedMediaKeyWord = (mediaQueryString: string) => {
    return mediaQueryString.replace('@media', '').trim();
};

const useMediaQuery = (mediaQueryString: string) => {
    const queryString = removeReservedMediaKeyWord(mediaQueryString);
    const query = React.useMemo(() => window.matchMedia(queryString), [queryString]);
    const [matches, setMatches] = React.useState(query.matches); // one-time, instantaneous check

    React.useEffect(() => {
        const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
        query.addEventListener('change', listener);
        return () => query.removeEventListener('change', listener);
    }, [query]);

    return matches;
};

export default useMediaQuery;
