import styled, { css } from 'styled-components';

export const Card = styled.div.withConfig({
    shouldForwardProp: (prop) => !['opened', 'bgColor'].includes(prop),
})<{ opened: boolean; bgColor: 'default' | 'inverted' }>`
    width: 100%;
    min-width: 250px;
    max-height: 75px;
    text-align: center;
    padding: 10px;
    margin-bottom: 15px;
    border: 2px solid transparent;
    border-radius: 10px;
    background: ${({ theme, bgColor }) => (bgColor === 'default' ? theme.colors.neutral.neutral96 : theme.colors.basic.white)};
    transition: all 0.2s ease-out;
    cursor: pointer;

    &:first-of-type {
        margin-top: 20px;
    }

    ${({ theme }) => theme.media.lg`
        max-width: 780px;
    `}

    &:hover {
        background: ${({ theme, bgColor }) => (bgColor === 'default' ? theme.colors.neutral.neutral94 : theme.colors.basic.white)};
    }

    &:active {
        background: ${({ theme, bgColor }) => (bgColor === 'default' ? theme.colors.neutral.neutral92 : theme.colors.basic.white)};
    }

    & p {
        transition: opacity 0s ease-out;
        overflow: hidden;
        opacity: 0;
        height: 0;
        padding-top: 0;
    }

    ${({ opened, bgColor }) =>
        opened &&
        css`
            max-height: 800px;
            transition: all 0.6s ease-in;
            background: ${({ theme }) => (bgColor === 'default' ? theme.colors.neutral.neutral96 : theme.colors.basic.white)};

            p {
                transition: opacity 0.8s ease-in;
                height: auto;
                opacity: 1;
                padding-top: 12px;
            }
        `}
`;

export const HeaderFaqs = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
`;

export const PlusIcon = styled.span.withConfig({
    shouldForwardProp: (prop) => !['opened'].includes(prop),
})<{ opened: boolean }>`
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
    transition: all 0.4s ease-in;
    text-align: center;
    transform: rotate(${({ opened }) => (opened === true ? '135deg' : 0)});
`;
