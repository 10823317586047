import styled, { css } from 'styled-components';

const handleColorTypeItem = (color, theme) => {
    switch (color) {
        case 'economy':
            return theme.colors.neutral.neutral94;
        case 'business':
            return theme.colors.neutral.neutral92;
        default:
            return theme.colors.neutral.neutral90;
    }
};

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 263px;
    font-size: 30px;
    text-align: center;
    background-color: ${({ theme, color }) => handleColorTypeItem(color, theme)};

    --color: ${({ theme, color }) => handleColorTypeItem(color, theme)};

    ${({ price }) =>
        !price &&
        css`
            background: linear-gradient(to top right, var(--color) calc(50% - 2px), ${({ theme }) => theme.colors.neutral.neutral80}, var(--color) calc(50% + 2px));
        `}

    p {
        margin-bottom: 10px;
        ${({ available }) =>
            !available &&
            css`
                color: ${({ theme }) => theme.colors.neutral.neutral50};
            `}
    }

    p:first-of-type {
        margin-bottom: 10px;

        ${({ price, available }) =>
            price &&
            !available &&
            css`
                text-decoration: line-through;
            `}
    }
`;
