import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Wrapper } from './MinimalLayout.styles';

const MinimalLayout = ({ children }) => {
    return (
        <Wrapper>
            <div>{children}</div>
        </Wrapper>
    );
};

MinimalLayout.propTypes = {
    children: PropTypes.node,
};

export default MinimalLayout;
