import styled from 'styled-components';
import { fadeIn } from 'styles/animations';

export const Card = styled.div`
    width: 100%;
    max-width: 420px;
    height: 362px;
    background-color: ${({ theme }) => theme.colors.basic.white};
    display: flex;
    flex-flow: column;
    border-radius: 25px;
    overflow: hidden;
    animation: ${fadeIn} 1s ease-in-out;
`;

export const TopContainer = styled.div`
    height: 210px;
    position: relative;
    display: inline-block;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: inline-block; /* FF3.6-15 */
        background: linear-gradient(top, rgb(0 0 0 / 65%) 0%, rgb(0 0 0 / 0%) 50%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgb(0 0 0 / 65%) 0%, rgb(0 0 0 / 0%) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    img {
        display: block;
        height: 210px;
        width: auto;
    }
`;

export const BottomContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
`;

export const Offer = styled.div`
    display: flex;
    flex-flow: row;
    height: 80px;
    justify-content: center;
    padding-bottom: 18px;
`;

export const FirstPlace = styled.div`
    display: flex;
    flex-flow: column;
    flex-basis: 42%;

    h5,
    p {
        text-align: ${(align) => (align ? 'right' : 'center')};
    }

    h5:first-child {
        height: 50px;
        overflow: hidden;
    }
`;

export const IconPlus = styled.span`
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-basis: 16%;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.neutral.neutral10};
`;

export const SecondPlace = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    flex-basis: 42%;

    h5:first-child {
        height: 50px;
        overflow: hidden;
    }
`;

export const Badges = styled.span`
    position: absolute;
    top: 16px;
    left: 24px;
    color: ${({ theme }) => theme.colors.basic.white};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    z-index: 2;

    span {
        padding: 0 1px;
    }

    span.plus-top {
        position: relative;
        bottom: 6px;
        left: -1px;
    }
`;

export const NewBadge = styled.span`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    position: absolute;
    top: 47px;
    left: 24px;
    z-index: 2;
    padding: 3px 12px;
    border-radius: 12px;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
    `}
`;

export const Pricing = styled.div`
    position: absolute;
    top: 16px;
    right: 24px;
    z-index: 2;
`;
