import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import UrlMapper from '@/config/url-mapper';
import { capitalize } from '@/utils/helpers';
import { Typography } from '@/components/atoms';
import { FeaturedDestinationProps } from './FeaturedDestinations.types';
import { SwiperGlobalStyles, Frame, Card } from './FeaturedDestinations.styles';

import 'swiper/css';
import 'swiper/css/navigation';

const DEFAULT_IMAGE = '/images/default-logo.png';

const FeaturedDestinations: React.FC<FeaturedDestinationProps> = ({ destinations }) => {
    const theme = useTheme(); // Get current theme
    const router = useRouter(); // Router

    return (
        <>
            <SwiperGlobalStyles />
            <Swiper
                modules={[Autoplay, Navigation]}
                navigation
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1400: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                }}
                speed={500}
                className="FeaturedDestinationsSwiper"
            >
                {destinations?.map((item) => {
                    const principalImage = item?.city?.images?.filter((image) => image?.principal === true)[0];

                    return (
                        <SwiperSlide key={`destination-${item.id}`}>
                            {principalImage && (
                                <Card
                                    onClick={() => {
                                        void router.push(UrlMapper.destination_details.replace('[slug]', item.slug as string));
                                    }}
                                >
                                    <Image
                                        src={principalImage?.image?.url || DEFAULT_IMAGE}
                                        alt={item?.destination as string}
                                        fill
                                        style={{ objectPosition: 'center center', objectFit: 'cover' }}
                                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    />
                                    <Frame>
                                        <Typography variant="h6" color={theme.colors.basic.white} ml={20}>
                                            {item?.city.name}
                                        </Typography>
                                        <Typography variant="body1" color={theme.colors.basic.white} ml={20} mb={16}>
                                            {capitalize(item?.city.country.name)}
                                        </Typography>
                                    </Frame>
                                </Card>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
};

export default FeaturedDestinations;
