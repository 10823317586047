import styled, { css } from 'styled-components';
import { inputFileState } from '@/styles/animations';
import { TextfieldProps } from './Textfield.types';
import { FormikErrors } from 'formik';

export const FormGroup = styled.div.withConfig({
    shouldForwardProp: (prop) => !['mb'].includes(prop),
})<{ mb?: string | number }>`
    position: relative !important;
    margin-bottom: ${({ mb }) => (typeof mb === 'number' ? `${mb}px` : mb || '40px')};
    width: 100%;
`;

const Affix = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    top: 0;
    padding: 0 8px 8px;
    height: 50px;
    z-index: 2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: ${({ theme }) => theme.colors.neutral.neutral60};
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}
`;

export const Prefix = styled(Affix).withConfig({
    shouldForwardProp: (prop) => !['haveLeftIcon'].includes(prop),
})<{ haveLeftIcon?: boolean }>`
    left: 0.8rem;

    ${({ haveLeftIcon }) =>
        haveLeftIcon &&
        css`
            left: 3rem;
        `};
`;

export const Suffix = styled(Affix).withConfig({
    shouldForwardProp: (prop) => !['haveRightIcon'].includes(prop),
})<{ haveRightIcon?: boolean }>`
    right: 0.8rem;

    ${({ haveRightIcon }) =>
        haveRightIcon &&
        css`
            right: 3rem;
        `};
`;

export const SmallLetter = styled.span`
    position: absolute;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-top: 2px;
    background-color: transparent !important;
    font-family: ${({ theme }) => theme.font.cap.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.cap.mobile.style};

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.tablet.size};
        font-weight: ${theme.font.cap.tablet.weight};
        line-height: ${theme.font.cap.tablet.lineheight};
        font-style: ${theme.font.cap.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
        font-style: ${theme.font.cap.desktop.style};
    `}
`;

export const HelpBlock = styled(SmallLetter)`
    color: ${({ theme }) => theme.colors.neutral.neutral20};
    padding-left: 20px;
    text-align: left;
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

export const ErrorBlock = styled(HelpBlock)<{
    children: string | false | string[] | FormikErrors<unknown> | FormikErrors<unknown>[];
}>`
    color: ${({ theme }) => theme.colors.error.error50};
`;

export const LetterCounter = styled(SmallLetter).withConfig({
    shouldForwardProp: (prop) => !['haveErrors'].includes(prop),
})<{ haveErrors?: boolean }>`
    color: ${({ theme, haveErrors }) => (!haveErrors ? theme.colors.neutral.neutral20 : theme.colors.error.error50)};
    width: 100%;
    padding-right: 20px;
    text-align: right;
`;

// noinspection CssInvalidPseudoSelector
export const FormControl = styled.input.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'haveLeftIcon', 'haveRightIcon', 'withPrefix', 'withSuffix', 'borderRadius', 'success', 'innerRef'].includes(prop),
})<
    TextfieldProps & {
        haveErrors?: boolean;
        haveLeftIcon?: boolean;
        haveRightIcon?: boolean;
        withPrefix?: boolean;
        withSuffix?: boolean;
    }
>`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    caret-color: ${({ theme }) => theme.colors.tertiary.tertiary50};
    border: 1px solid transparent;
    border-radius: ${({ borderRadius }) => borderRadius || '24px'};
    background-color: ${({ theme }) => theme.colors.basic.white};
    background-clip: padding-box;
    box-sizing: border-box;
    width: ${({ widthInput }) => widthInput || '100%'};
    height: 50px;
    letter-spacing: 0.005em;
    display: block;
    overflow: visible;
    transition: all 0.5s ease-in-out;
    margin: 0;
    outline: 0;
    padding: ${({ haveLeftIcon, haveRightIcon, withPrefix, withSuffix }) =>
        haveLeftIcon && haveRightIcon
            ? '1.31rem 3.4rem 0.44rem 3.4rem'
            : haveLeftIcon && withPrefix
            ? '1.31rem 0.6rem 0.44rem 5.2rem'
            : haveRightIcon && withSuffix
            ? '1.31rem 5.2rem 0.44rem 1.3rem'
            : haveLeftIcon
            ? '1.31rem 0.6rem 0.44rem 3.4rem'
            : withPrefix
            ? '1.31rem 0.6rem 0.44rem 3rem'
            : haveRightIcon
            ? '1.31rem 3.4rem 0.44rem 1.3rem'
            : withSuffix
            ? '1.31rem 3.4rem 0.44rem 1.3rem'
            : '1.31rem 0.6rem 0.44rem 1.3rem'};

    ${({ widthInput }) =>
        widthInput &&
        css`
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        `};

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}
    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}
  &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.neutral.neutral60};
        opacity: 0; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ theme }) => theme.colors.neutral.neutral60};
        opacity: 0;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ theme }) => theme.colors.neutral.neutral60};
        opacity: 0;
    }

    &:not(:focus, :read-only, [autoFocus], :disabled) {
        ${({ haveErrors }) =>
            haveErrors &&
            css`
                color: ${({ theme }) => theme.colors.error.error50};
                background-color: ${({ theme }) => theme.colors.error.error95};
                border: none;
            `};

        ~ ${Prefix} {
            ${({ value }) =>
                !value &&
                css`
                    opacity: 0;
                `};
        }

        ~ ${Suffix} {
            ${({ value }) =>
                !value &&
                css`
                    opacity: 0;
                `};
        }
    }

    &:hover {
        ${({ disabled }) =>
            !disabled &&
            css`
                border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80};
            `};
    }

    &:focus ~ div {
        svg {
            color: ${({ theme }) => theme.colors.neutral.neutral10} !important;
        }
    }

    &:focus ~ span:last-of-type {
        color: ${({ theme }) => theme.colors.neutral.neutral60} !important;
    }

    &:focus,
    &:read-only[autoFocus] {
        border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80};

        ${({ withPrefix, withSuffix }) =>
            (!withPrefix || !withSuffix) &&
            css`
                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    opacity: 1; /* Firefox */
                }

                &:input-placeholder {
                    /* Internet Explorer 10-11 */
                    opacity: 1;
                }

                &::input-placeholder {
                    /* Microsoft Edge */
                    opacity: 1;
                }
            `};

        ~ ${Prefix} {
            opacity: 1;
        }

        ~ ${Suffix} {
            opacity: 1;
        }

        ~ ${HelpBlock} {
            ${({ haveErrors }) =>
                haveErrors &&
                css`
                    display: none;
                `};
        }
    }

    &:focus ~ label,
    &.edited:not(:placeholder-shown, :disabled) ~ label,
    &:read-only[autoFocus] ~ label {
        transform: translateY(-70%);
        padding: ${({ haveLeftIcon, haveRightIcon }) =>
            haveLeftIcon && haveRightIcon
                ? '0.44rem 3.4rem 0'
                : haveLeftIcon
                ? '0.44rem  0.2rem 0 3.4rem'
                : haveRightIcon
                ? '0.44rem 3.4rem 0 1.3rem'
                : '0.44rem  0.2rem 0 1.3rem'};
        color: ${({ theme, haveErrors, value }) => ((!haveErrors && value) || (haveErrors && !value) ? theme.colors.neutral.neutral10 : theme.colors.error.error50)};
        z-index: 1;
        font-family: ${({ theme }) => theme.font.cap.family};
        font-size: ${({ theme }) => theme.font.cap.mobile.size};
        font-size: ${({ theme }) => theme.font.cap.mobile.size};
        font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
        line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
        font-style: ${({ theme }) => theme.font.cap.mobile.style};

        ${({ theme }) => theme.media.md`
            font-size: ${theme.font.cap.tablet.size};
            font-weight: ${theme.font.cap.tablet.weight};
            line-height: ${theme.font.cap.tablet.lineheight};
            font-style: ${theme.font.cap.tablet.style};
        `}

        ${({ theme }) => theme.media.xl`
            font-size: ${theme.font.cap.desktop.size};
            font-weight: ${theme.font.cap.desktop.weight};
            line-height: ${theme.font.cap.desktop.lineheight};
            font-style: ${theme.font.cap.desktop.style};
        `}
    }

    &:focus ~ label {
        color: ${({ theme }) => theme.colors.neutral.neutral10} !important;
    }

    &[type='file'] {
        color: ${({ theme, haveErrors, success }) => (haveErrors ? theme.colors.error.error95 : success ? theme.colors.basic.black : theme.colors.basic.white)} !important;

        &::file-selector-button {
            display: none;
        }

        &:focus {
            color: ${({ theme }) => theme.colors.basic.white};
            ${({ success }) =>
                !success &&
                css`
                    animation: ${inputFileState} 1s linear infinite alternate;
                `}
        }

        &.edited:not(:placeholder-shown) {
            color: ${({ theme, haveErrors }) => (!haveErrors ? theme.colors.neutral.neutral10 : theme.colors.error.error50)};
        }

        &.edited:not(:placeholder-shown) ~ label {
            color: ${({ theme, haveErrors }) => (!haveErrors ? theme.colors.tertiary.tertiary50 : theme.colors.error.error50)};
        }
    }

    &[type='search'] {
        padding-top: 0;
        padding-bottom: 0;

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 1; /* Firefox */
        }

        :input-placeholder {
            /* Internet Explorer 10-11 */
            opacity: 1;
        }

        ::input-placeholder {
            /* Microsoft Edge */
            opacity: 1;
        }
    }
`;

export const FormLabel = styled.label.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled', 'haveLeftIcon', 'haveRightIcon'].includes(prop),
})<{ haveErrors?: boolean; disabled?: boolean; haveLeftIcon?: boolean; haveRightIcon?: boolean }>`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme, haveErrors, disabled }) => (disabled ? theme.colors.neutral.neutral60 : !haveErrors ? theme.colors.neutral.neutral10 : theme.colors.error.error50)};
    display: block;
    position: absolute;
    top: 15px;
    padding: ${({ haveLeftIcon, haveRightIcon }) =>
        haveLeftIcon && haveRightIcon ? '0 3.4rem 0.6rem' : haveLeftIcon ? '0 0.2rem 0.6rem 3.4rem' : haveRightIcon ? '0 3.4rem 0.6rem 1.3rem' : '0 0.2rem 0.44rem 1.3rem'};
    transition: all 0.25s ease-in-out;
    margin-bottom: 0;
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}
`;

export const FormLeftIcon = styled.div.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled', 'notEditable', 'focused', 'withPrefix'].includes(prop),
})<{ haveErrors?: boolean; disabled?: boolean; notEditable?: boolean; focused?: boolean; withPrefix?: boolean }>`
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    top: 0;
    left: 20px;
    z-index: 2;
    height: 50px;
    width: 24px !important;
    line-height: 38px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;

    svg {
        left: 3px;
        position: absolute;
        color: ${({ theme, haveErrors, disabled, notEditable, focused }) =>
            disabled ? theme.colors.neutral.neutral60 : !haveErrors || (notEditable && focused) ? theme.colors.neutral.neutral10 : theme.colors.error.error50};
        overflow: hidden;
        vertical-align: middle;
    }
`;

export const FormRightIcon = styled.div.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled'].includes(prop),
})<{ haveErrors?: boolean; disabled?: boolean }>`
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 20px;
    z-index: 3;
    height: 50px;
    width: 24px !important;
    line-height: 38px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;

    svg {
        right: 0;
        position: absolute;
        color: ${({ theme, haveErrors, disabled }) => (disabled ? theme.colors.neutral.neutral60 : !haveErrors ? theme.colors.neutral.neutral10 : theme.colors.error.error50)};
        overflow: hidden;
        vertical-align: middle;
    }

    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
`;

export const DataSuggestions = styled.ul`
    position: absolute;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.basic.white};
    border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80};
    max-height: 200px;
    overflow: auto;
    padding-left: 0;
    z-index: 9997;
    margin-block-start: 3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

export const ListOption = styled.li`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    padding: 5px 10px;
    margin: 0;
    list-style: none;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary.primary90};
        cursor: pointer;
    }
`;

export const NoSuggestions = styled.ul`
    position: absolute;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.basic.white};
    border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80};
    margin-top: 4px;
    padding: 5px 10px;
    z-index: 9997;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

export const NoSuggestionsText = styled.li`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    padding: 5px 10px;
    margin: 0;
    list-style: none;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.tablet.size};
        font-weight: ${theme.font.body2.tablet.weight};
        line-height: ${theme.font.body2.tablet.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}
`;
