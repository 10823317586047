import React from 'react';
import { ProgressBarProps } from './ProgressBar.types';
import { Container, Progress } from './ProgressBar.styles';

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, total, width }) => {
    const [calcProgress, setCalcProgress] = React.useState(0);

    React.useEffect(() => {
        setCalcProgress((progress * 100) / total);
    }, [progress, total]);

    return (
        <Container width={width}>
            <Progress progress={calcProgress} />
        </Container>
    );
};

export default ProgressBar;
