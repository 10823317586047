import React from 'react';
import * as FeatherIcon from 'react-feather';
import { useTheme } from 'styled-components';
import { Logo } from '@/components/atoms';
import { LanguageSwitcher, Navigation, UserDropdown } from '@/components/molecules';
import useUserMenu from '@/modules/user-menu';
import useLanguages from '@/modules/languages';
import useNavigations from '@/modules/navigations';
import { HeaderProps, UserMenuItemType } from './Header.types';
import { InnerContainer, SectionWrapper } from './Header.styles';

const Header: React.FC<HeaderProps> = ({ activeItem, handleToggleSidebar, status, fixed = true }) => {
    const theme = useTheme();
    const { navigations } = useNavigations();
    const { userMenu } = useUserMenu();
    const { languages } = useLanguages();

    return (
        <SectionWrapper fixed={fixed}>
            <InnerContainer>
                <FeatherIcon.Menu id="burger-icon" size={24} color={theme.colors.tertiary.tertiary50} strokeWidth={2} onClick={handleToggleSidebar} />

                <Logo variant="horizontal" color="full-color" link="/" alt="Chagod Tour" height={56} />

                <Navigation id="main-navigation" type="main" menuItems={navigations.main} activeItem={activeItem} />

                <LanguageSwitcher id="language-switcher" languages={languages} />

                <UserDropdown id="user-menu" items={userMenu as UserMenuItemType[]} status={status} />
            </InnerContainer>
        </SectionWrapper>
    );
};

export default Header;
