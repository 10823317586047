import AboutUs from './AboutUs';
import Advantages from './Advantages';
import Advertising from './Advertising';
import Banner from './Banner';
import Carousel from './Carousel';
import Error from './Error';
import ExperienceSlider from "./ExperienceSlider";
import Faqs from './Faqs';
import FeaturedDestinations from './FeaturedDestinations';
import FiltersTabs from './FiltersTabs';
import FlightDetails from './FlightDetails';
import FlightPassengers from './FlightPassengers';
import FlightPayment from './FlightPayment';
import FlightsSchedule from './FlightsSchedule';
import FlightSummary from './FlightSummary';
import Footer from './Footer';
import Header from './Header';
import HowToFindUs from './HowToFindUs';
import JobOfferDetails from './JobOfferDetails';
import JobOffers from './JobOffers';
import PassengerPicker from './PassengerPicker';
import SearchSummary from './SearchSummary';
import Sidebar from './Sidebar';

///

import ExperienceDetails from './ExperienceDetails';
import Experiences from './Experiences';
import UserProfile from './UserProfile';
import FlightTripModal from './FlightTripModal';
import DestinationDetails from './DestinationDetails';
import Destinations from './Destinations';
import ExperienceSummary from './ExperienceSummary';
import ReviewsExperience from './ReviewsExperience';
import ExperienceLodging from './ExperienceLodging';
import ExperienceBaggage from './ExperienceBaggage';
import ExperienceAmenities from './ExperienceAmenities';
import ReserveExperience from './ReserveExperience';

export {
    AboutUs,
    Advantages,
    Advertising,
    Banner,
    Carousel,
    Error,
    ExperienceSlider,
    Faqs,
    FeaturedDestinations,
    FiltersTabs,
    FlightDetails,
    FlightPassengers,
    FlightPayment,
    FlightsSchedule,
    FlightSummary,
    Footer,
    Header,
    HowToFindUs,
    JobOfferDetails,
    JobOffers,
    PassengerPicker,
    SearchSummary,
    Sidebar,

    ///
    ExperienceDetails,
    Experiences,
    UserProfile,
    FlightTripModal,
    DestinationDetails,
    Destinations,
    ExperienceSummary,
    ReviewsExperience,
    ExperienceLodging,
    ExperienceBaggage,
    ExperienceAmenities,
    ReserveExperience,
};
