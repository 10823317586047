import { gql } from "@apollo/client";

export const PAGE_INFO_FRAGMENT = gql`
    fragment PageInfoType on PageInfoType {
        page
        pages
        hasNext
        hasPrev
        total
    }
`;
