export const camelToSnake = (camelCaseStr: string): string => {
    return camelCaseStr.replace(/[A-Z]/g, (match, index) => {
        return index === 0 ? match.toLowerCase() : '_' + match.toLowerCase();
    });
};

export const snakeToCamel = (snakeCaseStr: string): string => {
    return snakeCaseStr.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};

// Convert text from uppercase to lowercase except first letter
export function capitalize(text: string) {
    let textCapitalize = '';
    if (!text) return textCapitalize;
    const arrayText = text.split(' ');

    if (arrayText.length > 1) {
        textCapitalize = arrayText.map((char) => `${char.charAt(0).toUpperCase()}${char.slice(1).toLowerCase()}`).join(' ');
    } else {
        textCapitalize = `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
    }

    return textCapitalize;
}
