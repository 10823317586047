import styled, { css } from 'styled-components';
import { fadeIn } from '@/styles/animations';
import { AuthLayoutSidesType, AuthLayoutVariantType } from './AuthLayout.types';

export const Wrapper = styled.div`
    display: flex;
    height: 100vh;
    position: static;
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};
    overflow: hidden;
`;

export const Container = styled.section.withConfig({
    shouldForwardProp: (prop) => !['variant', 'activeSide'].includes(prop),
})<{
    variant: AuthLayoutVariantType;
    activeSide: AuthLayoutSidesType;
}>`
    width: 100%;
    height: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};
    left: 0;
    z-index: 5;
    transform: translateX(0);

    & > * {
        animation: ${fadeIn} 1.5s;

        ${({ variant }) =>
            variant === 'form' &&
            css`
                max-width: 409px;
            `}

        ${({ variant }) =>
            variant === 'message' &&
            css`
                max-width: 496px;
            `}
    }

    ${({ theme, activeSide }) => theme.media.lg`
        width: 50%;

         ${
             activeSide === 'right' &&
             css`
                 transform: translateX(100%);
             `
         }
    `}
`;

export const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 1;
    display: none;

    ${({ theme }) => theme.media.lg`
        display: block;
    `}
`;

export const Overlay = styled.div.withConfig({
    shouldForwardProp: (prop) => !['activeSide'].includes(prop),
})<{
    activeSide: AuthLayoutSidesType;
}>`
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;

    ${({ activeSide }) =>
        activeSide === 'right' &&
        css`
            transform: translateX(50%);
        `}
`;

export const OverlayPanel = styled.div.withConfig({
    shouldForwardProp: (prop) => !['activeSide'].includes(prop),
})<{
    activeSide: AuthLayoutSidesType;
}>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;

    img {
        object-fit: cover;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: inline-block; /* FF3.6-15 */
        background: ${({ theme }) => theme.overlays.overlay3};
    }

    ${({ activeSide }) => {
        if (activeSide === 'left') {
            return css`
                transform: translateX(100%);

                img {
                    object-position: right;
                }
            `;
        } else if (activeSide === 'right') {
            return css`
                transform: translateX(0);

                img {
                    object-position: left;
                }
            `;
        }
    }}
`;

export const LogoContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['activeSide'].includes(prop),
})<{
    activeSide: AuthLayoutSidesType;
}>`
    position: absolute;
    top: 11px;
    width: 100%;
    max-width: 280px;
    height: 60px;
    z-index: 99;
    transition: all 1.5s ease-in-out;

    ${({ activeSide }) => {
        if (activeSide === 'left') {
            return css`
                right: 15px;
            `;
        } else if (activeSide === 'right') {
            return css`
                left: 15px;
            `;
        }
    }}
`;
