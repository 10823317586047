import React from 'react';
import { Section } from '@/components/molecules';
import { BreadcrumbType } from '@/components/molecules/Breadcrumbs';
import { BackgroundColorsType } from '@/components/molecules/Section/Section.types';
import dynamic from 'next/dynamic';

const Breadcrumbs = dynamic(() => import('@/components/molecules/Breadcrumbs'), {
    ssr: false,
    loading: () => {
        return <div style={{ height: '100vh', width: '100%' }} />;
    },
});

interface BreadcrumbsSectionProps {
    breadcrumbs: BreadcrumbType[];
    current?: number;
    bgcolor?: BackgroundColorsType;
}

const BreadcrumbsSection: React.FC<BreadcrumbsSectionProps> = ({ breadcrumbs, current, bgcolor }) => {
    return (
        <Section id="breadcrumbs" bgcolor={bgcolor || 'white'} container="default" pt="18px" pb="10px" pl="0" pr="0">
            <Breadcrumbs breadcrumbs={breadcrumbs} current={current} />
        </Section>
    );
};

export default BreadcrumbsSection;
