import React from 'react';
import { useTranslation } from 'next-i18next';
import { useMutation } from '@apollo/client';
import { useSession, signIn } from 'next-auth/react';
import Swal from 'sweetalert2';
import mime from 'mime-types';
import withReactContent from 'sweetalert2-react-content';
import { useRouter } from 'next/router';

// Data
import { UPDATE_PROFILE } from '@/graphql/mutations';

// Utils
import UrlMapper from 'utils/url-mapper';
import { b64toBlob } from 'utils/helpers';

// Atoms
import { Typography } from 'components/atoms';

// Molecules
import { PersonalInfo, ImageEditor } from 'components/molecules';

// Styles
import { FormWrapper, AvatarWrapper } from './UserProfile.styles';

const MySwal = withReactContent(Swal);

const UserProfile = () => {
    // Hooks
    const { t, i18n } = useTranslation(); // Translations
    const router = useRouter(); // Router
    const { data: session } = useSession(); // Get current session
    const user = session?.user;

    // States
    const [avatar, setAvatar] = React.useState(null);
    const [errorList, setErrorList] = React.useState([]);
    const [sending, setSending] = React.useState(false);

    // mutation to update profile
    const [userProfileMutation] = useMutation(UPDATE_PROFILE);

    // access session file here
    React.useEffect(() => {
        if (!user?.image?.url) return;
        const url = user?.image.url;
        fetch(url).then(async () => {
            setAvatar(url);
        });
    }, [user?.image?.url]);

    const saveUser = async (values, birth, issue, expiration, resetForm) => {
        setSending(true);

        let blob;

        let variables = {
            firstName: values.firstName,
            lastName: values.lastName,
            country: values.country?.value,
            email: values.email,
            phone: values.phone,
            birthday: birth,
            passport: values.passport,
            passportExpireAt: expiration,
            passportEmittedAt: issue,
            passportEmissionCountry: values.countryPasp?.value,
            callbackUrl: `${process.env.NEXT_PUBLIC_DOMAIN_URL}`,
        };

        if (avatar !== user?.image?.url) {
            const block = avatar.split(';');
            // Get the content type of the image
            const contentType = block[0].split(':')[1];
            // get the real base64 content of the file
            const realData = block[1].split(',')[1];

            // Convert it to a blob to upload
            blob = b64toBlob(realData, contentType);

            // Optional, defaults to `blob`.
            if (blob) blob.name = `${user?.id}.${Date.now()}.${mime.extension(blob.type)}`;

            variables = {
                ...variables,
                image: blob,
            };
        }

        await userProfileMutation({
            variables,
            context: {
                headers: {
                    'X-Auth-Token': session.accessToken,
                    Language: i18n.language,
                },
            },
            update: async (cache, { data: { updateProfile = {} } }) => {
                const { status, errors, user: userNew } = updateProfile;
                if (errors?.lenght > 0) {
                    setErrorList(errors);
                    setSending(false);
                    return;
                }

                if (status === 200) {
                    if (user.email !== values.email) {
                        // go to email verification page
                        router.push({
                            pathname: `${UrlMapper['verify-email'][i18n.language]}`,
                        });
                    } else {
                        MySwal.fire({
                            title: t('profile:success.message.title'),
                            text: t('profile:success.message.text'),
                            icon: 'success',
                            confirmButtonText: t('profile:button.confirm'),
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: `btn btn-primary btn-confirm`,
                            },
                            buttonsStyling: false,
                        }).then(async () => {
                            resetForm();
                            setSending(false);
                            await signIn('update-user', {
                                userNew: JSON.stringify({ ...userNew, newProperty: true }),
                                accessToken: session.accessToken,
                                expiresIn: session.expiresIn,
                                refreshToken: session.refreshToken,
                                redirect: false,
                                callbackUrl: `${process.env.NEXT_PUBLIC_DOMAIN_URL}user-profile`,
                            });
                        });
                    }
                } else {
                    setErrorList(errors);
                    if (status === 400) {
                        MySwal.fire({
                            title: t('profile:error.message.title'),
                            html: (
                                <>
                                    <ul>
                                        {errors[0].messages.map((message) => (
                                            <li key={message.id}>{message}</li>
                                        ))}
                                    </ul>
                                </>
                            ),
                            icon: 'error',
                            confirmButtonText: t('profile:button.close'),
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: `btn btn-primary btn-confirm`,
                            },
                            buttonsStyling: false,
                        }).then(async () => {
                            setSending(false);
                        });
                    } else {
                        MySwal.fire({
                            title: t('profile:error.message.title'),
                            text: t('profile:error.message.text'),
                            icon: 'error',
                            confirmButtonText: t('profile:button.close'),
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: `btn btn-primary btn-confirm`,
                            },
                            buttonsStyling: false,
                        }).then(async () => {
                            setSending(false);
                        });
                    }
                    setSending(false);
                }
            },
            onError: () => {
                MySwal.fire({
                    title: t('profile:error.message.title'),
                    text: t('profile:error.message.text'),
                    icon: 'error',
                    confirmButtonText: t('profile:button.close'),
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: `btn btn-primary btn-confirm`,
                    },
                    buttonsStyling: false,
                }).then(async () => {
                    setSending(false);
                });
            },
        });
    };

    return (
        <>
            <AvatarWrapper>
                <ImageEditor currentImage={avatar} name={`${user?.firstName} ${user?.lastName}`} setSelectedImage={setAvatar} />
                <Typography type="h5" $align="center" $mt="10px">
                    {user?.firstName}
                </Typography>
            </AvatarWrapper>
            <FormWrapper>
                <PersonalInfo saveUser={saveUser} errorss={errorList} send={sending} />
            </FormWrapper>
        </>
    );
};

export default UserProfile;
