import { gql } from '@apollo/client';
import { DESTINATION_FRAGMENT, ERRORS_FRAGMENT } from '@/graphql/fragments';

export const DESTINATIONS_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${DESTINATION_FRAGMENT}
    query Destinations($skip: Int, $take: Int, $report: Boolean) {
        destinations(skip: $skip, take: $take, report: $report) {
            status
            errors {
                ...ErrorType
            }
            objects {
                ...DestinationType
            }
        }
    }
`;

export const DESTINATION_BY_SLUG_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${DESTINATION_FRAGMENT}
    query DestinationBySlug($slug: String!) {
        destinationBySlug(slug: $slug) {
            status
            errors {
                ...ErrorType
            }
            object {
                ...DestinationType
            }
        }
    }
`;

export const DESTINATIONS_MOST_POPULAR_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${DESTINATION_FRAGMENT}
    query DestinationsMostPopulars($size: Int) {
        destinationsMostPopulars(size: $size) {
            status
            errors {
                ...ErrorType
            }
            objects {
                ...DestinationType
            }
        }
    }
`;

export const MOMENT_DESTINATIONS_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${DESTINATION_FRAGMENT}
    query MomentDestinations {
        momentDestinations {
            status
            errors {
                ...ErrorType
            }
            objects {
                ...DestinationType
            }
        }
    }
`;

export const DESTINATIONS_RECENTLY_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${DESTINATION_FRAGMENT}
    query DestinationsRecently($size: Int) {
        destinationsRecently(size: $size) {
            status
            errors {
                ...ErrorType
            }
            objects {
                ...DestinationType
            }
        }
    }
`;
