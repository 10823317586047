import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/animations';

export const CardContainer = styled.div`
    width: 280px;
    height: 280px;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.basic.white};
    overflow: hidden;
    animation: ${fadeIn} 1s ease-in-out;
`;

export const Frame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    padding: 16px 20px;
    left: calc(50% - 280px / 2);
    bottom: 0;
    height: 140px;
    width: 100%;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%);
`;

export const CardContainerIcon = styled.li`
    display: inline-block;
    min-width: fit-content;
    cursor: pointer;
    padding: 10px;
    background: ${({ theme }) => theme.colors.primary.primary80};
    border-radius: 10px;
`;

export const FrameContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CircleIcon = styled.div`
    width: 48px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.primary.primary70};
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
    border-radius: 50%;
    text-align: center;
    padding-top: 10px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary.primary90};
        cursor: pointer;
    }

    ${({ active }) =>
        active &&
        css`
            background-color: ${({ theme }) => theme.colors.primary.primary50};

            &:hover {
                background-color: ${({ theme }) => theme.colors.primary.primary60};
            }
        `}
`;
