import React from 'react';
import { useTranslation } from 'next-i18next';
import createPersistedState from 'use-persisted-state';
import { FilterSearchKeys, FilterSearchType, TripType } from "./PersistentStates.types";

/**
 *  Trip Type
 */
const useTripTypeState = createPersistedState<TripType>('trip-type');

export const useTripType = (): [
    string,
    string,
    {
        [key: string]: string;
    },
    React.Dispatch<React.SetStateAction<TripType>>,
] => {
    const { t } = useTranslation();
    const [tripType, setTripType] = useTripTypeState('round-trip');

    const tripTypes = {
        'round-trip': t('filters:tab.flights.travel-type.round-trip'),
        'one-way': t('filters:tab.flights.travel-type.one-way'),
    };

    return [tripType, tripTypes[tripType], tripTypes, setTripType];
};

/**
 *  Filter Search
 */
const useFilterSearchState = createPersistedState<FilterSearchType>('filter-search');

const filterSearchInitialState = {
    origin: '',
    destination: '',
    departure_date: '',
    return_date: '',
    passengers: {
        adults: 1,
        children: 0,
    },
    airlineCode: '',
    cabin: 'ECONOMY',
    maxStopsQty: 4,
    directFlightsOnly: false,
    maxResponses: 10,
};

export const useFilterSearch = (): [FilterSearchType, React.Dispatch<React.SetStateAction<FilterSearchType>>, (key: FilterSearchKeys) => void] => {
    const [filterSearch, setFilterSearch] = useFilterSearchState(filterSearchInitialState);

    const deleteFilterSearchAttribute = (key: FilterSearchKeys) => {
        const updatedFilterSearch = { ...filterSearch };

        delete updatedFilterSearch[key];

        setFilterSearch(updatedFilterSearch);
    };


    return [filterSearch, setFilterSearch, deleteFilterSearchAttribute];
};
