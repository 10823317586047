import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 30px 0;

    ${({ theme }) => theme.media.lg`
        padding: 40px 0;
        align-items: flex-start;
    `}
`;
