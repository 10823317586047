import React from 'react';
import dynamic from 'next/dynamic';
import { WizardStep } from '@/modules/wizard/Wizard.types';

const DepartureFlightSection = dynamic(() => import('@/sections/departure-flight'), {
    ssr: false,
    loading: () => {
        return <div style={{ height: '100vh' }} />;
    },
});

const ReturnFlightSection = dynamic(() => import('@/sections/return-flight'), {
    ssr: false,
    loading: () => {
        return <div style={{ height: '100vh' }} />;
    },
});

// const PassengerDataSection = dynamic(() => import('@/sections/passenger-data'), {
//     ssr: false,
//     loading: () => {
//         return <div style={{ height: '100vh' }} />;
//     },
// });
//
// const FlightSummarySection = dynamic(() => import('@/sections/flight-summary'), {
//     ssr: false,
//     loading: () => {
//         return <div style={{ height: '100vh' }} />;
//     },
// });

const FlightPaymentSection = dynamic(() => import('@/sections/flight-payment'), {
    ssr: false,
    loading: () => {
        return <div style={{ height: '100vh' }} />;
    },
});

const initialSteps: WizardStep[] = [
    { id: 0, content: <DepartureFlightSection /> },
    { id: 1, content: <ReturnFlightSection /> },
    // { id: 2, content: <PassengerDataSection /> },
    // { id: 3, content: <FlightCustomizationSection /> },
    // { id: 3, content: <FlightSummarySection /> },
    { id: 2, content: <FlightPaymentSection /> },
];

export default initialSteps;
