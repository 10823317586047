import React from 'react';
import { Typography } from '@/components/atoms';
import { SectionProps } from './Section.types';
import { SectionWrapper } from './Section.styles';

const Section: React.FC<SectionProps> = ({
    id,
    title,
    description,
    bgcolor,
    aligntext,
    titlecolor,
    tb,
    container,
    type,
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    children,
    position,
    fadeInTransition,
    extendStyles,
    ...props
}) => {
    return (
        <SectionWrapper
            as={type === 'section' ? 'section' : 'div'}
            id={id}
            bgcolor={bgcolor}
            container={container}
            type={type}
            mt={mt}
            mb={mb}
            ml={ml}
            mr={mr}
            pt={pt}
            pb={pb}
            pl={pl}
            pr={pr}
            position={position}
            aligntext={aligntext}
            fadeInTransition={fadeInTransition}
            extendStyles={extendStyles}
            {...props}
        >
            {title && (
                <Typography
                    variant={type === 'section' ? 'h2' : 'h6'}
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                    ml={ml}
                    align={aligntext || 'center'}
                    color={titlecolor}
                    mb={type === 'section' ? (typeof tb === 'number' ? `${tb}px` : tb || '20px') : '10px'}
                />
            )}

            {description && (
                <Typography
                    variant={type === 'section' ? 'body1' : 'body2'}
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                    align={aligntext || 'center'}
                />
            )}

            {children}
        </SectionWrapper>
    );
};

Section.defaultProps = {
    bgcolor: 'default',
    container: 'default',
    type: 'section',
    fadeInTransition: true,
};

export default Section;
