import React from 'react';
import Link from 'next/link';
import { LogoProps, LogoSVGComponentProps } from "./Logo.types";

// Full Color
import FullHorizontalFullColor from '@/assets/svg/logo/full-color/full-horizontal.svg';
import FullVerticalFullColor from '@/assets/svg/logo/full-color/full-vertical.svg';
import HorizontalFullColor from '@/assets/svg/logo/full-color/horizontal.svg';
import VerticalFullColor from '@/assets/svg/logo/full-color/vertical.svg';
import ImagotypeFullColor from '@/assets/svg/logo/full-color/imagotype.svg';

// Primary
import FullHorizontalPrimary from '@/assets/svg/logo/primary/full-horizontal.svg';
import FullVerticalPrimary from '@/assets/svg/logo/primary/full-vertical.svg';
import HorizontalPrimary from '@/assets/svg/logo/primary/horizontal.svg';
import VerticalPrimary from '@/assets/svg/logo/primary/vertical.svg';
import ImagotypePrimary from '@/assets/svg/logo/primary/imagotype.svg';

// Secondary
import FullHorizontalSecondary from '@/assets/svg/logo/secondary/full-horizontal.svg';
import FullVerticalSecondary from '@/assets/svg/logo/secondary/full-vertical.svg';
import HorizontalSecondary from '@/assets/svg/logo/secondary/horizontal.svg';
import VerticalSecondary from '@/assets/svg/logo/secondary/vertical.svg';
import ImagotypeSecondary from '@/assets/svg/logo/secondary/imagotype.svg';

// Tertiary
import FullHorizontalTertiary from '@/assets/svg/logo/tertiary/full-horizontal.svg';
import FullVerticalTertiary from '@/assets/svg/logo/tertiary/full-vertical.svg';
import HorizontalTertiary from '@/assets/svg/logo/tertiary/horizontal.svg';
import VerticalTertiary from '@/assets/svg/logo/tertiary/vertical.svg';
import ImagotypeTertiary from '@/assets/svg/logo/tertiary/imagotype.svg';

// Positive
import FullHorizontalPositive from '@/assets/svg/logo/positive/full-horizontal.svg';
import FullVerticalPositive from '@/assets/svg/logo/positive/full-vertical.svg';
import HorizontalPositive from '@/assets/svg/logo/positive/horizontal.svg';
import VerticalPositive from '@/assets/svg/logo/positive/vertical.svg';
import ImagotypePositive from '@/assets/svg/logo/positive/imagotype.svg';

// Negative
import FullHorizontalNegative from '@/assets/svg/logo/negative/full-horizontal.svg';
import FullVerticalNegative from '@/assets/svg/logo/negative/full-vertical.svg';
import HorizontalNegative from '@/assets/svg/logo/negative/horizontal.svg';
import VerticalNegative from '@/assets/svg/logo/negative/vertical.svg';
import ImagotypeNegative from '@/assets/svg/logo/negative/imagotype.svg';

// Neutral
import FullHorizontalNeutral from '@/assets/svg/logo/neutral/full-horizontal.svg';
import FullVerticalNeutral from '@/assets/svg/logo/neutral/full-vertical.svg';
import HorizontalNeutral from '@/assets/svg/logo/neutral/horizontal.svg';
import VerticalNeutral from '@/assets/svg/logo/neutral/vertical.svg';
import ImagotypeNeutral from '@/assets/svg/logo/neutral/imagotype.svg';


const LogoSVGComponent: React.FC<LogoSVGComponentProps> = ({ color, variant, height }) => (
    <>
        {color === 'full-color' && (
            <>
                {variant === 'full-horizontal' && <FullHorizontalFullColor height={height} />}
                {variant === 'full-vertical' && <FullVerticalFullColor height={height} />}
                {variant === 'horizontal' && <HorizontalFullColor height={height} />}
                {variant === 'vertical' && <VerticalFullColor height={height} />}
                {variant === 'imagotype' && <ImagotypeFullColor height={height} />}
            </>
        )}
        {color === 'primary' && (
            <>
                {variant === 'full-horizontal' && <FullHorizontalPrimary height={height} />}
                {variant === 'full-vertical' && <FullVerticalPrimary height={height} />}
                {variant === 'horizontal' && <HorizontalPrimary height={height} />}
                {variant === 'vertical' && <VerticalPrimary height={height} />}
                {variant === 'imagotype' && <ImagotypePrimary height={height} />}
            </>
        )}
        {color === 'secondary' && (
            <>
                {variant === 'full-horizontal' && <FullHorizontalSecondary height={height} />}
                {variant === 'full-vertical' && <FullVerticalSecondary height={height} />}
                {variant === 'horizontal' && <HorizontalSecondary height={height} />}
                {variant === 'vertical' && <VerticalSecondary height={height} />}
                {variant === 'imagotype' && <ImagotypeSecondary height={height} />}
            </>
        )}
        {color === 'tertiary' && (
            <>
                {variant === 'full-horizontal' && <FullHorizontalTertiary height={height} />}
                {variant === 'full-vertical' && <FullVerticalTertiary height={height} />}
                {variant === 'horizontal' && <HorizontalTertiary height={height} />}
                {variant === 'vertical' && <VerticalTertiary height={height} />}
                {variant === 'imagotype' && <ImagotypeTertiary height={height} />}
            </>
        )}
        {color === 'positive' && (
            <>
                {variant === 'full-horizontal' && <FullHorizontalPositive height={height} />}
                {variant === 'full-vertical' && <FullVerticalPositive height={height} />}
                {variant === 'horizontal' && <HorizontalPositive height={height} />}
                {variant === 'vertical' && <VerticalPositive height={height} />}
                {variant === 'imagotype' && <ImagotypePositive height={height} />}
            </>
        )}
        {color === 'negative' && (
            <>
                {variant === 'full-horizontal' && <FullHorizontalNegative height={height} />}
                {variant === 'full-vertical' && <FullVerticalNegative height={height} />}
                {variant === 'horizontal' && <HorizontalNegative height={height} />}
                {variant === 'vertical' && <VerticalNegative height={height} />}
                {variant === 'imagotype' && <ImagotypeNegative height={height} />}
            </>
        )}
        {color === 'neutral' && (
            <>
                {variant === 'full-horizontal' && <FullHorizontalNeutral height={height} />}
                {variant === 'full-vertical' && <FullVerticalNeutral height={height} />}
                {variant === 'horizontal' && <HorizontalNeutral height={height} />}
                {variant === 'vertical' && <VerticalNeutral height={height} />}
                {variant === 'imagotype' && <ImagotypeNeutral height={height} />}
            </>
        )}
    </>
);

const Logo: React.FC<LogoProps> = ({ color, variant, link, alt= 'Logo', height }) => {
    return (
        <>
            {link ? (
                <Link href={link} role="link" aria-label={alt} title={alt} style={{ height }}>
                    <LogoSVGComponent key={`${variant}-${color}`} color={color} variant={variant} height={height} />
                </Link>
            ) : (
                <LogoSVGComponent key={`${variant}-${color}`} color={color} variant={variant} height={height} />
            )}
        </>
    );
};

Logo.defaultProps = {
    variant: 'full-horizontal',
    alt: 'Chagod Tour',
};

export default Logo;
