import React from 'react';
import { FormikErrors } from 'formik';
import { DefaultTheme, ThemeContext } from 'styled-components';
import ReactSelect, { SingleValue, MultiValue, StylesConfig, GroupBase } from 'react-select';
import { OptionType, SelectProps } from './Select.types';
import { ErrorBlock, FormLabel, FormLeftIcon, FormGroup, HelpBlock } from './Select.styles';

const customStyles = (
    theme: DefaultTheme,
    errors: string | false | string[] | FormikErrors<unknown> | FormikErrors<unknown>[] | undefined,
    defaultValue: OptionType | undefined,
    disabled: boolean | undefined,
    leftIcon: React.ReactNode
): StylesConfig<OptionType, false, GroupBase<OptionType>> => ({
    container: (provided) => ({
        ...provided,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 101,
        backgroundColor: theme.colors.basic.white,
    }),
    control: (provided, state) => ({
        ...provided,
        fontFamily: theme.font.body2.family,
        fontSize: theme.font.body2.mobile.size,
        fontWeight: theme.font.body2.mobile.weight,
        lineHeight: theme.font.body2.mobile.lineheight,
        caretColor: theme.colors.tertiary.tertiary50,
        transition: 'none',
        height: '50px',
        borderRadius: '24px',
        padding: state.isFocused || defaultValue ? '0.8rem 0.2rem 0 0.6rem' : '0 0.2rem 0 0.6rem',
        paddingLeft: leftIcon ? '2.7rem' : '',
        backgroundColor: errors && !state.isFocused ? theme.colors.error.error95 : theme.colors.basic.white,
        backgroundClip: 'padding-box',
        color: theme.colors.neutral.neutral10,
        border: state.isFocused ? `1px solid ${theme.colors.neutral.neutral80}` : '1px solid transparent',
        boxShadow: 'none',
        ':hover': {
            cursor: 'pointer',
            border: `1px solid ${theme.colors.neutral.neutral80}`,
            boxShadow: 'none',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        color: theme.colors.neutral.neutral10,
        backgroundColor: state.isSelected ? theme.colors.primary.primary80 : 'transparent',
        ':hover': {
            cursor: 'pointer',
            backgroundColor: theme.colors.primary.primary90,
        },
    }),
    placeholder: (provided, state) => ({
        ...provided,
        display: !state.isFocused ? 'none' : 'block',
        color: theme.colors.neutral.neutral60,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        position: 'absolute',
        right: '5px',
        top: '7px',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '0',
    }),
    dropdownIndicator: (provided, state) => {
        const changes = {
            transition: 'all .4s ease',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
            color: disabled ? theme.colors.neutral.neutral80 : theme.colors.neutral.neutral10,
            ':hover': {
                color: theme.colors.neutral.neutral10,
            },
        };
        return Object.assign(provided, changes);
    },
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
});

const Select: React.FC<SelectProps> = ({
    id,
    tabIndex,
    options,
    label,
    placeholder,
    leftIcon,
    defaultValue,
    help,
    errors,
    success,
    disabled,
    onChange,
    onBlur,
    autoComplete,
    isClearable,
    ...props
}) => {
    const theme = React.useContext(ThemeContext); // Get current theme
    const [focusSelect, setFocusSelect] = React.useState<boolean>(false);

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        if (onBlur) {
            void onBlur(event);
        }

        if (defaultValue === null) {
            setFocusSelect(false);
        }
    };

    const handleSelectChange = (newValue: SingleValue<OptionType> | MultiValue<OptionType>) => {
        if (onChange) {
            void onChange(newValue);
        }
    };

    return (
        <FormGroup id={`form-group-${id}`}>
            <ReactSelect
                inputId={id}
                name={id}
                tabIndex={tabIndex}
                defaultValue={defaultValue}
                options={options}
                isClearable={isClearable}
                onFocus={() => setFocusSelect(true)}
                styles={customStyles(theme as DefaultTheme, errors, defaultValue, disabled, leftIcon)}
                onChange={handleSelectChange}
                onBlur={handleBlur}
                isDisabled={disabled}
                placeholder={placeholder}
                {...props}
            />

            <FormLabel htmlFor={id} haveErrors={!!errors} focusSelect={focusSelect} defaultValue={defaultValue} disabled={disabled} leftIcon={leftIcon}>
                {label}
            </FormLabel>

            {leftIcon && (
                <FormLeftIcon haveErrors={!!errors} disabled={disabled} focusSelect={focusSelect}>
                    {leftIcon}
                </FormLeftIcon>
            )}

            {!errors ? help && <HelpBlock>{help}</HelpBlock> : !focusSelect && <ErrorBlock>{errors as string}</ErrorBlock>}
        </FormGroup>
    );
};

export default Select;
