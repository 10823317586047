import React from 'react';
import * as FeatherIcon from 'react-feather';
import { useTheme } from 'styled-components';
import { Typography } from '@/components/atoms';
import { FaqsProps } from './Faqs.types';
import { Card, HeaderFaqs, PlusIcon } from './Faqs.styles';
import { removePTagAndBR } from '@/utils/html';

const Faqs: React.FC<FaqsProps> = ({ items, bgColor = 'default' }) => {
    const theme = useTheme(); // Get current theme
    const [selected, setSelected] = React.useState<string>(items?.length > 0 ? items[0]?.id : '');

    const handleClick = (id: string) => {
        if (id === selected) {
            setSelected('');
        } else {
            setSelected(id);
        }
    };

    return items?.map((item) => {
        const sanitizedQuestion = removePTagAndBR(item.question);
        const sanitizedResponse = removePTagAndBR(item.response);

        return (
            <Card key={`faq-${item.id}`} onClick={() => handleClick(item.id)} opened={selected === item.id} bgColor={bgColor}>
                <HeaderFaqs>
                    <Typography
                        as="h3"
                        variant="sub2"
                        dangerouslySetInnerHTML={{
                            __html: sanitizedQuestion,
                        }}
                    />
                    <PlusIcon opened={selected === item.id}>
                        <FeatherIcon.Plus size={24} color={theme.colors.neutral.neutral10} strokeWidth={2} />
                    </PlusIcon>
                </HeaderFaqs>
                <Typography
                    variant="body2"
                    align="justify"
                    dangerouslySetInnerHTML={{
                        __html: sanitizedResponse,
                    }}
                />
            </Card>
        );
    });
};

export default Faqs;
