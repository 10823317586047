import React from 'react';
import { RenderContentProps, State } from './Wizard.types';
import BreadcrumbsSection from '@/sections/breadcrumbs';
import { BreadcrumbType } from '@/components/molecules/Breadcrumbs';
import { useTranslation } from 'next-i18next';
import { useWizard } from '@/modules/wizard/index';

const renderWizardContent = (state: State) => {
    const currentStepContent = state.steps.find((step) => step.id === state.currentStep)?.content;

    if (typeof currentStepContent === 'function' || React.isValidElement(currentStepContent)) {
        return React.cloneElement(currentStepContent, { ...state.data });
    }

    return null;
};

const RenderWizardContent: React.FC<RenderContentProps> = ({ state }) => {
    const { t } = useTranslation(); // Translations
    const { dispatch } = useWizard();
    const content = renderWizardContent(state);

    const breadcrumbs: BreadcrumbType[] = [
        {
            id: 0,
            value: '1. ' + t('flights:departure-flight.section.title'),
            onClick: () => {
                dispatch({
                    type: 'GO_TO_STEP',
                    payload: 0,
                });
            },
        },
        {
            id: 1,
            value: '2. ' + t('flights:return-flight.section.title'),
            onClick: () => {
                dispatch({
                    type: 'GO_TO_STEP',
                    payload: 1,
                });
            },
        },
        // {
        //     id: 2,
        //     value: '3. ' + t('flights:passenger-data.section.title'),
        //     onClick: () => {
        //         dispatch({
        //             type: 'GO_TO_STEP',
        //             payload: 2,
        //         });
        //     },
        // },
        // {
        //     id: 3,
        //     value: '4. ' + t('flights:summary.section.title'),
        //     onClick: () => {
        //         dispatch({
        //             type: 'GO_TO_STEP',
        //             payload: 3,
        //         });
        //     },
        // },
        {
            id: 2,
            value: '3. ' + t('flights:payment.section.title'),
            onClick: () => {
                dispatch({
                    type: 'GO_TO_STEP',
                    payload: 2,
                });
            },
        },
    ];

    return (
        <>
            <BreadcrumbsSection breadcrumbs={breadcrumbs} bgcolor="default" current={state.currentStep} />

            {content}
        </>
    );
};

export default RenderWizardContent;
