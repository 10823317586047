import styled from 'styled-components';

export const SubFrame3 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
`;

export const IconContainer = styled.div`
    height: 20px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral.neutral10};
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
`;

export const SubFrame2 = styled(TextContainer)`
    gap: 20px;
    width: 100%;
`;

export const SubFrame4 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 32px;
    gap: 5px;
`;

export const Divider = styled.hr`
    width: 100%;
    height: 0;
    border: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const Stop = styled(TextContainer)`
    padding-left: 32px;
`;
