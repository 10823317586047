import React from 'react';
import { primary } from 'styles/colors';

// Icons
import * as FeatherIcon from 'react-feather';

export const contact = {
    image: { url: '/images/banners/contact.jpg', alt: 'Contact' },
    title: { en: 'The world awaits you', es: 'El mundo te espera' },
    description: { en: 'Book flights and begin your next big adventure.', es: 'Reserva vuelos y comienza tu próxima gran aventura.' },
    buttonIcon: <FeatherIcon.Headphones size={24} color={primary.primary10} strokeWidth={2} />,
    buttonText: { en: 'Contact an agent', es: 'Contactar un agente' },
};

export const promotion = {
    image: { url: '/images/banners/promotion.jpg', alt: 'Promotion' },
    title: { en: 'Explore the world at your own pace.', es: 'Explora el mundo a tu propio ritmo.' },
    description: { en: 'Enjoy the freedom of traveling with our car rental service.', es: 'Disfruta la libertad de viajar con nuestro servicio de alquiler de autos.' },
    buttonText: { en: 'Discover more', es: 'Descubrir más' },
};
