import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

interface AlertType {
    title: string;
    text: string;
    type?: 'success' | 'error';
    confirmButtonText: string;
    customClass?: {
        confirmButton: string;
    };
    onConfirm?: () => void;
}

const showAlert = async ({ title, text, type, confirmButtonText, customClass, onConfirm }: AlertType) => {
    await MySwal.fire({
        title: title,
        text: text,
        icon: type,
        confirmButtonText: confirmButtonText,
        allowOutsideClick: false,
        customClass: customClass,
        buttonsStyling: false,
    }).then((result) => {
        if (result.isConfirmed && onConfirm) {
            onConfirm();
        }
    });
};

export const showSuccessAlert = async ({ title, text, confirmButtonText, onConfirm }: AlertType) => {
    const customClass = {
        confirmButton: 'btn btn-primary btn-confirm btn-success',
    };

    await showAlert({ title, text, type: 'success', confirmButtonText, customClass, onConfirm });
};

export const showErrorAlert = async ({ title, text, confirmButtonText, onConfirm }: AlertType) => {
    const customClass = {
        confirmButton: `btn btn-primary btn-confirm btn-error`,
    };

    await showAlert({ title, text, type: 'error', confirmButtonText, customClass, onConfirm });
};
