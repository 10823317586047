import React from 'react';
import Ripples from 'react-ripples';
import * as FeatherIcon from 'react-feather';
import { ButtonProps } from './Button.types';
import { RIPPLE_COLORS } from './Button.constants';
import { Component, Spinner } from './Button.styles';

const Button: React.FC<Omit<React.HTMLAttributes<HTMLButtonElement>, 'children' | 'content' | 'onClick'> & ButtonProps> = ({
    id,
    type,
    variant,
    content,
    contentOnSubmitting,
    isSubmitting,
    tabIndex,
    onClick,
    disabled,
    width,
    rounded,
    selected,
    className,
    icon,
    iconPosition,
    showRipples,
    target,
    ...props
}) => {
    return (
        <Component
            id={id}
            type={type}
            variant={variant}
            tabIndex={tabIndex}
            disabled={disabled}
            width={width}
            rounded={rounded}
            selected={selected}
            icon={icon}
            iconPosition={iconPosition}
            isSubmitting={isSubmitting}
            className={className}
            target={target}
            {...props}
        >
            <Ripples color={showRipples ? RIPPLE_COLORS[variant] : RIPPLE_COLORS.invisible} className="btn-ripple" onClick={onClick}>
                {isSubmitting ? (
                    <>
                        {contentOnSubmitting}
                        <Spinner variant={variant} />
                    </>
                ) : (
                    <>
                        {icon === 'chevron' && iconPosition === 'left' && <FeatherIcon.ChevronLeft size={20} strokeWidth={2} />}

                        {icon === 'chevron-down' && iconPosition === 'left' && <FeatherIcon.ChevronDown size={20} strokeWidth={2} />}

                        {icon === 'image' && iconPosition === 'left' && <FeatherIcon.Image size={20} strokeWidth={2} />}

                        {icon !== 'none' && icon !== 'chevron' && icon !== 'chevron-down' && icon !== 'image' && iconPosition === 'left' && <>{icon}</>}

                        {content}

                        {icon === 'chevron' && iconPosition === 'right' && <FeatherIcon.ChevronRight size={20} strokeWidth={2} />}

                        {icon === 'chevron-down' && iconPosition === 'right' && <FeatherIcon.ChevronDown size={20} strokeWidth={2} />}

                        {icon !== 'none' && icon !== 'chevron' && icon !== 'chevron-down' && iconPosition === 'right' && <>{icon}</>}
                    </>
                )}
            </Ripples>
        </Component>
    );
};

Button.defaultProps = {
    type: 'button',
    variant: 'filled',
    icon: 'none',
    iconPosition: 'left',
    rounded: false,
    selected: false,
    showRipples: true,
};

export default Button;
