import React from 'react';
import { useTranslation } from 'next-i18next';
import { JobOfferRequirement, JobOfferSummary } from '@/components/molecules';
import { JobOfferDetailsProps } from './JobOfferDetails.types';
import { First, Second, Wrapper } from './JobOfferDetails.styles';

const JobOfferDetails: React.FC<JobOfferDetailsProps> = ({ jobOffer }) => {
    const { t } = useTranslation(); // Translations

    const requirements = [
        {
            title: t('job-offers:job-details.label.what_we_offer'),
            content: jobOffer?.benefits,
        },
        {
            title: t('job-offers:job-details.label.what_do_we_demand'),
            content: jobOffer?.functions,
        },
        {
            title: t('job-offers:job-details.label.qualifications'),
            content: jobOffer?.skills,
        },
    ];

    return (
        <Wrapper>
            <First>
                {requirements.map((req, index) => (
                    <JobOfferRequirement key={`req-${index}`} title={req.title} content={req.content as string} />
                ))}
            </First>
            <Second>
                <JobOfferSummary
                    slug={jobOffer?.slug}
                    workingTime={jobOffer?.workingTime as string}
                    workingMode={jobOffer?.workingMode as string}
                    location={jobOffer?.location as string}
                />
            </Second>
        </Wrapper>
    );
};

export default JobOfferDetails;
