import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 20px;

    ${({ theme }) => theme.media.xl`
       flex-direction: row;
       align-items: center;
       justify-content: space-between;
    `}

    & > div {
        display: block;
        border-radius: 25px;

        ${({ theme }) => theme.media.xl`
           flex-basis: 50%;
        `}
    }
`;

export const ContactInfo = styled.div`
    display: flex;
    flex-flow: column;

    ${({ theme }) => theme.media.xl`
       flex-basis: 45%;
    `}
`;

export const CardContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    p {
        width: 80%;

        span {
            display: block;
        }
    }
`;

export const IconContainer = styled.span`
    background: ${({ color }) => color};
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 54px;
    height: 54px;

    ${({ theme }) => theme.media.lg`
       width: 64px;
       height: 64px;
    `}
`;

export const LinkInfo = styled.a`
    color: ${({ theme }) => theme.colors.neutral.neutral10};

    span {
        display: block;
    }
`;
