import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, COUNTRY_FRAGMENT } from '@/graphql/fragments';

export const COUNTRIES_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${COUNTRY_FRAGMENT}
    query Countries($skip: Int, $take: Int, $report: Boolean) {
        countries(report: $report, skip: $skip, take: $take) {
            status
            errors {
                ...ErrorType
            }
            objects {
                ...CountryType
            }
        }
    }
`;
