import styled from 'styled-components';

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    ${({ theme }) => theme.media.xl`
        grid-template-columns: 2.1fr 1fr;
        gap: 20px
    `}
`;

export const InnerWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral98};
    border-radius: 25px;
    margin-top: 20px;
    padding: 28px;
`;
