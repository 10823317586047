import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;

    ${({ theme }) => theme.media.lg`
       flex-direction: row;

       ${({ reverse }) =>
           reverse &&
           css`
               flex-direction: row-reverse !important;
           `}
    `}
`;

export const InnerWrapper = styled.div`
    width: 95%;

    .description {
        text-align: center;
    }

    ${({ theme }) => theme.media.lg`
           width: 50%;

           .description {
                text-align: left;

                ${({ reverse }) =>
                    reverse &&
                    css`
                        text-align: right;
                    `}
            }
    `}

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding: 20px 20px 0;
        `}
`;
