import { useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { KiuAvailabilityType, KiuGetAvailabilityQuery, KiuGetPricesQuery } from '@/graphql/types';
import { KIU_GET_AVAILABILITY_QUERY, KIU_GET_PRICES_QUERY } from '@/graphql/queries';

export const useFlights = ({
    origin,
    destination,
    departureDate,
    returnDate,
    tripType,
    passengers,
    maxStopsQty,
    cabin,
}: {
    origin: string;
    destination: string;
    departureDate: string;
    returnDate?: string | null;
    tripType: string;
    passengers: {
        adults?: number;
        children?: number;
    };
    maxStopsQty?: number;
    cabin?: string;
}) => {
    const { i18n } = useTranslation(); // Translations

    const { data, refetch, loading, error } = useQuery<KiuGetAvailabilityQuery>(KIU_GET_AVAILABILITY_QUERY, {
        variables: {
            originLocationCode: origin,
            destinationLocationCode: destination,
            airlineCode: '',
            cabin: cabin || 'ECONOMY',
            maxStopsQty: maxStopsQty || 4,
            departureDate: departureDate,
            returnDate: returnDate,
            directFlightsOnly: false,
            maxResponses: 10,
            combinedItineraries: false,
            isRoundTrip: tripType === 'round-trip',
            passengersQty: [
                { passengerClass: 'ADULT', passengerQty: passengers.adults },
                { passengerClass: 'CHILD', passengerQty: passengers.children },
            ],
            returnLocationCode: origin,
            isFlexibleDates: true,
        },
        context: { headers: { 'X-Language': i18n.language } },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    return { flights: data?.kiuGetAvailability as KiuAvailabilityType, refetch, loading, error };
};

export const useFlightsPrice = ({
    outboundDepartureDatetimes,
    outboundArrivalDatetimes,
    outboundFlightNumbers,
    outboundResBookDesigCodes,
    outboundAirlineCodes,
    outboundOriginLocationCodes,
    outboundDestinationLocationCodes,
    returnDepartureDatetimes,
    returnArrivalDatetimes,
    returnFlightNumbers,
    returnResBookDesigCodes,
    returnAirlineCodes,
    returnOriginLocationCodes,
    returnDestinationLocationCodes,
    passengers,
}: {
    outboundDepartureDatetimes: string[];
    outboundArrivalDatetimes: string[];
    outboundFlightNumbers: string[];
    outboundResBookDesigCodes: string[];
    outboundAirlineCodes: string[];
    outboundOriginLocationCodes: string[];
    outboundDestinationLocationCodes: string[];
    returnDepartureDatetimes: string[];
    returnArrivalDatetimes: string[];
    returnFlightNumbers: string[];
    returnResBookDesigCodes: string[];
    returnAirlineCodes: string[];
    returnOriginLocationCodes: string[];
    returnDestinationLocationCodes: string[];
    passengers: {
        adults?: number;
        children?: number;
    };
}) => {
    const { i18n } = useTranslation(); // Translations

    const { data, refetch, loading, error } = useQuery<KiuGetPricesQuery>(KIU_GET_PRICES_QUERY, {
        variables: {
            outboundDepartureDatetimes,
            outboundArrivalDatetimes,
            outboundFlightNumbers,
            outboundResBookDesigCodes,
            outboundAirlineCodes,
            outboundOriginLocationCodes,
            outboundDestinationLocationCodes,
            returnDepartureDatetimes,
            returnArrivalDatetimes,
            returnFlightNumbers,
            returnResBookDesigCodes,
            returnAirlineCodes,
            returnOriginLocationCodes,
            returnDestinationLocationCodes,
            passengersQty: [
                { passengerClass: 'ADULT', passengerQty: passengers.adults },
                { passengerClass: 'CHILD', passengerQty: passengers.children },
            ],
        },
        context: { headers: { 'X-Language': i18n.language } },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    return { prices: data?.kiuGetPrices, refetch, loading, error };
};
