import styled, { css } from 'styled-components';
import { BannerProps } from '@/components/organisms/Banner/Banner.types';

export const BannerContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop),
})<Omit<BannerProps, 'data'>>`
    background: ${({ theme }) => theme.colors.tertiary.tertiary90};
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    padding: 0;
    height: 640px;
    z-index: 2;

    ${({ theme }) => theme.media.md`
        height: 512px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
    `}

    ${({ theme }) => theme.media.xl`
        height: 620px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
    `}

    ${({ type }) =>
        type === 'promotion' &&
        css`
            display: flex;
        `}
`;

export const ImageSection = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop),
})<Omit<BannerProps, 'data'>>`
    position: relative;
    width: 100%;

    ${({ type }) =>
        type === 'contact' &&
        css`
            transform: matrix(-1, 0, 0, 1, 0, 0);
        `}
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop),
})<Omit<BannerProps, 'data'>>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 590px;
    padding: 30px 16px;

    a {
        max-width: 235px;
    }

    ${({ type }) =>
        type === 'promotion' &&
        css`
            justify-content: flex-end;
            position: absolute;
            max-width: 100%;
            width: 100%;
            top: 50%;
            bottom: 0;
            background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%);

            & a {
                max-width: 170px;
            }
        `}

    ${({ theme }) => theme.media.md`
        padding: 30px 24px;
    `}

    ${({ theme }) => theme.media.xl`
        padding: 42px;
    `}
`;
