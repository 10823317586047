import styled, { createGlobalStyle, css } from 'styled-components';

export const SwiperGlobalStyles = createGlobalStyle`

`;

export const Slideshow = styled.div`
    position: relative;
    width: 100%;
`;

export const CardDateFlights = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive', 'disabled'].includes(prop),
})<{
    isActive: boolean;
    disabled: boolean;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 76px;
    padding: 20px 20px;
    border-radius: 12px;
    cursor: pointer;

    ${({ isActive }) =>
        isActive
            ? css`
                  background-color: ${({ theme }) => theme.colors.primary.primary50};
              `
            : css`
                  background-color: ${({ theme }) => theme.colors.tertiary.tertiary80};
              `}

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${({ theme }) => theme.colors.tertiary.tertiary90};
            cursor: auto;
        `}
`;
