import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import * as IconFeather from 'react-feather';
import { useTheme } from 'styled-components';

// Iconos
import Car from 'assets/images/car.svg';
import Clock from 'assets/images/clock.svg';
import Compass from 'assets/images/compass.svg';
import Origins from 'assets/images/flight-departure2.svg';
import Lodgin from 'assets/images/lodgin.svg';
import Luggage from 'assets/images/luggage.svg';
import Plane from 'assets/images/plane-pack.svg';

//Atoms
import { Typography } from '@/components/atoms';

// Molecules
import { DestinationIconList } from '@/components/molecules';

// Styles
import { Container } from './ExperienceAmenities.styles';

const ExperienceAmenities = ({ experience }) => {
    // Translations
    const { t } = useTranslation();
    const theme = useTheme(); // Get current theme

    // load origins
    const loadOrigins = () => experience?.origins?.map((item) => item?.origin?.name).join(', ');

    // has stays
    const hasStays = () => experience?.origins?.some((item) => item?.durationDays !== null);

    // load stays
    const loadStays = () => {
        const copyOrigins = experience?.origins ?? [];
        if (copyOrigins && copyOrigins.length === 1) {
            return `${t('packages:amenities-stay-one')} ${copyOrigins[0]?.durationDays} days and ${copyOrigins[0]?.durationNights} nights`;
        }
        return copyOrigins?.map((item) => t('packages:amenities-stay-more', { day: item?.durationDays, night: item?.durationNights, origin: item?.origin.name })).join('; ');
    };

    // has frequency
    const hasFrequency = () => {
        const copyOrigins = experience?.origins ?? [];

        const arrayFrecuency = copyOrigins
            ?.map((item) => item?.frecuency)
            .filter((item) => {
                if (item && item.recurrenceRules) {
                    return item && item.recurrenceRules.length > 0;
                }
            });
        return arrayFrecuency.length > 0;
    };

    // load frequency
    const loadFrequency = () => {
        const origins = experience?.origins ?? [];

        const frequencies = origins
            .map((origin) => {
                const rules = origin?.frecuency?.recurrenceRules ?? [];

                if (rules.length > 0) {
                    const text = rules.map((rule) => rule?.replace(',', ', ')).join(', ');
                    return t('packages:amenities-frequency-more.origins', { frequency: text, origin: origin?.origin?.name });
                }

                return null;
            })
            .filter(Boolean);

        return frequencies.join('; ');
    };

    // load baggage
    const loadBaggage = (type) => {
        const copyBaggage = experience?.baggage ?? [];
        const typeBaggage = copyBaggage?.filter((item) => item?.type === type);

        return typeBaggage.length > 0 ? typeBaggage?.map((item) => `${item?.baggage?.qty} x ${item?.baggage?.weightKg} kg`).join('; ') : null;
    };

    // load lodgings
    const loadLodgings = () => experience?.lodgings?.filter((item) => item?.promotional === true).map((element) => element?.lodging.name);

    // has services
    const hasServices = () => experience?.services?.filter((item) => item?.promotional);

    // load services
    const getServices = ({ service, price, frecuency }) => {
        const { name, iconVariants } = service || {};
        const element = iconVariants?.find((elem) => elem?.theme === 'react-feather');
        let iconShow = <Compass />;
        if (element && element?.name !== '' && Object.prototype.hasOwnProperty.call(IconFeather, element?.name)) {
            iconShow = React.createElement(IconFeather[element.name], {
                size: 20,
                color: theme.colors.neutral.neutral10,
                strokeWidth: 2,
            });
        }
        const rules = frecuency?.recurrenceRules ?? [];
        const textRules = rules?.length > 0 ? rules.map((rule) => rule?.replace(',', ', ')).join('; ') : '';
        const text = `${name}${textRules ? `; ${textRules}` : ''}${price > 0 ? `: ${Math.round(price)} USD` : ''}`;
        return { iconShow, text };
    };

    const items = [];

    if (experience?.origins && experience?.origins?.length > 0) items.push({ icon: <Origins />, text: `${t('packages:amenities-origins')}: ${loadOrigins()}` });

    if (hasStays()) items.push({ icon: <Clock />, text: `${t('packages:amenities-stay')}: ${loadStays()}` });

    if (hasFrequency()) items.push({ icon: <IconFeather.RefreshCw />, text: `${t('packages:amenities-frequency')}: ${loadFrequency()}` });

    const lodgings = loadLodgings();
    if (lodgings && lodgings?.length > 0 && experience?.lodgings?.length)
        items.push({
            icon: <Lodgin />,
            text: `${t('packages:amenities-lodgings')}: ${lodgings.join(', ')} ${experience.lodgings.length > lodgings.length ? t('packages:amenities-lodgings2') : ''}`,
        });

    items.push({ icon: <Car />, text: `${t('packages:amenities-transfer')}` });

    const filteredServices = hasServices();
    if (filteredServices && filteredServices.length > 0) {
        filteredServices?.forEach((services) => {
            if (services) {
                const { iconShow, text } = getServices(services);
                items.push({ icon: iconShow, text });
            }
        });
    }

    items.push({ icon: <Plane />, text: `${t('packages:amenities-airplane-ticket')}` });

    if (loadBaggage('hand') !== null) items.push({ icon: <IconFeather.Briefcase />, text: `${t('packages:amenities-hand-baggage')}: ${loadBaggage('hand')}` });
    if (loadBaggage('invoiced') !== null) items.push({ icon: <Luggage />, text: `${t('packages:amenities-invoiced-baggage')}: ${loadBaggage('invoiced')}` });

    if (experience?.visa === true) items.push({ icon: <IconFeather.Book />, text: `${t('packages:amenities-turism-visa')}` });

    return items ? (
        <Container>
            <Typography variant="h2">{t(`packages:details.experiences.amenities`)}</Typography>
            <DestinationIconList items={items} />
        </Container>
    ) : null;
};

ExperienceAmenities.propTypes = {
    experience: PropTypes.object,
};

export default ExperienceAmenities;
