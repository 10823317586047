import styled from 'styled-components';

export const Wrapper = styled.main`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};
    position: absolute;
    z-index: 1;

    ${({ theme }) => theme.media.lg`
        height: 100vh;
    `}
`;
