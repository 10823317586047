import createPersistedState from 'use-persisted-state';

// persistent state to store passenger information
const usePassengersState = createPersistedState('flights-passengers');

export const usePassengers = () => {
    const [passengers, setPassengers] = usePassengersState([]);

    return [passengers, setPassengers];
};

// persistent state to store flight search information
const useSearchDataState = createPersistedState('search-data');

export const useSearchData = () => {
    const [searchData, setSearchData] = useSearchDataState({});

    return [searchData, setSearchData];
};

// persistent state to store the information of the list of Airports
const useListAirportState = createPersistedState('flights-listAirport');

export const useListAirport = () => {
    const [listAirport, setListAirport] = useListAirportState([]);

    return [listAirport, setListAirport];
};

// persistent state to store departure flight information
const useItinerariesFlightsState = createPersistedState('flights-itineraries');

export const useItinerariesFlights = () => {
    const [itinerariesFlights, setItinerariesFlights] = useItinerariesFlightsState([]);

    return [itinerariesFlights, setItinerariesFlights];
};

// persistent state to store departure flight information
const useOneWayFlightsState = createPersistedState('flights-oneWay');

export const useOneWayFlights = () => {
    const [oneWayFlights, setOneWayFlights] = useOneWayFlightsState([]);

    return [oneWayFlights, setOneWayFlights];
};

// persistent state to store arrival flight information
const useReturnFlightsState = createPersistedState('flights-return');

export const useReturnFlights = () => {
    const [returnFlights, setReturnFlights] = useReturnFlightsState([]);

    return [returnFlights, setReturnFlights];
};

// persistent state to store a summary of outbound flights booked
const useDepartureSummaryState = createPersistedState('departure-summary');

export const useDepartureSummary = () => {
    const [departureSummary, setDepartureSummary] = useDepartureSummaryState({});

    return [departureSummary, setDepartureSummary];
};

// persistent state to store a summary of outbound flights booked
const useArrivalSummaryState = createPersistedState('arrival-summary');

export const useArrivalSummary = () => {
    const [arrivalSummary, setArrivalSummary] = useArrivalSummaryState({});

    return [arrivalSummary, setArrivalSummary];
};

// persistent state for breadIndex
const useBreadIndexState = createPersistedState('bread-index');

export const useBreadIndex = () => {
    const [breadIndex, setBreadIndex] = useBreadIndexState(1);

    return [breadIndex, setBreadIndex];
};
