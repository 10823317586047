import React from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import { Typography } from '@/components/atoms';
import { UnexpectedErrorProps } from './UnexpectedError.types';

const UnexpectedError: React.FC<UnexpectedErrorProps> = ({ textError }) => {
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme

    return (
        <Typography variant="body2" color={theme.colors.error.error50} align="center" style={{ display: 'grid' }}>
            <span>{textError || t('common:unexpected-error')}</span>
            <span>
                {t('common:please-contact')}
                <Typography as="a" variant="body2" weight={600} color={theme.colors.error.error50} align="center" href="/support">
                    {` ${t('common:support')}`}
                </Typography>
            </span>
        </Typography>
    );
};

export default UnexpectedError;
