import React from 'react';
import styled, { css } from 'styled-components';

export const CheckboxRow = styled.div`
    display: flex;
    align-items: center;
`;

export const CheckboxContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['focus', 'haveErrors', 'disabled'].includes(prop),
})<{ focus: boolean; disabled?: boolean; haveErrors: boolean }>`
    position: relative;
    display: flex;

    ${({ disabled, haveErrors, focus }) =>
        !disabled &&
        css`
            &:hover {
                box-shadow: 0 0 0 13px ${({ theme }) => theme.colors.neutral.neutral92};
                border-radius: 50%;

                ${haveErrors &&
                css`
                    box-shadow: 0 0 0 13px ${({ theme }) => theme.colors.error.error95};
                `};
            }

            ${focus &&
            css`
                box-shadow: 0 0 0 13px ${({ theme }) => theme.colors.primary.primary80} !important;
                border-radius: 50%;

                ${haveErrors &&
                css`
                    box-shadow: 0 0 0 13px ${({ theme }) => theme.colors.error.error90} !important;
                `};
            `}
        `}
`;

export const CheckboxInput = styled.input<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    top: 0;

    &:focus ~ span:first-of-type {
        outline: none;
    }
`;

export const CheckboxIconContainer = styled.span.withConfig({
    shouldForwardProp: (prop) => !['width', 'height', 'haveErrors', 'disabled'].includes(prop),
})<{
    width?: string | number;
    height?: string | number;
    checked: boolean;
    haveErrors: boolean;
    disabled?: boolean;
}>`
    transform: rotate(-90deg);
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.neutral.neutral10};
    border-radius: 4px;
    border-width: 2px;
    position: relative;
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width || '20px')};
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height || '20px')};
    transition: all 0.2s ease;
    overflow: hidden;

    ${({ checked }) =>
        checked &&
        css`
            transform: rotate(0deg);
            border-color: ${({ theme }) => theme.colors.primary.primary50};
        `};

    ${({ haveErrors }) =>
        haveErrors &&
        css`
            border-color: ${({ theme }) => theme.colors.error.error50};
        `};

    ${({ checked, disabled }) =>
        disabled &&
        css`
            border-color: ${({ theme }) => theme.colors.neutral.neutral80};

            ${checked &&
            css`
                border-color: ${({ theme }) => theme.colors.neutral.neutral90};
            `};
        `};
`;

export const CheckboxIcon = styled.span.withConfig({
    shouldForwardProp: (prop) => !['haveErrors', 'disabled'].includes(prop),
})<{ checked: boolean; haveErrors: boolean; disabled?: boolean }>`
    left: 0;
    transform: translate(100%);
    transform-origin: right;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    transition: all 0.2s ease;
    z-index: 10;
    color: ${({ theme }) => theme.colors.neutral.neutral10};

    svg {
        overflow: hidden;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${({ checked, haveErrors, disabled }) =>
        checked &&
        css`
            background-color: ${({ theme }) => theme.colors.primary.primary50};
            transform: translate(0);
            display: flex;
            justify-content: center;
            align-items: center;

            ${haveErrors &&
            css`
                color: ${({ theme }) => theme.colors.basic.white};
                background-color: ${({ theme }) => theme.colors.error.error50};
            `};

            ${disabled &&
            css`
                background-color: ${({ theme }) => theme.colors.neutral.neutral90};
            `};

            svg {
                transform: translate(0);
                opacity: 1;
                line-height: 1.2;
            }
        `};
`;


export const CheckboxLabel = styled.label.withConfig({
  shouldForwardProp: (prop) => !['haveErrors', 'disabled'].includes(prop),
})<{ haveErrors: boolean; disabled?: boolean }>`
    font-family: ${({ theme }) => theme.font.body2.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.body2.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    position: relative;
    margin-left: 16px;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.tablet.style};
    `}
    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
        font-style: ${theme.font.body2.desktop.style};
    `}
  a {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.neutral.neutral10};
    }

    ${({ haveErrors }) =>
        haveErrors &&
        css`
            color: ${({ theme }) => theme.colors.error.error50};

            a {
                color: ${({ theme }) => theme.colors.error.error50};
            }
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            color: ${({ theme }) => theme.colors.neutral.neutral80};

            a {
                color: ${({ theme }) => theme.colors.neutral.neutral80};
            }
        `};
`;

export const HelpBlock = styled.span`
    font-family: ${({ theme }) => theme.font.cap.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    font-style: ${({ theme }) => theme.font.cap.mobile.style};
    color: ${({ theme }) => theme.colors.neutral.neutral20};
    background-color: transparent !important;
    margin-block-start: 0;
    margin-block-end: 0;
    position: relative;
    top: -2px;
    padding-left: 12px;
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.tablet.size};
        font-weight: ${theme.font.cap.tablet.weight};
        line-height: ${theme.font.cap.tablet.lineheight};
        font-style: ${theme.font.btn.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
        font-style: ${theme.font.btn.desktop.style};
    `}
`;

export const ErrorBlock = styled(HelpBlock)`
    color: ${({ theme }) => theme.colors.error.error50};
`;
