import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

// Hooks
import { useListAirport } from 'hooks/PersistedStateFlights';

// Icons
import * as FeatherIcon from 'react-feather';

// Atoms
import { Typography } from 'components/atoms';

// Molecules
import { neutral, success, primary } from 'styles/colors';

// Styles
import { FlightContainer, Divider, Frame1, IconContainer, Item } from './FlightsSeatsBags.styles';

const FlightsSeatsBags = ({ infoDetails }) => {
    const { t } = useTranslation(); // Translations
    const [listAirport] = useListAirport(); // persistent state list airport
    let originSegment;
    let destinySegment;

    const uploadInfoScale = (scale) => {
        originSegment = listAirport?.find((location) => location.AirportCode === scale.departure.airport);
        destinySegment = listAirport?.find((location) => location.AirportCode === scale.arrival.airport);
    };

    // TODO los datos se encuentran estáticos
    return (
        <>
            <Typography type="h6">{t('flights:seats-details')}</Typography>
            <FlightContainer>
                {infoDetails.segment?.map((scale, index) => (
                    <div key={`${scale.FlightNumber}-seatDeparture`}>
                        {uploadInfoScale(scale)}
                        <Frame1>
                            <Typography type="h6">{`${originSegment?.CityName} (${originSegment?.AirportCode}) - ${destinySegment?.CityName} (${destinySegment?.AirportCode})`}</Typography>
                            <Item>
                                <IconContainer color={success.success70}>
                                    <FeatherIcon.Check size={16} strokeWidth={2} color={neutral.neutral10} />
                                </IconContainer>
                                <Typography type="body1">Selección de asientos incluída</Typography>
                            </Item>
                            <Typography type="body2">John Doe: Asiento 12A ($0)</Typography>
                            <Typography type="body2">Lisa Doe: Asiento 12B ($0)</Typography>
                        </Frame1>
                        {index !== infoDetails.segment.length - 1 && <Divider />}
                    </div>
                ))}
            </FlightContainer>
            <Typography type="h6">{t('flights:bags-details')}</Typography>
            <FlightContainer>
                {infoDetails.segment?.map((scale, index) => (
                    <div key={`${scale.FlightNumber}-bagsDeparture`}>
                        {uploadInfoScale(scale)}
                        <Frame1>
                            <Typography type="h6">{`${originSegment?.CityName} (${originSegment?.AirportCode}) - ${destinySegment?.CityName} (${destinySegment?.AirportCode})`}</Typography>
                            <Item>
                                <IconContainer color={success.success70}>
                                    <FeatherIcon.Check size={16} strokeWidth={2} color={neutral.neutral10} />
                                </IconContainer>
                                <Typography type="body1">Objetos personales incluidos</Typography>
                            </Item>
                            <Item>
                                <IconContainer color={primary.primary70}>
                                    <FeatherIcon.DollarSign size={16} strokeWidth={2} color={neutral.neutral10} />
                                </IconContainer>
                                <Typography type="body1">Equipaje de mano con cargo</Typography>
                            </Item>
                            <Typography type="body2">John Doe: 1 bolso ($10)</Typography>
                            <Typography type="body2">Lisa Doe: 2 bolsos ($20)</Typography>
                        </Frame1>
                        {index !== infoDetails.segment.length - 1 && <Divider />}
                    </div>
                ))}
            </FlightContainer>
        </>
    );
};

FlightsSeatsBags.propTypes = {
    infoDetails: PropTypes.object,
};

export default FlightsSeatsBags;
