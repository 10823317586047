import styled, { css } from 'styled-components';
import { skeletonLoading, fadeIn } from 'styles/animations';

export const Card = styled.div`
    width: 100%;
    max-width: 420px;
    height: 362px;
    background-color: ${({ theme }) => theme.colors.basic.white};
    display: flex;
    flex-flow: column;
    border-radius: 25px;
    overflow: hidden;
    animation: ${fadeIn} 1s ease-in-out;
`;

export const TopContainer = styled.div`
    height: 210px;
    position: relative;
    display: flex;
    background-color: ${({ theme }) => theme.colors.neutral.neutral92};
    padding: 18px 24px;
    justify-content: space-between;
`;

export const BottomContainer = styled.div`
    padding: 20px 25px 30px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
`;

export const Skeleton = styled.div`
    animation: ${skeletonLoading} 1s linear infinite alternate;
    width: ${({ width }) => (width ? `${width}rem` : '100%')};
    height: ${({ height }) => (height ? `${height}rem` : '')};
    margin-bottom: 0.5rem;
    border-radius: ${({ radius }) => (radius ? `${radius}px` : '0')};
`;

export const SkeletonGroup = styled.div`
    display: flex;
    flex-flow: ${({ orientation }) => orientation || 'column'};
    gap: ${({ gap }) => (gap ? `${gap}rem` : '0 1rem')};
    margin: ${({ mt }) => (mt ? `${mt}rem` : '0')} ${({ mr }) => (mr ? `${mr}rem` : '0')} ${({ mb }) => (mb ? `${mb}rem` : '0')} ${({ ml }) => (ml ? `${ml}rem` : '0')};

    ${({ align, orientation }) =>
        align === 'left' &&
        css`
            ${orientation === 'row'
                ? css`
                      justify-content: flex-start;
                  `
                : css`
                      align-items: flex-start;
                  `}
        `}

    ${({ align, orientation }) =>
        align === 'right' &&
        css`
            ${orientation === 'row'
                ? css`
                      justify-content: flex-end;
                  `
                : css`
                      align-items: flex-end;
                  `}
        `}

    ${({ align, orientation }) =>
        align === 'center' &&
        css`
            ${orientation === 'row'
                ? css`
                      justify-content: center;
                  `
                : css`
                      align-items: center;
                  `}
        `}
`;
