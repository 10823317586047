import React from 'react';
import * as IconFeather from 'react-feather';
import { useTheme } from 'styled-components';
import { ToastProps } from './Toast.types';
import { Wrapper, IconClose } from './Toast.styles';

const Toast: React.FC<ToastProps> = ({ children, showToast, setShowToast, variant, top }) => {
    const theme = useTheme(); // Get current theme

    return (
        <Wrapper show={showToast} variant={variant} top={top}>
            {children}
            <IconClose onClick={() => setShowToast(false)}>
                <IconFeather.X size={20} color={variant === 'info' ? theme.colors.neutral.neutral10 : theme.colors.error.error50} strokeWidth={2} />
            </IconClose>
        </Wrapper>
    );
};

export default Toast;
