import styled from 'styled-components';
import { Container } from '@/styles/common';

export const SectionWrapper = styled.header.withConfig({
    shouldForwardProp: (prop) => !['fixed'].includes(prop),
})<{ fixed: boolean }>`
    position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
    top: 0;
    width: 100%;
    z-index: 9998;
    background-color: ${({ theme }) => theme.colors.basic.white};
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
`;

export const InnerContainer = styled(Container)`
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    gap: 0 0;
    align-items: center;
    max-width: 95%;

    ${({ theme }) => theme.media.lg`
        display: grid;
        grid-template-columns: auto auto 0 0 auto;
        grid-template-rows: 1fr;
        grid-template-areas: '. . . . .';
        grid-template-columns: 1.8fr auto auto auto auto;
        max-width: 960px;
    `}

    ${({ theme }) => theme.media.xl`
        max-width: 1180px;
    `}

    #main-navigation,
    #language-switcher {
        display: none;

        ${({ theme }) => theme.media.lg`
            display: inherit;
        `}
    }

    #burger-icon {
        margin-left: 0;

        ${({ theme }) => theme.media.lg`
            display: none;
            margin-left: 15px;
        `};
    }
`;
