import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0px 14px;

    ${({ theme }) => theme.media.md`
        padding: 0
   `};
`;

export const Container = styled.div`
    ul,
    li {
        display: flex;
        margin: 0;
        padding-left: 0;
    }

    ul {
        justify-content: start;
        flex-wrap: wrap;
    }
`;

export const Item = styled.li`
    position: relative;

    span {
        margin: 0 0.5rem;
    }
`;

export const ItemContainer = styled.span`
    cursor: pointer;
`;
