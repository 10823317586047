import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard } from 'swiper/modules';
import Image from 'next/image';

import 'swiper/css';
import 'swiper/css/navigation';

// Styles
import { Slideshow } from './ModalImage.styles';

const ModalImage = ({ images, currentPosition }) => {
    return (
        <Slideshow>
            <Swiper centeredSlides loop initialSlide={currentPosition} navigation keyboard modules={[Navigation, Keyboard]} className="ExperiencesDetailSwiper">
                {images?.map((item, index) => {
                    const transformedKey = item.name + index;

                    return (
                        <SwiperSlide key={transformedKey}>
                            {/* ToDo standardize url field  */}
                            <Image src={item?.url || item?.urlImg} alt="alt" layout="fill" priority="true" />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </Slideshow>
    );
};

ModalImage.propTypes = {
    images: PropTypes.array,
    currentPosition: PropTypes.number,
};

export default ModalImage;
