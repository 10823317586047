import styled from 'styled-components';

export const Table = styled.table`
    margin: 37px auto 50px;
    text-align: left;
    border-collapse: collapse;
    width: 95%;
    max-width: 750px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral.neutral10};
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TableHeadCell = styled.th`
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral.neutral10};
    padding-bottom: 12px;
    width: 30%;
`;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
    padding: 14px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral50};
`;
