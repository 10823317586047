import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    z-index: 3;

    ${({ theme }) => theme.media.lg`
        max-width: 970px;
        border-radius: 25px;
    `}

    ${({ theme }) => theme.media.xl`
        max-width: 90%;
    `}

    ${({ theme }) => theme.media.xxl`
        max-width: 1280px;
    `}
`;
