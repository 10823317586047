import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const CustomForm = styled(Form)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const InputWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop),
})<{ type: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: ${({ type }) => (type !== 'footer' ? '2rem' : '0')};
    width: 100%;

    ${({ theme, type }) =>
        type === 'section' &&
        css`
            ${theme.media.lg`
                flex-direction: row;
                justify-content: space-between;
                gap: 20px;
            `}
        `}
`;

export const ModalContent = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral92};
    margin: 0 auto;
    display: flex;
    width: 95%;
    border-radius: 25px;
    overflow: hidden;
    padding: 0;
`;

export const ImageSection = styled.div`
    position: relative;
    width: 0;

    ${({ theme }) => theme.media.md`
        width: 50%;
    `}
`;
