import { gql } from '@apollo/client';

export const COUNTRY_FRAGMENT = gql`
    fragment CountryType on CountryType {
        id
        code
        name
        nationality
        requirement
    }
`;
