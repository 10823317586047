import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useModal } from '@/modules/modal';

// Atoms
import { Typography } from 'components/atoms';

// Molecules
import { ImageGrid, ModalImage } from 'components/molecules';

// Styles
import { Wrapper, InnerWrapper } from './ExperienceDetails.styles';

const ExperienceDetails = ({ destinations, reverse }) => {
    // Hooks
    const { setModal } = useModal(); // Modal

    const { mainImage, images } = destinations;
    const imgs = [mainImage, ...images];

    const handleDetailsImage = (position) => {
        setModal(<ModalImage images={imgs} currentPosition={position} />);
    };

    return (
        <Wrapper reverse={reverse}>
            <InnerWrapper>
                <ImageGrid destinations={destinations} onClickImage={handleDetailsImage} />
            </InnerWrapper>
            <InnerWrapper withPadding reverse={reverse}>
                <Typography className="description" type="body1">
                    {destinations?.description}
                </Typography>
            </InnerWrapper>
        </Wrapper>
    );
};

ExperienceDetails.propTypes = {
    destinations: PropTypes.object,
    reverse: PropTypes.bool,
};

export default ExperienceDetails;
