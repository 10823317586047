import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0px 93px 0px 93px;
    background-color: #f5f5f5;
    ${({ theme }) => theme.media.md`
    display: grid;

        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    `}
    ${({ theme }) => theme.media.xl`
        grid-template-columns: 1fr 2fr;
        gap: 20px;
    `};
`;

export const TitleContainer = styled.div`
    width: 100%;
    padding: 50px 93px 50px 93px;
    background-color: #f5f5f5;
`;

export const ScrollView = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll; /* Add the ability to scroll */
    scrollbar-width: none;

    /* Webkit(Chrome, Android browser, Safari, Edge...) */
    &::-webkit-scrollbar {
        display: none;
    }

    gap: 22px 16px;
    margin-top: 16px;
    ${({ theme }) => theme.media.md`
    flex-direction: column;
    `}
`;
export const PointSummary = styled.div``;

export const Review = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 3px;
`;

export const Frame1 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Summary = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Frame2 = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
`;

export const Frame3 = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    gap: 2px;
`;

export const MoreDetails = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 6px;
`;

export const FilterMenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 26px;

    & > div,
    label {
        display: flex;
        align-items: center;
    }
`;

export const MenuRight = styled.div`
    svg {
        transform: rotate(0deg);
        transition: all 0.6s;
        ${({ isAsc }) =>
            isAsc &&
            css`
                transform: rotate(180deg);
            `};
    }
`;

export const LeftLabel = styled.label``;
