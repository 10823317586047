import styled from 'styled-components';

export const TabPresentationContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.media.md`
    width: 70%;
    `}
`;

export const RatingContainer = styled.div`
    div {
        display: flex;
        justify-content: left;
    }
`;

export const TextContainer = styled.div`
    width: 100%;
    padding-top: 26px;
    margin-bottom: 26px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    gap: 20px;

    ${({ theme }) => theme.media.xs`
    padding: 0px 0px 0px 14px;
    width: 180%;
    margin-left: 0;
    margin-right: inherit;
    
    `}

    ${({ theme }) => theme.media.md`
    margin-left: 66px;
    `}
`;
