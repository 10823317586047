import React from 'react';

// Styles
import { Card, TopContainer, BottomContainer, Skeleton, SkeletonGroup } from './ExperienceCardSkeleton.styles';

const ExperienceCardSkeleton = () => {
    return (
        <Card>
            <TopContainer>
                <Skeleton width={4.5} height={2} />
                <SkeletonGroup align="right">
                    <Skeleton width={3} height={1} />
                    <Skeleton width={5} height={2} />
                </SkeletonGroup>
            </TopContainer>
            <BottomContainer>
                <SkeletonGroup orientation="row" align="center" gap={4} mb={2}>
                    <SkeletonGroup align="right">
                        <Skeleton width={7} height={1} />
                        <Skeleton width={4} height={1} />
                    </SkeletonGroup>
                    <SkeletonGroup align="left">
                        <Skeleton width={7} height={1} />
                        <Skeleton width={4} height={1} />
                    </SkeletonGroup>
                </SkeletonGroup>
                <Skeleton height={3} radius={25} />
            </BottomContainer>
        </Card>
    );
};

export default ExperienceCardSkeleton;
