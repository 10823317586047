import React from 'react';
import { DefaultTheme, ThemeContext } from "styled-components";
import { bubble as Menu } from 'react-burger-menu';
import useNavigations from "@/modules/navigations";
import { hex2Rgba } from '@/styles/colors';
import { Logo } from '@/components/atoms';
import { Navigation } from '@/components/molecules';
import { SidebarProps } from "./Sidebar.types";
import { MenuNav, LogoContainer } from './Sidebar.styles';

const customStyles = (theme: DefaultTheme | undefined) => ({
    bmCrossButton: {
        height: '24px',
        width: '24px',
        top: '10px',
        left: '10px',
    },
    bmCross: {
        background: theme?.colors.tertiary.tertiary50,
        width: '2px',
        height: '24px',
        borderRadius: '25px',
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        zIndex: '9999',
    },
    bmMenu: {
        background: theme?.colors.basic.white,
        padding: '0',
        width: '100%',
    },
    bmMorphShape: {
        fill: theme?.colors.basic.white,
    },
    bmItemList: {
        color: theme?.colors.basic.white,
        padding: '0 2.8em',
    },
    bmItem: {
        display: 'inline-block',
    },
    bmOverlay: {
        zIndex: '9998',
        background: theme?.colors.primary.primary50 ? hex2Rgba(theme?.colors.primary.primary50, 0.05) : '',
    },
});

const Sidebar: React.FC<SidebarProps> = ({ activeItem, isOpen, handleToggleSidebar }) => {
    const { navigations } = useNavigations();
    const themeContext = React.useContext(ThemeContext); // Get current theme

    const closeSidebarOnEsc = (e: { key: string; keyCode: number }) => {
        if (e.key === 'Escape' || e.keyCode === 27) {
            handleToggleSidebar();
        }
    };

    return (
        <Menu
            isOpen={isOpen}
            right
            width="100%"
            customBurgerIcon={false}
            styles={customStyles(themeContext)}
            pageWrapId="page-wrap"
            outerContainerId="outer-container"
            onClose={handleToggleSidebar}
            disableOverlayClick
            customOnKeyDown={closeSidebarOnEsc}
        >
            <MenuNav>
                <LogoContainer>{isOpen && <Logo variant="vertical" color="full-color" link="/" alt="Chagod Tour" />}</LogoContainer>

                <Navigation id="sidebar" type="sidebar" menuItems={navigations.sidebar} activeItem={activeItem} handleToggleSidebar={handleToggleSidebar} />
            </MenuNav>
        </Menu>
    );
};

export default Sidebar;
