import * as crypto from 'crypto';

export const generateUniqueCode = (values: string | string[]): string => {
    // Create a SHA-256 hash object
    const sha256Hash = crypto.createHash('sha256');

    // Convert single value to an array if it's not already
    const valueArray = Array.isArray(values) ? values : [values];

    // Concatenate the values into a string
    const concatenatedValues = valueArray.join(',');

    // Update the hash object with the concatenated values
    sha256Hash.update(concatenatedValues);

    // Get the hash in hexadecimal format
    return sha256Hash.digest('hex');
};
