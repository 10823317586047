import styled, { css } from 'styled-components';
import NextImage from 'next/image';

export const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    display: inline-flex;
    width: 180px;
    height: 180px;

    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
`;

export const Image = styled(NextImage)`
    object-fit: cover;
    width: 100%;
    border-radius: 50%;
`;

export const IconWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    border-radius: 50%;

    &:hover {
        border: 2px solid ${({ theme }) => theme.colors.primary.primary70} !important;
        background: ${({ theme }) => theme.colors.primary.primary70} !important;
    }
`;

export const InnerWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral92};
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    padding: 20px;
    width: 90vw;
    max-width: 450px;
    border-radius: 25px;
`;

export const ErrorBlock = styled.span`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    color: ${({ theme }) => theme.colors.error.error50};
    background-color: transparent !important;
    margin-block-start: 0;
    margin-block-end: 0;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
    `}
`;

export const Body = styled.div`
    overflow: hidden;
    border-radius: 8px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 35px;
    gap: 5px;

    button {
        flex-basis: 100%;
    }

    ${({ theme }) => theme.media.md`
        gap: 20px;

        button {
            flex-basis: 50%;
        }
    `}
`;
