import styled from 'styled-components';

export const PassengerSelector = styled.span`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const SelectorContainer = styled.div`
    margin: 0;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    padding: 20px;
`;

export const List = styled.ul`
    list-style: none;
    padding-inline-start: 0;
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    height: 50px;
`;

export const InfoContainer = styled.div`
    min-width: 96px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-right: 48px;
`;

export const RoomInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
`;

export const RoomController = styled.div`
    svg + svg {
        margin-left: 42px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 18px;
    gap: 5px;

    button {
        flex-basis: 100%;
    }

    ${({ theme }) => theme.media.md`
        gap: 20px;

        button {
            flex-basis: 50%;
        }
    `}
`;

export const Divider = styled.hr`
    margin-bottom: 30px;
`;
