import { gql } from '@apollo/client';
import { CITY_FRAGMENT, KIU_DATE_FRAGMENT, KIU_PRICE_FRAGMENT, KIU_TAX_FRAGMENT } from "@/graphql/fragments";

export const KIU_GET_AVAILABILITY_QUERY = gql`
    ${CITY_FRAGMENT}
    ${KIU_DATE_FRAGMENT}
    query KiuGetAvailability(
        $originLocationCode: String!
        $destinationLocationCode: String!
        $cabin: CabinTypesEnum
        $maxStopsQty: Int
        $airlineCode: String
        $departureDate: Date!
        $returnDate: Date
        $directFlightsOnly: Boolean!
        $maxResponses: Int
        $combinedItineraries: Boolean
        $isRoundTrip: Boolean
        $isFlexibleDates: Boolean
        $passengersQty: [PassengerQtyType]!
        $returnLocationCode: String
    ) {
        kiuGetAvailability(
            params: {
                originLocationCode: $originLocationCode
                destinationLocationCode: $destinationLocationCode
                cabin: $cabin
                maxStopsQty: $maxStopsQty
                airlineCode: $airlineCode
                departureDate: $departureDate
                returnDate: $returnDate
                directFlightsOnly: $directFlightsOnly
                maxResponses: $maxResponses
                combinedItineraries: $combinedItineraries
                isRoundTrip: $isRoundTrip
                isFlexibleDates: $isFlexibleDates
                passengersQty: $passengersQty
                returnLocationCode: $returnLocationCode
            }
        ) {
            status
            errors {
                field
                messages
            }
            response {
                routeType
                originCity {
                    ...CityType
                }
                destinationCity {
                    ...CityType
                }
                returnCity {
                    ...CityType
                }
                departingDate
                returnDate
                adults
                children
                passengersQty
                outboundRoutes {
                    ...KIUDateType
                }
                returnRoutes {
                    ...KIUDateType
                }
                invalidCities
            }
        }
    }
`;

export const KIU_GET_PRICES_QUERY = gql`
    ${KIU_PRICE_FRAGMENT}
    ${KIU_TAX_FRAGMENT}
    query KiuGetPrices(
        $outboundDepartureDatetimes: [DateTime]!
        $outboundArrivalDatetimes: [DateTime]!
        $outboundFlightNumbers: [String]!
        $outboundResBookDesigCodes: [String]!
        $outboundAirlineCodes: [String]!
        $outboundOriginLocationCodes: [String]!
        $outboundDestinationLocationCodes: [String]!
        $returnDepartureDatetimes: [DateTime]!
        $returnArrivalDatetimes: [DateTime]!
        $returnFlightNumbers: [String]!
        $returnResBookDesigCodes: [String]!
        $returnAirlineCodes: [String]!
        $returnOriginLocationCodes: [String]!
        $returnDestinationLocationCodes: [String]!
        $passengersQty: [PassengerQtyType]!
    ) {
        kiuGetPrices(
            params: {
                outboundDepartureDatetimes: $outboundDepartureDatetimes
                outboundArrivalDatetimes: $outboundArrivalDatetimes
                outboundFlightNumbers: $outboundFlightNumbers
                outboundResBookDesigCodes: $outboundResBookDesigCodes
                outboundAirlineCodes: $outboundAirlineCodes
                outboundOriginLocationCodes: $outboundOriginLocationCodes
                outboundDestinationLocationCodes: $outboundDestinationLocationCodes
                returnDepartureDatetimes: $returnDepartureDatetimes
                returnArrivalDatetimes: $returnArrivalDatetimes
                returnFlightNumbers: $returnFlightNumbers
                returnResBookDesigCodes: $returnResBookDesigCodes
                returnAirlineCodes: $returnAirlineCodes
                returnOriginLocationCodes: $returnOriginLocationCodes
                returnDestinationLocationCodes: $returnDestinationLocationCodes
                passengersQty: $passengersQty
            }
        ) {
            status
            errors {
                messages
                field
            }
            response {
                baseFare {
                    ...KIUPriceType
                }
                equivFare {
                    ...KIUPriceType
                }
                taxes {
                    ...KIUTaxType
                }
                totalFare {
                    ...KIUPriceType
                }
                totalTaxes {
                    ...KIUPriceType
                }
                fee {
                    ...KIUPriceType
                }
                totalGds {
                    ...KIUPriceType
                }
                total {
                    ...KIUPriceType
                }
            }
        }
    }
`;
