import React from 'react';
import { TextareaProps } from './Textarea.types';
import { ErrorBlock, FormControl, FormGroup, FormLabel, HelpBlock, InnerFormGroup, LetterCounter } from './Textarea.styles';

const Textarea: React.FC<TextareaProps> = ({ id, tabIndex, placeholder, label, value, maxlength, size, height, help, errors, success, disabled, onChange, onBlur, ...props }) => {
    const refInput = React.useRef<HTMLTextAreaElement>(null); // Reference
    const [focus, setFocus] = React.useState<boolean>(false);
    const [count, setCount] = React.useState<number>(0);

    React.useEffect(() => {
        if (maxlength) {
            const handleInput = (e: Event) => {
                if (e.target instanceof HTMLTextAreaElement) {
                    const inputValue = e.target.value;
                    setCount(inputValue.length);
                }
            };

            const inputElement = refInput.current;

            if (inputElement) {
                inputElement.addEventListener('input', handleInput);
            }

            return () => {
                if (inputElement) {
                    inputElement.removeEventListener('input', handleInput);
                }
            };
        }
    }, [maxlength]);

    return (
        <FormGroup id={`form-group-${id}`}>
            <InnerFormGroup haveErrors={!!errors} focus={focus}>
                <FormControl
                    id={id}
                    ref={refInput}
                    tabIndex={tabIndex}
                    placeholder={placeholder}
                    value={!disabled ? value : ''}
                    autoComplete="off"
                    onChange={onChange}
                    onFocus={() => setFocus(true)}
                    onBlur={(event) => {
                        setFocus(false);
                        if (onBlur) {
                            onBlur(event);
                        }
                    }}
                    maxLength={maxlength}
                    className={`${value || errors ? 'edited' : ''}`}
                    haveErrors={!!errors}
                    success={!!success}
                    disabled={disabled}
                    size={size}
                    height={height}
                    {...props}
                />
                <FormLabel htmlFor={id} haveErrors={!!errors} disabled={disabled}>
                    {label}
                </FormLabel>
            </InnerFormGroup>
            {!errors ? help && <HelpBlock>{help}</HelpBlock> : <ErrorBlock>{errors}</ErrorBlock>}
            {maxlength && <LetterCounter haveErrors={!!errors}>{`${count} / ${maxlength}`}</LetterCounter>}
        </FormGroup>
    );
};

export default Textarea;
