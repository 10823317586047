import styled, { createGlobalStyle } from 'styled-components';
import { fadeIn } from '@/styles/animations';

export const SwiperGlobalStyles = createGlobalStyle`
  .FeaturedDestinationsSwiper.swiper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 40px 0 0;
  }

  .FeaturedDestinationsSwiper.swiper.showOnly2 .swiper-slide:first-child {
    justify-content: flex-end;
  }

  .FeaturedDestinationsSwiper.swiper.showOnly2 .swiper-slide:last-child {
    justify-content: flex-start;
  }

  .FeaturedDestinationsSwiper .swiper-slide {
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    animation: ${fadeIn} 1s ease-in-out;
  }

  .FeaturedDestinationsSwiper .swiper-button-next,
  .FeaturedDestinationsSwiper .swiper-button-prev {
    color: ${({ theme }) => theme.colors.primary.primary10};
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    border-radius: 50%;
    width: 64px;
    height: 64px;
    top: calc(50% - 64px / 2);
    margin: 0;
  }

  .FeaturedDestinationsSwiper .swiper-button-disabled {
    display: none;
  }

  .FeaturedDestinationsSwiper .swiper-button-next {
    right: -30px;
  }

  .FeaturedDestinationsSwiper .swiper-button-prev {
    left: -30px;
  }

  .FeaturedDestinationsSwiper .swiper-button-next:after {
    margin-left: -22px;
  }

  .FeaturedDestinationsSwiper .swiper-button-prev:after {
    margin-left: 22px;
  }

  .FeaturedDestinationsSwiper .swiper-button-prev:after,
  .FeaturedDestinationsSwiper .swiper-button-next:after {
    font-size: 18px;
    font-weight: 900;
  }
`;

export const Frame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    height: 140px;
    width: 100%;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%);
`;

export const Card = styled.div`
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
`;
