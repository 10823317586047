import React from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { fadeIn } from '@/styles/animations';
import { SectionProps } from './Section.types';

export const SectionWrapper = styled.section.withConfig({
    shouldForwardProp: (prop) => !['mt', 'mr', 'mb', 'ml', 'pt', 'pr', 'pb', 'pl', 'fadeInTransition', 'bgcolor', 'type', 'container', 'position', 'extendStyles'].includes(prop),
})<
    Omit<SectionProps, 'title' | 'description' | 'tb' | 'titlecolor'> & {
        as?: keyof React.JSX.IntrinsicElements;
        extendStyles: CSSProp;
    }
>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : mt || 0)} ${({ mr }) => (typeof mr === 'number' ? `${mr}px` : mr || 0)}
        ${({ mb }) => (typeof mb === 'number' ? `${mb}px` : mb || 0)} ${({ ml }) => (typeof ml === 'number' ? `${ml}px` : ml || 0)};
    padding: ${({ pt }) => (typeof pt === 'number' ? `${pt}px` : pt || '30px')} ${({ pr }) => (typeof pr === 'number' ? `${pr}px` : pr || '15px')}
        ${({ pb }) => (typeof pb === 'number' ? `${pb}px` : pb || '30px')} ${({ pl }) => (typeof pl === 'number' ? `${pl}px` : pl || '15px')};

    & > h2:first-child {
        ${({ aligntext }) =>
            aligntext &&
            css`
                width: 100%;
            `}
    }

    ${({ bgcolor }) => {
        if (bgcolor === 'default') {
            return css`
                background-color: ${({ theme }) => theme.colors.neutral.neutral96};
            `;
        } else if (bgcolor === 'primary') {
            return css`
                background-color: ${({ theme }) => theme.colors.primary.primary50};
            `;
        } else if (bgcolor === 'white') {
            return css`
                background-color: ${({ theme }) => theme.colors.basic.white};
            `;
        } else if (bgcolor === 'pastel') {
            return css`
                background-color: ${({ theme }) => theme.colors.primary.primary80};
            `;
        } else if (bgcolor === 'tertiary') {
            return css`
                background-color: ${({ theme }) => theme.colors.tertiary.tertiary80};
            `;
        } else if (bgcolor === 'transparent') {
            return css`
                background-color: transparent;
            `;
        }
    }}

    ${({ type, theme }) =>
        type === 'modal' &&
        css`
            width: 100%;
            position: relative;
            padding: 15px 5px;

            ${theme.media.sm`
                padding: 30px 20px;
            `}

            ${theme.media.md`
                width: 50%;
            `}

            ${theme.media.lg`
                padding: 64.5px 28px;
            `}
        `};

    & > * {
        margin-right: auto;
        margin-left: auto;
        position: relative;

        ${({ position }) =>
            position === 'left' &&
            css`
                margin-left: 0;
            `}

        ${({ position }) =>
            position === 'right' &&
            css`
                margin-right: 0;
            `}

        ${({ container, theme }) => {
            if (container === 'default') {
                return css`
                    ${theme.media.sm`
                        max-width: 540px;
                    `}

                    ${theme.media.md`
                        max-width: 736px;
                    `}

                    ${theme.media.lg`
                          max-width: 960px;
                    `}

                    ${theme.media.xl`
                          max-width: 1180px;
                    `}
                `;
            } else if (container === 'oversized') {
                return css`
                    ${theme.media.sm`
                        max-width: 100%;
                    `}

                    ${theme.media.xl`
                          max-width: 1200px;
                    `}

                    ${theme.media.xxl`
                          max-width: 1300px;
                    `}
                `;
            } else if (container === 'fluid') {
                return css`
                    width: 100%;

                    ${theme.media.sm`
                        padding-right: 16px;
                        padding-left: 16px;
                    `}

                    ${theme.media.md`
                      padding-right: 24px;
                      padding-left: 24px;
                    `}

                    ${theme.media.xl`
                        padding-right: 93px;
                        padding-left: 93px;
                    `}
                `;
            } else if (container === 'ultra-fluid') {
                return css`
                    max-width: 100%;
                `;
            } else if (container === 'less-compacted') {
                return css`
                    width: 95%;

                    ${theme.media.sm`
                        max-width: 540px;
                    `}

                    ${theme.media.md`
                        max-width: 540px;
                    `}

                    ${theme.media.lg`
                        max-width: 980px;
                    `}
                `;
            } else if (container === 'compacted') {
                return css`
                    width: 95%;

                    ${theme.media.sm`
                        max-width: 540px;
                    `}

                    ${theme.media.md`
                        max-width: 540px;
                    `}

                    ${theme.media.lg`
                        max-width: 780px;
                    `}
                `;
            } else if (container === 'ultra-compacted') {
                return css`
                    ${theme.media.sm`
                        max-width: 540px;
                    `}

                    ${theme.media.md`
                        max-width: 680px;
                    `}
                `;
            } else if (container === 'small') {
                return css`
                    width: 95%;

                    ${theme.media.md`
                        max-width: 496px;
                    `}
                `;
            }
        }}
    }

    ${({ fadeInTransition }) =>
        fadeInTransition &&
        css`
            & > * {
                animation: ${fadeIn} 2s ease-in-out;
            }
        `}

    ${({ extendStyles }) => extendStyles}
`;
