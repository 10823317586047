import styled from 'styled-components';

export const Buttons = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 40px;
    gap: 20px;

    ${({ theme }) => theme.media.sm`
        flex-flow: row;
        justify-content: center;
    `}
`;
