import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './image.gql';
import { COUNTRY_FRAGMENT } from './country.gql';

export const USER_FRAGMENT = gql`
    ${IMAGE_FRAGMENT}
    ${COUNTRY_FRAGMENT}
    fragment UserType on UserType {
        id
        firstName
        lastName
        email
        emailVerified
        isSuperuser
        isActive
        isStaff
        image {
            ...ImageType
        }
        phone
        birthday
        country {
            ...CountryType
        }
        passport
        passportExpireAt
        cardNumber
        cardExpireAt
        paymentMethod
        passportEmittedAt
        isSubscribed
        passportEmissionCountry {
            ...CountryType
        }
    }
`;

export const TOKEN_FRAGMENT = gql`
    ${USER_FRAGMENT}
    fragment AccessTokenType on AccessTokenType {
        tokenType
        accessToken
        refreshToken
        expiresIn
        user {
            ...UserType
        }
    }
`;
