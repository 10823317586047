import styled from 'styled-components';

export const MenuNav = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    padding-inline-start: 0;
`;

export const LogoContainer = styled.div`
    width: 100%;

    a {
        display: flex !important;
        justify-content: center !important;

        svg {
            width: 200px;
        }
    }
`;
