import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

// Utils
import UrlMapper from 'utils/url-mapper';
import { currencyFormatter } from 'utils/helpers';

// Atoms
import { Typography, Button, Icon } from 'components/atoms';

// Styles
import { Card, TopContainer, BottomContainer, Offer, FirstPlace, SecondPlace, IconPlus, Badges, Pricing, NewBadge } from './ExperienceCard.styles';

const ExperienceCard = ({ item }) => {
    // Hooks
    const { t, i18n } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme
    const router = useRouter(); // Router

    const image = item?.mainImage.url;
    const firstPlaceName = item?.destinations[0]?.destination;
    const sndPlaceName = item?.destinations[1]?.destination;
    const firstPlaceNights = item?.destinations[0]?.nightsQty;
    const sndPlaceNights = item?.destinations[1]?.nightsQty;
    const price = item?.lowestPrice;
    const services = item?.services || [];

    const servicesIcon = {
        flight_ico: <Icon.Flight size={28} color={theme.colors.basic.white} />,
        lodging_ico: <Icon.Hotel size={30} color={theme.colors.basic.white} />,
        transportation_ico: <Icon.Car size={30} color={theme.colors.basic.white} />,
    };

    return (
        <Card>
            <TopContainer>
                <Image src={image} alt={firstPlaceName} layout="fill" objectFit="cover" objectPosition="center center" />
                <Badges>
                    {services.map((service, index) => {
                        return (
                            <span key={service.id}>
                                {index > 0 ? <span className="plus-top">+</span> : null}
                                {servicesIcon[service.code]}
                            </span>
                        );
                    })}
                </Badges>
                {item?.new && <NewBadge>{t('experiences:experience-card.label.new')}</NewBadge>}
                <Pricing>
                    <Typography type="sub2" $align="right" $color={theme.colors.basic.white}>
                        {t('experiences:experience-card.label.from')}
                    </Typography>
                    <Typography as="h3" type="h5" $align="right" $color={theme.colors.basic.white}>
                        {currencyFormatter.format(price)}
                    </Typography>
                </Pricing>
            </TopContainer>
            <BottomContainer>
                <Offer>
                    <FirstPlace align={sndPlaceName}>
                        <Typography type="sub2">{firstPlaceName}</Typography>
                        <Typography type="body2">
                            {firstPlaceNights} {t('experiences:experience-card.label.nights')}
                        </Typography>
                    </FirstPlace>
                    {sndPlaceName && (
                        <>
                            <IconPlus>+</IconPlus>
                            <SecondPlace>
                                <Typography type="sub2">{sndPlaceName}</Typography>
                                <Typography type="body2">
                                    {sndPlaceNights} {t('experiences:experience-card.label.nights')}
                                </Typography>
                            </SecondPlace>
                        </>
                    )}
                </Offer>

                <Button
                    content={t('experiences:experience-card.button.more-details')}
                    type="button"
                    variant="filled"
                    width="100%"
                    onClick={() => {
                        router.push(`${UrlMapper.experiences[i18n.language]}/${item?.slug}`, undefined, { shallow: true });
                    }}
                />
            </BottomContainer>
        </Card>
    );
};

ExperienceCard.propTypes = {
    item: PropTypes.object,
};

export default ExperienceCard;
