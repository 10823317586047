import React from 'react';
import Image from 'next/image';
import { Logo } from '@/components/atoms';
import defaultImage from '@/assets/images/backgrounds/auth.jpg';
import { AuthLayoutProps } from './AuthLayout.types';
import { Container, LogoContainer, Overlay, OverlayContainer, OverlayPanel, Wrapper } from './AuthLayout.styles';

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, activeSide, variant, backgroundImage }) => {
    return (
        <Wrapper>
            <Container activeSide={activeSide} variant={variant}>
                {children}
            </Container>
            <OverlayContainer>
                <Overlay activeSide={activeSide}>
                    <OverlayPanel activeSide={activeSide}>
                        <LogoContainer activeSide={activeSide}>
                            <Logo variant="horizontal" color="full-color" link="/" alt="Chagod Tour" />
                        </LogoContainer>
                        <Image
                            src={backgroundImage || defaultImage}
                            alt=""
                            priority
                            fill
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    </OverlayPanel>
                </Overlay>
            </OverlayContainer>
        </Wrapper>
    );
};

AuthLayout.defaultProps = {
    activeSide: 'left',
    variant: 'form',
};

export default AuthLayout;
