import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import { useTheme } from 'styled-components';

// Atoms
import { Typography, Button } from 'components/atoms';

// Styles
import { CardContainer, UserInfo, Avatar, TextContainer, ContainerDates, ButtonContainer } from './ReviewCard.styles';

const ReviewCard = ({ card }) => {
    // Hooks
    const { t, i18n } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme

    return (
        <CardContainer>
            <UserInfo>
                <Avatar />
                <TextContainer>
                    <Typography type="sub2" color={theme.colors.basic.black} weight="bold">
                        {card.name}
                    </Typography>
                    <Typography type="body2" color={theme.colors.basic.black}>
                        {`${card.criterium}/5 ${card.review[i18n.language]}`}
                    </Typography>
                    <Typography type="cap" color={theme.colors.neutral.neutral50}>
                        {`${card.totalReviews} ${t('packages:details.review')} · ${card.utilReviews} ${t('packages:experience.util.review')}`}
                    </Typography>
                </TextContainer>
            </UserInfo>
            <Typography type="sub2" color={theme.colors.basic.black} mt="12px" weight="bold">
                {card.subject}
            </Typography>
            <Typography type="body2" mt="4px" mb="12px">
                {card.textreview}
            </Typography>
            <ContainerDates>
                <Typography type="btn" color={theme.colors.basic.black}>
                    {t('packages:experience.date.stay')}
                </Typography>
                <Typography type="body2" color={theme.colors.basic.black}>
                    {card.datestay[i18n.language]}
                </Typography>
            </ContainerDates>
            <ContainerDates>
                <Typography type="btn" color={theme.colors.basic.black}>
                    {t('packages:experience.date.review')}
                </Typography>
                <Typography type="body2" color={theme.colors.basic.black}>
                    {card.reviewdate[i18n.language]}
                </Typography>
            </ContainerDates>
            <ButtonContainer>
                <Button
                    content={t('packages:experience.button.util')}
                    icon={<FeatherIcon.ThumbsUp size={24} color={theme.colors.neutral.neutral10} strokeWidth={2} />}
                    variant="text"
                    type="button"
                />
                <Button
                    content={t('packages:experience.button.report')}
                    icon={<FeatherIcon.Flag size={24} color={theme.colors.neutral.neutral10} strokeWidth={2} />}
                    variant="text"
                    type="button"
                />
            </ButtonContainer>
        </CardContainer>
    );
};

ReviewCard.propTypes = {
    card: PropTypes.object,
};

export default ReviewCard;
