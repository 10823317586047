import React from 'react';
import * as FeatherIcon from 'react-feather';
import { ModalProps } from './Modal.types';
import { ModalWrapper, ModalBody, CloseModal } from './Modal.styles';

const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
    // States
    const [closing, setClosing] = React.useState(false);

    const handleOnClose = React.useCallback(() => {
        setClosing(true);

        setTimeout(() => {
            onClose();
            setClosing(false);
        }, 500);
    }, [onClose]);

    return (
        <ModalWrapper closing={closing}>
            <CloseModal onClick={handleOnClose}>
                <FeatherIcon.X size={40} />
            </CloseModal>

            <ModalBody closing={closing} className={closing ? 'closing' : ''}>
                {children}
            </ModalBody>
        </ModalWrapper>
    );
};

export default Modal;
