import React from 'react';
import { Icon } from '@/components/atoms';
import { SocialLinksProps } from "./SocialLinks.types";
import { SocialItem, Wrapper } from './SocialLinks.styles';

const SocialLinks: React.FC<SocialLinksProps> = ({ id, social }) => {
    return (
        <Wrapper id={id}>
            {social?.facebook && (
                <SocialItem href={social.facebook} target="_blank" title="Facebook" role="link" aria-label="Facebook">
                    <Icon.Facebook size={24} />
                </SocialItem>
            )}
            {social?.instagram && (
                <SocialItem href={social.instagram} target="_blank" title="Instagram" role="link" aria-label="Instagram">
                    <Icon.Instagram size={24} />
                </SocialItem>
            )}
            {social?.linkedin && (
                <SocialItem href={social.linkedin} target="_blank" title="Linkedin" role="link" aria-label="Linkedin">
                    <Icon.Linkedin size={24} />
                </SocialItem>
            )}
            {social?.pinterest && (
                <SocialItem href={social.pinterest} target="_blank" title="Pinterest" role="link" aria-label="Pinterest">
                    <Icon.Pinterest size={24} />
                </SocialItem>
            )}
            {social?.telegram && (
                <SocialItem href={social.telegram} target="_blank" title="Telegram" role="link" aria-label="Telegram">
                    <Icon.Telegram size={24} />
                </SocialItem>
            )}
            {social?.youtube && (
                <SocialItem href={social.youtube} target="_blank" title="Youtube" role="link" aria-label="Youtube">
                    <Icon.Youtube size={24} />
                </SocialItem>
            )}
        </Wrapper>
    );
};

export default SocialLinks;
