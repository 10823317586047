import React from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import Link from 'next/link';
import { useMediaQuery } from '@/modules/media-query';
import * as FeatherIcon from 'react-feather';
// import FilterIcon from 'assets/images/filter-2.svg';

// data
import { reviews, filterScore, filterTraveler, filterSeasons, filterLanguage } from 'config/experiences';

// atoms
import { Typography, ProgressBar, Button } from 'components/atoms';

// molecules
import { Rating, ReviewCard, SideFilter, Section } from 'components/molecules';

// styles
import {
    Container,
    PointSummary,
    Review,
    Frame1,
    Frame2,
    Summary,
    Frame3,
    MoreDetails,
    ScrollView,
    FilterMenuHeader,
    MenuRight,
    LeftLabel,
    TitleContainer,
} from './ReviewsExperience.styles';

const FilterMenu = () => {
    const { t } = useTranslation(); // Translations

    const [isHeaderVisible, setIsHeaderVisible] = React.useState(true);
    const [isContentVisible, setIsContentVisible] = React.useState(false);
    const [isAsc, setIsAsc] = React.useState(true);
    const theme = useTheme(); // Get current theme

    // Media Queries
    const isMd = useMediaQuery('(min-width: 768px)');

    React.useEffect(() => {
        if (isMd) {
            setIsHeaderVisible(false);
            setIsContentVisible(true);
        } else {
            setIsHeaderVisible(true);
            setIsContentVisible(false);
        }
    }, [isMd]);

    const handleShowContent = () => {
        setIsContentVisible((prevState) => !prevState);
    };

    const handleChangeOrder = () => {
        setIsAsc((prevState) => !prevState);
    };
    const icon = !isContentVisible ? (
        <FeatherIcon.ChevronRight size={20} strokeWidth={2} color={theme.colors.tertiary.tertiary50} />
    ) : (
        <FeatherIcon.ChevronDown size={20} strokeWidth={2} color={theme.colors.tertiary.tertiary50} />
    );
    const menuTitle = isAsc ? t('packages:filter.most_commented') : t('packages:filter.less_commented');
    return (
        <>
            {isHeaderVisible && (
                <FilterMenuHeader>
                    <LeftLabel onClick={handleShowContent}>
                        <Typography type="btn" $color={theme.colors.tertiary.tertiary50}>
                            {t('packages:filters')}
                        </Typography>
                        {icon}
                    </LeftLabel>
                    {/* <Button onClick={handleShowContent} content={t('packages:filters')} icon={icon} variant="text" type="button" iconPosition="right" /> */}
                    <MenuRight isAsc={!isAsc} onClick={handleChangeOrder}>
                        <Typography type="btn" $mr="8px">
                            {menuTitle}
                        </Typography>
                        {/* <FilterIcon size={25} strokeWidth={2} /> */}
                    </MenuRight>
                </FilterMenuHeader>
            )}
            {isContentVisible && (
                <div>
                    <SideFilter data={filterScore} title={t('packages:filter.score')} dropdown progresBar />
                    <SideFilter data={filterTraveler} title={t('packages:filter.traveler')} dropdown />
                    <SideFilter data={filterSeasons} title={t('packages:filter.seasons')} dropdown />
                    <SideFilter data={filterLanguage} title={t('packages:filter.language')} dropdown />
                </div>
            )}
        </>
    );
};
const ReviewsExperience = () => {
    // Hooks
    const { t, i18n } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme

    return (
        <>
            <TitleContainer>
                <Typography variant="h1" size="50px" weight="bold" align="left">
                    {t(`packages:details.review`)}
                </Typography>
            </TitleContainer>
            <Container>
                <div>
                    <Summary>
                        <Frame2>
                            <Typography type="h1" color={theme.colors.secondary.secondary50} size="50px" weight="bold">
                                {reviews.summary.totalPoints}
                            </Typography>
                            <Frame3>
                                <Typography type="sub2" color={theme.colors.secondary.secondary50}>
                                    {reviews.summary.review[i18n.language]}
                                </Typography>
                                <Rating fillItems={reviews.summary.stars} reviews={reviews.summary.totalreviews} $mt="0" />
                            </Frame3>
                        </Frame2>
                        <Button
                            content={t('packages:experience.write.review')}
                            icon={<FeatherIcon.Edit3 size={24} color={theme.colors.neutral.neutral10} strokeWidth={2} />}
                            variant="filled"
                            type="button"
                        />
                    </Summary>
                    <PointSummary>
                        {reviews.pointsSummary.map((point) => (
                            <Review key={point.id}>
                                <Frame1>
                                    <Typography type="body2" color={theme.colors.basic.black}>
                                        {point.services[i18n.language]}
                                    </Typography>
                                    <Typography type="body2" color={theme.colors.basic.black}>
                                        {point.criterium}
                                    </Typography>
                                </Frame1>
                                <ProgressBar progress={point.criterium} total={5} />
                            </Review>
                        ))}
                    </PointSummary>

                    <FilterMenu />
                </div>
                <div>
                    <ScrollView>
                        {reviews.allReviews.map((card) => (
                            <ReviewCard key={card.id} card={card} />
                        ))}
                        <Link href="/" passHref>
                            <MoreDetails>
                                <Typography type="btn" color={theme.colors.tertiary.tertiary50}>
                                    {t('packages:experience.all.reviews')}
                                </Typography>
                                <FeatherIcon.ChevronRight size={20} color={theme.colors.tertiary.tertiary50} strokeWidth={2} />
                            </MoreDetails>
                        </Link>
                    </ScrollView>
                </div>
            </Container>
        </>
    );
};

export default ReviewsExperience;
