import { gql } from "@apollo/client";

export const PASSENGER_FRAGMENT = gql`
    fragment PassengerType on PassengerType {
        id
        email
        birthDate
        passport
        phone
        fullName
    }
`;
