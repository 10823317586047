import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './image.gql';
import { COUNTRY_FRAGMENT } from './country.gql';
import { LOCATION_FRAGMENT } from './location.gql';

export const DESTINATION_CITY_FRAGMENT = gql`
    ${COUNTRY_FRAGMENT}
    ${IMAGE_FRAGMENT}
    fragment DestinationCityType on DestinationCityType {
        id
        name
        country {
            ...CountryType
        }
        images {
            ...ImageType
        }
    }
`;

export const PACKAGES_ORIGIN_FRAGMENT = gql`
    ${DESTINATION_CITY_FRAGMENT}
    fragment TouristPackageOriginType on TouristPackageOriginType {
        id
        origin {
            ...DestinationCityType
        }
        durationDays
        durationNights
        availableFrom
        frecuency {
            frequency
            recurrenceRules
        }
    }
`;

export const SERVICES_FRAGMENT = gql`
    fragment ServiceType on ServiceType {
        id
        name
        iconVariants {
            id
            name
            theme
        }
    }
`;

export const PLACE_INTEREST_FRAGMENT = gql`
    fragment PlaceInterestType on PlaceInterestType {
        id
        name
        icon
    }
`;

export const EXPERIENCE_REQUIREMENT_FRAGMENT = gql`
    fragment ExperienceRequirementType on ExperienceRequirementType {
        id
        name
        icon
    }
`;

export const LODGINGS_FRAGMENT = gql`
    ${SERVICES_FRAGMENT}
    ${PLACE_INTEREST_FRAGMENT}
    ${EXPERIENCE_REQUIREMENT_FRAGMENT}
    ${LOCATION_FRAGMENT}
    fragment LodgingsType on LodgingsType {
        id
        name
        services {
            ...ServiceType
        }
        placesInterest {
            ...PlaceInterestType
        }
        requirements {
            ...ExperienceRequirementType
        }
        address
        phone
        email
        contactName
        description
        enterAfter
        enterBefore
        roomQty
        bathQty
        category
        lodgingType
        coordinates {
            ...CoordinatePointType
        }
    }
`;

export const PACKAGE_LODGINGS_FRAGMENT = gql`
    ${LODGINGS_FRAGMENT}
    fragment TouristPackageLodgingType on TouristPackageLodgingType {
        id
        lodging {
            ...LodgingsType
        }
        promotional
        isActive
    }
`;

export const FRECUENCY_FRAGMENT = gql`
    fragment FrequencyFieldType on FrequencyFieldType {
        frequency
        recurrenceRules
    }
`;

export const PACKAGE_SERVICE_FRAGMENT = gql`
    ${SERVICES_FRAGMENT}
    ${FRECUENCY_FRAGMENT}
    fragment TouristPackageServiceType on TouristPackageServiceType {
        id
        service {
            ...ServiceType
        }
        promotional
        price
        frecuency {
            ...FrequencyFieldType
        }
    }
`;

export const BAGGAGE_FRAGMENT = gql`
    fragment BaggageType on BaggageType {
        id
        name
        qty
        weightKg
    }
`;

export const PACKAGE_BAGGAGE_FRAGMENT = gql`
    ${BAGGAGE_FRAGMENT}
    fragment TouristPackageBaggageKind on TouristPackageBaggageKind {
        id
        baggage {
            ...BaggageType
        }
        type
        price
        comments
    }
`;

export const PACKAGE_RELATED_FRAGMENT = gql`
    ${PACKAGE_SERVICE_FRAGMENT}

    fragment RelatedType on TouristPackageType {
        related {
            id
            name
            price
            types {
                id
                name
            }
            images {
                id
                principal
                image {
                    ...ImageType
                }
            }
            origins {
                id
                durationDays
                durationNights
                availableFrom
            }
            destination {
                id
                destination
                description
                slug
                visits
            }
            excursions {
                id
                name
                icon
            }
            lodgings {
                id
                isActive
                promotional
            }
            services {
                ...TouristPackageServiceType
            }

            baggage {
                id
                type
                price
                comments
            }
        }
    }
`;

export const PACKAGES_FRAGMENT = gql`
    ${IMAGE_FRAGMENT}
    ${COUNTRY_FRAGMENT}
    ${PACKAGES_ORIGIN_FRAGMENT}
    ${PLACE_INTEREST_FRAGMENT}
    ${PACKAGE_LODGINGS_FRAGMENT}
    ${PACKAGE_BAGGAGE_FRAGMENT}
    ${PACKAGE_RELATED_FRAGMENT}
    fragment TouristPackageType on TouristPackageType {
        id
        name
        price
        types {
            id
            name
        }
        images {
            id
            principal
            image {
                ...ImageType
            }
        }
        origins {
            ...TouristPackageOriginType
        }
        destination {
            id
            destination
            description
            city {
                id
                name
                country {
                    ...CountryType
                }
                images {
                    ...ImageType
                }
            }
        }
        related {
            ...RelatedType
        }
        excursions {
            ...PlaceInterestType
        }
        lodgings {
            ...TouristPackageLodgingType
        }
        services {
            ...TouristPackageServiceType
        }
        portion
        program
        termsAndConditions
        unlimitedBaggage
        unlimitedBaggageWeight
        unlimitedBaggagePrice
        baggageComments
        visa
        visaPrice
        baggage {
            ...TouristPackageBaggageKind
        }
    }
`;

export const DESTINATION_FRAGMENT = gql`
    ${PACKAGES_FRAGMENT}
    ${DESTINATION_CITY_FRAGMENT}
    ${LODGINGS_FRAGMENT}
    fragment DestinationType on DestinationType {
        id
        destination
        city {
            ...DestinationCityType
        }
        description
        slug
        visits
        packages {
            ...TouristPackageType
        }
        lodgings {
            ...LodgingsType
        }
    }
`;
