import { Action, State } from './Wizard.types';

export const initialState: State = {
    currentStep: 0,
    steps: [],
    data: {
        passengerActive: 0,
    },
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'NEXT_STEP':
            return {
                ...state,
                currentStep: Math.min(state.currentStep + 1, state.steps.length - 1),
            };
        case 'PREVIOUS_STEP':
            return {
                ...state,
                currentStep: Math.max(state.currentStep - 1, 0),
            };
        case 'GO_TO_STEP':
            return {
                ...state,
                currentStep: action.payload,
            };
        case 'UPDATE_DATA':
            return {
                ...state,
                data: { ...state.data, ...action.payload },
            };
        case 'REMOVE_DATA':
            return {
                ...state,
                data: {
                    ...initialState.data,
                },
            };
        default:
            return state;
    }
};

export default reducer;
