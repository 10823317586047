import styled from 'styled-components';

export const InnerWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.primary.primary90};
    border-radius: 25px;
    margin-top: 20px;
    padding: 28px;
    height: fit-content;
`;

export const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 28px 0 0;
`;
