import styled from 'styled-components';

export const CardsContainerUL = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: auto;
    padding: 0;
    margin: 4px 0 0;
    gap: 12px;
    max-width: 95%;
    width: 100%;
    scrollbar-width: none;
    z-index: 2;

    /* Webkit(Chrome, Android browser, Safari, Edge...) */
    &::-webkit-scrollbar {
        display: none;
    }

    ${({ theme }) => theme.media.sm`
        max-width: 540px;
    `}

    ${({ theme }) => theme.media.md`
        gap: 16px;
        max-width: 736px;
    `}


    ${({ theme }) => theme.media.lg`
        max-width: 960px;
    `}

    ${({ theme }) => theme.media.xl`
        flex-wrap: wrap;
        max-width: 1180px;
        gap: 20px;
    `}
`;
