import React from 'react';
import { FlightLine, Skeleton } from "@/components/atoms";
import { Card, TopContainer, BottomContainer, Departure, Scale, Arrival, Separator } from './FlightCardSkeleton.styles';

const FlightCardSkeleton = () => {
    return (
        <Card>
            <TopContainer>
                <Departure>
                    <Skeleton width={4.6} height={1.8} radius={5} mb={0.5} />
                    <Skeleton width={2.8} height={1.3} radius={5} />
                </Departure>
                <Scale>
                    <Skeleton width={4.6} height={1} radius={5} />
                    <FlightLine steps={0} display="horizontal" variant="normal" />
                    <Skeleton width={6.8} height={1} radius={5} />
                </Scale>
                <Arrival>
                    <Skeleton width={4.6} height={1.8} radius={5} mb={0.5} />
                    <Skeleton width={2.8} height={1.3} radius={5}  />
                </Arrival>
            </TopContainer>
            <Separator />
            <BottomContainer>
                <Skeleton width={10} height={3.2} radius={25} />
                <Skeleton width={10} height={3.2} radius={25} />
            </BottomContainer>
        </Card>
    );
};

export default FlightCardSkeleton;
