import React from 'react';
import { GlobalState, GlobalStateProviderProps } from './GlobalState.types';
import { DispatchStateContext, GlobalStateContext } from './GlobalStateContext';

const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
    const [global, dispatch] = React.useReducer((state: GlobalState, newValue: GlobalState) => ({ ...state, ...newValue }), {});

    return (
        <GlobalStateContext.Provider value={global}>
            <DispatchStateContext.Provider value={dispatch}>{children}</DispatchStateContext.Provider>
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;
