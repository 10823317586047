import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, PAGE_INFO_FRAGMENT, IMAGE_FRAGMENT } from '@/graphql/fragments';

export const ADVERTISING_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    ${IMAGE_FRAGMENT}
    query Advertising($skip: Int, $take: Int, $report: Boolean) {
        advertisings(skip: $skip, take: $take, report: $report) {
            status
            errors {
                ...ErrorType
            }
            pageInfo {
                ...PageInfoType
            }
            objects {
                id
                name
                image {
                    ...ImageType
                }
                description
                begin
                end
                facebookLink
                instagramLink
            }
        }
    }
`;
