import styled from 'styled-components';

export const Wrapper = styled.ul`
    width: 100%;
    margin: 0 auto;
    padding: 0 0 30px 0;

    ${({ theme }) => theme.media.lg`
        padding: 32px 0;
    `}
`;

export const ItemWrapper = styled.div`
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const Buttons = styled.div`
    display: flex;
    flex-flow: column;
    gap: 20px;
    margin-top: 30px;
`;
