import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT } from '@/graphql/fragments';

export const SOCIAL_QUERY = gql`
    ${ERRORS_FRAGMENT}
    query SocialMedia {
        socialMedia {
            status # Int
            errors {
                ...ErrorType
            }
            media {
                facebook
                instagram
                linkedin
                telegram
                pinterest
                youtube
            }
        }
    }
`;
