import styled, { createGlobalStyle, css } from 'styled-components';
import { Container } from '@/styles/common';

export const CarouselStyles = createGlobalStyle<{ loaded: boolean; height: number }>`
    .CarouselSwiper.swiper {
        width: 100%;
        height: ${({ height }) => `${height}px`};
        background-color: ${({ theme }) => theme.colors.tertiary.tertiary50};
        overflow: visible;
    }

    .CarouselSwiper .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 1rem;
        height: 100%;
    }

    .CarouselSwiper .swiper-slide img {
        object-fit: cover;
        position: absolute;
        height:  ${({ height }) => `${height}px`} !important;
        opacity: ${({ loaded }) => (loaded ? '1' : '0')};
        transition: opacity 1s ease-in-out;
    }

    .CarouselSwiper .swiper-slide h1,
    .CarouselSwiper .swiper-slide p {
        position: relative;
    }

    .CarouselSwiper .swiper-button-next,
    .CarouselSwiper .swiper-button-prev {
        color: #edf5fa;
        margin-top: 0;
        margin-bottom: 0;
        display: none;
    }

    .CarouselSwiper .swiper-pagination {
        display: block;
    }

    .CarouselSwiper .swiper-pagination-bullet {
        background: rgb(255 255 255 / 50%);
        width: 12px;
        height: 12px;
    }

    .CarouselSwiper .swiper-pagination-bullet-active {
        background: #fff;
    }

    @media (width >= 768px) {
        .CarouselSwiper .swiper-button-prev::after,
        .CarouselSwiper .swiper-button-next::after {
            font-size: 36px;
        }

        .CarouselSwiper .swiper-button-prev {
            left: 30px;
        }

        .CarouselSwiper .swiper-button-next {
            right: 30px;
        }
    }

    @media (width >= 992px) {
        .CarouselSwiper .swiper-slide {
            padding: 3rem;
        }

        .CarouselSwiper .swiper-button-prev::after,
        .CarouselSwiper .swiper-button-next::after {
            width: 18px;
            height: 36px;
        }

        .CarouselSwiper .swiper-button-next,
        .CarouselSwiper .swiper-button-prev {
            display: block;
        }

        .CarouselSwiper .swiper-button-prev {
            left: 37px;
        }

        .CarouselSwiper .swiper-button-next {
            right: 37px;
        }

        .CarouselSwiper .swiper-pagination {
            display: none;
        }
    }
`;

export const SlideContent = styled(Container).withConfig({
    shouldForwardProp: (prop) => !['centered', 'isDynamic'].includes(prop),
})<{ centered?: boolean; isDynamic?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 3;

    ${({ centered }) =>
        centered &&
        css`
            align-items: center !important;
        `}

    h1 {
        margin-top: 2.5rem;

        span {
            display: block;
            color: ${({ theme }) => theme.colors.primary.primary50};
        }
    }

    div,
    input {
        width: 330px;
    }

    ${({ theme }) => theme.media.md`
        width: 475px;
   `};

    ${({ theme, isDynamic }) => theme.media.lg`
        width: ${isDynamic ? '90%' : '100%'};
        justify-content: flex-start;
        align-items: flex-start;

        p {
            max-width: 580px;
        }
   `};
`;

export const Overlay = styled.div.withConfig({
    shouldForwardProp: (prop) => !['overlay'].includes(prop),
})<{ overlay?: string }>`
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 100%;
    margin: -1rem;
    background: ${({ overlay }) => overlay || 'linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))'};

    ${({ theme }) => theme.media.lg`
        margin: -3rem;
   `};
`;
