import styled, { css } from 'styled-components';

export const CardsContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['mt'].includes(prop),
})<{ mt?: string | number }>`
    position: relative;
    margin-top: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : mt || '24px')};
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    z-index: 2;
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll; /* Add the ability to scroll */
    scrollbar-width: none;

    /* Webkit(Chrome, Android browser, Safari, Edge...) */

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['contentType'].includes(prop),
})<{ contentType?: 'default' | 'chips' }>`
    display: grid;
    grid-auto-flow: column;
    padding: 0;
    gap: 12px;

    ${({ theme, contentType }) => theme.media.md`
        gap: ${contentType === 'chips' ? '12px;' : '0 16px'};
    `}

    ${({ theme, contentType }) => theme.media.xl`
        gap: ${contentType === 'chips' ? '12px' : '0 20px'};
    `}
`;

export const ButtonPrev = styled.a.withConfig({
    shouldForwardProp: (prop) => !['contentType'].includes(prop),
})<{ contentType?: 'default' | 'chips' }>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    ${({ contentType }) =>
        contentType === 'default' &&
        css`
            background-color: ${({ theme }) => theme.colors.primary.primary50};
            border-radius: 50%;
            width: 48px;
            height: 48px;
            left: -24px;
            top: 120px;

            &:hover {
                background-color: ${({ theme }) => theme.colors.primary.primary60};
            }

            &:active {
                background-color: ${({ theme }) => theme.colors.primary.primary40};
            }
        `}

    ${({ contentType }) =>
        contentType === 'chips' &&
        css`
            width: 30px;
            height: 30px;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            background: linear-gradient(270deg, #fff 50%, rgb(255 255 255 / 0%) 100%);
        `}
`;

export const ButtonNext = styled.a.withConfig({
    shouldForwardProp: (prop) => !['contentType'].includes(prop),
})<{ contentType?: 'default' | 'chips' }>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    ${({ contentType }) =>
        contentType === 'default' &&
        css`
            background-color: ${({ theme }) => theme.colors.primary.primary50};
            border-radius: 50%;
            width: 48px;
            height: 48px;
            right: -24px;
            top: 120px;

            &:hover {
                background-color: ${({ theme }) => theme.colors.primary.primary60};
            }

            &:active {
                background-color: ${({ theme }) => theme.colors.primary.primary40};
            }
        `}

    ${({ contentType }) =>
        contentType === 'chips' &&
        css`
            width: 30px;
            height: 30px;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            background: linear-gradient(270deg, #fff 50%, rgb(255 255 255 / 0%) 100%);
        `}
`;
