import styled, { css } from 'styled-components';
import { rotate } from '@/styles/animations';
import { ButtonProps, ButtonVariant } from './Button.types';

export const Component = styled.button.withConfig({
    shouldForwardProp: (prop) => !['variant', 'icon', 'iconPosition', 'width', 'rounded', 'selected', 'isSubmitting'].includes(prop),
})<Omit<ButtonProps, 'content' | 'contentOnSubmitting'>>`
    font-family: ${({ theme }) => theme.font.btn.family};
    font-size: ${({ theme }) => theme.font.btn.mobile.size};
    line-height: ${({ theme }) => theme.font.btn.mobile.lineheight};
    font-weight: ${({ theme }) => theme.font.btn.mobile.weight};
    font-style: ${({ theme }) => theme.font.btn.mobile.style};

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.btn.tablet.size};
        font-weight: ${theme.font.btn.tablet.weight};
        line-height: ${theme.font.btn.tablet.lineheight};
        font-style: ${theme.font.btn.tablet.style};
    `}

    ${({ theme }) => theme.media.xl`
        font-size: ${theme.font.btn.desktop.size};
        font-weight: ${theme.font.btn.desktop.weight};
        line-height: ${theme.font.btn.desktop.lineheight};
        font-style: ${theme.font.btn.desktop.style};
    `}

    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    min-width: ${({ width }) => typeof width === 'number' ? `${width}px` : width || 'auto'};
    height: 50px !important;
    outline: 0 !important;
    padding: 0;
    border-radius: 50px;
    transition: 0.6s ease all;
    cursor: pointer;

    &:disabled,
    &[disabled] {
        pointer-events: none;
    }

    & > svg {
        margin-left: 13px;
    }

    & > .btn-ripple {
        display: flex !important;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 50px !important;
        border-radius: 50px;
        padding: 10px 18px;
        width: 100%;
    }

    ${({ theme }) => theme.media.md`
        & > .btn-ripple {
            padding: 10px 28px;
        }
    `}

    // Variants
    ${({ variant, rounded, selected, theme }) => {
        if (variant === 'filled') {
            return css`
                color: ${theme.colors.primary.primary10};
                border: 2px solid ${theme.colors.primary.primary50};
                background: ${theme.colors.primary.primary50};

                &:hover {
                    border: 2px solid ${theme.colors.primary.primary70};
                    background: ${theme.colors.primary.primary70};
                }

                &:active {
                    border: 2px solid ${theme.colors.primary.primary60};
                    background: ${theme.colors.primary.primary60};
                }

                &:disabled,
                &[disabled] {
                    color: ${theme.colors.neutral.neutral70};
                    border: 2px solid ${theme.colors.neutral.neutral90};
                    background: ${theme.colors.neutral.neutral90};
                }

                // Rounded and Unselect
                ${rounded &&
                !selected &&
                css`
                    border: 2px solid ${theme.colors.primary.primary70};
                    background: ${theme.colors.primary.primary70};

                    &:hover {
                        border: 2px solid ${theme.colors.primary.primary80};
                        background: ${theme.colors.primary.primary80};
                    }
                `}

                // Rounded and Select
                ${rounded &&
                selected &&
                css`
                    &:hover {
                        border: 2px solid ${theme.colors.primary.primary60};
                        background: ${theme.colors.primary.primary60};
                    }

                    &:active {
                        border: 2px solid ${theme.colors.primary.primary40};
                        background: ${theme.colors.primary.primary40};
                    }

                    &:disabled,
                    &[disabled] {
                        color: ${theme.colors.neutral.neutral60};
                        border: 2px solid ${theme.colors.neutral.neutral80};
                        background: ${theme.colors.neutral.neutral80};
                    }
                `}
            `;
        } else if (variant === 'filled-tonal') {
            return css`
                color: ${theme.colors.primary.primary20};
                border: 2px solid ${theme.colors.primary.primary80};
                background: ${theme.colors.primary.primary80};

                &:hover {
                    border: 2px solid ${theme.colors.primary.primary70};
                    background: ${theme.colors.primary.primary70};
                }

                &:active {
                    border: 2px solid ${theme.colors.primary.primary60};
                    background: ${theme.colors.primary.primary60};
                }

                &:disabled,
                &[disabled] {
                    color: ${theme.colors.neutral.neutral80};
                    border: 2px solid ${theme.colors.neutral.neutral94};
                    background: ${theme.colors.neutral.neutral94};
                }

                // Rounded and Unselect
                ${rounded &&
                !selected &&
                css`
                    color: ${theme.colors.primary.primary30};
                    border: 2px solid ${theme.colors.primary.primary80};
                    background: ${theme.colors.primary.primary80};

                    &:hover {
                        border: 2px solid ${theme.colors.primary.primary90};
                        background: ${theme.colors.primary.primary90};
                    }

                    &:active {
                        border: 2px solid ${theme.colors.primary.primary70};
                        background: ${theme.colors.primary.primary70};
                    }

                    &:disabled,
                    &[disabled] {
                        color: ${theme.colors.neutral.neutral80};
                        border: 2px solid ${theme.colors.neutral.neutral92};
                        background: ${theme.colors.neutral.neutral92};
                    }
                `}

                // Rounded and Select
                ${rounded &&
                selected &&
                css`
                    color: ${theme.colors.primary.primary30};
                    border: 2px solid ${theme.colors.primary.primary70};
                    background: ${theme.colors.primary.primary70};

                    &:hover {
                        border: 2px solid ${theme.colors.primary.primary80};
                        background: ${theme.colors.primary.primary80};
                    }

                    &:active {
                        border: 2px solid ${theme.colors.primary.primary60};
                        background: ${theme.colors.primary.primary60};
                    }

                    &:disabled,
                    &[disabled] {
                        color: ${theme.colors.neutral.neutral70};
                        border: 2px solid ${theme.colors.neutral.neutral90};
                        background: ${theme.colors.neutral.neutral90};
                    }
                `}
            `;
        } else if (variant === 'outlined') {
            return css`
                color: ${theme.colors.neutral.neutral10};
                border: 2px solid ${theme.colors.primary.primary50};
                background: transparent;

                &:hover {
                    border: 2px solid ${theme.colors.primary.primary50};
                    background: ${theme.colors.primary.primary90};
                }

                &:active {
                    border: 2px solid ${theme.colors.primary.primary50};
                    background: ${theme.colors.primary.primary80};
                }

                &:disabled,
                &[disabled] {
                    color: ${theme.colors.neutral.neutral80};
                    border: 2px solid ${theme.colors.neutral.neutral80};
                    background: transparent;
                }

                // Rounded and Unselect
                ${rounded &&
                !selected &&
                css`
                    &:disabled,
                    &[disabled] {
                        color: ${theme.colors.neutral.neutral70};
                        border: 2px solid ${theme.colors.neutral.neutral70};
                    }
                `}

                // Rounded and Select
                ${rounded &&
                selected &&
                css`
                    color: ${theme.colors.primary.primary20};
                    background: ${theme.colors.primary.primary50};

                    &:hover {
                        border: 2px solid ${theme.colors.primary.primary60};
                        background: ${theme.colors.primary.primary60};
                    }

                    &:active {
                        border: 2px solid ${theme.colors.primary.primary40};
                        background: ${theme.colors.primary.primary40};
                    }

                    &:disabled,
                    &[disabled] {
                        color: ${theme.colors.neutral.neutral60};
                        border: 2px solid ${theme.colors.neutral.neutral80};
                        background: ${theme.colors.neutral.neutral80};
                    }
                `}
            `;
        } else if (variant === 'text' || variant === 'standard') {
            return css`
                color: ${theme.colors.neutral.neutral10};
                border: 2px solid transparent;
                background: transparent;

                &:hover {
                    border: 2px solid ${theme.colors.primary.primary90};
                    background: ${theme.colors.primary.primary90};
                }

                &:active {
                    border: 2px solid ${theme.colors.primary.primary80};
                    background: ${theme.colors.primary.primary80};
                }

                &:disabled,
                &[disabled] {
                    color: ${theme.colors.neutral.neutral80};
                    border: 2px solid transparent;
                    background: transparent;
                }

                // Rounded and Unselect
                ${rounded &&
                !selected &&
                css`
                    &:disabled,
                    &[disabled] {
                        color: ${theme.colors.neutral.neutral70};
                    }
                `}

                // Rounded and Select
                ${rounded &&
                selected &&
                css`
                    color: ${theme.colors.secondary.secondary50};

                    &:disabled,
                    &[disabled] {
                        color: ${theme.colors.neutral.neutral70};
                    }
                `}
            `;
        }
    }}

    // Rounded Buttons
    ${({ rounded, width }) =>
        rounded &&
        css`
            width: ${typeof width === 'number' ? `${width}px` : width || '48px'};
            height: ${typeof width === 'number' ? `${width}px` : width || '48px'} !important;
            border-radius: calc(${width || '48px'} / 2);

            & > div {
                padding: 0 !important;
            }

            & > .btn-ripple {
                height: ${typeof width === 'number' ? `${width}px` : width || '48px'} !important;
            }

            & svg {
                margin: 0 !important;
            }
        `}

    // Icon positions
    ${({ icon, iconPosition, isSubmitting }) => {
        if (icon !== 'none' && iconPosition === 'left') {
            return css`
                & svg {
                    margin-right: 10px;
                }

                & > .btn-ripple {
                    padding: 10px 28px 10px 18px;
                }
            `;
        } else if (icon !== 'none' && iconPosition === 'right') {
            return css`
                & svg {
                    margin-left: 10px;
                }

                & > .btn-ripple {
                    padding: 10px 18px 10px 28px;
                }
            `;
        } else if (icon !== 'none' && isSubmitting) {
            return css`
                & > .btn-ripple {
                    padding: 10px 28px;
                }
            `;
        }
    }}
`;

export const Spinner = styled.span.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{ variant: ButtonVariant }>`
    margin: auto;
    width: 25px !important;
    height: 25px;
    border-top: 3px solid rgba(255, 255, 255, 0.5);
    border-bottom: 3px solid rgba(255, 255, 255, 0.5);
    border-right: 3px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: ${rotate} 1.5s linear infinite;

    // Variants
    ${({ theme, variant }) => {
        if (variant === 'filled') {
            return css`
                border-left: 3px solid ${theme.colors.primary.primary10};
            `;
        } else if (variant === 'filled-tonal') {
            return css`
                border-left: 3px solid ${theme.colors.primary.primary20};
            `;
        } else if (variant === 'outlined') {
            return css`
                border-left: 3px solid ${theme.colors.neutral.neutral10};
            `;
        } else if (variant === 'text' || variant === 'standard') {
            return css`
                border-left: 3px solid ${theme.colors.neutral.neutral10};
            `;
        }
    }}
`;
