import styled from 'styled-components';
import { fadeIn } from 'styles/animations';

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;

    /* gap: 12px; */
    min-width: 280px;
    width: 100%;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.basic.white};
    overflow: hidden;
    animation: ${fadeIn} 1s ease-in-out;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const Avatar = styled.div`
    width: 48px;
    height: 48px;
    background: ${({ theme }) => theme.colors.primary.primary80};
    border: none;
    border-radius: 50%;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 0;
`;

export const ContainerDates = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
    gap: 12px;
`;
