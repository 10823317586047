import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import { useTheme } from 'styled-components';
import Link from 'next/link';

// Iconos
import IconClock from 'assets/images/clock.svg';
import IconCar from 'assets/images/car.svg';
import IconLodgin from 'assets/images/lodgin.svg';
import IconPlane from 'assets/images/plane-pack.svg';

// Utils
import UrlMapper from 'utils/url-mapper';

// Atoms
import { Button, Typography } from 'components/atoms';

// Molecules
import DestinationCard from '../DestinationCard';
// import Rating from '../Rating';
import DestinationIconList from '../DestinationIconList';

// Styles
import { Container, DescriptionFrame, PricePerson, ContainerListItems } from './DestinationCardInformative.styles';

const DestinationCardInformative = ({ experience, typeIcon, button, since }) => {
    // Translations
    const { t, i18n } = useTranslation();
    // Hooks
    const theme = useTheme(); // Get current theme

    const iconValue = typeIcon === 'bookmark' ? <FeatherIcon.Bookmark size={25} strokeWidth={2} /> : '';

    const { id, name, price, origins, lodgings } = experience;
    // const stars = 4;
    // const reviews = 130;

    const durationPack = () => {
        let text = '';
        const listOrigins = [...origins] ?? [];
        const list = listOrigins?.length > 1 ? listOrigins?.sort((a, b) => b.durationDays - a.durationDays) : listOrigins;
        if (list?.[0]?.durationDays) text = t('destinations:details.experience-duration', { day: list[0]?.durationDays, night: list[0]?.durationNights });

        return text;
    };

    // Arrays details Experiences
    const details = [];

    // Add Airtickets
    details.push({ icon: <IconPlane />, text: t('destinations:details.experience-airticket') });

    // Add Duration
    const duration = durationPack();
    if (duration !== '') details.push({ icon: <IconClock />, text: duration });

    // Add Lodgings
    if (lodgings?.length > 0) details.push({ icon: <IconLodgin />, text: t('destinations:details.experience-lodging') });

    // Add Transfer
    details.push({ icon: <IconCar />, text: t('destinations:details.experience-transfer') });

    return (
        <Container>
            <DestinationCard key={id} card={experience} icon={iconValue} url="destinations-experiences" />
            <DescriptionFrame>
                {name && <Typography type="h6">{name}</Typography>}
                {/* {stars && <Rating fillItems={stars} reviews={reviews} />} */}
                {/* <Typography type="body2" $mt="10px">
                    {description}
                </Typography> */}

                <ContainerListItems>
                    <DestinationIconList items={details} />
                    <Link href={`${UrlMapper['destinations-experiences'][i18n.language]}/${id}`}>
                        <a role="link" aria-label="details.more" title="details.more">
                            <Typography type="btn" $color={theme.colors.tertiary.tertiary50}>
                                {t('destinations:details.more')}
                                <FeatherIcon.ChevronRight size={20} strokeWidth={1} />
                            </Typography>
                        </a>
                    </Link>
                </ContainerListItems>
                {since && (
                    <Typography type="body2" $mt="10px">
                        {t('destinations:details.since')}
                    </Typography>
                )}
                <PricePerson>
                    <div>
                        {price && <Typography type="h5">${Math.round(price)}</Typography>}
                        {/* {personType && <Typography type="body2">/{personType[i18n.language]}</Typography>}
                        {previousPrice && (
                            <Typography type="body1">
                                <TextDecoration>${previousPrice}</TextDecoration>
                            </Typography>
                        )} */}
                    </div>
                    {button && <Button content={t('destinations:btn.reserve')} variant="filled" type="button" width="110px" />}
                </PricePerson>
            </DescriptionFrame>
        </Container>
    );
};

DestinationCardInformative.defaultProps = {
    typeIcon: 'bookmark',
    since: true,
    button: false,
};

DestinationCardInformative.propTypes = {
    since: PropTypes.bool,
    button: PropTypes.bool,
    experience: PropTypes.object,
    typeIcon: PropTypes.oneOf(['none', 'bookmark']),
};

export default DestinationCardInformative;
