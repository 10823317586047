import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';

// Atoms
import { Button, Dropdown, Typography } from 'components/atoms';

// Molecules
import NumberPicker from 'components/molecules/NumberPicker';

// Styles
import { colors } from 'styles/colors';
import { ButtonContainer, Divider, InfoContainer, RoomController, RoomInfo, List, ListItem, SelectorContainer } from './LodgingPicker.styles';

// Reducer Initial State
const INITIAL_STATE = {
    rooms: [{ id: 0, adults: 0, kids: 0, infants: 0 }],
};

const lodgingReducer = (state, action) => {
    switch (action.type) {
        case 'add_room':
            return { ...state, rooms: [...state.rooms, action.payload] };
        case 'remove_room':
            return { ...state, rooms: state.rooms.filter((_, index) => index !== action.index) };
        case 'update_room': {
            const newList = state.rooms.map((item, i) => {
                if (i === action.payload.index) {
                    return {
                        ...item,
                        [action.payload.field]: action.payload.value,
                    };
                }
                return item;
            });

            return { ...state, rooms: newList };
        }
        default:
            throw new Error();
    }
};

const LodgingPicker = ({ trigger }) => {
    // Hooks
    const { t } = useTranslation(); // Translations

    // State to manage passenger by rooms
    const [state, dispatch] = React.useReducer(lodgingReducer, INITIAL_STATE);

    // States close Dropdown
    const [closeDropdown, setCloseDropdown] = React.useState(false);

    // Close dropdown and reset state rooms
    const resetDropdown = () => {
        setCloseDropdown(true);
        // setRooms([{ roomId: 1, adults: 0, kids: 0, infant: 0 }]);
    };

    const addRoomHandler = (id) => {
        dispatch({
            type: 'add_room',
            payload: { id, adults: 0, kids: 0, infants: 0 },
        });
    };

    const removeRoomHandler = (index) => {
        dispatch({
            type: 'remove_room',
            index,
        });
    };

    const updateHandler = (field, value, index) => {
        dispatch({
            type: 'update_room',
            payload: { field, value, index },
        });
    };

    return (
        <Dropdown
            triggerLabel={t('common:picker.lodging')}
            trigger={trigger}
            closeDropdown={closeDropdown}
            setCloseDropdown={setCloseDropdown}
            alwaysVisible={state.rooms.length > 0}
        >
            <SelectorContainer>
                {state.rooms.map((room, index) => (
                    <React.Fragment key={`room${index + 1}`}>
                        <RoomInfo>
                            <Typography type="body1">
                                {t('common:room')} {`#${index + 1}`}
                            </Typography>
                            <RoomController>
                                {index > 0 && <FeatherIcon.Minus size={20} color={colors.black} onClick={() => removeRoomHandler(index)} />}
                                <FeatherIcon.Plus size={20} color={colors.black} onClick={() => addRoomHandler(state.rooms.length)} />
                            </RoomController>
                        </RoomInfo>
                        <List>
                            <ListItem>
                                <InfoContainer>
                                    <Typography type="body1">{t('common:passenger-picker.adult')}</Typography>
                                    <Typography type="cap">{t('common:passenger-picker.adult-info')}</Typography>
                                </InfoContainer>
                                <NumberPicker value={room.adults} setValue={(value) => updateHandler('adults', value, index)} />
                            </ListItem>
                            <ListItem>
                                <InfoContainer>
                                    <Typography type="body1">{t('common:passenger-picker.child')}</Typography>
                                    <Typography type="cap">{t('common:passenger-picker.child-info')}</Typography>
                                </InfoContainer>
                                <NumberPicker
                                    key={`kids${index + 1}`}
                                    value={room.kids}
                                    setValue={(value) => {
                                        updateHandler('kids', value, index);
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <InfoContainer>
                                    <Typography type="body1">{t('common:passenger-picker.infant')}</Typography>
                                    <Typography type="cap">{t('common:passenger-picker.infant-info')}</Typography>
                                </InfoContainer>
                                <NumberPicker
                                    key={`infants${index + 1}`}
                                    value={room.infants}
                                    setValue={(value) => {
                                        updateHandler('infants', value, index);
                                    }}
                                />
                            </ListItem>
                        </List>
                        <Typography type="body1">
                            {t('common:passenger-picker.total')} {room.adults + room.kids + room.infants}
                        </Typography>
                        {index !== state.rooms.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
                <ButtonContainer>
                    <Button content={t('common:cancel')} type="button" variant="outlined" onClick={() => resetDropdown()} />
                    <Button content={t('common:set')} type="button" variant="filled" />
                </ButtonContainer>
            </SelectorContainer>
        </Dropdown>
    );
};

LodgingPicker.propTypes = {
    trigger: PropTypes.node,
};

export default LodgingPicker;
