import React from 'react';
import Moment from 'react-moment';
import { useTranslation } from 'next-i18next';
import { Typography, Button } from '@/components/atoms';
import { PriceResumeCardProps, ResumeBlockProps, GroupedPassengers } from './PriceResumeCard.types';
import { Block, Row } from './PriceResumeCard.styles';

const ResumeBlock = <T,>({ title, items, mapItemToProps }: ResumeBlockProps<T>) => {
    return (
        <Block>
            {title && (
                <Typography variant="sub2" style={{ paddingBottom: '10px' }}>
                    {title}
                </Typography>
            )}
            {items?.map((item, index) => {
                const { left, right } = mapItemToProps(item);
                return (
                    <Row key={index}>
                        <Typography variant="body2">{left}</Typography>
                        <Typography variant="body2">{right}</Typography>
                    </Row>
                );
            })}
        </Block>
    );
};

const PriceResumeCard: React.FC<PriceResumeCardProps> = ({ flights, passengers, airFare, taxes, subtotalAndTaxes, total, disabled, hideButton = false, onAccept }) => {
    const { t } = useTranslation(); // Translations

    const groupedPassengers = passengers.reduce((groups: GroupedPassengers, passenger) => {
        const { type } = passenger;

        if (!groups[type]) {
            groups[type] = {
                type,
                total: 0,
            };
        }

        groups[type].total += 1;

        return groups;
    }, {});

    const passengersString = Object.values(groupedPassengers)
        .map((group) => t(`flights:passenger.${group.type}${group.total > 1 ? '_plural' : ''}`, { count: group.total }))
        .join(', ');

    return (
        <>
            <ResumeBlock
                title={t('flights:resume.flights')}
                items={flights}
                mapItemToProps={(item) => ({
                    left: (
                        <>
                            <span>
                                {item.origin.code} · <Moment format="HH:mm" parse="YYYY-MM-DDTHH:mm:ss" date={item.departingDatetime} />
                            </span>
                            {' - '}
                            <span>
                                {item.destination.code} · <Moment format="HH:mm" parse="YYYY-MM-DDTHH:mm:ss" date={item.arrivingDatetime} />
                            </span>
                        </>
                    ),
                    right: (
                        <>
                            <Moment format="ddd, DD MMM, YYYY">{item.departingDatetime}</Moment>
                        </>
                    ),
                })}
            />
            <ResumeBlock
                title={t('flights:resume.air-fare', {
                    passengers: passengersString,
                })}
                items={airFare}
                mapItemToProps={(item) => ({
                    left: item.name,
                    right: item.value,
                })}
            />
            <ResumeBlock
                title={t('flights:resume.taxes')}
                items={taxes}
                mapItemToProps={(item) => ({
                    left: item.name,
                    right: item.value,
                })}
            />
            <ResumeBlock
                items={subtotalAndTaxes}
                mapItemToProps={(item) => ({
                    left: item.name,
                    right: item.value,
                })}
            />
            <Row style={{ paddingTop: '0' }}>
                <Typography variant="h6">{t('flights:resume.total')}</Typography>
                <Typography variant="h6">{total}</Typography>
            </Row>
            {!hideButton && (
                <Row>
                    <Button
                        width="100%"
                        content={t('flights:resume.button.accept-continue')}
                        type="submit"
                        variant="filled"
                        style={{ marginTop: '24px' }}
                        disabled={disabled}
                        onClick={onAccept}
                    />
                </Row>
            )}
        </>
    );
};

export default PriceResumeCard;
