import React from 'react';
import { Typography, Checkbox } from '@/components/atoms';
import { FlightFilterProps } from './FlightFilters.types';
import { Wrapper, Header, Content, Item } from './FlightFilters.styles';


const FlightFilters: React.FC<FlightFilterProps> = ({ title, filters }) => {
    return (
        <Wrapper>
            <Header>
                <Typography variant="sub2" align="left">
                    {title}
                </Typography>
            </Header>
            <Content>
                {filters?.map((item) => {
                    return (
                        <Item key={item.id}>
                            <Checkbox
                                label={item.label}
                                checked={item.condition()}
                                value={item.id}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    item.onSelect(value);
                                }}
                            />
                        </Item>
                    );
                })}
            </Content>
        </Wrapper>
    );
};

export default FlightFilters;
