import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

// Styles
import { ImageGridContainer, Info } from './ImageGrid.styles';

const ImageGrid = ({ destinations, onClickImage }) => {
    const { mainImage, images } = destinations;

    if (mainImage && images) {
        const imagesCount = images.length;
        const moreImg = imagesCount - 2;

        return (
            <>
                {destinations && (
                    <ImageGridContainer imagesCount={imagesCount}>
                        <Info first onClick={() => onClickImage('0')} imagesCount={imagesCount}>
                            <Image src={mainImage?.url} alt={mainImage?.name} layout="fill" objectFit="cover" objectPosition="center center" />
                        </Info>
                        <Info onClick={() => onClickImage(1)} imagesCount={imagesCount}>
                            <Image src={images[0]?.url} alt={images[0]?.name} layout="fill" objectFit="cover" objectPosition="center center" />
                        </Info>
                        <Info containMore={moreImg > 1} onClick={() => onClickImage(2)} imagesCount={imagesCount}>
                            <Image src={images[1]?.url} alts={images[1]?.name} layout="fill" objectFit="cover" objectPosition="center center" />
                            {moreImg > 0 && <span className="others-img-btn">+{moreImg}</span>}
                        </Info>
                    </ImageGridContainer>
                )}
            </>
        );
    }
    return null;
};

ImageGrid.propTypes = {
    destinations: PropTypes.object,
    onClickImage: PropTypes.func,
    mainImage: PropTypes.array,
    images: PropTypes.array,
};

export default ImageGrid;
