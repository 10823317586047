import React from 'react';
import { useTheme } from 'styled-components';
import * as FeatherIcon from 'react-feather';
import { Typography } from '@/components/atoms';
import { ListItemLargeProps } from './ListItemLarge.types';
import { Item, WrapperText, IconContainer, Frame1 } from './ListItemLarge.styles';

const ListItemLarge: React.FC<ListItemLargeProps> = ({ id, variant, icon, primaryText, secondaryText, link }) => {
    const theme = useTheme(); // Get current theme

    return (
        <Item id={id} variant={variant}>
            <WrapperText>
                <IconContainer>{icon}</IconContainer>
                <Frame1>
                    <Typography variant="btn" color={theme.colors.basic.black}>
                        {primaryText}
                    </Typography>
                    <Typography variant="cap" color={theme.colors.basic.black}>
                        {secondaryText}
                    </Typography>
                </Frame1>
            </WrapperText>

            {link && (
                <IconContainer>
                    <FeatherIcon.ArrowRight size={20} color={theme.colors.neutral.neutral10} strokeWidth={2} />
                </IconContainer>
            )}
        </Item>
    );
};

export default ListItemLarge;
