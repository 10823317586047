import React from 'react';
import { AsteriskProps } from "./Asterisk.types";
import { RedAsterisk } from './Asterisk.styles';

const Asterisk: React.FC<AsteriskProps> = ({ text }) => {
    return (
        <>
            {text} <RedAsterisk>*</RedAsterisk>
        </>
    );
};

export default Asterisk;
