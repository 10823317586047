import React from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import UrlMapper from '@/config/url-mapper';
import { useWizard } from '@/modules/wizard';
import { Icon, Typography, Button } from '@/components/atoms';
import { FlightPaymentProps } from './FlightPayment.types';
import { InnerWrapper, Row } from './FlightPayment.styles';
import { GroupedPassengers } from '@/components/molecules/PriceResumeCard/PriceResumeCard.types';
import { extractTimeFromDatetime, formatDate } from '@/utils/helpers';

const FlightPayment: React.FC<FlightPaymentProps> = ({ passengers }) => {
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme
    const { state } = useWizard();

    const groupedPassengers = passengers?.reduce((groups: GroupedPassengers, passenger) => {
        const { type } = passenger;

        if (!groups[type]) {
            groups[type] = {
                type,
                total: 0,
            };
        }

        groups[type].total += 1;

        return groups;
    }, {});

    const passengersString =
        Object.values(groupedPassengers || [])
            .map((group) => t(`flights:passenger.${group.type}${group.total > 1 ? '_plural' : ''}`, { count: group.total }))
            .join(` ${t('flights:payment.and')} `) + '.';

    const outboundRouteSelectedString = `${t('flights:departure-flight.section.title')}: ${formatDate(state?.data?.outboundRouteSelected?.departingDatetime as string)} >> ${state
        ?.data?.outboundRouteSelected?.origin.code} · ${extractTimeFromDatetime(state?.data?.outboundRouteSelected?.departingDatetime as string)} - ${state?.data
        ?.outboundRouteSelected?.destination.code} · ${extractTimeFromDatetime(state?.data?.outboundRouteSelected?.arrivingDatetime as string)} | `;
    const returnRouteSelectedString = `${t('flights:return-flight.section.title')}: ${formatDate(state?.data?.returnRouteSelected?.departingDatetime as string)} >> ${state?.data
        ?.returnRouteSelected?.origin.code} · ${extractTimeFromDatetime(state?.data?.returnRouteSelected?.departingDatetime as string)} - ${state?.data?.returnRouteSelected
        ?.destination.code} · ${extractTimeFromDatetime(state?.data?.returnRouteSelected?.arrivingDatetime as string)}`;

    const whatsappMessage = `${t('flights:payment.button.contact-message')} ${passengersString} ${outboundRouteSelectedString} ${returnRouteSelectedString}`;

    return (
        <InnerWrapper>
            <Typography variant="h6">{t('flights:payment.message.title')}</Typography>
            <Typography variant="body2" style={{ paddingTop: '28px' }}>
                {t('flights:payment.message.description')}
            </Typography>
            <Row>
                <Button
                    as="a"
                    icon={<Icon.Agent size={20} color={theme.colors.primary.primary10} strokeWidth={2} />}
                    iconPosition="left"
                    content={t('flights:payment.button.contact-agent')}
                    type="button"
                    variant="filled"
                    role="button"
                    aria-label={t('flights:payment.button.contact-agent')}
                    target="_blank"
                    href={`${UrlMapper.whatsapp_agents}/?text=${whatsappMessage}`}
                    rel="noopener noreferrer"
                />
            </Row>
        </InnerWrapper>
    );
};

export default FlightPayment;
