import React from 'react';
import { useTranslation } from 'next-i18next';
import EsFlag from '@/assets/images/languages/es.svg';
import EnFlag from '@/assets/images/languages/en.svg';

const useLanguages = () => {
    const { t } = useTranslation(); // Translations

    return {
        languages: [
            {
                key: 'es',
                icon: <EsFlag />,
                code: 'ES',
                href: '/es',
                text: t('common:language.spanish'),
            },
            {
                key: 'en',
                icon: <EnFlag />,
                code: 'EN',
                href: '/en',
                text: t('common:language.english'),
            },
        ],
    };
};

export default useLanguages;
