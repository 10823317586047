import styled from 'styled-components';

export const WrapperInput = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    input {
        width: 100%;
        text-overflow: ellipsis;
    }
`;

export const FilterSuggestions = styled.div`
    position: absolute;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.basic.white};
    border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80};
    border-radius: 2px;
    margin-top: 55px;
    max-height: 200px;
    overflow: auto;
    padding-left: 0;
    z-index: 9997;

    .suggestion-active {
        background-color: ${({ theme }) => theme.colors.primary.primary80};
    }
`;

export const FilterNoSuggestions = styled.div`
    position: absolute;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.basic.white};
    border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80};
    border-radius: 2px;
    margin-top: 55px;
    z-index: 9997;
`;

export const ListOption = styled.li`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.body2.mobile.size};
    font-weight: ${({ theme }) => theme.font.body2.mobile.weight};
    line-height: ${({ theme }) => theme.font.body2.mobile.lineheight};
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    padding: 0.3rem;
    margin: 0;
    list-style: none;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.body2.desktop.size};
        font-weight: ${theme.font.body2.desktop.weight};
        line-height: ${theme.font.body2.desktop.lineheight};
    `}

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary.primary90};
        cursor: pointer;
    }
`;

export const TextNoSuggestions = styled.p`
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    color: ${({ theme }) => theme.colors.neutral.neutral20};
    padding-left: 0.5rem;

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
    `}
`;

export const ErrorBlock = styled.span`
    position: absolute;
    color: ${({ theme }) => theme.colors.error.error50};
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${({ theme }) => theme.font.cap.mobile.size};
    font-weight: ${({ theme }) => theme.font.cap.mobile.weight};
    line-height: ${({ theme }) => theme.font.cap.mobile.lineheight};
    background-color: transparent !important;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 110px;
    z-index: 9997;
    padding-left: 20px;
    text-align: left;
    -webkit-touch-callout: none; /* iOS Safari */ /* Safari */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    ${({ theme }) => theme.media.md`
        font-size: ${theme.font.cap.desktop.size};
        font-weight: ${theme.font.cap.desktop.weight};
        line-height: ${theme.font.cap.desktop.lineheight};
    `}

    ${({ theme }) => theme.media.lg`
        position: relative;
        margin-top: -35px;
    `}
`;
