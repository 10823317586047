import styled from 'styled-components';
import { fadeIn } from 'styles/animations';

export const Tabs = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 35px;
    max-width: 100%;
    list-style: none;
    justify-content: space-evenly;
    padding: 20px 0 0;

    li {
        padding: 5px 8px;

        h3 {
            cursor: pointer;
            transition: all 0.25s ease-in-out;
        }
    }

    ${({ theme }) => theme.media.sm`
        justify-content: center;
        max-width: 80%;

        li {
            padding-left: 37px;
        }
    `}
`;

export const CardGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    animation: ${fadeIn} 1s ease-in-out;
    margin-bottom: 50px;
    justify-items: center;

    ${({ theme }) => theme.media.md`
        grid-template-columns: repeat(2, 1fr);
    `}
    ${({ theme }) => theme.media.xl`
        grid-template-columns: repeat(3, 1fr);

    `}
        & + button {
        margin: 0 auto;
    }
`;

export const AlternativeDialog = styled.div`
    padding: 0 10px 50px;
    animation: ${fadeIn} 1s ease-in-out;
`;
