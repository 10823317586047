import styled from 'styled-components';

export const IconUserOptions = styled.div`
    color: ${({ theme }) => theme.colors.tertiary.tertiary50};
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    cursor: pointer;

    i::before {
        color: ${({ theme }) => theme.colors.tertiary.tertiary50};
    }

    i:hover {
        &::before {
            color: ${({ theme }) => theme.colors.primary.primary50};
        }
    }
`;

export const OptionsContainer = styled.ul`
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`;

export const Option = styled.li`
    width: 98%;
    padding: 10px 25px;
    white-space: nowrap;

    &:hover {
        background: ${({ theme }) => theme.colors.neutral.neutral96};
    }

    a {
        display: block;
    }
`;
