import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';
import { useTheme } from 'styled-components';

// Atoms
import { Typography } from 'components/atoms';

// Molecules
import { DestinationCardInformative, DestinationCard, ScrollCard } from 'components/molecules';

// Styles
import { Container, Heading } from './DestinationDetails.styles';

const DestinationDetails = ({ type, packages, children, mt }) => {
    // Hooks
    const theme = useTheme(); // Get current theme

    // Translations
    const { t } = useTranslation();

    const typeFilter = type !== 'hiking' && type !== 'tours' && type !== 'related' && type !== 'related-exper';

    return (
        <Container $mt={mt}>
            {children}
            {type && type !== 'related' && type !== 'related-exper' && type !== 'visited' && (
                <Heading align={typeFilter}>
                    <Typography type="h6" $size="20px">
                        {t(`destinations:details.${type}.title`)}
                    </Typography>
                    {typeFilter && packages.length > 3 && (
                        <Typography type="btn" $color={theme.colors.tertiary.tertiary50}>
                            {t('destinations:details.seeAll')}
                            <FeatherIcon.ChevronRight size={20} strokeWidth={1} />
                        </Typography>
                    )}
                </Heading>
            )}
            <ScrollCard $mt="4px">
                {React.Children.toArray(
                    packages?.map((experience) => (type !== 'related' ? <DestinationCardInformative experience={experience} /> : <DestinationCard card={experience} />))
                )}
            </ScrollCard>
        </Container>
    );
};

DestinationDetails.propTypes = {
    type: PropTypes.oneOf(['experiences', 'lodging', 'feeding', 'excursions', 'hiking', 'tours', 'related', 'related-exper', 'visited']),
    packages: PropTypes.array,
    children: PropTypes.node,
    mt: PropTypes.string,
};

export default DestinationDetails;
