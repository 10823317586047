import { gql } from '@apollo/client';
import { IMAGE_FRAGMENT } from './image.gql';

export const JOB_CATEGORY_FRAGMENT = gql`
    ${IMAGE_FRAGMENT}
    fragment JobCategoryType on JobCategoryType {
        id
        name
        description
        slug
        image {
            ...ImageType
        }
    }
`;

export const JOB_OFFER_FRAGMENT = gql`
    ${JOB_CATEGORY_FRAGMENT}
    fragment JobOfferType on JobOfferType {
        id
        name
        description
        workingTime
        workingMode
        location
        benefits
        functions
        skills
        category {
            ...JobCategoryType
        }
        slug
    }
`;
