import React from 'react';
import { useTranslation } from 'next-i18next';
import { formatCurrency } from '@/utils/helpers';
import { useFlightsPrice } from '@/services/flights';
import { PriceResumeCard } from '@/components/molecules';
import { useWizard } from '@/modules/wizard';
import { useFilterSearch } from '@/modules/persistent-states';
import { KiuRouteType } from '@/graphql/types';
import { FlightDetailsProps } from './FlightDetails.type';
import { Wrapper, InnerWrapper } from './FlightDetails.styles';

const FlightDetails: React.FC<FlightDetailsProps> = ({ children, hideAcceptButton = false }) => {
    const { t, i18n } = useTranslation(); // Translations
    const [filterSearch] = useFilterSearch();
    const { state } = useWizard();

    const passengers: {
        number: number;
        type: string;
    }[] = [];

    let count = 1;
    let adultCount = 0;
    let childrenCount = 0;

    // Add adults to the list
    for (let i = 1; i <= filterSearch.passengers?.adults; i++) {
        passengers.push({ number: count, type: 'Adult' });
        adultCount++;
        count++;
    }

    // Add children to the list
    for (let i = 1; i <= filterSearch.passengers?.children; i++) {
        passengers.push({ number: count, type: 'Child' });
        childrenCount++;
        count++;
    }

    const { prices } = useFlightsPrice({
        outboundDepartureDatetimes: [state.data?.outboundRouteSelected?.departingDatetime],
        outboundArrivalDatetimes: [state.data?.outboundRouteSelected?.arrivingDatetime],
        outboundFlightNumbers: state.data?.outboundRouteSelected?.flights.map((flight) => flight?.flightNumber) as string[],
        outboundResBookDesigCodes: state.data?.outboundRouteSelected?.categories as string[],
        outboundAirlineCodes: state.data?.outboundRouteSelected?.airlines as string[],
        outboundOriginLocationCodes: [state.data?.outboundRouteSelected?.origin.code] as string[],
        outboundDestinationLocationCodes: [state.data?.outboundRouteSelected?.destination.code] as string[],
        returnDepartureDatetimes: [state.data?.returnRouteSelected?.departingDatetime],
        returnArrivalDatetimes: [state.data?.returnRouteSelected?.arrivingDatetime],
        returnFlightNumbers: state.data?.returnRouteSelected?.flights.map((flight) => flight?.flightNumber) as string[],
        returnResBookDesigCodes: state.data?.returnRouteSelected?.categories as string[],
        returnAirlineCodes: state.data?.returnRouteSelected?.airlines as string[],
        returnOriginLocationCodes: [state.data?.returnRouteSelected?.origin.code] as string[],
        returnDestinationLocationCodes: [state.data?.returnRouteSelected?.destination.code] as string[],
        passengers: {
            adults: adultCount,
            children: childrenCount,
        },
    });

    return (
        <Wrapper>
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    passengers: passengers,
                    key: index,
                })
            )}
            <InnerWrapper>
                <PriceResumeCard
                    flights={[state?.data?.outboundRouteSelected as KiuRouteType, state?.data?.returnRouteSelected as KiuRouteType]}
                    passengers={passengers}
                    airFare={[
                        {
                            name: t('flights:resume.base-fare'),
                            value: formatCurrency(prices?.response?.baseFare?.amount as number, prices?.response?.baseFare.currency as string, i18n.language),
                        },
                    ]}
                    // taxes={
                    //     prices?.response?.taxes?.map((tax) => ({
                    //         name: tax?.code as string,
                    //         value: formatCurrency(tax?.price?.amount as number, tax?.price?.currency as string, i18n.language),
                    //     })) as {
                    //         name: string;
                    //         value: string;
                    //     }[]
                    // }
                    subtotalAndTaxes={[
                        {
                            name: t('flights:resume.subtotal'),
                            value: formatCurrency(prices?.response?.totalFare.amount as number, prices?.response?.totalFare.currency as string, i18n.language),
                        },
                        {
                            name: t('flights:resume.taxes'),
                            value: formatCurrency(prices?.response?.totalTaxes.amount as number, prices?.response?.totalTaxes.currency as string, i18n.language),
                        },
                        {
                            name: t('flights:resume.fee'),
                            value: formatCurrency(prices?.response?.fee.amount as number, prices?.response?.fee.currency as string, i18n.language),
                        },
                    ]}
                    total={formatCurrency(prices?.response?.total.amount as number, prices?.response?.total.currency as string, i18n.language)}
                    disabled={true}
                    hideButton={hideAcceptButton}
                    onAccept={() => {
                        // TODO: Agregar accion del boton
                        console.log('Accept and continue');
                    }}
                />
            </InnerWrapper>
        </Wrapper>
    );
};

export default FlightDetails;
