import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT } from '@/graphql/fragments';

export const VERIFY_DOCUMENT = gql`
    ${ERRORS_FRAGMENT}
    query VerifiedItineraryPdf($verificationCode: String!, $passport: String!, $recaptchaToken: String!) {
        verifiedItineraryPdf(verificationCode: $verificationCode, passport: $passport, recaptchaToken: $recaptchaToken) {
            status
            errors {
                ...ErrorType
            }
            documentUrl
        }
    }
`;
