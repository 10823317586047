import React from 'react';
import { useRouter } from 'next/router';
import { I18nContext, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useTheme } from 'styled-components';
import * as FeatherIcon from 'react-feather';
import { Typography, Dropdown } from '@/components/atoms';
import { LanguageSwitcherProps } from "./LanguageSwitcher.types";
import { LanguageContainer, LanguageSelector, Language } from './LanguageSwitcher.styles';

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ id, languages }) => {
    const { i18n } = React.useContext(I18nContext); // Translations Context
    const { t } = useTranslation(); // Translations
    const theme = useTheme(); // Get current theme
    const router = useRouter(); // Router

    const lng = languages.find((l) => l.key === i18n.language);

    return (
        <Dropdown
            id={id}
            triggerLabel={t('common:language-switcher.label')}
            trigger={
                <LanguageSelector>
                    {lng?.icon}
                    <Typography as="span" variant="sub2" color={theme.colors.tertiary.tertiary50}>
                        {lng?.code}
                    </Typography>
                    <FeatherIcon.ChevronDown size={20} color={theme.colors.tertiary.tertiary50} />
                </LanguageSelector>
            }
        >
            <LanguageContainer>
                {languages.map((lang) => {
                    const queryID = router?.query.slug !== undefined ? `/${router?.query.slug}` : '';

                    return (
                        <Language key={lang.key}>
                            {lang.icon}
                            <Link href={`${router?.pathname}${queryID}`} locale={lang.key} passHref>
                                <Typography as="span" variant="sub2">
                                    {lang?.text}
                                </Typography>
                            </Link>
                        </Language>
                    );
                })}
            </LanguageContainer>
        </Dropdown>
    );
};

export default LanguageSwitcher;
