import React from "react";
import ModalContext from "./ModalContext";
import Modal from "./Modal";

interface ModalProviderProps {
    children: React.ReactNode;
}

const ModalProvider: React.FC<ModalProviderProps> = ({ children }: ModalProviderProps) => {
    const [modal, setModal] = React.useState<React.ReactNode>();

    const unSetModal = React.useCallback(() => {
        setModal(null);
    }, []);

    const value = React.useMemo(() => ({ unSetModal, setModal }), [unSetModal, setModal]);

    return (
        <ModalContext.Provider value={value}>
            {children}
            {modal && <Modal onClose={() => unSetModal()}>{modal}</Modal>}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
