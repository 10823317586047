import styled from 'styled-components';

export const AvatarWrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${({ theme }) => theme.media.md`
         width: 780px;
    `}
`;
