import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import UrlMapper from '@/config/url-mapper';
import { Button, Typography } from '@/components/atoms';
import { JobOfferSummaryProps } from './JobOfferSummary.types';
import { Buttons, ItemWrapper, Wrapper } from './JobOfferSummary.styles';

const JobOfferSummary: React.FC<JobOfferSummaryProps> = ({ slug, workingTime, workingMode, location }) => {
    const { t } = useTranslation(); // Translations
    const router = useRouter(); // Router

    return (
        <Wrapper>
            <ItemWrapper>
                <Typography variant="sub1" weight={600} mb="6px">
                    {t('job-offers:job-details.label.workplace')}
                </Typography>
                <Typography variant="body2">{workingMode}</Typography>
            </ItemWrapper>
            <ItemWrapper>
                <Typography variant="sub1" weight={600} mb="6px">
                    {t('job-offers:job-details.label.location')}
                </Typography>
                <Typography variant="body2">{location}</Typography>
            </ItemWrapper>
            <ItemWrapper>
                <Typography variant="sub1" weight={600} mb="6px">
                    {t('job-offers:job-details.label.employment')}
                </Typography>
                <Typography variant="body2">{workingTime}</Typography>
            </ItemWrapper>
            <Buttons>
                <Button
                    content={t('job-offers:job-details.button.apply-vacancy')}
                    type="button"
                    variant="filled"
                    width="100%"
                    onClick={() => {
                        void router.push(UrlMapper.job_offers_apply.replace('[slug]', slug), undefined, { shallow: true });
                    }}
                />
                <Button
                    content={t('job-offers:job-details.button.other-vacancies')}
                    type="button"
                    variant="outlined"
                    icon="chevron"
                    iconPosition="left"
                    width="100%"
                    onClick={() => {
                        void router.push(UrlMapper.job_offers, undefined, { shallow: true });
                    }}
                />
            </Buttons>
        </Wrapper>
    );
};

export default JobOfferSummary;
