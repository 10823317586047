import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0;

    a:first-child {
        padding-left: 0 !important;
    }

    a:last-child {
        padding-right: 0 !important;
    }

    ${({ theme }) => theme.media.lg`
        gap: 20px;
    `}
`;

export const SocialItem = styled.a`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    i::before {
        color: ${({ theme }) => theme.colors.tertiary.tertiary50} !important;
    }

    i:hover::before {
        color: ${({ theme }) => theme.colors.primary.primary50} !important;
    }
`;
