// 👇️ Format date ex 00:50:00 to 50min
export const formatTime = (time: string): string => {
    const [hours, minutes] = time.split(':').map(Number);

    if (hours > 0) {
        let result = `${hours}h`;
        if (minutes > 0) {
            result += ` ${minutes}min`;
        }
        return result;
    } else {
        return `${minutes}min`;
    }
};

// 👇️ Format date ex 2023-01-13 (YYYY-MM-DD)
export const formatDate = (date: string): string => {
    const dateObj = new Date(date);
    const year = dateObj.toLocaleString('default', { year: 'numeric' });
    const month = dateObj.toLocaleString('default', { month: '2-digit' });
    const day = dateObj.toLocaleString('default', { day: '2-digit' });
    return [year, month, day].join('-');
};

export const extractTimeFromDatetime = (datetimeString: string): string => {
    try {
        const datetime = new Date(datetimeString);

        const hours = datetime.getHours();
        const minutes = datetime.getMinutes();

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    } catch (error) {
        return '';
    }
};
