import React from 'react';
import WizardContext from './WizardContext';
import usePersistentWizard from "./usePersistentWizardState";
import reducer from './WizardReducer';
import { WizardStep } from './Wizard.types';

const WizardProvider: React.FC<{ initialSteps: WizardStep[]; children: React.ReactNode }> = ({ initialSteps, children }) => {
    const [persistentState, setPersistentState] = usePersistentWizard();

    const [state, dispatch] = React.useReducer(reducer, {
        ...persistentState,
        steps: initialSteps,
    });

    React.useEffect(() => {
        if (state?.currentStep !== persistentState?.currentStep || state?.data !== persistentState?.data) {
            setPersistentState({
                currentStep: state.currentStep,
                data: state.data,
            });
        }
    }, [state, persistentState, setPersistentState]);

    return <WizardContext.Provider value={{ state, dispatch }}>{children}</WizardContext.Provider>;
};

export default WizardProvider;
