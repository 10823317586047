import { useTranslation } from 'next-i18next';
import { signOut } from 'next-auth/react';
import UrlMapper from '@/config/url-mapper';

const useUserMenu = () => {
    const { t } = useTranslation(); // Translations

    return {
        userMenu: [
            {
                key: 'login',
                text: t('user-menu:login'),
                href: UrlMapper.login,
                visible: (status: string) => status === 'unauthenticated',
            },
            {
                key: 'signup',
                text: t('user-menu:signup'),
                href: UrlMapper.signup,
                visible: (status: string) => status === 'unauthenticated',
            },
            {
                key: 'profile',
                color: 'info',
                text: t('user-menu:profile'),
                href: UrlMapper.profile,
                visible: (status: string) => status === 'authenticated',
            },
            {
                key: 'logout',
                text: t('user-menu:logout'),
                onClick: () => signOut(),
                visible: (status: string) => status === 'authenticated',
            },
        ],
    };
};

export default useUserMenu;
