import styled from 'styled-components';

export const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    margin: 40px 10px 0;

    ${({ theme }) => theme.media.md`
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: (2, 1fr);
    `}

    ${({ theme }) => theme.media.xl`
        grid-template-columns: repeat(4, 280px);
    `}
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 291px;
`;

export const IconContainer = styled.span`
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    margin: 0 auto 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 138px;
    height: 138px;
`;
