import React from 'react';
import { DispatchStateContext, GlobalStateContext } from './GlobalStateContext';

const useGlobalState = () => {
    const globalState = React.useContext(GlobalStateContext);
    const dispatchState = React.useContext(DispatchStateContext);

    if (globalState === undefined && dispatchState === undefined) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }

    return [globalState, dispatchState];
};

export default useGlobalState;
