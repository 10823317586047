import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import * as FeatherIcon from 'react-feather';

// Atoms
import { Typography, Checkbox, ProgressBar } from 'components/atoms';

// Styles
import { Wrapper, Header, Content, RightContent } from './SideFilter.styles';

const Check = ({ item }) => {
    // Translations
    const { i18n } = useTranslation();
    const [active, setActive] = React.useState(false);

    const handleChange = (event) => {
        setActive(event.target.checked);
    };

    return <Checkbox label={item.label[i18n.language]} checked={active} onChange={handleChange} onClick={() => setActive(!active)} />;
};

const SideFilter = ({ data, dropdown, progresBar, title }) => {
    const [isShow, setIsShow] = React.useState(true);
    // Translations
    const { t } = useTranslation();

    const icon = isShow ? <FeatherIcon.ChevronUp size={25} strokeWidth={2} /> : <FeatherIcon.ChevronDown size={25} strokeWidth={2} />;

    const rightHeader = dropdown ? icon : <Typography type="sub2">{t('flights:dateSelector.since')}</Typography>;
    return (
        <Wrapper>
            <Header onClick={() => setIsShow(!isShow)}>
                <Typography type="sub2">{title}</Typography>
                {rightHeader}
            </Header>
            {isShow && (
                <Content>
                    {React.Children.toArray(
                        data.map((item) => (
                            <li>
                                <Check item={item} />
                                <RightContent>
                                    {progresBar && <ProgressBar progress={3.8} total={5} width="171px" />}
                                    <Typography type="body2" $ml="15px">
                                        {item.value}
                                    </Typography>
                                </RightContent>
                            </li>
                        ))
                    )}
                </Content>
            )}
        </Wrapper>
    );
};

SideFilter.propTypes = {
    data: PropTypes.array,
    dropdown: PropTypes.bool,
    progresBar: PropTypes.bool,
    title: PropTypes.string,
};

Check.propTypes = {
    item: PropTypes.object,
};

export default SideFilter;
