import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as FeatherIcon from 'react-feather';
import UrlMapper from '@/config/url-mapper';
import * as colors from '@/styles/colors';
import { capitalize } from '@/utils/helpers';
import { useCities } from '@/services/cities';
import { useWizard } from '@/modules/wizard';
import { FilterSearchType, useFilterSearch, useTripType } from '@/modules/persistent-states';
import type { TripType } from '@/modules/persistent-states';
import { Typography, Textfield, Button } from '@/components/atoms';
import { Datepicker } from '@/components/molecules';
import { PassengerPicker } from '@/components/organisms';

import {
    WrapperContainer,
    CustomForm,
    ElementDatePicker,
    Row1,
    Row2,
    ElementFilter,
    ElementButton,
    ElementPassenger,
    InvertButton,
    Selector,
    OptionContainer,
    Option,
    ContainerDropdown,
} from './FlightsForm.styles';

const Dropdown = dynamic(() => import('@/components/atoms/Dropdown'), {
    ssr: false,
});

interface FlightsFormProps {
    refetching?: boolean;
}

const FlightsForm: React.FC<FlightsFormProps> = ({ refetching }) => {
    const { t } = useTranslation();
    const router = useRouter(); // Router
    const [rotation, setRotation] = React.useState<number>(0);
    const [focused, setFocused] = React.useState<string>('');

    const { dispatch } = useWizard();
    const [tripType, tripTypeName, tripTypes, setTripType] = useTripType();
    const [filterSearch, setFilterSearch] = useFilterSearch();
    const { cities } = useCities();

    const citiesOptions =
        cities &&
        cities.map((city) => ({
            key: city.code,
            value: `${city.code} - ${capitalize(city.name)}, ${capitalize(city.country.name)}`,
        }));

    // Form initial values
    const initialValues = {
        ...filterSearch,
    };

    // Validation schema
    const validationSchema = Yup.object({
        origin: Yup.string().required(t('filters:tab.flights.origin.required')),
        destination: Yup.string()
            .required(t('filters:tab.flights.destination.required'))
            .test('not-equal', t('filters:tab.flights.destination.not_equal'), function (value) {
                return value !== (this.parent as { origin: string }).origin;
            }),
        departure_date: Yup.date().nullable().required(t('filters:tab.flights.departure_date.required')),
        return_date: Yup.date()
            .nullable()
            .test('conditional', t('filters:tab.flights.return_date.required'), (value) => {
                return !(tripType === 'round-trip' && !value);
            }),
        passengers: Yup.object().test('at-least-one-passenger', t('filters:tab.flights.passengers.required'), function (value) {
            const { adults, children } = value as { adults: number; children: number };
            return adults !== 0 || children !== 0;
        }),
    });

    const handleSearch = async (values: FilterSearchType) => {
        setFilterSearch({ ...values });
        dispatch({
            type: 'REMOVE_DATA',
        });

        dispatch({
            type: 'GO_TO_STEP',
            payload: 0,
        });

        if (!refetching) {
            void router.push(
                {
                    pathname: UrlMapper.flights,
                },
                undefined,
                { shallow: true }
            );
        }
    };

    const handleClickTripType = (selected: string) => {
        setTripType(selected as TripType);
    };

    return (
        <WrapperContainer>
            <ContainerDropdown>
                <Dropdown
                    id="travel-type"
                    triggerLabel={t('filters:tab.flights.travel-type')}
                    trigger={
                        <Selector>
                            <Typography variant="body2">{tripTypeName}</Typography>
                            <FeatherIcon.ChevronDown size={20} strokeWidth={2} color={colors.neutral.neutral10} />
                        </Selector>
                    }
                    borderRadius="10px"
                >
                    <OptionContainer>
                        {Object.entries(tripTypes).map(([key, name]) => {
                            return (
                                <Option key={key} isActive={key === tripType} onClick={() => handleClickTripType(key)}>
                                    {key === tripType && <FeatherIcon.Check size={20} strokeWidth={2} color={colors.neutral.neutral10} />}
                                    <Typography variant="body2" as="span">
                                        {name}
                                    </Typography>
                                </Option>
                            );
                        })}
                    </OptionContainer>
                </Dropdown>
            </ContainerDropdown>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSearch}>
                {({ errors, values, touched, setFieldValue, setFieldTouched, handleBlur, handleChange }) => {
                    const invertOriginDestination = () => {
                        if (values.origin && values.destination) {
                            setRotation((prevRotation) => prevRotation + 180);
                            void setFieldTouched('origin', false);
                            void setFieldTouched('destination', false);
                            void setFieldValue('origin', values?.destination);
                            void setFieldValue('destination', values?.origin);
                        }
                    };

                    const departureDate = values?.departure_date ? new Date(values?.departure_date) : null;
                    const returnDate = values?.return_date ? new Date(values?.return_date) : null;

                    const totalPassengers = values?.passengers?.adults + values?.passengers?.children;

                    let textPassengers = '';
                    switch (totalPassengers) {
                        case 0:
                            textPassengers = '';
                            break;
                        case 1:
                            textPassengers = t('filters:tab.flights.passengers.total-passengers', { count: totalPassengers });
                            break;
                        default:
                            textPassengers = t('filters:tab.flights.passengers.total-passengers_plural', { count: totalPassengers });
                            break;
                    }

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    React.useEffect(() => {
                        if (tripType === 'one-way') {
                            void setFieldValue('return_date', '');
                        }
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                    }, [tripType]);

                    return (
                        <CustomForm>
                            <Row1>
                                <ElementFilter>
                                    <Textfield
                                        id="origin"
                                        type="text"
                                        value={values?.origin}
                                        label={t('filters:tab.flights.origin.label')}
                                        placeholder={t('filters:tab.flights.origin.placeholder')}
                                        dataSuggestions={citiesOptions}
                                        requireValueInSuggestions
                                        showClearIcon={!!values?.origin}
                                        onClearIcon={() => {
                                            void setFieldValue('origin', '');
                                        }}
                                        onChange={handleChange}
                                        onFocus={() => {
                                            setFocused('origin');
                                        }}
                                        onBlur={(e) => {
                                            setFocused('');
                                            handleBlur(e);
                                        }}
                                        errors={touched.origin && errors.origin}
                                        success={(touched.origin || values?.origin !== '') && !errors.origin}
                                        className={values?.origin && focused === 'origin' ? 'focused' : ''}
                                    />
                                    <InvertButton type="button" tabIndex={-1} onClick={invertOriginDestination} rotation={rotation} disabled={tripType === 'one-way'}>
                                        <FeatherIcon.Repeat size={26} color={colors.basic.black} strokeWidth={3} />
                                    </InvertButton>
                                    <Textfield
                                        id="destination"
                                        type="text"
                                        value={values?.destination}
                                        label={t('filters:tab.flights.destination.label')}
                                        placeholder={t('filters:tab.flights.destination.placeholder')}
                                        dataSuggestions={citiesOptions}
                                        requireValueInSuggestions
                                        showClearIcon={!!values?.destination}
                                        onClearIcon={() => {
                                            void setFieldValue('destination', '');
                                        }}
                                        onChange={handleChange}
                                        onFocus={() => {
                                            setFocused('destination');
                                        }}
                                        onBlur={(e) => {
                                            setFocused('');
                                            handleBlur(e);
                                        }}
                                        errors={touched.destination && errors.destination}
                                        success={(touched.destination || values?.destination !== '') && !errors.destination}
                                        className={values?.destination && focused === 'destination' ? 'focused' : ''}
                                    />
                                </ElementFilter>
                            </Row1>
                            <Row2>
                                <ElementDatePicker>
                                    <Datepicker
                                        id="departure_date"
                                        dateFormat="dd/MM/yyyy"
                                        selected={departureDate}
                                        label={t('filters:tab.flights.departure_date.label')}
                                        placeholder={t('filters:tab.flights.departure_date.placeholder')}
                                        leftIcon={<FeatherIcon.Calendar />}
                                        borderRadius="24px 0 0 24px"
                                        widthContainer="210px"
                                        minDate={new Date(new Date().setHours(new Date().getHours() + 48))}
                                        maxDate={values?.return_date ? new Date(values?.return_date) : null}
                                        selectsStart={tripType === 'round-trip'}
                                        startDate={tripType === 'round-trip' ? departureDate : null}
                                        endDate={tripType === 'round-trip' ? returnDate : null}
                                        errors={touched.departure_date && errors.departure_date}
                                        success={(touched.departure_date || values?.departure_date !== '') && !errors.departure_date}
                                        onChange={(date) => void setFieldValue('departure_date', date)}
                                        onBlur={handleBlur}
                                        showClearIcon={!!values?.departure_date}
                                        onClearIcon={() => {
                                            void setFieldValue('departure_date', '');
                                        }}
                                    />
                                    <Datepicker
                                        id="return_date"
                                        dateFormat="dd/MM/yyyy"
                                        selected={returnDate}
                                        label={t('filters:tab.flights.return_date.label')}
                                        placeholder={t('filters:tab.flights.return_date.placeholder')}
                                        leftIcon={<FeatherIcon.Calendar />}
                                        borderRadius="0 24px 24px 0"
                                        widthContainer="210px"
                                        disabled={tripType === 'one-way'}
                                        selectsEnd
                                        startDate={departureDate}
                                        endDate={returnDate}
                                        minDate={departureDate || new Date()}
                                        errors={touched.return_date && errors.return_date}
                                        success={(touched.return_date || values?.return_date !== '') && !errors.return_date}
                                        onChange={(date) => void setFieldValue('return_date', date)}
                                        onBlur={handleBlur}
                                        showClearIcon={!!values?.return_date}
                                        onClearIcon={() => {
                                            void setFieldValue('return_date', '');
                                        }}
                                    />
                                </ElementDatePicker>
                                <ElementPassenger>
                                    <PassengerPicker
                                        id="passengers-picker"
                                        trigger={
                                            <Textfield
                                                id="passengers"
                                                type="text"
                                                label={t('filters:tab.flights.passengers.label')}
                                                placeholder={t('filters:tab.flights.passengers.placeholder')}
                                                leftIcon={<FeatherIcon.Users />}
                                                editable={false}
                                                mb="1px"
                                                value={textPassengers}
                                                errors={touched.passengers && errors.passengers}
                                                success={(touched.passengers || values?.passengers !== null) && !errors.passengers}
                                            />
                                        }
                                        passengers={values?.passengers}
                                        setPassengers={(passengers) => void setFieldValue('passengers', passengers)}
                                    />
                                </ElementPassenger>
                                <ElementButton>
                                    <Button
                                        className="compact"
                                        content={<FeatherIcon.Search size={24} color={colors.basic.black} strokeWidth={2} />}
                                        type="submit"
                                        width="48px"
                                        variant="filled"
                                        selected
                                        rounded
                                    />
                                    <Button
                                        className="full"
                                        icon={<FeatherIcon.Search size={24} color={colors.basic.black} strokeWidth={2} />}
                                        iconPosition="left"
                                        content={t('filters:tab.flights.search')}
                                        type="submit"
                                        width="100%"
                                        variant="filled"
                                    />
                                </ElementButton>
                            </Row2>
                        </CustomForm>
                    );
                }}
            </Formik>
        </WrapperContainer>
    );
};

export default FlightsForm;
