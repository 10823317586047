import { css, RuleSet } from "styled-components";
import { Interpolation, Styles } from "styled-components/dist/types";

const BREAKPOINTS: { [key: string]: number } = {
    xs: 360,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

type MediaQueries = {
    [key in keyof typeof BREAKPOINTS]: (
        styles: Styles<object>,
        ...interpolations: Interpolation<object>[]
    ) => RuleSet<object>;
};

const mediaQueries: MediaQueries = Object.keys(BREAKPOINTS).reduce((acc, label) => {
    acc[label] = (styles: Styles<object>, ...interpolations: Interpolation<object>[]) => css`
        @media (min-width: ${BREAKPOINTS[label]}px) {
          ${css(styles, ...interpolations)};
        }
  `;

    return acc;
}, {} as MediaQueries);

export default mediaQueries;
