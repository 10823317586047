import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, PAGE_INFO_FRAGMENT, CITY_FRAGMENT } from '@/graphql/fragments';

export const CITIES_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    ${CITY_FRAGMENT}
    query Cities($skip: Int, $take: Int, $report: Boolean) {
        cities(report: $report, skip: $skip, take: $take) {
            status
            pageInfo {
                ...PageInfoType
            }
            errors {
                ...ErrorType
            }
            objects {
                ...CityType
            }
        }
    }
`;
