import styled from 'styled-components';

export const FlightContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.neutral.neutral98};
    border-radius: 25px;
    padding: 28px;
    gap: 20px;
`;

export const Divider = styled.hr`
    width: 100%;
    height: 0;
    border: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};
`;

export const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const IconContainer = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ color }) => color};
`;
