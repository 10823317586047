import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { SOCIAL_QUERY } from "@/graphql/queries";
import { SocialMediaQuery, SocialMediaType } from "@/graphql/types";

export const useSocialLinks = () => {
    const { i18n } = useTranslation(); // Translations

    const { data, loading, error } = useQuery<SocialMediaQuery>(SOCIAL_QUERY, {
        variables: {
            report: true,
        },
        context: { headers: { "X-Language": i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    return { social: data?.socialMedia?.media as SocialMediaType, loading, error };
};
