import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// atoms components
import { Textfield } from 'components/atoms';

// Utils
import UrlMapper from 'utils/url-mapper';

// Styles
import { WrapperInput, FilterSuggestions, FilterNoSuggestions, TextNoSuggestions, ListOption, ErrorBlock } from './FilterInput.styles';

const FilterInput = ({
    items,
    id,
    type,
    label,
    placeholder,
    borderRadius,
    value,
    setFieldValue,
    setFieldTouched,
    errors,
    success,
    onBlur,
    errorMatched,
    mb,
    valueOrigin,
    valueDestiny,
    leftIcon,
}) => {
    const { t, i18n } = useTranslation();
    // Hooks
    const ref = React.useRef(null); // Reference
    const router = useRouter(); // Router

    // The active selection's index
    const [activeSuggestion, setActiveSuggestion] = React.useState(0);

    // The suggestions that match the value's input
    const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);

    // Whether or not the suggestion list is shown
    const [showSuggestions, setShowSuggestions] = React.useState(false);

    // var for no-suggestions
    let noSuggestions = false;

    const onChange = (e) => {
        const valueIn = e.currentTarget.value;
        let listFilter;

        if (id === 'origin') {
            let listOrig;

            if (valueDestiny === '') {
                listOrig = items?.map((item) => `${item.code} - ${item.name} - ${item.country.name}`);
            } else {
                listOrig = items?.map((item) => (item.code !== valueDestiny.slice(0, 3) ? `${item.code} - ${item.name} -  ${item.country.name} ` : ''));
            }
            listFilter = listOrig?.filter((valor, indice) => {
                return valor !== '' && listOrig.indexOf(valor) === indice;
            });
        }
        if (id === 'destiny') {
            let listDest;
            if (valueOrigin === '') {
                listDest = items?.map((item) => `${item.code} - ${item.country.name} `);
            } else {
                listDest = items?.map((item) => (item.code !== valueOrigin.slice(0, 3) ? `${item.code} - ${item.name} - ${item.country.name} ` : ''));
            }

            listFilter = listDest?.filter((valor, indice) => {
                return valor !== '' && listDest.indexOf(valor) === indice;
            });
        }

        if (id === 'search') {
            listFilter = items?.map((item) => `${item.destination}, ${item.city.country.name}`);
        }

        // Filter our suggestions that don't contain the input
        const filter = listFilter?.filter((item) => item.toLowerCase().indexOf(valueIn.toLowerCase()) > -1);

        setActiveSuggestion(0);
        setFilteredSuggestions(filter);
        setShowSuggestions(true);
        if (id === 'origin' || id === 'destiny') setFieldValue(id, e.currentTarget.value);
        if (id === 'search') setFieldValue(e.currentTarget.value);
    };

    const saveLocalStorage = (destinys) => {
        const storage = JSON.parse(localStorage.getItem('search-destiny'));

        if (!storage) {
            localStorage.setItem('search-destiny', JSON.stringify([destinys]));
        } else {
            const exist = storage.find((item) => item.id === destinys.id);
            if (!exist) {
                storage.unshift(destinys);
                localStorage.setItem('search-destiny', JSON.stringify(storage));
            }
        }
    };

    const onClick = (e) => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        if (id === 'origin' || id === 'destiny') setFieldValue(id, e.currentTarget.innerText);
        if (id === 'search') {
            const values = e.currentTarget.innerText;
            setFieldValue(values);
            const data = values.split(',')[0];
            const destiny = items.find((item) => item.destination === data);
            saveLocalStorage(destiny);
            router.push(`${UrlMapper.destinations[i18n.language]}/${destiny.id}`, undefined, { shallow: true });
        }
    };

    const onKeyDown = (e) => {
        // User pressed the enter key
        if (e.keycode === 13) {
            setActiveSuggestion(0);
            setShowSuggestions(false);
            if (id === 'origin' || id === 'destiny') setFieldValue(id, filteredSuggestions[activeSuggestion]);
            if (id === 'search') {
                const values = filteredSuggestions[activeSuggestion];
                setFieldValue(values);
                const data = values.split(',')[0];
                const destiny = items.find((item) => item.textDestiny === data);
                saveLocalStorage(destiny);
                router.push(`${UrlMapper.destinations[i18n.language]}/${destiny.id}`, undefined, { shallow: true });
            }
        }

        // User pressed the up arrow
        else if (e.keycode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1);
        }
        // User pressed the down arrow
        else if (e.keycode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            setActiveSuggestion(activeSuggestion + 1);
        }
    };

    const outsideClickHandler = (event) => {
        // close if click outside the popup
        if (ref.current && !ref.current.contains(event.target)) {
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            if (id === 'origin' || id === 'destiny') setFieldValue(id, '');
            if (id === 'search') setFieldValue('');
        }
    };

    // useEffect for event listener
    React.useEffect(() => {
        // Adding a click event listener
        document.addEventListener('click', outsideClickHandler);

        // Removing a click event listener
        return () => document.removeEventListener('click', outsideClickHandler);
    }, []);

    let suggestionsListComponent;

    if (showSuggestions && value) {
        if (filteredSuggestions?.length) {
            noSuggestions = false;
            suggestionsListComponent = (
                <FilterSuggestions ref={ref}>
                    {filteredSuggestions?.map((suggestion, index) => {
                        let classN;

                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                            classN = 'suggestion-active';
                        }

                        return (
                            <ListOption
                                className={classN}
                                value="listoption"
                                key={suggestion}
                                onClick={onClick}
                                dangerouslySetInnerHTML={{ __html: `${suggestion.replace(new RegExp(`(${value})`, 'i'), '<b>$1</b>')}` }}
                            />
                        );
                    })}
                </FilterSuggestions>
            );
        } else {
            noSuggestions = true;
            suggestionsListComponent = (
                <FilterNoSuggestions>
                    <TextNoSuggestions>{t('common:filter-no.suggestions')}</TextNoSuggestions>
                </FilterNoSuggestions>
            );
        }
    }

    return (
        <WrapperInput>
            <Textfield
                id={id}
                type={type}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={value}
                label={label}
                leftIcon={leftIcon}
                showClearIcon={!!value}
                onClearIcon={() => {
                    if (id === 'origin' || id === 'destiny') {
                        setFieldValue(id, '');
                        setFieldTouched(id, false);
                    }
                    if (id === 'search') {
                        setFieldValue('');
                    }
                }}
                mb={mb}
                errors={errors}
                success={success}
                onBlur={(e) => {
                    if (id === 'origin' || id === 'destiny') {
                        onBlur(e);
                        if (noSuggestions) setFieldValue(id, '');
                    }
                }}
                placeholder={placeholder}
                borderRadius={borderRadius}
            />
            {suggestionsListComponent}

            {errorMatched === true && <ErrorBlock>{t(`filters:filter-error.${id}`)}</ErrorBlock>}
        </WrapperInput>
    );
};

FilterInput.propTypes = {
    items: PropTypes.array,
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    borderRadius: PropTypes.string,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    errorMatched: PropTypes.bool,
    errors: PropTypes.any,
    success: PropTypes.bool,
    onBlur: PropTypes.func,
    mb: PropTypes.string,
    valueOrigin: PropTypes.string,
    valueDestiny: PropTypes.string,
    leftIcon: PropTypes.object,
};

export default FilterInput;
