import React from 'react';
import { Typography } from '@/components/atoms';
import { AdvantagesProps } from './Advantages.types';
import { CardContainer, GridWrapper, IconContainer } from './Advantages.styles';

const Advantages: React.FC<AdvantagesProps> = ({ cards }) => {
    return (
        <GridWrapper>
            {cards.map((card) => (
                <CardContainer key={card.title}>
                    <IconContainer>{card.icon}</IconContainer>
                    <Typography as="h3" variant="h5" align="center">
                        {card.title}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {card.text}
                    </Typography>
                </CardContainer>
            ))}
        </GridWrapper>
    );
};

export default Advantages;
