import styled, { css } from 'styled-components';

export const DateContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['height', 'width'].includes(prop),
})<{ height?: string | number; width?: string | number }>`
    width: 100%;
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height || '80px')};

    ${({ theme, width }) => theme.media.lg`
       width: ${typeof width === 'number' ? `${width}px` : width};
       display: flex;
       flex-direction: column;
       align-items: center;
    `}
`;

export const ContHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 14px;
`;

export const WrapperSelect = styled.div`
    width: 180px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.basic.white};
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:hover {
        cursor: pointer;
    }
`;

export const ButtonHeader = styled.button`
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 24px;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
`;

export const TextSelect = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

export const ContainerSelect = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{ show: boolean }>`
    display: none;
    height: 258px;
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};
    width: 100%;
    border-radius: 0 0 25px 25px;

    ${({ show }) =>
        show &&
        css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
        `}
`;

export const Chevron = styled.span.withConfig({
    shouldForwardProp: (prop) => !['opened'].includes(prop),
})<{ opened: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in;
    transform: rotate(${({ opened }) => (opened === true ? '-180deg' : 0)});
`;

export const YearMonthSelect = styled.div`
    display: flex;
    width: 135px;
    height: 238px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 15px;
`;

export const WrapperColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const IconCell = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 5px;
    width: 60px;
    height: 190px;
    overflow: hidden;
    box-sizing: border-box;

    /* Chrome, Opera, Safari, Edge */

    & ::-webkit-scrollbar {
        display: none;
    }

    .selected {
        border-radius: 18px;
        background-color: ${({ theme }) => theme.colors.primary.primary60};
    }
`;

export const Cell = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 6px 10px;
    gap: 10px;
    width: 60px;
    height: 36px;
    user-select: none;

    &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.colors.neutral.neutral90};
        border-radius: 18px;
    }
`;
