import styled, { css } from 'styled-components';
import { fadeIn } from '@/styles/animations';

export const ButtonContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{ show: boolean }>`
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 148px;
    gap: 10px;
    bottom: 79px;
    right: 12px;
    left: calc(100% - 160px);
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    animation: ${fadeIn} 0.3s ease-in-out;

    ${({ show }) =>
        show &&
        css`
            opacity: 1;
            z-index: 777;
        `}
`;

export const Tooltips = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{ show: boolean }>`
    display: flex;
    align-self: stretch;
    position: relative;
    width: 148px;
    height: 76px;
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    border-radius: 10px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    pointer-events: none;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);

    &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 76px;
        left: 45%;
        border-top: 7px solid ${({ theme }) => theme.colors.primary.primary50};
        border-right: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid transparent;
    }

    ${({ show }) =>
        show &&
        css`
            opacity: 1;
            pointer-events: visible;
        `}
`;

export const CloseTooltips = styled.button`
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    position: absolute;
    z-index: 9998;
    top: 5px;
    right: 2px;
    cursor: pointer;
`;
