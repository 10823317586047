import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT } from '@/graphql/fragments';

export const CONTACT_US = gql`
    ${ERRORS_FRAGMENT}
    mutation SendContactUs($name: String!, $email: String!, $subject: String!, $message: String!, $recaptchaToken: String!) {
        sendContactMessage(input: { name: $name, email: $email, subject: $subject, message: $message }, recaptchaToken: $recaptchaToken) {
            status # Int
            errors {
                ...ErrorType
            }
            contactMessage {
                name
                email
                subject
                message
                sent
                createdAt
            }
        }
    }
`;
