import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['active'].includes(prop),
})<{ active: boolean }>`
    display: flex;
    padding: 40px 30px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    align-self: stretch;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.colors.neutral.neutral98};

    ${({ active }) =>
        active &&
        css`
            outline: 2px solid ${({ theme }) => theme.colors.primary.primary50};
        `}
`;

export const Route = styled.div`
    display: flex;
    flex-flow: row;
    width: 100%;
`;

export const Details = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
`;

export const Separator = styled.div`
    background-color: ${({ theme }) => theme.colors.neutral.neutral90};
    height: 1px;
    width: 100%;
    margin: 20px 0 0;
`;

export const Departure = styled.div`
    padding: 0 30px 0 0;
`;

export const Scale = styled.div`
    width: 100%;
`;

export const Arrival = styled.div`
    padding: 0 0 0 30px;
`;
