import styled from 'styled-components';
import { Form } from 'formik';

export const CustomForm = styled(Form)`
    width: 100%;

    h2 {
        margin: 0 auto 40px;
    }
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.media.md`
        flex-direction: row;
        gap: 20px;
    `}
`;

export const Col = styled.div`
    width: 100%;
    flex-direction: row;

    ${({ theme }) => theme.media.md`
        flex-direction: column;
    `}
`;

export const Buttons = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 75px;
    gap: 5px;

    button {
        flex-basis: 100%;
    }

    ${({ theme }) => theme.media.md`
        gap: 20px;
       
        button {
            flex-basis: 180px;
        }
    `}
`;
