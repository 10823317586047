import React from 'react';
import SessionCookieContext from './SessionCookieContext';

const useSessionCookie = () => {
    const context = React.useContext(SessionCookieContext);
    if (context === undefined) {
        throw new Error('useSessionCookie should be used within a SessionCookieProvider');
    }
    return context;
};

export default useSessionCookie;
