import styled, { css } from 'styled-components';

export const Page = styled.div.withConfig({
    shouldForwardProp: (prop) => !['showing'].includes(prop),
})<{ showing: boolean }>`
    position: relative;
    opacity: 1;
    transition: all 1s ease-in-out 2s;

    ${({ showing }) =>
        !showing &&
        css`
            display: none;
            opacity: 0;
        `}
`;

export const ModalBanner = styled.div`
    position: relative;
    width: 95vw;
    height: 60vh;
`;
