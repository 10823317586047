import React from 'react';
import { Typography } from '@/components/atoms';
import { TabHeaderProps, TabPanelProps, TabProps, TabType } from './Tab.types';
import { Wrapper, TabContainer, TabItem, IconContainer, ShowText, Box } from './Tab.styles';

export const Tab: React.FC<TabProps> = ({ children, onTabSelected, variant, changeSection, link, observer }) => {
    const [selectedTab, setSelectedTab] = React.useState<number>(0);

    React.useEffect(() => {
        if (!link && onTabSelected) {
            onTabSelected(selectedTab);
        }
        if (link && observer && observer >= 0) setSelectedTab(observer);
    }, [link, observer, onTabSelected, selectedTab]);

    return (
        <Wrapper variant={variant} link={link}>
            <TabContainer variant={variant}>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child as React.ReactElement, {
                        onClick: () => {
                            setSelectedTab(index);
                            if (link && changeSection) {
                                changeSection(index + 1);
                            }
                        },
                        selected: selectedTab === index,
                        variant,
                        link,
                    });
                })}
            </TabContainer>
        </Wrapper>
    );
};

export const TabHeader: React.FC<TabHeaderProps> = React.memo(({ icon, title, children, variant, selected, link, ...restProps }) => {
    const [isShown, setIsShown] = React.useState(false);

    const handleMouseInOut = (isMouseOver: boolean | ((prevState: boolean) => boolean)) => {
        setIsShown(isMouseOver);
    };

    const renderPrimaryTab = () => (
        <>
            <IconContainer isShown={isShown} isSelected={selected}>
                {icon}
            </IconContainer>
            <ShowText isShown={isShown} isSelected={selected}>
                <Typography variant="sub1" align="center">
                    {title}
                </Typography>
            </ShowText>
        </>
    );

    const renderSecondaryTab = () => (
        <Typography variant="sub1" align="center">
            {title}
        </Typography>
    );

    return (
        <>
            {variant === 'primary' ? (
                <TabItem {...restProps} variant={variant} selected={selected} onMouseEnter={() => handleMouseInOut(true)} onMouseLeave={() => handleMouseInOut(false)}>
                    {renderPrimaryTab()}
                </TabItem>
            ) : (
                <TabItem {...restProps} variant={variant} selected={selected}>
                    {renderSecondaryTab()}
                </TabItem>
            )}

            {!link && children}
        </>
    );
});

TabHeader.displayName = 'TabHeader';

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 300);
    }, []);


    return (
        <Box role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} isSelected={value === index} isLoaded={isLoaded} {...other}>
            {value === index && children}
        </Box>
    );
};

Tab.defaultProps = {
    variant: 'primary',
    link: false,
};

export type { TabType };
