import React from 'react';
import { Typography } from '@/components/atoms';
import { Wrapper } from './JobOfferRequirement.styles';
import { JobOfferRequirementProps } from './JobOfferRequirement.types';

const JobOfferRequirement: React.FC<JobOfferRequirementProps> = ({ title, content }) => {
    return (
        <Wrapper>
            <Typography
                variant="h2"
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
                mb={20}
            />
            <div
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        </Wrapper>
    );
};

export default JobOfferRequirement;
