export const formatCurrency = (amount: number, currency: string = 'USD', locale: string = 'en'): string => {
    try {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        }).format(amount);
    } catch (error) {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'USD',
        }).format(0);
    }
};
