import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
// import { useTheme } from 'styled-components';

// atoms
import { SwiperLoading } from 'components/atoms';

// molecules
import { Section, DestinationCard, ScrollCard } from 'components/molecules';

// data
import { contact, promotion } from 'config/banners';

// Schemas
import { BANNERS_QUERY } from '@/graphql/queries';

// organisms
import Banner from '../Banner';
import Advertising from '../Advertising';

import { CardsContainerUL } from './Destinations.styles';

const Destinations = ({ destinations }) => {
    // Hooks
    const { t, i18n } = useTranslation(); // Translations
    // const theme = useTheme(); // Get current theme
    const [searchRecently, setSearchRecently] = React.useState([]); // state destiny search recently
    const [viewRecently, setViewRecently] = React.useState([]); // state destiny view recently

    // Banners
    const { loading: loadingBanners, data: bannersData } = useQuery(BANNERS_QUERY, {
        variables: {
            report: true,
        },
        context: { headers: { Language: i18n.language } },
        fetchPolicy: 'cache-and-network',
    });

    React.useEffect(() => {
        const searches = JSON.parse(localStorage.getItem('search-destiny'));
        const views = JSON.parse(localStorage.getItem('view-destiny'));
        setSearchRecently(searches);
        setViewRecently(views);
    }, []);

    if (loadingBanners) return <SwiperLoading />;

    return (
        <>
            {searchRecently && (
                <Section id="search_recently" title={t('destinations:search-recently')} bgcolor="white">
                    <CardsContainerUL>
                        {searchRecently.map((card) => (
                            <DestinationCard key={card.id} card={card} type="search" />
                        ))}
                    </CardsContainerUL>
                </Section>
            )}
            {viewRecently && (
                <Section id="view_recently" title={t('destinations:section1.title')} bgcolor="white">
                    <ScrollCard $mt="4px">
                        {viewRecently.map((card) => (
                            <DestinationCard key={card.id} card={card} stored />
                        ))}
                    </ScrollCard>
                </Section>
            )}
            {destinations && (
                <Section id="destiny_moment" title={t('destinations:section2.title')} bgcolor="white" tb="6px">
                    <ScrollCard>
                        {destinations.map((card) => (
                            <DestinationCard key={card.id} card={card} stored />
                        ))}
                    </ScrollCard>
                </Section>
            )}
            {contact && <Banner type="contact" data={contact} />}
            {bannersData?.banners?.objects?.length > 0 && (
                <Section id="whats-new" title={t('whats-new:section.title')} bg="variant1" bgcolor="white">
                    <Advertising banners={bannersData?.banners?.objects} />
                </Section>
            )}
            {promotion && <Banner type="promotion" data={promotion} />}
        </>
    );
};

Destinations.propTypes = {
    destinations: PropTypes.array,
};

export default Destinations;
