import { SEOConfigType } from "./types";

const config: SEOConfigType  = {
    local: {
        es: {
            title: 'Chagod Tour',
            titleTemplate: '%s | Chagod Tour',
            description:
                'Somos una empresa joven con la visión de ser la tour-operadora de preferencia en la región. Nuestra misión es que encuentres con nosotros las mejores ofertas de paquetes turísticos, pasajes, casas y autos en renta de los países de la región.',
            canonical: 'https://localhost:3000/es',
            openGraph: {
                type: 'website',
                title: 'Chagod Tour',
                description:
                    'Somos una empresa joven con la visión de ser la tour-operadora de preferencia en la región. Nuestra misión es que encuentres con nosotros las mejores ofertas de paquetes turísticos, pasajes, casas y autos en renta de los países de la región.',
                url: 'https://localhost:3000/',
                site_name: 'Chagod Tour',
                images: [
                    {
                        url: 'https://localhost:3000/images/og-big.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Chagod Tour',
                    },
                ],
            },
            twitter: {
                handle: '@twitter_user',
                site: '@twitter_user',
                cardType: 'summary_large_image',
            },
        },
        en: {
            title: 'Chagod Tour',
            titleTemplate: '%s | Chagod Tour',
            description:
                'We are a young company but with the vision of being the preferred tour operator in the region. Our mission is that you find with us the best offers of tourist packages, tickets, houses and cars for rent in the countries of the region.',
            canonical: 'http://localhost:3000/en',
            openGraph: {
                type: 'website',
                title: 'Chagod Tour',
                description:
                    'We are a young company but with the vision of being the preferred tour operator in the region. Our mission is that you find with us the best offers of tourist packages, tickets, houses and cars for rent in the countries of the region.',
                url: 'http://localhost:3000/',
                site_name: 'Chagod Tour',
                images: [
                    {
                        url: 'http://localhost:3000/images/og-big.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Chagod Tour',
                    },
                ],
            },
            twitter: {
                handle: '@twitter_user',
                site: '@twitter_user',
                cardType: 'summary_large_image',
            },
        },
    },
    development: {
        es: {
            title: 'Chagod Tour',
            titleTemplate: '%s | Chagod Tour',
            description:
                'Somos una empresa joven con la visión de ser la tour-operadora de preferencia en la región. Nuestra misión es que encuentres con nosotros las mejores ofertas de paquetes turísticos, pasajes, casas y autos en renta de los países de la región.',
            canonical: 'https://staging.chagodtour.travel/',
            openGraph: {
                type: 'website',
                title: 'Chagod Tour',
                description:
                    'Somos una empresa joven con la visión de ser la tour-operadora de preferencia en la región. Nuestra misión es que encuentres con nosotros las mejores ofertas de paquetes turísticos, pasajes, casas y autos en renta de los países de la región.',
                url: 'https://staging.chagodtour.travel/',
                site_name: 'Chagod Tour',
                images: [
                    {
                        url: 'https://staging.chagodtour.travel/images/og-big.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Chagod Tour',
                    },
                ],
            },
            twitter: {
                handle: '@twitter_user',
                site: '@twitter_user',
                cardType: 'summary_large_image',
            },
        },
        en: {
            title: 'Chagod Tour',
            titleTemplate: '%s | Chagod Tour',
            description:
                'We are a young company but with the vision of being the preferred tour operator in the region. Our mission is that you find with us the best offers of tourist packages, tickets, houses and cars for rent in the countries of the region.',
            canonical: 'https://staging.chagodtour.travel/',
            openGraph: {
                type: 'website',
                title: 'Chagod Tour',
                description:
                    'We are a young company but with the vision of being the preferred tour operator in the region. Our mission is that you find with us the best offers of tourist packages, tickets, houses and cars for rent in the countries of the region.',
                url: 'https://staging.chagodtour.travel/',
                site_name: 'Chagod Tour',
                images: [
                    {
                        url: 'https://staging.chagodtour.travel/images/og-big.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Chagod Tour',
                    },
                ],
            },
            twitter: {
                handle: '@twitter_user',
                site: '@twitter_user',
                cardType: 'summary_large_image',
            },
        },
    },
    production: {
        es: {
            title: 'Chagod Tour',
            titleTemplate: '%s | Chagod Tour',
            description:
                'Somos una empresa joven con la visión de ser la tour-operadora de preferencia en la región. Nuestra misión es que encuentres con nosotros las mejores ofertas de paquetes turísticos, pasajes, casas y autos en renta de los países de la región.',
            canonical: 'https://chagodtour.travel/',
            openGraph: {
                type: 'website',
                title: 'Chagod Tour',
                description:
                    'Somos una empresa joven con la visión de ser la tour-operadora de preferencia en la región. Nuestra misión es que encuentres con nosotros las mejores ofertas de paquetes turísticos, pasajes, casas y autos en renta de los países de la región.',
                url: 'https://chagodtour.travel/',
                site_name: 'Chagod Tour',
                images: [
                    {
                        url: 'https://chagodtour.travel/images/og-big.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Chagod Tour',
                    },
                ],
            },
            twitter: {
                handle: '@twitter_user',
                site: '@twitter_user',
                cardType: 'summary_large_image',
            },
        },
        en: {
            title: 'Chagod Tour',
            titleTemplate: '%s | Chagod Tour',
            description:
                'We are a young company but with the vision of being the preferred tour operator in the region. Our mission is that you find with us the best offers of tourist packages, tickets, houses and cars for rent in the countries of the region.',
            canonical: 'https://chagodtour.travel/',
            openGraph: {
                type: 'website',
                title: 'Chagod Tour',
                description:
                    'We are a young company but with the vision of being the preferred tour operator in the region. Our mission is that you find with us the best offers of tourist packages, tickets, houses and cars for rent in the countries of the region.',
                url: 'https://chagodtour.travel/',
                site_name: 'Chagod Tour',
                images: [
                    {
                        url: 'https://chagodtour.travel/images/og-big.jpg',
                        width: 800,
                        height: 600,
                        alt: 'Chagod Tour',
                    },
                ],
            },
            twitter: {
                handle: '@twitter_user',
                site: '@twitter_user',
                cardType: 'summary_large_image',
            },
        },
    },
};

export default config;
