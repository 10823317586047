export const removePTagAndBR = (htmlString: string): string => {
    let trimmedHTML = htmlString.trim();

    if (trimmedHTML.startsWith('<p>')) {
        trimmedHTML = trimmedHTML.slice('<p>'.length);
    }

    if (trimmedHTML.endsWith('</p>')) {
        trimmedHTML = trimmedHTML.slice(0, -'</p>'.length);
    }

    if (trimmedHTML.endsWith('<br>')) {
        trimmedHTML = trimmedHTML.slice(0, -'<br>'.length);
    }

    return trimmedHTML;
};
