import React from 'react';
import Image from 'next/image';
import { useTheme } from 'styled-components';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import UrlMapper from '@/config/url-mapper';
import { useModal } from '@/modules/modal';
import { Icon, Typography } from '@/components/atoms';
import { AdvertisingProps } from './Advertising.types';
import {
    AdvertisingDetails,
    AdvertisingSwiperDetailsGlobalStyles,
    AdvertisingSwiperGlobalStyles,
    ContentWrapper,
    IconsContainer,
    IconWrapper,
    ImageContainer,
    Slideshow,
} from './Advertising.styles';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ModalBannerDetails: React.FC<AdvertisingProps & { init_selected: number }> = ({ advertising, init_selected }) => {
    const theme = useTheme(); // Get current theme

    // const [iconsHover, setIconsHover] = React.useState(false);
    const [selected, setSelected] = React.useState(init_selected);
    const swiperRef = React.useRef<SwiperRef | null>(null);

    const handleClick = (index: number) => {
        setSelected(index);
        swiperRef.current?.swiper?.slideTo(index);
    };

    return (
        advertising[selected] && (
            <Slideshow>
                <AdvertisingDetails>
                    <ImageContainer>
                        <Image
                            src={advertising[selected]?.image?.url as string}
                            alt={advertising[selected]?.image?.description as string}
                            priority
                            fill
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                        />
                    </ImageContainer>
                    <ContentWrapper>
                        <Typography
                            variant="body1"
                            color={theme.colors.basic.white}
                            align="left"
                            dangerouslySetInnerHTML={{
                                __html: advertising[selected]?.description as string,
                            }}
                        />
                        <IconsContainer>
                            <IconWrapper as="a" href={UrlMapper.whatsapp_agents} rel="noopener noreferrer" target="_blank">
                                <Icon.Whatsapp size={28} color={theme.colors.neutral.neutral10} strokeWidth={1.5} />
                            </IconWrapper>
                        </IconsContainer>
                    </ContentWrapper>
                </AdvertisingDetails>
                {/* Thumbs sliders */}
                <AdvertisingSwiperDetailsGlobalStyles />
                <Swiper
                    ref={swiperRef}
                    slidesPerView={5}
                    spaceBetween={15}
                    centeredSlides
                    navigation
                    onSlideChange={(swiper) => setSelected(swiper.activeIndex)}
                    modules={[Navigation]}
                    initialSlide={init_selected}
                    // Classes
                    className="AdvertisingDetailThumbsSwiper"
                >
                    {advertising?.map((item, index) => {
                        return (
                            item?.image && (
                                <SwiperSlide key={item.id}>
                                    <Image
                                        src={item?.image.url}
                                        alt={item?.image.description as string}
                                        fill
                                        style={{ objectPosition: 'center center' }}
                                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                        onClick={() => handleClick(index)}
                                    />
                                </SwiperSlide>
                            )
                        );
                    })}
                </Swiper>
            </Slideshow>
        )
    );
};

const Advertising: React.FC<AdvertisingProps> = ({ advertising }) => {
    const { setModal } = useModal();

    const handleDetails = (index: number) => {
        setModal(<ModalBannerDetails advertising={advertising} init_selected={index} />);
    };

    if (!advertising) return null;

    return (
        <>
            <AdvertisingSwiperGlobalStyles />
            <Swiper
                // Modules
                modules={[Autoplay, Pagination, Navigation]}
                // Navigation
                navigation
                // Pagination
                pagination={{
                    clickable: false,
                }}
                // Loop
                loop
                // Autoplay
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                // Center slides
                centeredSlides
                centeredSlidesBounds
                centerInsufficientSlides
                // Breakpoints
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: -30,
                    },
                    992: {
                        slidesPerView: 2,
                        spaceBetween: -30,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: -50,
                    },
                    1400: {
                        slidesPerView: 3,
                        spaceBetween: -30,
                    },
                }}
                // Touch
                allowTouchMove={false}
                // Speed
                speed={500}
                // Classname
                className="AdvertisingSwiper"
            >
                {advertising?.map((item, index) => {
                    return (
                        item?.image && (
                            <SwiperSlide key={item.id}>
                                <Image
                                    src={item.image.url}
                                    alt={item?.name as string}
                                    fill
                                    priority
                                    style={{ objectPosition: 'center center' }}
                                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    onClick={() => handleDetails(index)}
                                />
                            </SwiperSlide>
                        )
                    );
                })}
            </Swiper>
        </>
    );
};

export default Advertising;
