const UrlMapper = {
    homepage: '/',
    flights: '/flights',
    destinations: '/destinations',
    destination_details: '/destinations/[slug]',
    about_us: '/about-us',
    job_offers: '/jobs',
    job_offers_apply: '/jobs/apply/[slug]',
    login: '/login',
    signup: '/signup',
    signup_verification: '/signup/verification',
    activate_account_callback: `${process.env.NEXT_PUBLIC_DOMAIN_URL}signup/activate`,
    forgot_password: '/forgot-password',
    forgot_password_callback: `${process.env.NEXT_PUBLIC_DOMAIN_URL}forgot-password`,
    forgot_password_verification: '/forgot-password/verification',
    recover_success: '/forgot-password/success',
    document_verification: '/verification',
    document_verification_download: '/verification/download/[downloadId]',
    payment_verification: '/pay-verification',
    payment_verification_download: '/pay-verification/download/[downloadId]',
    profile: '/profile',
    cookie_policy: '/cookies-policy',
    privacy_policy: '/privacy-policy',
    return_policy: '/return-policy',
    terms_and_conditions: '/terms-and-conditions',
    whatsapp_agents: 'https://wa.me/18763206269',
    error_403: '/403',
    error_404: '/404',
    error_500: '/500',
};

export default UrlMapper;
