import styled, { css } from 'styled-components';

export const Item = styled.li.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant: string;
}>`
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 58px;
    width: 100%;

    // filled
    ${({ variant }) =>
        variant === 'filled' &&
        css`
            border-radius: 8px;

            &:hover {
                background-color: ${({ theme }) => theme.colors.primary.primary80};
            }

            &:active {
                background-color: ${({ theme }) => theme.colors.primary.primary60};
            }
        `} // outlined

    ${({ variant }) =>
        variant === 'outlined' &&
        css`
            border: 1px solid ${({ theme }) => theme.colors.neutral.neutral80};

            &:hover {
                background-color: ${({ theme }) => theme.colors.primary.primary90};
            }

            &:active {
                background-color: ${({ theme }) => theme.colors.primary.primary80};
            }
        `}
`;

export const WrapperText = styled.div`
    display: flex;
    padding: 0;
    gap: 8px;
    flex-grow: 1;
`;

export const IconContainer = styled.span`
    width: 20px;
    display: flex;
    justify-content: center;
`;

export const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
`;
