import styled from 'styled-components';

export const Container = styled.div.withConfig({
    shouldForwardProp: (prop) => !['width'].includes(prop),
})<{ width: number | string }>`
    height: 10px;
    width: ${({ width }) => typeof width === 'number' ? `${width}px` : width || '100%'};
    background: ${({ theme }) => theme.colors.basic.white};
    border-radius: 6px;
`;


export const Progress = styled.div.withConfig({
    shouldForwardProp: (prop) => !['progress'].includes(prop),
})<{ progress: number }>`
    height: 100%;
    width: ${({ progress }) => `${progress}%`};
    background: ${({ theme }) => theme.colors.primary.primary50};
    border-radius: 6px;
`;
