import { gql } from '@apollo/client';
import { ERRORS_FRAGMENT, PAGE_INFO_FRAGMENT } from "@/graphql/fragments";

export const FAQS_QUERY = gql`
    ${ERRORS_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
    query Faqs($skip: Int, $take: Int, $report: Boolean, $searchFields: [FAQSearchFieldInput], $sortFields: FAQSortFieldInput) {
        faqs(report: $report, skip: $skip, take: $take, searchFields: $searchFields, sortFields: $sortFields) {
            status
            errors {
                ...ErrorType
            }
            pageInfo {
                ...PageInfoType
            }
            objects {
                id
                question
                response
            }
        }
    }
`;
