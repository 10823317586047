import styled from 'styled-components';
import { ButtonProps } from '@/components/atoms/Button/Button.types';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['playVideo'].includes(prop),
})<{ playVideo: boolean }>`
    background-color: ${({ theme }) => theme.colors.basic.white};
    overflow: hidden;
    border-radius: 25px;
    height: 327px;
    transition: all 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 10px 15px;

    ${({ theme }) => theme.media.xl`
       flex-basis: 50%;
       margin: 0;
    `}
    img {
        transition: all 0.5s ease-in-out;

        &:hover {
            filter: brightness(0.8);
        }
    }

    svg {
        transition: all 0.2s ease-in-out;
        position: absolute;
        cursor: pointer;
        z-index: 999;
        opacity: ${({ playVideo }) => (!playVideo ? '1' : '0')};

        &:hover {
            stroke: ${({ theme }) => theme.colors.primary.primary50};
            fill: ${({ theme }) => theme.colors.primary.primary50};

            & + span img {
                filter: brightness(0.8);
            }
        }
    }

    video {
        transition: all 0.5s ease-in-out;
        opacity: ${({ playVideo }) => (playVideo ? '1' : '0')};
        z-index: ${({ playVideo }) => (playVideo ? '9999' : '-1')};
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

export const Video = styled.video.withConfig({
    shouldForwardProp: (prop) => ![''].includes(prop),
})``;
