import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-flow: column;

    a {
        color: ${({ theme }) => theme.colors.neutral.neutral10};
        font-weight: 500;
    }
`;

export const ImageWrapper = styled.figure`
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;

    ${({ theme }) => theme.media.md`
        width: 240px;
        height: 240px;
    `}
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;
