import styled, { css } from 'styled-components';

export const Header = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    gap: 20px;

    ${({ theme }) => theme.media.md`
         flex-flow: row;
    `}
`;

export const Row = styled.div`
    display: flex;
    flex-flow: column;

    ${({ theme }) => theme.media.md`
         flex-flow: row;
    `}
`;

export const Item = styled.div`
    display: inline-block;
    padding: 0.5rem 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral.neutral10};

    ${({ theme }) => theme.media.md`
        padding: 0 0.5rem;
        border-bottom: 0;
        border-right: 2px solid ${theme.colors.neutral.neutral10};

        &:last-child {
            border-right: none;
        }
    `}

    span {
        padding: 0 5px;
    }

    & svg {
        margin: 0 0.3rem;
        translate: 0 3px;
    }
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['showContent'].includes(prop),
})<{ showContent?: boolean }>`
    position: relative;
    max-height: 0;
    padding: 0;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    background-color: ${({ theme }) => theme.colors.primary.primary90};

    section {
        transition: all 1.5s ease-in-out;
        opacity: 0;
    }

    ${({ showContent }) =>
        showContent &&
        css`
            opacity: 1;
            padding: 0;
            max-height: 500px;

            section {
                transition: all 1s ease-in-out;
                opacity: 1;
            }
        `}
`;
