import { gql } from "@apollo/client";

export const SOCIAL_MEDIA_FRAGMENT = gql`
    fragment SocialMediaType on SocialMediaType {
        facebook
        instagram
        linkedin
        telegram
        pinterest
        youtube
    }
`;
