import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Section } from '@/components/molecules';
import NewslettersForm from './NewslettersForm';
import { ImageSection, ModalContent } from './NewslettersForm.styles';

// Blur color
const blurColor = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8ctq/DwAHJwKOx8gB0wAAAABJRU5ErkJggg==';

const ModalNewsletters: React.FC = () => {
    const { t } = useTranslation(); // Translations

    return (
        <ModalContent>
            <Section id="newsletters" title={t('newsletter:section.title')} description={t('newsletter:section.description')} bgcolor="default" container="compacted" type="modal">
                <NewslettersForm type="modal" />
            </Section>
            <ImageSection>
                <Image
                    src="/images/couple-beach.jpg"
                    alt={t('newsletter:section.title')}
                    fill
                    priority
                    style={{ objectFit: 'cover', objectPosition: 'center center' }}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    placeholder="blur"
                    blurDataURL={blurColor}
                />
            </ImageSection>
        </ModalContent>
    );
};

export default ModalNewsletters;
