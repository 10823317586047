import React from 'react';
import { ListItemLarge, Map } from '@/components/molecules';
import { HowToFindUsProps } from './HowToFindUs.types';
import { ContactInfo, LinkInfo, Wrapper } from './HowToFindUs.styles';

const HowToFindUs: React.FC<HowToFindUsProps> = ({ location, info }) => {
    return (
        <Wrapper>
            <Map latitude={location.latitude} longitude={location.longitude} height="371px" />

            <ContactInfo>
                {info.map((item) => {
                    return item.key === 'phones' || item.key === 'mail' || item.key === 'address' ? (
                        <LinkInfo
                            key={item.key}
                            href={item.key === 'phones' ? `tel:${item.text}` : item.key === 'mail' ? `mailto:${item.text}` : item.url}
                            target={item.key === 'address' ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                        >
                            <ListItemLarge id={item.key} variant="filled" icon={item.icon} primaryText={item.text} secondaryText={item.title} link />
                        </LinkInfo>
                    ) : (
                        <ListItemLarge key={item.key} id={item.key} variant="filled" icon={item.icon} primaryText={item.text} secondaryText={item.title} />
                    );
                })}
            </ContactInfo>
        </Wrapper>
    );
};

export default HowToFindUs;
