import styled from 'styled-components';
import { fadeIn } from '@/styles/animations';

export const Table = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

export const TableRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};

    &:first-of-type {
        margin-top: 34px;
        border-top: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};
    }

    ${({ theme }) => theme.media.xxl`
        width: 90%;
    `}
`;

export const TableContent = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 100%;
    cursor: pointer;

    ${({ theme }) => theme.media.lg`
        grid-template-columns: 30% 30% 20% 20%;
    `}

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
`;

export const Chevron = styled.span`
    color: ${({ theme }) => theme.colors.neutral.neutral10};

    svg {
        position: relative;
        top: 4px;
    }
`;

export const AlternativeDialog = styled.div`
    padding-top: 31px;
    max-width: 600px;
    animation: ${fadeIn} 1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
        margin: 0 auto 20px;
        stroke: ${({ theme }) => theme.colors.primary.primary50};
    }
`;
