import styled, { createGlobalStyle } from 'styled-components';
import { fadeIn } from '@/styles/animations';

export const SwiperGlobalStyles = createGlobalStyle`
  .PackagesSwiper.swiper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 0 20px 0;
  }

  .PackagesSwiper.swiper.showOnly2 .swiper-slide:first-child {
    justify-content: flex-end;
  }

  .PackagesSwiper.swiper.showOnly2 .swiper-slide:last-child {
    justify-content: flex-start;
  }

  .PackagesSwiper .swiper-slide {
    display: flex;
    justify-content: center;
    position: relative;
    animation: ${fadeIn} 1s ease-in-out;
  }

  .PackagesSwiper .swiper-button-next,
  .PackagesSwiper .swiper-button-prev {
    color: ${({ theme }) => theme.colors.primary.primary10};
    background-color: ${({ theme }) => theme.colors.primary.primary50};
    border-radius: 50%;
    width: 64px;
    height: 64px;
    top: calc(50% - 64px / 2);
    margin: 0;
  }

  .PackagesSwiper .swiper-button-disabled {
    display: none;
  }

  .PackagesSwiper .swiper-button-next {
    right: -30px;
  }

  .PackagesSwiper .swiper-button-prev {
    left: -30px;
  }

  .PackagesSwiper .swiper-button-next:after {
    margin-left: -22px;
  }

  .PackagesSwiper .swiper-button-prev:after {
    margin-left: 22px;
  }

  .PackagesSwiper .swiper-button-prev:after,
  .PackagesSwiper .swiper-button-next:after {
    font-size: 18px;
    font-weight: 900;
  }
`;

export const Card = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const CardImage = styled.figure`
    width: 100%;
    max-height: 280px;
    aspect-ratio: 1 / 1;
    position: relative;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    img {
        border-radius: 20px;
    }
`;

export const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    height: 140px;
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%);
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    cursor: default;

    a span {
        color: ${({ theme }) => theme.colors.tertiary.tertiary50};
        display: flex;
        align-items: center;
        padding: 10px 0;

        &:hover {
            color: ${({ theme }) => theme.colors.tertiary.tertiary70};
        }
    }
`;

export const Service = styled.div`
    display: flex;

    i,
    svg {
        width: 20px;
        height: 20px;
        margin: 0 8px 0 0;
        position: relative;
        top: 2px;
    }
`;

export const Price = styled.span`
    display: flex;
    flex-direction: column;
`;
