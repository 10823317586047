import React from 'react';
import * as FeatherIcon from 'react-feather';
import AvatarIcon from 'assets/images/avatar-icon.svg';
import CheckedIcon from 'assets/images/checked-icon.svg';
import { useTheme } from 'styled-components';
import { ChipProps } from './Chip.types';
import { ChipTag, LeftSide, RightSide } from './Chip.styles';

const Chip: React.FC<ChipProps> = ({ id, type, content, color, outLineColor, elevated, disabled, icon, iconFilter, selected, onClick, handleClose, display }) => {
    const theme = useTheme(); // Get current theme
    let iconShowed = icon;
    if (type === 'input' && !icon) iconShowed = selected ? <CheckedIcon /> : <AvatarIcon />;
    if (type === 'filter' && icon) iconShowed = selected ? <FeatherIcon.Check size={24} /> : icon;

    return (
        <ChipTag id={id} type={type} color={color} outLineColor={outLineColor} elevated={elevated} disabled={disabled} selected={selected} display={display} onClick={onClick}>
            <LeftSide>{iconShowed}</LeftSide>
            {content}
            {type === 'input' && (
                <RightSide
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <FeatherIcon.X size={24} color={theme.colors.neutral.neutral10} strokeWidth={2} />
                </RightSide>
            )}
            {type === 'filter' && iconFilter && (
                <RightSide>
                    <FeatherIcon.ChevronDown size={24} color={theme.colors.neutral.neutral10} strokeWidth={2} onClick={handleClose} />
                </RightSide>
            )}
        </ChipTag>
    );
};

Chip.defaultProps = {
    type: 'suggestion',
    content: 'Suggestion chip',
    color: 'filled',
    outLineColor: '',
    elevated: false,
    disabled: false,
    iconFilter: true,
};

export default Chip;
