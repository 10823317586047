import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { toHoursAndMinutes } from 'utils/helpers';
// import { findAirline } from 'graphql/queries';

// Icons
import Departure from 'assets/images/flight-departure.svg';
import Arrival from 'assets/images/flight-arrival.svg';
// import Copa from 'assets/images/copa.svg';

// Atoms
import { Typography, Button } from 'components/atoms';

// Hooks
import { useModal } from '@/modules/modal';
import { useBreadIndex, useSearchData } from 'hooks/PersistedStateFlights';

// Styles
import { SubFrame2, SubFrame3, IconContainer, TextContainer, SubFrame4, Divider, Stop } from './FlightModalCard.styles';

const FlightModalCard = ({ scale, scaleDuration, rest }) => {
    // Hooks
    const { t } = useTranslation(); // Translations

    const [breadIndex, setBreadIndex] = useBreadIndex(); // persistent state index
    const [searchData] = useSearchData(); // persistent state of search data
    const { unSetModal } = useModal(); // Modal

    // State
    // const [departureSummary] = useDepartureSummary(); // persistent state flights summary
    // const [arrivalSummary] = useArrivalSummary(); // persistent state arrival summary
    // const [listAirport] = useListAirport(); // persistent state list airport
    // const [originSegment, setOriginSegment] = React.useState({}); // state object origin segment
    // const [destinySegment, setDestinySegment] = React.useState({}); // state object destiny segment
    // const [airline, setAirline] = React.useState(''); // state object destiny segment
    // const [breadIndex] = useBreadIndex(); // persistent state index

    // const flightSummary = breadIndex === 1 ? departureSummary : arrivalSummary;
    // const { arrival, carrier, departure, elapsedTime } = scale;
    const { departingDatetime, arrivingDatetime, flightNumber, departingCity, arrivingCity } = scale;
    const [flightHour, flightMin] = rest;

    // React.useEffect(() => {
    //     const resultOrigin = listAirport?.find((location) => location.AirportCode === departure.airport);
    //     setOriginSegment(resultOrigin);

    //     const resultDestiny = listAirport?.find((location) => location.AirportCode === arrival.airport);
    //     setDestinySegment(resultDestiny);
    // }, [listAirport]);

    // React.useEffect(() => {
    //     const getAirlineName = async () => {
    //         const result = await findAirline(carrier.marketing);
    //         setAirline(result.AirlineName);
    //     };
    //     getAirlineName();
    // }, []);

    const departureTime = departingDatetime.substring(11, 16);
    const arrivalTime = arrivingDatetime.substring(11, 16);
    // const time = toHoursAndMinutes(arrivingDatetime);
    // const timeScaleDisplay = toHoursAndMinutes(arrivingDatetime);

    const timeScale = toHoursAndMinutes(scaleDuration);
    const [timeScaleHour, timeScaleMin] = timeScale;

    return (
        <SubFrame2>
            <SubFrame2>
                <SubFrame3>
                    <IconContainer>
                        <Departure />
                    </IconContainer>
                    <TextContainer>
                        <Typography type="sub1" $size="16px">
                            {`${departureTime} - ${departingCity?.name}, ${departingCity?.country.name}`}
                        </Typography>
                        {/* <Typography type="body2">{`${originSegment?.AirportName} (${originSegment?.AirportCode})`}</Typography> */}
                    </TextContainer>
                </SubFrame3>
                <SubFrame4>
                    {/* <SubFrame3>
                        <Copa />
                        <Typography type="body2">
                            {airline} {`· ${t('flights:flights')} ${carrier}`}
                        </Typography>
                    </SubFrame3> */}
                    <Typography type="body2">
                        {t('flights:flight.number')} {flightNumber}
                    </Typography>
                    {/* <Typography type="body2">Económica</Typography> */}
                    <Typography type="body2">
                        {flightHour > 0 ? `${flightHour}h` : ''} {flightMin > 0 ? `${flightMin}min` : ''}
                    </Typography>
                </SubFrame4>
                <SubFrame3>
                    <IconContainer>
                        <Arrival />
                    </IconContainer>
                    <TextContainer>
                        <Typography type="sub1" $size="16px">
                            {`${arrivalTime} - ${arrivingCity?.name}, ${arrivingCity?.country.name}`}
                        </Typography>
                        {/* <Typography type="body2">{`${destinySegment?.AirportName} (${destinySegment?.AirportCode})`}</Typography> */}
                    </TextContainer>
                </SubFrame3>
            </SubFrame2>
            {(timeScaleHour > 0 || timeScaleMin > 0) && (
                <>
                    <Divider />
                    <Stop>
                        <Typography type="sub2">
                            {t('flights:filter.scale')} {timeScaleHour > 0 ? `${timeScaleHour}h` : ''} {timeScaleMin > 0 ? `${timeScaleMin}min` : ''}
                        </Typography>
                        <Typography type="body2">{`${arrivingCity?.name}, ${arrivingCity?.country.name}`}</Typography>
                    </Stop>
                    <Divider />
                </>
            )}

            <Button
                content={t('flights:btn.select')}
                width="100%"
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    const cont = searchData.returnDate ? 1 : 2;
                    setBreadIndex(breadIndex + cont);
                    unSetModal();
                }}
            />
        </SubFrame2>
    );
};

FlightModalCard.propTypes = {
    scale: PropTypes.object,
    scaleDuration: PropTypes.object,
    rest: PropTypes.object,
    // timeScale: PropTypes.number,
};

export default FlightModalCard;
