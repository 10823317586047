import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    ${({ theme }) => theme.media.md`    
        flex-direction: row; 
        gap: 20px;  
    `}
`;

export const TitleContainer = styled.div`
    width: 100%;

    margin: 0px 0px 20px 0px;
`;

export const ListItems = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0;
    margin: 0;
    width: 100%;
`;

export const Item = styled.li`
    display: flex;
    gap: 8px;
    padding: 0;
    margin: 0;
`;

export const IconContainer = styled.span`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Frame2 = styled.div`
    width: 100%;
    margin-top: 10px;
`;
