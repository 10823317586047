import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding-bottom: 28px;
    margin-bottom: 37px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.neutral90};

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Column = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
`;
