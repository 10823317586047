import styled from 'styled-components';

export const WrapperFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 28px 20px 20px;
    gap: 20px;
    position: relative;
    background-color: ${({ theme }) => theme.colors.neutral.neutral96};
    overflow-y: scroll;
    height: 100vh;
`;

export const Header = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

export const SubFrame1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 2px;
`;

export const FrameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 20px;

    ${({ theme }) => theme.media.lg`
       flex-direction: row;
       align-self: stretch;
       align-items: flex-start;
       max-height: 726px;
    `}
`;

export const FlightSummary = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    max-width: 380px;
    height: 100%;
    overflow: auto;
    gap: 28px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.basic.white};
`;

export const Fare = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border-radius: 12px;
    align-self: stretch;
    background-color: ${({ theme }) => theme.colors.basic.white};
`;
