import React from 'react';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import * as FeatherIcon from 'react-feather';
import { useTranslation } from 'next-i18next';
import range from 'lodash/range';
import { Textfield } from '@/components/atoms';
import type { TextfieldProps } from '@/components/atoms/Textfield';
import { MONTH_NAMES } from './Datepicker.constants';
import { DatepickerProps } from './Datepicker.types';
import {
    DateContainer,
    ButtonHeader,
    Cell,
    Chevron,
    Column,
    ContainerSelect,
    ContHeader,
    IconCell,
    TextSelect,
    WrapperColumn,
    WrapperSelect,
    YearMonthSelect,
} from './Datepicker.styles';

export const years = range(1920, getYear(new Date()) + 15, 1);

const ForwardRefCustomInput = React.forwardRef<HTMLInputElement | null, TextfieldProps>((props, innerRef) => <Textfield {...props} innerRef={innerRef} />);

ForwardRefCustomInput.displayName = 'Datepicker';

const Datepicker: React.FC<DatepickerProps> = ({
    id,
    tabIndex,
    dateFormat,
    label,
    placeholder,
    selected,
    minDate,
    maxDate,
    leftIcon,
    rightIcon,
    borderRadius,
    widthInput,
    widthContainer,
    mb,
    height,
    startDate,
    endDate,
    help,
    errors,
    selectsStart,
    selectsEnd,
    success,
    disabled,
    onChange,
    onBlur,
    prefix,
    suffix,
    showClearIcon,
    onClearIcon,
    includeDates,
    ...props
}) => {
    const refDate = React.useRef<HTMLDivElement | null>(null); // Reference
    const inputRef = React.useRef<HTMLInputElement | null>(null); // Reference
    const { i18n } = useTranslation(); // Translations
    const [showSelect, setShowSelect] = React.useState(false);
    const [monthsChange, setMonthsChange] = React.useState<number>(1);
    const [yearChange, setYearChange] = React.useState<number>(2000);
    const [languageMonths, setLanguageMonths] = React.useState<string[]>([]);

    const scrollUpMonths = () => {
        const element = document.getElementById('firstColumn');
        if (element) {
            element.scrollTop -= 180;
        }
    };

    const scrollDownMonths = () => {
        const element = document.getElementById('firstColumn');
        if (element) {
            element.scrollTop += 180;
        }
    };

    const scrollUpYear = () => {
        const element = document.getElementById('secondColumn');
        if (element) {
            element.scrollTop -= 180;
        }
    };

    const scrollDownYear = () => {
        const element = document.getElementById('secondColumn');
        if (element) {
            element.scrollTop += 180;
        }
    };

    const resetClickMonths = () => {
        document.querySelectorAll('.clickMonths').forEach((el) => el.classList.remove('selected'));
    };

    const resetClickYear = () => {
        document.querySelectorAll('.clickYear').forEach((el) => el.classList.remove('selected'));
    };

    const show = (event: MouseEvent | TouchEvent) => {
        // Show false if click outside the popper Datepicker
        if (refDate.current && event.target instanceof Element && !refDate.current.contains(event.target)) {
            setShowSelect(false);
        }
    };

    React.useEffect(() => {
        setLanguageMonths(MONTH_NAMES[i18n.language as LanguageType]);

        // Adding a click event listener
        document.addEventListener('click', show);

        // Removing a click event listener
        return () => document.removeEventListener('click', show);
    }, [i18n.language]);

    React.useEffect(() => {
        const objDivMonths = document.getElementById('firstColumn');
        if (objDivMonths instanceof HTMLElement) {
            const selectedMonthElement = objDivMonths.querySelector('.clickMonths.selected');
            if (selectedMonthElement instanceof HTMLElement) {
                const { top } = selectedMonthElement.getBoundingClientRect();
                objDivMonths.scrollTop = top - 108;
            }
        }

        const objDivYear = document.getElementById('secondColumn');
        if (objDivYear instanceof HTMLElement) {
            const selectedYearElement = objDivYear.querySelector('.clickYear.selected');
            if (selectedYearElement instanceof HTMLElement) {
                const { top } = selectedYearElement.getBoundingClientRect();
                objDivYear.scrollTop = top - 108;
            }
        }
    }, [showSelect]);

    React.useEffect(() => {
        document.querySelectorAll('.clickMonths').forEach((el) => {
            el.addEventListener('click', (e) => {
                const targetElement = e.target as HTMLElement;
                const idMonths = targetElement.getAttribute('id');
                if (idMonths) {
                    resetClickMonths();
                    el.classList.toggle('selected');
                    setMonthsChange(languageMonths.indexOf(idMonths));
                }
            });
            el.removeEventListener('click', () => {});
        });

        document.querySelectorAll('.clickYear').forEach((elem) => {
            elem.addEventListener('click', (e) => {
                const targetElement = e.target as HTMLElement;
                const idYear = targetElement.getAttribute('id');
                if (idYear) {
                    resetClickYear();
                    elem.classList.toggle('selected');
                    setYearChange(parseInt(idYear));
                }
            });
            elem.removeEventListener('click', () => {});
        });
    }, [languageMonths]);

    return (
        <DateContainer width={widthContainer} height={height}>
            <DatePicker
                dateFormat={dateFormat}
                selected={!disabled ? selected : null}
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                className={`${selected ? 'edited' : ''}`}
                placeholderText={placeholder}
                disabled={disabled}
                startDate={startDate}
                endDate={endDate}
                selectsStart={selectsStart}
                selectsEnd={selectsEnd}
                maxDate={maxDate}
                minDate={minDate}
                includeDates={includeDates}
                locale={i18n.language}
                customInput={
                    <ForwardRefCustomInput
                        innerRef={inputRef}
                        type="text"
                        tabIndex={tabIndex}
                        leftIcon={leftIcon}
                        rightIcon={rightIcon}
                        label={label}
                        borderRadius={borderRadius}
                        widthInput={widthInput}
                        mb={mb}
                        help={help}
                        errors={errors}
                        success={success}
                        disabled={disabled}
                        prefix={prefix}
                        suffix={suffix}
                        showClearIcon={showClearIcon}
                        onClearIcon={onClearIcon}
                        {...props}
                    />
                }
                renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                    <div ref={refDate}>
                        <ContHeader>
                            <ButtonHeader type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <FeatherIcon.ChevronLeft />
                            </ButtonHeader>
                            <WrapperSelect onClick={() => setShowSelect(!showSelect)}>
                                <TextSelect>
                                    {languageMonths[getMonth(date)]} {getYear(date)}
                                </TextSelect>
                                <Chevron opened={showSelect}>
                                    <FeatherIcon.ChevronDown />
                                </Chevron>
                            </WrapperSelect>
                            <ButtonHeader type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <FeatherIcon.ChevronRight />
                            </ButtonHeader>
                        </ContHeader>
                        <ContainerSelect show={showSelect}>
                            <YearMonthSelect>
                                <WrapperColumn>
                                    <IconCell onClick={() => scrollUpMonths()}>
                                        <FeatherIcon.ChevronUp />
                                    </IconCell>
                                    <Column id="firstColumn">
                                        {languageMonths.map((option) => {
                                            if (option === languageMonths[getMonth(date)]) {
                                                return (
                                                    <Cell key={option} id={option} className="clickMonths selected" onClick={() => changeMonth(monthsChange)}>
                                                        {option}
                                                    </Cell>
                                                );
                                            }
                                            return (
                                                <Cell key={option} id={option} className="clickMonths" onClick={() => changeMonth(monthsChange)}>
                                                    {option}
                                                </Cell>
                                            );
                                        })}
                                    </Column>
                                    <IconCell onClick={() => scrollDownMonths()}>
                                        <FeatherIcon.ChevronDown />
                                    </IconCell>
                                </WrapperColumn>
                                <WrapperColumn>
                                    <IconCell onClick={() => scrollUpYear()}>
                                        <FeatherIcon.ChevronUp />
                                    </IconCell>
                                    <Column id="secondColumn">
                                        {years.map((option) => {
                                            if (option === getYear(date)) {
                                                return (
                                                    <Cell key={option} id={option.toString()} className="clickYear selected" onClick={() => changeYear(yearChange)}>
                                                        {option}
                                                    </Cell>
                                                );
                                            }
                                            return (
                                                <Cell key={option} id={option.toString()} className="clickYear" onClick={() => changeYear(yearChange)}>
                                                    {option}
                                                </Cell>
                                            );
                                        })}
                                    </Column>
                                    <IconCell onClick={() => scrollDownYear()}>
                                        <FeatherIcon.ChevronDown />
                                    </IconCell>
                                </WrapperColumn>
                            </YearMonthSelect>
                        </ContainerSelect>
                    </div>
                )}
            />
        </DateContainer>
    );
};

export default Datepicker;
